import React, { useState } from 'react'
import { Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { StyledTableCellOrdenado, StyledTableCellHead, StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { IconDeleteAlarm, IconEditPencil } from 'utils/icon-centrocontrol'
import { ordenarDatos, getUTMCoordinates } from 'utils/Utils'
import './SensorLine.scss'

export const TableConfigSensorLinea = ({ sensores, handleEditSensor, handleDeleteSensor }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })

    return (
        <Table aria-label='customized table'>
            <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                <TableRow>
                    <StyledTableCellOrdenado
                        align='center'
                        celda={'numserie'}
                        funcion={() => {
                            ordenarDatos(sensores, sortConfig, setSortConfig, 'numserie')
                        }}
                        sortConfig={sortConfig}
                    >
                        Número de Serie
                    </StyledTableCellOrdenado>
                    <StyledTableCellOrdenado
                        align='center'
                        celda={'nick'}
                        funcion={() => {
                            ordenarDatos(sensores, sortConfig, setSortConfig, 'nick', null, 'position')
                        }}
                        sortConfig={sortConfig}
                    >
                        Nombre
                    </StyledTableCellOrdenado>
                    <StyledTableCellOrdenado
                        align='center'
                        celda={'nick_medidor_asociado'}
                        funcion={() => {
                            ordenarDatos(sensores, sortConfig, setSortConfig, 'nick_medidor_asociado', null, 'position')
                        }}
                        sortConfig={sortConfig}
                    >
                        Línea asociada
                    </StyledTableCellOrdenado>
                    <StyledTableCellHead align='center' celda={'position'}>
                        Orden Dentro de la Línea
                    </StyledTableCellHead>
                    <StyledTableCellOrdenado
                        align='center'
                        celda={'address'}
                        funcion={() => {
                            ordenarDatos(sensores, sortConfig, setSortConfig, 'address')
                        }}
                        sortConfig={sortConfig}
                    >
                        Dirección
                    </StyledTableCellOrdenado>
                    <StyledTableCellHead align='center' celda={'utm'}>
                        Utm
                    </StyledTableCellHead>
                    <StyledTableCellHead align='center' celda={'acciones'}>
                        Acciones
                    </StyledTableCellHead>
                </TableRow>
            </TableHead>
            <TableBody>
                {sensores?.map((sensor, i) => {
                    return (
                        <StyledTableRow key={i}>
                            <StyledTableCell align='left'>{sensor?.numserie}</StyledTableCell>
                            <StyledTableCell align='left'>{sensor?.nick}</StyledTableCell>
                            <StyledTableCell align='left'>{sensor?.nick_medidor_asociado}</StyledTableCell>
                            <StyledTableCell align='left'>{sensor?.position}</StyledTableCell>
                            <StyledTableCell align='left'>{sensor?.address}</StyledTableCell>
                            <StyledTableCell align='left'>{sensor?.address && getUTMCoordinates(sensor?.lat, sensor?.lng)}</StyledTableCell>
                            <StyledTableCell align='left'>
                                <Grid container columns={15}>
                                    <Grid item xs={2} md={0} lg={0} xl={2} />
                                    <Grid item xs={5} md={15} lg={6} xl={5}>
                                        <ButtonStyledIcon
                                            className='boton-edit-alarm'
                                            title='Editar'
                                            onClick={() => {
                                                handleEditSensor(sensor)
                                            }}
                                        >
                                            <IconEditPencil className='boton-edit-hijo' />
                                        </ButtonStyledIcon>
                                    </Grid>
                                    <Grid item xs={1} md={0} lg={1} xl={1} />
                                    <Grid item xs={5} md={15} lg={6} xl={5}>
                                        <ButtonStyledIcon
                                            className='boton-delete-alarm'
                                            title='Borrar'
                                            onClick={() => {
                                                handleDeleteSensor(sensor)
                                            }}
                                        >
                                            <IconDeleteAlarm className='boton-delete-hijo' />
                                        </ButtonStyledIcon>
                                    </Grid>
                                    <Grid item xs={2} md={0} lg={0} xl={2} />
                                </Grid>
                            </StyledTableCell>
                        </StyledTableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
