import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { updateInstalacion } from 'reduxSlice/instalacionSlice'
import { updateLoading } from 'reduxSlice/loadingSlice'
import { createToast } from 'reduxSlice/toastSlice'

export const ConfiguracionMabtResumen = ({
    cambio,
    dispositivosInstalacion,
    token,
    modalConfigurar,
    setModalConfigurar,
    medidores,
    setMedidores,
    dispositivo,
    instalacion,
    potTrans,
    transCorr,
    address,
    lat,
    lng,
    setCambio
}) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const url = `updateDeviceInfo/${dispositivo?.[0]?.numserie}?idCCMaster=${dispositivo?.[0]?.numserie}`
    const vtn = medidores.find((ele) => ele.vtn)

    const patchMabt = async () => {
        let obj = {}
        if (instalacion !== dispositivo?.[0]?.nick) {
            obj.nick = instalacion
        }
        if (dispositivo?.[0]?.pap_trafo !== potTrans) {
            obj.pap_trafo = potTrans === '' ? null : parseFloat(potTrans)
        } else {
            obj.pap_trafo = dispositivo?.[0]?.pap_trafo
        }
        if (dispositivo?.[0]?.max_iac !== transCorr) {
            obj.max_iac = transCorr === '' ? null : parseFloat(transCorr)
        } else {
            obj.max_iac = dispositivo?.[0]?.max_iac
        }
        if (lat !== dispositivo?.[0]?.latitud) {
            obj.latitud = lat
        }
        if (lng !== dispositivo?.[0]?.longitud) {
            obj.longitud = lng
        }
        if (address !== dispositivo?.[0]?.address) {
            obj.address = address
        }
        if (Object.keys(obj).length !== 0) {
            try {
                const response = await fetch(urls.scada + url, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(obj) })
                if (response.ok) {
                    dispatch(updateInstalacion({ name: instalacion }))
                    dispositivo[0].nick = instalacion
                    dispositivo[0].pap_trafo = obj.pap_trafo
                    dispositivo[0].max_iac = obj.max_iac
                    dispositivo[0].latitud = lat
                    dispositivo[0].longitud = lng
                    dispositivo[0].address = address
                    dispatch(updateLoading())
                    return true
                } else {
                    return false
                }
            } catch (error) {
                console.error('Error en patchMabt:', error)
                return false
            }
        }
    }

    const patchDispositivo = async (medidor) => {
        let url = `updateDeviceInfo/${medidor.numserie}?idCCMaster=${dispositivo[0].numserie}`
        try {
            const response = await fetch(urls.scada + url, {
                method: 'PATCH',
                headers: headersScada(token),
                body: JSON.stringify({ nick: medidor.nick })
            })
            if (response.ok) {
                let dispositivoEncontrado = dispositivosInstalacion.find((ele) => ele.numserie === medidor.numserie)
                dispositivoEncontrado.nick = medidor.nick
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error('Error en patchDispositivo:', error)
            return false
        }
    }

    const onGuardar = async () => {
        setLoading(true)
        let status
        let message
        try {
            const mabtCambio = await patchMabt()
            const medidoresCambio = await Promise.all(medidores.map(patchDispositivo))
            if (mabtCambio && !medidoresCambio.find((ele) => ele === false)) {
                setCambio(false)
                status = 200
                message = 'Cambio realizado correctamente'
                setMedidores([])
                setModalConfigurar(false)
            } else {
                status = 400
                message = 'Error al realizar el cambio'
                setModalConfigurar(false)
            }
            dispatch(createToast({ status: status, message: message, reload: false }))
        } catch (error) {
            console.error('Error al guardar los datos:', error)
        }
        setLoading(false)
    }

    return (
        <Modal open={modalConfigurar} setOpen={setModalConfigurar} title='Guardar cambios' loading={loading} functSave={onGuardar}>
            <hr className='' />
            {cambio && (
                <Grid className='flex-center justify-center flex-columm' item xs={12} md={12} lg={12} xl={12}>
                    <h3>MABT</h3>
                    {dispositivo[0].nick !== instalacion && (
                        <p>
                            Nombre: {dispositivo[0].nick} &rarr; <span style={{ fontWeight: 'bold' }}>{instalacion}</span>
                        </p>
                    )}
                    {dispositivo[0].pap_trafo !== potTrans && potTrans === '' && (
                        <p>
                            Se va a eliminar Potencia Transformador kVA &rarr;{' '}
                            <span style={{ fontWeight: 'bold' }}>{dispositivo[0].pap_trafo}</span>
                        </p>
                    )}
                    {dispositivo[0].pap_trafo !== potTrans && potTrans !== '' && (
                        <p>
                            Potencia Transformador kVA: {dispositivo[0].pap_trafo} &rarr;{' '}
                            <span style={{ fontWeight: 'bold' }}>{potTrans}</span>
                        </p>
                    )}
                    {dispositivo[0].max_iac !== transCorr && transCorr === '' && (
                        <p>
                            Se va a eliminar Transformadores Corriente &rarr;{' '}
                            <span style={{ fontWeight: 'bold' }}>{dispositivo[0].max_iac}</span>
                        </p>
                    )}
                    {dispositivo[0].max_iac !== transCorr && transCorr !== '' && (
                        <p>
                            Transformadores Corriente: {dispositivo[0].max_iac} &rarr;{' '}
                            <span style={{ fontWeight: 'bold' }}>{transCorr}</span>
                        </p>
                    )}
                    {dispositivo[0].address !== address && address === '' && (
                        <p>
                            Se va a eliminar la dirección &rarr; <span style={{ fontWeight: 'bold' }}>{dispositivo[0].address}</span>
                        </p>
                    )}
                    {dispositivo[0].address !== address && address !== '' && (
                        <p>
                            Dirección: {dispositivo[0].address} &rarr; <span style={{ fontWeight: 'bold' }}>{address}</span>
                        </p>
                    )}
                </Grid>
            )}
            {medidores && (
                <Grid className='flex-center justify-center flex-columm' item xs={12} md={12} lg={12} xl={12}>
                    {vtn && (
                        <>
                            <h3>VTN</h3>
                            <p>
                                Nombre: {vtn.antiguo} &rarr; <span style={{ fontWeight: 'bold' }}>{vtn.nick}</span>
                            </p>
                        </>
                    )}
                    {((medidores.length > 0 && !vtn) || (medidores.length > 1 && vtn)) && (
                        <>
                            <h3>Salidas</h3>
                            {medidores.map((ele) => {
                                return (
                                    ele.vtn === undefined && (
                                        <p>
                                            Nombre: {ele.antiguo} &rarr; <span style={{ fontWeight: 'bold' }}>{ele.nick}</span>
                                        </p>
                                    )
                                )
                            })}
                        </>
                    )}
                </Grid>
            )}
        </Modal>
    )
}
