import React, { useEffect, useState } from 'react'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { Modal } from 'components/Modal/Modal'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const ModalChangeAliasCalendar = ({ data, dataRelay, alias, idCcMaster, setOpen, open }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [body, setBody] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setBody({
            ID_entry: data?.ID_entry,
            alias: data?.name
        })
    }, [data])

    const asyncCalendar = () => {
        if (alias) {
            setLoading(true)
        }
        let status
        fetch(urls.scada + `calendarRelay/${idCcMaster}?idCCMaster=${idCcMaster}`, {
            method: 'PATCH',
            headers: headersScada(token),
            body: JSON.stringify(body)
        })
            .then((res) => {
                status = res.status
                if (res.status === 200) {
                    if (dataRelay && data?.ID_entry === 1) {
                        dataRelay.out1_alias = data.name
                    } else if (dataRelay && data?.ID_entry === 2) {
                        dataRelay.out2_alias = data.name
                    }
                    if (alias) {
                        data.prevName = data.name
                    }
                } else {
                }
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: false }))
            })
            .catch((err) => console.error('ERROR. API Scada', err))
            .finally(() => {
                setOpen(false)
            })
    }

    const functCancel = () => {
        setOpen(false)
    }

    return (
        <Modal
            onClick={(e) => e.stopPropagation()}
            open={open}
            setOpen={setOpen}
            disabled={loading}
            functSave={asyncCalendar}
            title='Cambios en Punto de control'
            buttonAction='Aplicar'
            functCancel={functCancel}
        >
            <span className='content-modal-center'>
                <h3>¿Deseas aplicar estos cambios a {data?.prevName ? data?.prevName : data?.name}?</h3>
            </span>
        </Modal>
    )
}
