import React from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useEffect } from 'react'

/**
 * @param {{success: Boolean, error: Boolean, onClose: VoidFunction, mensajeSuccess: string, mensajeError: string}}
 * @param success Estado del modal de exito
 * @param error Estado del modal de error
 * @param onClose Funcion que se ejecuta al cerrar el modal
 * @param mensajeSuccess Mensaje que se mostrara en el modal de confirmacion
 * @param mensajeError Mensaje que se mostrara en el modal de error
 * @returns Devuelve el comentario publico de la alarma
 */
export const ModalRespuesta = ({ success, error, mensajeSuccess, onClose, mensajeError }) => {
    const noteToast = (message) => {
        if (success) {
            toast.success(<p>{message}</p>)
        } else if (error) {
            toast.error(<p>{message}</p>)
        }
        onClose()
    }

    useEffect(() => {
        if (success) {
            noteToast(mensajeSuccess)
        } else if (error) {
            noteToast(mensajeError)
        }
    }, [mensajeSuccess, mensajeError])

    return (
        <>
            <Toaster position='buttom-right' reverseOrder={false} />
        </>
    )
}
