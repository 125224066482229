import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import CalendarComponentV2 from 'components/Calendar/CalendarComponentV2.jsx'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip.jsx'
import { Loader } from 'components/Loader/Loader'
import { apiScada } from 'services/api.jsx'
import { OjoConsumo, IconDownloadFile } from 'utils/icon-centrocontrol.jsx'
import { esHoy, isMobile, compararFecha } from 'utils/Utils.jsx'
import { cambiarCabecera } from 'utils/funcionesCsv.jsx'
import { dispositivos } from 'utils/constant.jsx'
import ConsuptionIntensityRealTime from 'views/Scada/Consumption/GraphConsumption/ScadaConsumptionIntensity/ConsuptionIntensityRealTime.jsx'
import ConsuptionTensionRealTime from 'views/Scada/Consumption/GraphConsumption/ScadaConsumptionTension/ConsuptionTensionRealTime.jsx'
import ConsuptionPowerRealTime from 'views/Scada/Consumption/GraphConsumption/ScadaConsumptionPower/PhasesActivepower.jsx'
import { MenuInformesConsumo } from 'views/Scada/Consumption/GraphConsumption/MenuInformesConsumo.jsx'
import ConsuptionTable from 'views/Scada/Consumption/GraphConsumption/ConsuptionTable.jsx'
import { DropDownDevices } from 'views/Devices/MenuDevices/DropDownDevices'
import { cargarDatosGrafica, convertirHistoricoEnergiaGrafica, convertirHistoricoGraficaVtn } from './utils.jsx'
import { GraficaMabt } from './GraficaMabt.jsx'

export const ScadaMabtMedidor = ({ device, data, devices, masterPermission, mabt, idMaster }) => {
    const [loading, setLoading] = useState(true)
    const [loadingGraph, setLoadingGraph] = useState(true)
    const [graphData, setGraphData] = useState()
    const [energyData, setEnergyData] = useState()
    const [typeGraph, setTypeGraph] = useState('pact')
    const [typeField, setTipoField] = useState('kW')
    const [graphVisible, setGraphVisible] = useState(true)
    const [start, setStart] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
    const [end, setEnd] = useState(moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }))
    const maxDate = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    const [daily, setDaily] = useState('days')
    const [today, setToday] = useState(true)
    const mobile = isMobile(1535)
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const [activeTabGraph, setActiveTabGraph] = useState(0)
    const toggleDevice = (tab) => setActiveTabDevice(tab)
    const [graphVisibility, setGraphVisibility] = useState(true)

    const fetchEnergy = async (ele) => {
        try {
            const response = await apiScada.get(
                `getHistoricalDataScada/${device?.numserie}?idCCMaster=${idMaster}&iniDate=${ele?.start?.format('YYYY-MM-DD')}&endDate=${ele?.end?.format('YYYY-MM-DD')}&groupDate=${ele?.daily}`
            )
            if (response.status === 200) {
                setEnergyData(response?.data)
                if (activeTabGraph === 2 && device?.id_tipo_dispositivo !== dispositivos.ccmabtv) {
                    setGraphData(
                        convertirHistoricoEnergiaGrafica(
                            response?.data,
                            device?.id_tipo_dispositivo === dispositivos.ccmabtv ? 'vtn' : typeGraph
                        )
                    )
                }
            }
        } catch (err) {
            setEnergyData([])
            console.error('ERROR. API Scada', err)
        }
    }

    const fetchDataGraph = async (ele) => {
        const auxToday = ele?.start?.format('YYYY-MM-DD') === moment()?.format('YYYY-MM-DD')
        const aux =
            device?.id_tipo_dispositivo === dispositivos.ccmabtm
                ? [typeGraph]
                : device?.id_tipo_dipositivo === dispositivos.ccmabtv && esHoy(ele?.start)
                  ? ['vtn']
                  : ['vtn_max', 'vtn_min']
        const body = {
            iniDate: `${ele?.start?.format('YYYY-MM-DD')}`,
            endDate: `${ele?.end?.format('YYYY-MM-DD')}`,
            iniTime: `00:00`,
            endTime: auxToday ? `${moment().format('HH:mm')}` : `23:59`,
            interval: !auxToday ? '60' : '5',
            axis: [
                {
                    idDeviceType: Number(device?.id_tipo_dispositivo),
                    fields: aux,
                    numseries: [`${device?.numserie}`],
                    unit: typeField
                }
            ]
        }
        try {
            const response = await apiScada.post(`chart/getDataChartsCurves/${device?.numserie}?idCCMaster=${idMaster}`, body)
            if (response.status === 200) {
                setGraphData(cargarDatosGrafica(response?.data))
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        }
    }

    const handleTab = (index) => {
        setActiveTabGraph(index)
        switch (index) {
            case 0:
                setTipoField('kW')
                setTypeGraph('pact')
                break
            case 1:
                setTipoField('A')
                setTypeGraph('corriente')
                break
            case 2:
                setTipoField('kWh')
                setTypeGraph('energia')
                break
            case 3:
                setTipoField('V')
                setTypeGraph('tension')
                break
            default:
                break
        }
    }

    const handleDate = async (ele) => {
        setLoading(true)
        setLoadingGraph(true)
        setGraphData([])
        const objDate = ele ?? { start: start, end: end, daily: daily ?? 'days' }
        const visible = compararFecha(objDate?.start, objDate?.end)
        setToday(esHoy(objDate?.start))
        setGraphVisible(visible)
        if (ele) {
            setStart(ele?.start)
            setEnd(ele?.end)
            setDaily(ele?.daily)
        }
        if (!visible) {
            handleTab(2)
        }
        await fetchEnergy(objDate)
        if (visible && activeTabGraph !== 2) {
            await fetchDataGraph(objDate)
        }
        setLoadingGraph(false)
        setLoading(false)
    }

    useEffect(() => {
        handleDate()
    }, [activeTabGraph])

    return (
        <Grid container spacing={0} className='pl-3 pr-3'>
            {device && (
                <>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='flex-space-between align-items-end box-shadow-0 margin-bot-10px'>
                        <span className='flex-center justify-end'>
                            {device?.id_tipo_dispositivo === dispositivos.ccmabtm && (
                                <DropDownDevices device={device} masterPermissionNew={masterPermission} />
                            )}
                            <h3 className='ml-2 mr-2'> {device?.nick}</h3>
                        </span>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='grayDivider mb-3' />
                    </Grid>
                    {data && energyData ? (
                        <>
                            {device?.id_tipo_dispositivo === dispositivos.ccmabtm && (
                                <>
                                    <Grid item xs={8} md={8} lg={8} xl={8} className={`${mobile ? 'height-auto' : 'height-50'}`}>
                                        <ConsuptionPowerRealTime ccm={devices} data={data?.currentData} />
                                        <ConsuptionIntensityRealTime ccm={devices} data={data?.currentData} />
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4} xl={4}>
                                        <ConsuptionTensionRealTime ccm={devices} data={data?.currentData} mabt={true} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} style={{ padding: window.innerWidth < 1536 ? '3%' : '0%' }}>
                                        <ul className='nav nav-tabs list-general mb-5'>
                                            <li
                                                className='nav-item'
                                                button
                                                selected={activeTabDevice === 0}
                                                onClick={() => {
                                                    toggleDevice(0)
                                                }}
                                            >
                                                <span className='nav-link active list-p'>Informes</span>
                                            </li>
                                        </ul>
                                        <Grid container>
                                            <Grid item xs={12} md={8} lg={8} xl={8}>
                                                <MenuInformesConsumo
                                                    id_tipo_dispositivo={devices?.id_tipo_dispositivo}
                                                    activeTab={activeTabGraph}
                                                    seleccionarTab={handleTab}
                                                    graphVisible={graphVisible}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4} xl={4}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: window.innerWidth > 1536 ? 'flex-end' : 'center',
                                                        marginTop: '1%',
                                                        marginBottom: window.innerWidth > 1536 ? '0%' : '5%',
                                                        marginLeft: window.innerWidth > 1300 && window.innerWidth < 1500 ? '10%' : '0%',
                                                        alignItems: 'flex-start'
                                                    }}
                                                >
                                                    <CalendarComponentV2 maxDate={maxDate} handleDate={handleDate} select={true} />
                                                    <CSVLink
                                                        data={energyData ? cambiarCabecera(energyData, mabt, true, false, today) : []}
                                                        separator={';'}
                                                        className='color-white'
                                                        filename={`${device?.nick ? device?.nick : 'Sin nombre ' + device?.position}.csv`}
                                                    >
                                                        <HtmlTooltip title={<span>Descargar datos</span>}>
                                                            <IconDownloadFile fill={energyData && energyData?.length > 0 && '#FF8A1F'} />
                                                        </HtmlTooltip>
                                                    </CSVLink>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} className='flex-start justify-start margin-bot-15px'>
                                            <span
                                                style={{ height: 30 }}
                                                className='d-flex align-items-center margin-right-10px cursor-pointer'
                                                onClick={() => setGraphVisibility(!graphVisibility)}
                                            >
                                                <OjoConsumo onClick={() => setGraphVisibility(!graphVisibility)} />
                                            </span>
                                            <h3>{start?.format('DD-MM-YYYY')}</h3>
                                        </Grid>
                                        {graphData && !loadingGraph ? (
                                            <Grid item xs={12} md={12} lg={12} xl={12} className={'powerMargins'}>
                                                {graphVisibility && (
                                                    <GraficaMabt datosGrafica={graphData} escalaTipo={typeField} tipoGrafica={typeGraph} />
                                                )}
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                                                <div className='justify-content-center'>
                                                    <Loader />
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} className='margin-top-20px margin-bot-10px'>
                                        <ConsuptionTable
                                            daily={daily}
                                            data={data?.currentData}
                                            energyData={energyData}
                                            loading={loading}
                                            hoy={today}
                                        />
                                    </Grid>
                                </>
                            )}
                            {device?.id_tipo_dispositivo === dispositivos.ccmabtv && (
                                <>
                                    <Grid item xs={12} md={8} lg={8} xl={8} />
                                    <Grid item xs={12} md={4} lg={4} xl={4}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: window.innerWidth > 1536 ? 'flex-end' : 'center',
                                                marginTop: '1%',
                                                marginBottom: window.innerWidth > 1536 ? '0%' : '5%',
                                                marginLeft: window.innerWidth > 1300 && window.innerWidth < 1500 ? '10%' : '0%',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <CalendarComponentV2 maxDate={end} handleDate={handleDate} select={true} />
                                            <CSVLink
                                                data={energyData ? cambiarCabecera(energyData, mabt, true, false, today) : []}
                                                separator={';'}
                                                className='color-white'
                                                filename={`${device?.nick ? device?.nick : 'Sin nombre ' + device?.position}.csv`}
                                            >
                                                <HtmlTooltip title={<span>Descargar datos</span>}>
                                                    <IconDownloadFile fill={energyData && energyData?.length > 0 && '#FF8A1F'} />
                                                </HtmlTooltip>
                                            </CSVLink>
                                        </div>
                                    </Grid>
                                    {graphData && !loadingGraph ? (
                                        <Grid item xs={12} md={12} lg={12} xl={12} className={'powerMargins'}>
                                            <GraficaMabt
                                                datosGrafica={
                                                    !compararFecha(start, end) ? convertirHistoricoGraficaVtn(energyData) : graphData
                                                }
                                                escalaTipo={'V'}
                                                tipoGrafica={!compararFecha(start, end) ? 'vtn' : 'pact'}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                                            <div className='justify-content-center'>
                                                <Loader />
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={12} lg={12} xl={12} className='margin-top-20px margin-bot-10px'>
                                        <ConsuptionTable
                                            daily={daily}
                                            data={data?.currentData}
                                            energyData={energyData}
                                            loading={loading}
                                            hoy={today}
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    ) : (
                        <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                            <div className='justify-content-center'>
                                <Loader />
                            </div>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    )
}
