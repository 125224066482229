import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { InputAdornment, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { StyledTableCellHead, StyledTableRow, StyledTableCell } from 'components/Table/TableStyle'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { Loader } from 'components/Loader/Loader'
import { headersCentroControl, headersScada } from 'services/headers'
import { IconWarning, IconEditPencil, IconSave, IconExit } from 'utils/icon-centrocontrol'
import { validatorNumber, validatorInput } from 'utils/ValidatorForm'
import { dispositivos, urls } from 'utils/constant'
import './EditDataDevice.scss'

export const TableEditDataDevice = ({ master, centroControl, setDataEdit, dataEdit, setDataInitial, dataInitial }) => {
    const token = sessionStorage?.token
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [filas, setFilas] = useState(5)
    const [edit, setEdit] = useState({})

    const fetchPotMax = (numserie) => {
        setLoading(true)
        let header
        let url
        if (centroControl || master?.id_tipo_dispositivo === dispositivos.ccmaster) {
            url = urls.scada + `getEMSPower/${numserie}?idCCMaster=${numserie}`
            header = headersScada(token)
        } else {
            url = urls.ccontrol + `getConfigPlant/${numserie}`
            header = headersCentroControl(token)
        }
        fetch(url, { method: 'GET', headers: header })
            .then((res) => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then((data) => {
                setData(JSON.parse(JSON.stringify(data)))
                setDataInitial(JSON.parse(JSON.stringify(data)))
            })
            .catch((err) => console.error('ERROR. API Scada', err))
            .finally(() => setLoading(false))
    }

    const handleEdit = (key) => {
        setEdit((prev) => ({
            ...prev,
            [data?.[key]?.numserie]: {
                nick: data?.[key]?.nick || '',
                potencia_contratada: data?.[key]?.potencia_contratada || ''
            }
        }))
    }

    const handleApply = (numserie) => {
        setDataEdit([])
        data.forEach((device, index) => {
            if (device?.nick !== dataInitial[index].nick || device?.potencia_contratada !== dataInitial[index].potencia_contratada) {
                setDataEdit((prev) => [...prev, device])
            }
        })
        setEdit((prev) => ({ ...prev, [numserie]: false }))
    }

    const handleCancel = (dev) => {
        data?.forEach((device, index) => {
            if (device?.numserie === dev?.numserie) {
                if (device?.nick !== dataInitial?.[index]?.nick) handleChange(dev, dataInitial?.[index]?.nick, 'nick')
                if (device?.potencia_contratada !== dataInitial?.[index]?.potencia_contratada)
                    handleChange(dev, dataInitial?.[index]?.potencia_contratada, 'potencia_contratada')
            }
        })
        setDataEdit(dataEdit?.filter((device) => device?.numserie !== dev?.numserie))
        setEdit((prev) => ({ ...prev, [dev?.numserie]: false }))
    }

    const handleChange = (device, e, field) => {
        let value = e
        setData((prevData) => {
            const updatedData = prevData?.map((dev) => {
                if (device?.numserie === dev?.numserie) {
                    switch (field) {
                        case 'nick':
                            return { ...dev, nick: value }
                        case 'potencia_contratada':
                            return { ...dev, potencia_contratada: Number(value) }
                        default:
                            return dev
                    }
                }
                return dev
            })
            return updatedData
        })
    }

    useEffect(() => {
        fetchPotMax(master?.numserie)
    }, [])

    return (
        <>
            {loading && <Loader />}
            {!loading && data?.length > 0 && (
                <InfiniteScroll
                    style={{
                        minHeight: 150,
                        width: '100%'
                    }}
                    dataLength={filas}
                    next={() => {
                        setFilas(filas + 5)
                    }}
                >
                    <TableContainer>
                        <Table aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCellHead align='center'>Nombre</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Potencia Max(W)</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Editar</StyledTableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data?.map((device, key) => {
                                        return (
                                            <StyledTableRow key={key}>
                                                <StyledTableCell align='center'>
                                                    {device && (
                                                        <TextField
                                                            size='small'
                                                            name='nick'
                                                            id={`${device.nick}`}
                                                            value={device?.nick}
                                                            helperText={
                                                                validatorInput(device.nick)
                                                                    ? 'Caracteres no permitidos'
                                                                    : !device.nick
                                                                      ? 'Por favor, rellene el campo'
                                                                      : ''
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(device, e.target.value, 'nick')
                                                            }}
                                                            placeholder='-'
                                                            style={{
                                                                textAlignLast: 'center',
                                                                border: 'none',
                                                                width: '100%',
                                                                color: 'black',
                                                                fontSize: '12px',
                                                                fontWeight: 'bold',
                                                                background: 'transparent',
                                                                WebkitAppearance: 'textfield',
                                                                MozAppearance: 'spinner-textfield'
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        {(device?.potencia_contratada === 0 ||
                                                                            device?.potencia_contratada === null) &&
                                                                            device.id_tipo_dispositivo !== dispositivos.ccmaster && (
                                                                                <HtmlTooltip
                                                                                    title={
                                                                                        <span>
                                                                                            Falta configuración de la potencia contratada.
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <IconWarning width={20} height={20} />
                                                                                </HtmlTooltip>
                                                                            )}
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            disabled={!edit[device.numserie]}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    {device.id_tipo_dispositivo !== dispositivos.ccmaster && (
                                                        <TextField
                                                            size='small'
                                                            id={`${device.potencia_contratada}`}
                                                            value={device.potencia_contratada}
                                                            helperText={
                                                                validatorNumber(device.potencia_contratada)
                                                                    ? 'Por favor, escriba un número'
                                                                    : device.potencia_contratada > 50000
                                                                      ? 'La potencia Máxima no puede ser mayor a 50.000'
                                                                      : ''
                                                            }
                                                            placeholder='-'
                                                            name='pMax'
                                                            onChange={(e) => {
                                                                handleChange(device, e.target.value, 'potencia_contratada')
                                                            }}
                                                            style={{
                                                                textAlignLast: 'center',
                                                                border: 'none',
                                                                width: '100%',
                                                                color: 'black',
                                                                fontSize: '12px',
                                                                fontWeight: 'bold',
                                                                background: 'transparent',
                                                                WebkitAppearance: 'textfield',
                                                                MozAppearance: 'spinner-textfield'
                                                            }}
                                                            disabled={!edit[device.numserie]}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell key={device.numserie} align='center'>
                                                    {edit[device.numserie] && (
                                                        <>
                                                            <ButtonStyledIcon
                                                                className='boton-icon mr-1'
                                                                title={'Guardar'}
                                                                disabled={
                                                                    validatorNumber(data?.[key]?.potencia_contratada) ||
                                                                    data?.[key]?.potencia_contratada > 50000 ||
                                                                    validatorInput(data?.[key].nick)
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={() => handleApply(device.numserie)}
                                                            >
                                                                <IconSave className='color-icon' />
                                                            </ButtonStyledIcon>
                                                            <ButtonStyledIcon
                                                                className='boton-icon-red mr-1'
                                                                title={'Cancelar'}
                                                                backgroundColor='RED'
                                                                onClick={() => handleCancel(device)}
                                                            >
                                                                <IconExit className='color-icon-red' />
                                                            </ButtonStyledIcon>
                                                        </>
                                                    )}
                                                    {!edit[device.numserie] && (
                                                        <ButtonStyledIcon
                                                            className='boton-icon mr-1'
                                                            title={'Editar'}
                                                            onClick={() => handleEdit(key)}
                                                        >
                                                            <IconEditPencil className='color-icon' />
                                                        </ButtonStyledIcon>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfiniteScroll>
            )}
        </>
    )
}
