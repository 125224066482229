import React, { useEffect, useState } from 'react'
import { PDFViewer, pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { FormControl, Grid, MenuItem } from '@mui/material'
import { TableDataCalendar } from 'views/Scada/CcMaster/tabs/Calendar/TableDataCalendar'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { SelectStyled } from 'components/Select/SelectStyled'
import { ButtonModal } from 'components/Buttons/ButtonModal'
import { Loader } from 'components/Loader/Loader'
import { Modal } from 'components/Modal/Modal'
import moment from 'moment'
import './StyleModal.scss'

export const ModalDownloadCalendar = ({ master, modal, setModal, tableData }) => {
    const startOfToday = moment().startOf('day')
    const endOfToday = moment().endOf('day')
    const [end, setEnd] = useState(endOfToday)
    const [start, setStart] = useState(startOfToday)
    const [seePdf, setSeePdf] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [daily, setDaily] = useState('days')
    const [selectedMaster, setSelectedMaster] = useState(master[0]?.nick || '')
    const [filteredData, setFilteredData] = useState(tableData)

    const generateDateRange = (start, end) => {
        let dates = []
        let currentDate = moment(start)
        let endDate = moment(end)
        while (currentDate <= endDate) {
            dates.push(currentDate.format('DD/MM/YYYY'))
            currentDate.add(1, 'days')
        }

        return dates
    }

    const dates = generateDateRange(start, end)

    const Download = () => {
        const handleDownload = async () => {
            setIsDownloading(true)
            const blob = await pdf(
                <TableDataCalendar relayOneData={relayOneData} relayTwoData={relayTwoData} dates={dates} selectedMaster={selectedMaster} />
            ).toBlob()
            saveAs(blob, 'Datos_Calendario.pdf')
            setIsDownloading(false)
            setModal(!modal)
        }

        return (
            <ButtonModal fullWidth={true} className='button-ok-modal' disabled={isDownloading} onClick={handleDownload}>
                {isDownloading ? 'Descargando...' : 'Descargar'}
            </ButtonModal>
        )
    }

    const RelayOne = (data) => {
        let relay1 = []
        relay1 = data?.filter((ele) => ele?.ID_output === 0) || []
        return relay1
    }

    const RelayTwo = (data) => {
        let relay2 = []
        relay2 = data?.filter((ele) => ele?.ID_output === 1) || []
        return relay2
    }

    const relayOneData = RelayOne(filteredData)

    const relayTwoData = RelayTwo(filteredData)

    const handleSelectedMaster = (e) => {
        setSelectedMaster(e.target.value)
    }

    useEffect(() => {
        if (selectedMaster) {
            const filteredDataMaster = tableData?.filter((elem) => elem.nick_master === selectedMaster)
            setFilteredData(filteredDataMaster)
        }
    }, [selectedMaster, tableData])

    return (
        <Modal
            maxWidth={seePdf ? 'xl' : 'sm'}
            open={modal}
            buttonAction='Descargar'
            setOpen={setModal}
            title='Descargar PDF'
            footer={
                <Grid container className='mt-4 mb-4'>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='modal-footer'>
                            {!seePdf ? (
                                <ButtonModal fullWidth={true} className='button-cancel-modal' onClick={() => setSeePdf(!seePdf)}>
                                    Ver PDF
                                </ButtonModal>
                            ) : (
                                <ButtonModal fullWidth={true} className='button-cancel-modal' onClick={() => setSeePdf(!seePdf)}>
                                    Volver atrás
                                </ButtonModal>
                            )}
                            <div style={{ width: '2em' }}></div>
                            <Download />
                        </div>
                    </Grid>
                </Grid>
            }
        >
            <Grid items xs={12} className='d-flex justify-content-center align-items-center flex-column p-2'>
                {!seePdf && (
                    <Grid container className='pr-5 pl-5 flex'>
                        <Grid className='pr-2' item xs={12} md={12} lg={6} xl={6}>
                            <div className='pb-1'>
                                <small className='color-black'>Elija un dispostivo:</small>
                            </div>
                            <FormControl className='width-100' size='small'>
                                <SelectStyled value={selectedMaster} onChange={handleSelectedMaster}>
                                    {master.map((elem) => (
                                        <MenuItem value={elem.nick} key={elem.numserie}>
                                            {elem.nick}
                                        </MenuItem>
                                    ))}
                                </SelectStyled>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <div className='pb-1'>
                                <small className='color-black'>Elija una fecha:</small>
                            </div>
                            <CalendarComponent
                                className='w-full'
                                setStart={setStart}
                                setEnd={setEnd}
                                setDaily={setDaily}
                                daily={daily}
                                enableButton={true}
                            />
                        </Grid>
                    </Grid>
                )}
                {seePdf && (
                    <>
                        <div
                            style={{
                                margin: 'auto',
                                width: '100%',
                                height: '60vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Loader />
                        </div>
                        <PDFViewer
                            style={{
                                width: '100%',
                                height: '60vh',
                                border: 'none',
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                left: '0',
                                top: '10'
                            }}
                        >
                            <TableDataCalendar
                                dates={dates}
                                relayOneData={relayOneData}
                                relayTwoData={relayTwoData}
                                selectedMaster={selectedMaster}
                            />
                        </PDFViewer>
                    </>
                )}
            </Grid>
        </Modal>
    )
}
