import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { ButtonModal } from 'components/Buttons/ButtonModal'
import InputStyled from 'components/Input/InputStyled'
import { Loader } from 'components/Loader/Loader'
import { CustomValidation } from 'utils/Utils'

export const ChangePassProfile = ({
    sendNewPass,
    disabled,
    loading,
    loadingButton,
    passOld,
    setPassOld,
    setValidmsg,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    customPass,
    setCustomPass,
    setCloseSessions,
    closeSessions
}) => {
    const [visible, setVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    let email = localStorage.getItem('email')

    const handleBlur = () => {
        sendToCheck()
        validatePass()
    }

    const sendToCheck = () => {
        if (passOld === '') {
            setValidmsg(false)
            setErrorMessage('Por favor, introduzca su contraseña actual.')
        } else if (password === '') {
            setValidmsg(false)
            setErrorMessage('Por favor, introduzca la nueva contraseña.')
        } else if (confirmPassword === '') {
            setValidmsg(false)
            setErrorMessage('Por favor, introduzca la nueva contraseña una vez más.')
        } else {
            setValidmsg(true)
            setErrorMessage('')
        }
    }

    const validatePass = () => {
        setCustomPass(CustomValidation(email, password))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (disabled) {
            sendNewPass()
        }
    }

    return (
        <>
            {loading && (
                <Grid item xs={12} md={12} lg={12} xl={12} className='input-profile-text'>
                    <Loader loading={loading} />
                </Grid>
            )}
            {!loading && (
                <>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='input-profile-text'>
                        <div className='pt-2 pb-1'>
                            <p className='label-profile-bold'>Contraseña actual:</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <InputStyled
                                type='password'
                                fullWidth
                                onClick={() => setVisible(true)}
                                name='Contraseña actual'
                                onChange={(e) => {
                                    setPassOld(e)
                                }}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='input-profile-text'>
                        <div className='pt-2 pb-1'>
                            <p className='label-profile-bold'>Nueva contraseña:</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <InputStyled
                                type='password'
                                fullWidth
                                onClick={() => setVisible(true)}
                                name='Nueva contraseña'
                                onChange={(e) => {
                                    setPassword(e)
                                }}
                                onBlur={handleBlur}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='input-profile-text'>
                        <div className='pt-2 pb-1'>
                            <p className='label-profile-bold'>Repetir contraseña:</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <InputStyled
                                type='password'
                                fullWidth
                                name='Repetir contraseña'
                                onClick={() => setVisible(true)}
                                onChange={(e) => {
                                    setConfirmPassword(e)
                                }}
                                onBlur={handleBlur}
                            />
                        </form>
                        {errorMessage && <p className='information-text'>{errorMessage}</p>}
                        {visible && (
                            <Grid item xs={12} sm={12} lg={12} xl={12} className='register-login-textfield mt-1'>
                                <Grid item xs={11} sm={11} lg={12} xl={12}>
                                    <ul className='font-size-10px'>
                                        <li
                                            className={`${password?.length > 0 && customPass.includes(1) ? 'error-checklist-password checklist-password' : password?.length > 7 && !customPass?.includes(1) ? 'correct-checklist-password checklist-password' : 'checklist-password'}`}
                                        >
                                            Mínimo 8 caracteres
                                        </li>
                                        <li
                                            className={`${password?.length > 0 && customPass.includes(3) ? 'error-checklist-password checklist-password' : password?.length > 7 && !customPass?.includes(3) ? 'correct-checklist-password checklist-password' : 'checklist-password'}`}
                                        >
                                            Las contraseñas no pueden coincidir con el email
                                        </li>
                                        <li
                                            className={`${password?.length > 0 && customPass.includes(2) ? 'error-checklist-password checklist-password' : password?.length > 7 && !customPass?.includes(2) ? 'correct-checklist-password checklist-password' : 'checklist-password'}`}
                                        >
                                            Debe contener al menos una mayúscula, minúscula, número
                                        </li>
                                        {confirmPassword?.length > 0 && (
                                            <li
                                                className={`${confirmPassword?.length > 0 && password !== confirmPassword ? 'error-checklist-password checklist-password' : password?.length > 0 && confirmPassword?.length > 0 && password === confirmPassword ? 'correct-checklist-password checklist-password' : 'checklist-password'}`}
                                            >
                                                Las contraseñas coinciden
                                            </li>
                                        )}
                                    </ul>
                                </Grid>
                            </Grid>
                        )}
                        <div className='flex-center mt-3'>
                            <SwitchStyled
                                id='habilitado'
                                className='switch-small'
                                checked={closeSessions}
                                onClick={() => setCloseSessions(!closeSessions)}
                            />
                            <span className='text-muted text-confirm-information ml-2'>Cerrar todas las sesiones abiertas</span>
                        </div>
                        <Grid item xs={12} className='containter-button-profile justify-content-end'>
                            <ButtonModal
                                fullWidth={true}
                                loading={loadingButton}
                                disabled={!disabled}
                                className='button-ok-modal button-save-profile'
                                onClick={handleSubmit}
                            >
                                Guardar{' '}
                            </ButtonModal>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}
