// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generation-calendar-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.generation-calendar-select > * {
  margin: 0px 10px;
}

.container-filter-component {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.container-filter-component > * {
  margin: 15px 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Filter/StyleFilterScada.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".generation-calendar-select {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-top: 30px;\n}\n\n.generation-calendar-select > * {\n    margin: 0px 10px;\n}\n\n.container-filter-component {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.container-filter-component > * {\n    margin: 15px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
