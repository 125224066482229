import React, { useEffect, useState } from 'react'
import { Grid, Card } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { dispositivos } from 'utils/constant'
import { Chart } from '../../../SelfConsumption/GraphsUtils/LineChart'
import { isMobile } from 'utils/Utils'
import { IconWarning } from 'utils/icon-centrocontrol'
import { datoNull } from 'views/Scada/MABT/utils'
import './IntensityRadialGraphs.scss'

const ConsuptionIntensityRealTime = ({ data, ccm }) => {
    const mobile = isMobile()
    const [loading, setLoading] = useState(false)
    const [L1Graph, setL1Graph] = useState(0)
    const [L2Graph, setL2Graph] = useState(0)
    const [L3Graph, setL3Graph] = useState(0)
    const [LNGraph, setLNGraph] = useState(0)
    const [L1GraphNegative, setL1GraphNegative] = useState(0)
    const [L2GraphNegative, setL2GraphNegative] = useState(0)
    const [L3GraphNegative, setL3GraphNegative] = useState(0)
    const [LNGraphNegative, setLNGraphNegative] = useState(0)
    const [i1, setI1] = useState()
    const [i2, setI2] = useState()
    const [i3, setI3] = useState()
    const [iN, setIN] = useState()
    const hiredIntensity = ccm?.id_tipo_dispositivo === dispositivos.ccmabt ? ccm?.max_iac : data?.intensidad_magneto

    const iValue = (value) => {
        if (ccm?.max_iac && value) {
            let aux = (value * 100) / ccm?.max_iac
            if (aux > 100) {
                aux = 100
            }
            return Number(aux).toFixed(1)
        } else {
            return 0
        }
    }

    const dataFilterPositive = (data, id) => {
        let power = data
        if (!data || data < 0) {
            power = 0
        }
        let rest = 100 - power
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [power, rest],
                    backgroundColor: ['#3B9FD1', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: -90,
                    borderWidth: 3,
                    cutout: '80',
                    radius: '80%'
                }
            ]
        }
        return res
    }

    const dataFilterNegative = (data, id) => {
        let power = data
        if (!data || data > 0) {
            power = 0
        }
        let rest = 100 - power
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [power, rest],
                    backgroundColor: ['#B4DA9D', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: 90,
                    borderWidth: 3,
                    cutout: '80',
                    radius: '80%'
                }
            ],
            options: {
                plugins: {
                    options: {
                        tooltips: {
                            display: false
                        }
                    }
                }
            }
        }
        return res
    }

    useEffect(() => {
        if (data) {
            setI1(data?.i1)
            setI2(data?.i2)
            setI3(data?.i3)
            setIN(data?.ine)
        }
    }, [data])

    useEffect(() => {
        setL1GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(iValue(i1), 1)} graphStyle={'doughnut'} />)
        setL1Graph(<Chart imprime={true} dataFilter={dataFilterPositive(iValue(i1), 2)} graphStyle={'doughnut'} />)
        setL2GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(iValue(i2), 3)} graphStyle={'doughnut'} />)
        setL2Graph(<Chart imprime={true} dataFilter={dataFilterPositive(iValue(i2), 4)} graphStyle={'doughnut'} />)
        setL3GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(iValue(i3), 5)} graphStyle={'doughnut'} />)
        setL3Graph(<Chart imprime={true} dataFilter={dataFilterPositive(iValue(i3), 6)} graphStyle={'doughnut'} />)
        setLNGraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(iValue(iN), 7)} graphStyle={'doughnut'} />)
        setLNGraph(<Chart imprime={true} dataFilter={dataFilterPositive(iValue(iN), 8)} graphStyle={'doughnut'} />)
    }, [loading])

    useEffect(() => {
        if (i1 && i2 && i3) {
            setLoading(!loading)
        }
    }, [i1, i2, i3])

    return (
        <Card className={`${mobile ? 'height-55' : 'height-22'} box-shadow-0`}>
            <Grid container columns={23}>
                <Grid item xs={23} md={23} lg={23} xl={23}>
                    <h1 className='flex justify-center text-enerclic'>Intensidad</h1>
                </Grid>
                <Grid item xs={1} md={1} lg={1} xl={1} />
                <Grid item xs={mobile ? 16 : 4} md={4} lg={4} xl={4} className={`${mobile ? 'height-15' : 'height-auto'}`}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <div className='chart-consumption'>
                                <span className='text-align-center'>
                                    <div className='chart-up-consumption'>{L1Graph}</div>
                                    <h1 className='chart-title-consumption'>
                                        {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'R' : 'L1'}
                                    </h1>
                                    <p className='chart-percent-consumption'>
                                        {hiredIntensity === null ? (
                                            <span className='chart-tooltip-consumption'>
                                                <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                    <IconWarning width={20} height={20} />
                                                </HtmlTooltip>
                                            </span>
                                        ) : i1 !== ' - ' && i1 !== null && i1 !== undefined ? (
                                            iValue(i1) + '%'
                                        ) : (
                                            ' - %'
                                        )}
                                    </p>
                                    <div className='chart-down-consumption'>{L1GraphNegative}</div>
                                    <span className='chart-pie-consumption'>
                                        <h2>{datoNull(i1)} A</h2>
                                    </span>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={mobile ? 20 : 2} md={2} lg={2} xl={2} className='chart-div-consumption'>
                    <div className={`divider position-relative ${mobile ? 'divider-consumption-mobile' : 'divider-consumption'}`} />
                </Grid>
                <Grid item xs={mobile ? 20 : 4} md={4} lg={4} xl={4} className={`${mobile ? 'height-15' : 'height-auto'}`}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <div className='chart-consumption'>
                                <span className='text-align-center'>
                                    <div className='chart-up-consumption'>{L2Graph}</div>
                                    <h1 className='chart-title-consumption'>
                                        {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'S' : 'L2'}
                                    </h1>
                                    <p className='chart-percent-consumption'>
                                        {hiredIntensity === null ? (
                                            <span className='chart-tooltip-consumption'>
                                                <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                    <IconWarning width={20} height={20} />
                                                </HtmlTooltip>
                                            </span>
                                        ) : i2 !== ' - ' && i2 !== null && i2 !== undefined ? (
                                            iValue(i2) + '%'
                                        ) : (
                                            ' - %'
                                        )}
                                    </p>
                                    <div className='chart-down-consumption'>{L2GraphNegative}</div>
                                    <span className='chart-pie-consumption'>
                                        <h2>{datoNull(i2)} A</h2>
                                    </span>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={mobile ? 20 : 2} md={2} lg={2} xl={2} className='chart-div-consumption'>
                    <div className={`divider position-relative ${mobile ? 'divider-consumption-mobile' : 'divider-consumption'}`} />
                </Grid>
                {mobile && <Grid item xs={2} />}
                <Grid item xs={mobile ? 16 : 4} md={4} lg={4} xl={4} className={`${mobile ? 'height-15' : 'height-auto'}`}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <div className='chart-consumption'>
                                <span className='text-align-center'>
                                    <div className='chart-up-consumption'>{L3Graph}</div>
                                    <h1 className='chart-title-consumption'>
                                        {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'T' : 'L3'}
                                    </h1>
                                    <p className='chart-percent-consumption'>
                                        {hiredIntensity === null ? (
                                            <span className='chart-tooltip-consumption'>
                                                <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                    <IconWarning width={20} height={20} />
                                                </HtmlTooltip>
                                            </span>
                                        ) : i3 !== ' - ' && i3 !== null && i3 !== undefined ? (
                                            iValue(i3) + '%'
                                        ) : (
                                            ' - %'
                                        )}
                                    </p>
                                    <div className='chart-down-consumption'>{L3GraphNegative}</div>
                                    <span className='chart-pie-consumption'>
                                        <h2>{datoNull(i3)} A</h2>
                                    </span>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={mobile ? 20 : 2} md={2} lg={2} xl={2} className='chart-div-consumption'>
                    <div className={`divider position-relative ${mobile ? 'divider-consumption-mobile' : 'divider-consumption'}`} />
                </Grid>
                <Grid item xs={mobile ? 16 : 4} md={4} lg={4} xl={4} className={`${mobile ? 'height-15' : 'height-auto'}`}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <div className='chart-consumption'>
                                <span className='text-align-center'>
                                    <div className='chart-up-consumption'>{LNGraph}</div>
                                    <h1 className='chart-title-consumption'>
                                        {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'N' : 'L3'}
                                    </h1>
                                    <p className='chart-percent-consumption'>
                                        {hiredIntensity === null ? (
                                            <span className='chart-tooltip-consumption'>
                                                <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                    <IconWarning width={20} height={20} />
                                                </HtmlTooltip>
                                            </span>
                                        ) : iN !== ' - ' && iN !== null && iN !== undefined ? (
                                            iValue(iN) + '%'
                                        ) : (
                                            ' - %'
                                        )}
                                    </p>
                                    <div className='chart-down-consumption'>{LNGraphNegative}</div>
                                    <span className='chart-pie-consumption'>
                                        <h2>{datoNull(iN)} A</h2>
                                    </span>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ConsuptionIntensityRealTime
