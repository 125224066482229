import React from 'react'
import { Grid } from '@mui/material'

import { toKilowDate } from 'utils/functionsforGraphs'
import { IconPanel } from 'utils/icon-centrocontrol'

export const CardPhotovoltaic = ({ selfConsumption, handleView, data, setKeySwitch }) => {
    return (
        <>
            <Grid item sm={12} lg={12} xl={12} style={{ margin: '10px 0px' }}>
                <div
                    className={selfConsumption === 'production' ? 'Card-self-Active' : 'Card-self'}
                    onClick={() => {
                        handleView('production', data?.produccion?.numserie)
                        setKeySwitch(Date.now())
                    }}
                >
                    <span className='header-Card-self'>
                        <span className='grid-Card-self'>
                            <IconPanel />
                            <h2 className='title-card-self'>Generación</h2>
                        </span>
                        <span className='grid-Card-self'>
                            <span className='power-card-self-section'>
                                <p className='element-card-self-mini' id='production'>
                                    Potencia: {toKilowDate(data?.produccion?.datetime, data?.produccion?.pact)} kW
                                </p>
                                {/* <p className="element-card-sub-self">
                                    Autoconsumo: {toKilowPercent(importSelf)} %
                                </p> */}
                            </span>
                        </span>
                    </span>
                    <div className='divider-self' />
                    <span className='cardData-self'>
                        <p className='element-card-self'>
                            Generada: {toKilowDate(data?.produccion?.datetime, data?.produccion?.eagendia)} kWh
                        </p>
                    </span>
                </div>
            </Grid>
        </>
    )
}
