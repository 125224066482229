import React from 'react'

export const UserPermission = ({ className, project, style }) => {
    return (
        <>
            {project?.permissions && (
                <>
                    {project?.permissions && (project?.permissions === 'admin' || project?.permissions === 'Admin') ? (
                        <span className={`${className} type-permission admin`} style={style} title='Administrador'>
                            A
                        </span>
                    ) : project?.permissions && project?.permissions === 'instal' ? (
                        <span className={`${className} type-permission instal`} style={style} title='Instalador'>
                            I
                        </span>
                    ) : (
                        <span className={`${className} type-permission user`} style={style} title='Usuario'>
                            U
                        </span>
                    )}
                </>
            )}
        </>
    )
}
