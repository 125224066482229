import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { urls } from 'utils/constant'
import InputStyled from 'components/Input/InputStyled'
import { validatorInput } from 'utils/ValidatorForm'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { convertDatetime } from 'utils/Utils'
import { headersCentroControl } from 'services/headers'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

export const ModalInfoMABT = ({ modal, setModal, ...props }) => {
    const [isValidAlias, setIsValidAlias] = useState(false)
    const [alias, setAlias] = useState()
    const [loading, setLoading] = useState(false)
    const [numSerie, setNumSerie] = useState()
    const [date, setDate] = useState()
    const [isValid, setIsValid] = useState(false)
    const [originalData, setOriginalData] = useState({})
    const [body, setBody] = useState({})
    const token = sessionStorage?.token
    const dispatch = useDispatch()

    const toggle = () => setModal(!modal)

    const handleChangeNick = (e) => {
        setAlias(e)
        setIsValidAlias(validatorInput(e))
    }

    useEffect(() => {
        if (originalData && alias && originalData?.nick !== alias && !isValidAlias) {
            setIsValid(false)
            setBody((prev) => ({ ...prev, nick: alias }))
        } else {
            setIsValid(true)
        }
    }, [alias, originalData, isValidAlias])

    useEffect(() => {
        setIsValidAlias(false)
        setIsValid(true)
    }, [modal])

    useEffect(() => {
        const getData = async ({ numserie, mabt_serial }) => {
            let url = `userInfoDeviceChildren/${numserie}/${mabt_serial}`

            try {
                const res = await fetch(urls.ccontrol + url, {
                    method: 'GET',
                    headers: headersCentroControl(token)
                })
                if (res.ok) {
                    const data = await res.json()
                    setNumSerie(data.numserie)
                    setDate(convertDatetime(data.fecha_sync))
                    setAlias(data.nick)
                    setOriginalData({ ...data })
                } else {
                    console.error('Error')
                }
            } catch (error) {
                console.error('Error en la solicitud')
            }
        }
        getData({ numserie: props?.device?.[0]?.numserie, mabt_serial: props.device[0].mabt_serial })
    }, [modal])

    const patchData = () => {
        setLoading(true)
        let status
        if (props.devices.mabt_serial) {
            let url = `updateDeviceInfo/${props?.devices?.numserie}?idCCMaster=${props.devices?.mabt_serial}`
            fetch(urls.scada + url, {
                method: 'PATCH',
                headers: headersScada(token),
                body: JSON.stringify(body)
            })
                .then((res) => {
                    setLoading(false)
                    status = res.status
                    return res.json()
                })
                .then((data) => {
                    dispatch(createToast({ status: status, message: data.message, reload: true }))
                    toggle()
                })
                .catch((err) => {
                    toggle()
                    console.error('ERROR. API Scada', err)
                })
        }
    }

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                open={modal}
                setOpen={setModal}
                title={`Información del MABT "${originalData?.nick}"`}
                functSave={patchData}
                loading={loading}
                disabled={isValid}
            >
                <Grid container className='pr-5 pl-5 mt-3'>
                    <Grid className='pr-2 pb-3' item xs={12} md={6} lg={6} xl={6}>
                        <div className='pb-1'>
                            <small className='color-black'>Número de serie:</small>
                        </div>
                        <InputStyled value={numSerie} disabled={true} fullWidth={true} />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div className='pb-1'>
                            <small className='color-black'>Fecha de registro:</small>
                        </div>
                        <InputStyled value={date} disabled={true} fullWidth={true} />
                    </Grid>
                    <Grid className='pb-3' item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className='color-black'>Nombre del dispositivo:</small>
                        </div>
                        <InputStyled value={alias} onChange={handleChangeNick} fullWidth={true} error={isValidAlias} maxLength={100} />
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
