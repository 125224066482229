import React from 'react'
import { CSVLink } from 'react-csv'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import './styleSelfConsumption.scss'

export const CsvDownloader = ({ daily, start, end, historicalData, finalData, headersCsv, today, name }) => {
    return (
        <CSVLink
            data={finalData !== undefined && today === true && daily === 'days' ? finalData : historicalData}
            separator={';'}
            headers={headersCsv}
            style={{ color: 'white', padding: '0px' }}
            filename={`${name ? name : 'Datos_Autoconsumo'}_${start ? start?.format('DD-MM-YYYY') : ' '}_${end ? end?.format('DD-MM-YYYY') : ' '}.csv`}
        >
            <HtmlTooltip title={<span>Descargar datos</span>}>
                <IconDownloadFile />
            </HtmlTooltip>
        </CSVLink>
    )
}
