import React from 'react'
import { Button } from '@mui/material'
import { capitalize } from 'utils/Capitalize'

export const ButtonModal = ({ onClick, children, className, fullWidth, disabled, loading, messageLoading }) => {
    return (
        <Button className={`button-modal ${className}`} onClick={onClick} fullWidth={fullWidth} disabled={loading ? loading : disabled}>
            {loading !== true || (loading === true && messageLoading === false) ? (
                typeof children === 'string' ? (
                    capitalize(children)
                ) : (
                    children
                )
            ) : (
                <>
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                    <span className='visually-hidden ml-1'>Cargando...</span>
                </>
            )}
        </Button>
    )
}
