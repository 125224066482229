// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-configuracion {
  display: flex;
  border-bottom: 1px solid #8a8a8a !important;
  margin: 0px !important;
}

.table-responsive-md {
  margin-top: 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/views/Configuracion/Configuracion.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2CAAA;EACA,sBAAA;AACJ;;AAEA;EACI,2BAAA;AACJ","sourcesContent":[".menu-configuracion {\n    display: flex;\n    border-bottom: 1px solid #8a8a8a !important;\n    margin: 0px !important;\n}\n\n.table-responsive-md {\n    margin-top: 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
