import React from 'react'
import { Grid } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import Map from 'components/Mapa/MapShow'

export const ResumeFormCreateSensor = ({ body, medidores }) => {
    const medidor = medidores?.find(medidor => medidor?.numserie === body?.numserie_medidor)

    return (
        <Grid container>
            <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                <Grid item xs={12} lg={6} className="m-1">
                    <div className='pb-1'>
                        <small className="color-black">
                            Nombre:
                        </small>
                    </div>
                    <InputStyled
                        disabled
                        fullWidth
                        value={body?.nick}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className="m-1 ">
                    <div className='pb-1'>
                        <small className="color-black">
                            Número de serie:
                        </small>
                    </div>
                    <InputStyled
                        disabled
                        fullWidth
                        value={body?.numserie}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                <Grid item xs={12} lg={12} className="m-1">
                    <div className='pb-1'>
                        <small className="color-black">
                            Medidor Asociado:
                        </small>
                    </div>
                    <InputStyled
                        disabled
                        fullWidth
                        value={medidor?.nick + ' - ' + body?.numserie_medidor}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                <Grid item xs={12} lg={12} className="m-1 ">
                    <div className='pb-1'>
                        <small className="color-black">
                            Orden Dentro de la Línea:
                        </small>
                    </div>
                    <InputStyled
                        disabled
                        fullWidth
                        value={body?.position}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                <Grid item xs={12} lg={12} className="m-1">
                    <div className='pb-1'>
                        <small className="color-black">
                            Localización:
                        </small>
                    </div>
                    <InputStyled
                        disabled
                        fullWidth
                        value={body?.address}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                <Grid item xs={12} lg={12} className="m-1 d-flex">
                    <Map places={[{lat: body?.latitud, lng: body?.longitud}]} onClickMarker={()=>{}} zoom={5} className="" styleMap={{}} />
                </Grid>
            </Grid>
        </Grid>
    )
}