import './chart.scss'

export default function ChartInline({ total = 30, used = 29.5 }) {
    const percentage = (used / total) * 100

    return (
        <div className='chart-container'>
            <div className='chart-bar-wrapper'>
                <div className='chart-bar chart-bar-used chart-bar-used-dark' style={{ width: percentage + '%' }}>
                    <p className='chart-bar-text'>{used}%</p>
                </div>
                <div className='chart-bar chart-bar-empty chart-bar-empty-dark'></div>
            </div>
        </div>
    )
}
