import React, { useState, useEffect, useContext } from 'react'
import { Grid, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GradeIcon from '@mui/icons-material/Grade'
import { IconShared, IconUserAdmin } from 'utils/icon-centrocontrol'
import { DropdownSubgroup } from 'views/SubGroup/MenuSubGroup/DropdownSubgroup'
import { DropdownGroup } from 'views/Group/MenuGroup/DropdownGroup'
import { DropdownProject } from 'views/Project/MenuProject/DropdownProject'
import { Loader } from 'components/Loader/Loader'
import { UserPermission } from 'views/User/Functions/userPermission'
import { headersCentroControl } from 'services/headers'
import { validatorInput } from 'utils/ValidatorForm'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { nombreInstalacion } from 'utils/Utils'
import { iconoCategoria } from 'utils/Utils'
import { DropdownMaster } from '../Master/MenuMaster/DropdownMaster'
import { ShareUser } from '../Share/ShareUser'
import { GlobalContext } from 'context/GlobalContext'
import { urls } from 'utils/constant'
import { jwtDecode } from 'utils/JwtUtils'
import './styleUsersAdministration.scss'
import ModalDeleteUser from 'views/User/UnShareUser/ModalDeleteUser'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'

/**
 * @param {*} props
 * @param Hacemos una llamada a usermanagement donde recibimos todos los datos de usuario que nos sirven para llevar a cabo el árbol
 * @params hacemos una llamada
 * @returns Imprimimos a través de acordeones de Material UI todo el árbol que posee el usuario, añadiendoles los modales de ceder y compartir todo lo que tiene
 * el usuario
 */
export const ManagementUsers = () => {
    const token = sessionStorage?.token
    const { refreshApp, updateDevice } = useContext(GlobalContext)
    const [management, setManagement] = useState()
    const [searchTerm, setSearchTerm] = useState('')
    const [expanded, setExpanded] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [expandedItems, setExpandedItems] = useState([])
    const [expandedItemsG, setExpandedItemsG] = useState([])
    const [expandedItemsSub, setExpandedItemsSub] = useState([])
    const [debouncedValue, setDebouncedValue] = useState([])
    const [showFavs, setShowFavs] = useState(false)
    const [favs, setFavs] = useState([])
    const idUser = jwtDecode(token)

    const userManagement = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `usermanagement`, { method: 'GET', headers: headersCentroControl(token) })
            .then((res) => {
                status = res?.status
                return res.json()
            })
            .then((data) => {
                if (status === 200) {
                    setManagement(data)
                    setDebouncedValue(data)
                    setError(false)
                } else {
                    setDebouncedValue([])
                    setError(true)
                }
            })
            .catch((err) => {
                console.error('No se ha podido conectar con la API...USERMANAGEMENT', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchFavProjects = async () => {
        try {
            const res = await fetch(urls.ccontrol + 'userSettings', { method: 'GET', headers: headersCentroControl(token) })
            if (res.status === 200) {
                let json = await res.json()
                setFavs(json?.project_fav)
            } else {
                setFavs([])
            }
            return true
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        }
    }

    const fetchAddDeleteFavProjects = async (idProject, isFav) => {
        let updatedFavs = []
        if (isFav) {
            updatedFavs = favs.filter((id) => id !== idProject)
        } else {
            updatedFavs = [...favs, idProject]
        }
        setFavs(updatedFavs)
        try {
            await fetch(urls.ccontrol + 'userSettings', {
                method: isFav ? 'DELETE' : 'POST',
                headers: headersCentroControl(token),
                body: JSON.stringify({ project_fav: idProject })
            })
            return true
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        }
    }

    const handleSearch = (event) => {
        if (!validatorInput(event.target.value)) {
            const query = event.target.value
            setSearchTerm(query)
            const results = management?.filter((item) => {
                const users = item?.users
                return (
                    item?.nick?.toLowerCase().includes(query.toLowerCase()) ||
                    users?.some(
                        (user) =>
                            user?.nick?.toLowerCase().includes(query.toLowerCase()) ||
                            user?.email?.toLowerCase().includes(query.toLowerCase())
                    )
                )
            })
            setDebouncedValue(results)
        }
    }

    const toggleAccordionProject = (index) => {
        const newExpandedItems = [...expandedItems]
        newExpandedItems[index] = !newExpandedItems[index]
        setExpandedItems(newExpandedItems)
    }

    const toggleAccordionGroup = (index, id) => {
        const newExpandedItemsG = [...expandedItemsG]
        const i = index + '' + id
        newExpandedItemsG[i] = !newExpandedItemsG[i]
        setExpandedItemsG(newExpandedItemsG)
    }

    const toggleAccordionSub = (index, id) => {
        const newExpandedItemsSub = [...expandedItemsSub]
        const i = index + '' + id
        newExpandedItemsSub[i] = !newExpandedItemsSub[i]
        setExpandedItemsSub(newExpandedItemsSub)
    }

    useEffect(() => {
        userManagement()
    }, [refreshApp, updateDevice])

    useEffect(() => {
        window.document.title = 'Administración - Enerclic'
        fetchFavProjects()
    }, [])

    return (
        <div className='administration-box'>
            <span className='header-projectList'>
                <div className='header-title'>
                    <IconUserAdmin />
                    <h2 className='title-section'> Administrar Proyectos </h2>
                </div>
                <Grid item xs={12} md={6} lg={6} xl={6} className='buscador-projects'>
                    <ButtonStyled className='p-1 button-with-text mr-2 flex-center' onClick={(e) => setShowFavs(!showFavs)}>
                        {showFavs ? (
                            <GradeIcon className='icon-hover-with-text-enerclic' />
                        ) : (
                            <StarOutlineIcon className='icon-hover-with-text-enerclic' />
                        )}
                        <p className='ml-2'>{showFavs ? 'Todos' : 'Favoritos'}</p>
                    </ButtonStyled>
                    <input
                        className='search-projects'
                        type='search'
                        placeholder='Buscar por proyecto o usuario'
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </Grid>
            </span>
            {showFavs && favs?.length === 0 ? (
                <h4 align='center' className='mt-3'>
                    Aún no hay proyectos marcados cómo favoritos
                </h4>
            ) : (
                <>
                    <Grid item xs={12} className='header-table-user-admin'>
                        <Grid item xs={8} md={8} lg={10} xl={10} className='name-icon-project'>
                            <h3>Mis proyectos</h3>
                        </Grid>
                        <Grid item xs={2} md={2} lg={1} xl={1} className='header-table-admin-project'>
                            <h3>Rol</h3>
                        </Grid>
                        <Grid item xs={2} md={2} lg={1} xl={1} className='header-table-admin-project'>
                            <h3>Compartido</h3>
                        </Grid>
                    </Grid>
                    {loading === true ? (
                        <Loader />
                    ) : (
                        <span>
                            {error && (
                                <h3 className='texto-error-carga'>
                                    Error al cargar los datos de la tabla, contacte con el soporte técnico.
                                </h3>
                            )}
                            {management &&
                                debouncedValue?.map((project, index) => {
                                    let fav = favs.includes(project.id_project)
                                    return (
                                        <Accordion
                                            className={`${(showFavs && !fav) || ['enerSIM', 'IP-Meter'].includes(project.category) ? 'display-none' : ''} principal-accordion-project`}
                                            style={{ boxShadow: 'none !important', border: 'none' }}
                                            elevation={0}
                                            key={index}
                                            expanded={expandedItems[index] || false}
                                            onChange={() => toggleAccordionProject(index)}
                                        >
                                            <Grid item xs={12} md={12} lg={12} xl={12} className='hover-select'>
                                                <Grid item xs={8} md={8} lg={10} xl={10} className='name-icon-project'>
                                                    <div className='pr-1'>
                                                        <HtmlTooltip title={<span>{nombreInstalacion(project?.category)}</span>}>
                                                            {iconoCategoria(project?.category, true)}
                                                        </HtmlTooltip>
                                                    </div>
                                                    <DropdownProject project={project} permissions={project?.permissions} />
                                                    <a
                                                        onClick={() => {
                                                            fetchAddDeleteFavProjects(project.id_project, fav)
                                                        }}
                                                    >
                                                        {fav ? <GradeIcon className='color-enerclic' /> : <StarOutlineIcon />}
                                                    </a>
                                                    <AccordionSummary
                                                        aria-controls='panel1a-content'
                                                        id='panel1a-header'
                                                        className='admin-left-menu'
                                                        expandIcon={
                                                            project?.groups.length > 0 ? (
                                                                <ExpandMoreIcon className='icon-spand-project' />
                                                            ) : null
                                                        }
                                                    >
                                                        <p
                                                            className={`nick-acordion-administration ${project?.nick?.length > 30 ? 'resume-word' : null}`}
                                                        >
                                                            {project?.nick}
                                                        </p>
                                                    </AccordionSummary>
                                                    {project.permissions === 'admin' && (
                                                        <span
                                                            className={
                                                                expandedItems[index] ? 'open-accordion-project' : 'accordion-share-User'
                                                            }
                                                        >
                                                            <ShareUser project={project} type='project' listUser={project?.users} />
                                                        </span>
                                                    )}
                                                </Grid>
                                                <Grid item xs={2} md={2} lg={1} xl={1} className='icon-admin-project table-admin-project'>
                                                    <UserPermission project={project} />
                                                </Grid>
                                                <Grid item xs={2} md={2} lg={1} xl={1}>
                                                    {project.shared === 1 && (
                                                        <span
                                                            className='icon-shared-project-admin icon-delete-user-administration'
                                                            title={`Proyecto compartido por: ${project?.owner}`}
                                                        >
                                                            <IconShared />
                                                            <ModalDeleteUser
                                                                project={project}
                                                                fontSize='12px'
                                                                idUser={idUser?.idUser}
                                                                shared={true}
                                                                type='project'
                                                            />
                                                        </span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <AccordionDetails className='accordion-details-admin'>
                                                {project?.groups &&
                                                    project?.groups?.map((grup, index) => (
                                                        <Accordion
                                                            className='align-acordion-admin'
                                                            style={{ border: 'none !important' }}
                                                            elevation={0}
                                                            key={index}
                                                            expanded={expandedItemsG[index + '' + grup.id_group] || false}
                                                            onChange={() => toggleAccordionGroup(index, grup.id_group)}
                                                        >
                                                            <Grid item xs={12} md={12} lg={12} xl={12} className='hover-select '>
                                                                <Grid
                                                                    item
                                                                    xs={8}
                                                                    md={8}
                                                                    lg={10}
                                                                    xl={10}
                                                                    className='name-icon-project card-projects-name-group'
                                                                >
                                                                    <DropdownGroup groups={grup} projects={project} />
                                                                    <AccordionSummary
                                                                        aria-controls='panel1a-content'
                                                                        id='panel1a-header'
                                                                        className='admin-left-menu'
                                                                        expandIcon={
                                                                            grup?.subgroups?.length > 0 || grup?.devices?.length > 0 ? (
                                                                                <ExpandMoreIcon className='icon-spand-group' />
                                                                            ) : null
                                                                        }
                                                                    >
                                                                        <p
                                                                            className={`nick-acordion-administration ${project?.nick?.length > 12 ? 'resume-word' : null}`}
                                                                        >
                                                                            {grup?.nick}
                                                                        </p>
                                                                    </AccordionSummary>
                                                                    {grup.permissions === 'admin' && (
                                                                        <span
                                                                            className={
                                                                                expandedItemsG[index + '' + grup?.id_group]
                                                                                    ? 'accordion-share-User open-accordion-project'
                                                                                    : 'accordion-share-User'
                                                                            }
                                                                        >
                                                                            <ShareUser
                                                                                grup={grup}
                                                                                type='group'
                                                                                listUser={grup?.users}
                                                                                idUser={idUser?.idUser}
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    lg={1}
                                                                    xl={1}
                                                                    className='icon-admin-project table-admin-group'
                                                                >
                                                                    <UserPermission project={grup} />
                                                                </Grid>
                                                                <Grid item xs={2} md={2} lg={1} xl={1} className='icon-admin-project '>
                                                                    {grup.shared === 1 && (
                                                                        <span
                                                                            className='icon-shared-project-admin icon-delete-user-administration'
                                                                            title={`Proyecto compartido por: ${project?.owner}`}
                                                                        >
                                                                            <IconShared />
                                                                            <ModalDeleteUser
                                                                                group={grup}
                                                                                fontSize='12px'
                                                                                idUser={idUser?.idUser}
                                                                                shared={true}
                                                                                type='group'
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            <AccordionDetails className='accordion-details-admin'>
                                                                {grup?.devices &&
                                                                    grup.devices.map((inst) => (
                                                                        <Grid item xs={12} md={12} lg={12} xl={12} className='hover-select'>
                                                                            <Grid
                                                                                item
                                                                                xs={8}
                                                                                md={8}
                                                                                lg={10}
                                                                                xl={10}
                                                                                className='name-icon-project card-projects-name-device'
                                                                            >
                                                                                <DropdownMaster
                                                                                    token={token}
                                                                                    master={inst}
                                                                                    device={inst}
                                                                                    project={project}
                                                                                    className='menu-admin-title'
                                                                                    admin={true}
                                                                                />
                                                                                <AccordionSummary
                                                                                    aria-controls='panel1a-content'
                                                                                    id='panel1a-header'
                                                                                    className='admin-left-menu'
                                                                                >
                                                                                    <p className='nick-acordion-administration pl-2'>
                                                                                        {inst.nick}
                                                                                    </p>
                                                                                </AccordionSummary>
                                                                                {inst.permissions === 'admin' && (
                                                                                    <ShareUser
                                                                                        master={inst}
                                                                                        type='master'
                                                                                        listUser={inst?.users}
                                                                                    />
                                                                                )}
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={2}
                                                                                md={2}
                                                                                lg={1}
                                                                                xl={1}
                                                                                className='icon-admin-project table-admin-group'
                                                                            >
                                                                                <UserPermission project={inst} />
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={2}
                                                                                md={2}
                                                                                lg={1}
                                                                                xl={1}
                                                                                className='icon-admin-project '
                                                                            >
                                                                                {inst.shared === 1 && (
                                                                                    <span
                                                                                        className='icon-shared-project-admin icon-delete-user-administration'
                                                                                        title={`Proyecto compartido por: ${project?.owner}`}
                                                                                    >
                                                                                        <IconShared />
                                                                                        <ModalDeleteUser
                                                                                            master={inst}
                                                                                            fontSize='12px'
                                                                                            idUser={idUser?.idUser}
                                                                                            shared={true}
                                                                                            type='master'
                                                                                        />
                                                                                    </span>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}
                                                                {grup?.subgroups &&
                                                                    grup?.subgroups.map((sub, index) => (
                                                                        <Accordion
                                                                            style={{
                                                                                border: 'none !important',
                                                                                padding: 'none !important'
                                                                            }}
                                                                            elevation={0}
                                                                            key={index}
                                                                            expanded={
                                                                                expandedItemsSub[index + '' + sub?.id_subgroup] || false
                                                                            }
                                                                            onChange={() => toggleAccordionSub(index, sub?.id_subgroup)}
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                md={12}
                                                                                lg={12}
                                                                                xl={12}
                                                                                className='hover-select'
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={8}
                                                                                    md={8}
                                                                                    lg={10}
                                                                                    xl={10}
                                                                                    className='name-icon-project card-projects-name-subgroup'
                                                                                >
                                                                                    <DropdownSubgroup
                                                                                        groups={grup}
                                                                                        expanded={expanded}
                                                                                        setExpanded={setExpanded}
                                                                                        projects={project}
                                                                                        subgroups={sub}
                                                                                        className='menu-admin-title'
                                                                                        admin={true}
                                                                                    />
                                                                                    <AccordionSummary
                                                                                        aria-controls='panel1a-content'
                                                                                        id='panel1a-header'
                                                                                        className='admin-left-menu'
                                                                                        expandIcon={
                                                                                            sub?.devices.length > 0 ? (
                                                                                                <ExpandMoreIcon className='icon-spand-subgroup' />
                                                                                            ) : null
                                                                                        }
                                                                                    >
                                                                                        <p className='nick-acordion-administration'>
                                                                                            {sub.nick}
                                                                                        </p>
                                                                                    </AccordionSummary>
                                                                                    {sub.permissions === 'admin' && (
                                                                                        <span
                                                                                            className={
                                                                                                expandedItemsSub[
                                                                                                    index + '' + sub?.id_subgroup
                                                                                                ]
                                                                                                    ? 'accordion-share-User open-accordion-project'
                                                                                                    : 'accordion-share-User'
                                                                                            }
                                                                                        >
                                                                                            <ShareUser
                                                                                                subgroup={sub}
                                                                                                type='subgroup'
                                                                                                listUser={sub?.users}
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={2}
                                                                                    md={2}
                                                                                    lg={1}
                                                                                    xl={1}
                                                                                    className='icon-admin-project table-admin-group'
                                                                                >
                                                                                    <UserPermission project={sub} />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={2}
                                                                                    md={2}
                                                                                    lg={1}
                                                                                    xl={1}
                                                                                    className='icon-admin-project '
                                                                                >
                                                                                    {sub.shared === 1 && (
                                                                                        <span
                                                                                            className='icon-shared-project-admin icon-delete-user-administration'
                                                                                            title={`Proyecto compartido por: ${project?.owner}`}
                                                                                        >
                                                                                            <IconShared />
                                                                                            <ModalDeleteUser
                                                                                                subgroup={sub}
                                                                                                fontSize='12px'
                                                                                                idUser={idUser?.idUser}
                                                                                                shared={true}
                                                                                                type='subgroup'
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                            <AccordionDetails className='accordion-details-admin'>
                                                                                {sub.devices &&
                                                                                    sub.devices.map((inst) => (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={12}
                                                                                            md={12}
                                                                                            lg={12}
                                                                                            xl={12}
                                                                                            className='hover-select'
                                                                                        >
                                                                                            <Grid
                                                                                                item
                                                                                                xs={8}
                                                                                                md={8}
                                                                                                lg={10}
                                                                                                xl={10}
                                                                                                className='name-icon-project card-projects-name-device'
                                                                                            >
                                                                                                <DropdownMaster
                                                                                                    token={token}
                                                                                                    master={inst}
                                                                                                    device={inst}
                                                                                                    project={project}
                                                                                                    className='menu-admin-title'
                                                                                                    admin={true}
                                                                                                />
                                                                                                <AccordionSummary
                                                                                                    aria-controls='panel1a-content'
                                                                                                    id='panel1a-header'
                                                                                                    className='admin-left-menu'
                                                                                                >
                                                                                                    <p className='nick-acordion-administration pl-2 '>
                                                                                                        {inst.nick}
                                                                                                    </p>
                                                                                                </AccordionSummary>
                                                                                                {inst.permissions === 'admin' && (
                                                                                                    <ShareUser
                                                                                                        master={inst}
                                                                                                        type='master'
                                                                                                        listUser={inst?.users}
                                                                                                    />
                                                                                                )}
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                item
                                                                                                xs={2}
                                                                                                md={2}
                                                                                                lg={1}
                                                                                                xl={1}
                                                                                                className='icon-admin-project table-admin-group'
                                                                                            >
                                                                                                <UserPermission project={inst} />
                                                                                            </Grid>
                                                                                            <Grid item xs={2} md={2} lg={1} xl={1}>
                                                                                                {inst.shared === 1 && (
                                                                                                    <span
                                                                                                        className='icon-shared-project-admin icon-delete-user-administration'
                                                                                                        title={`Proyecto compartido por: ${project?.owner}`}
                                                                                                    >
                                                                                                        <IconShared />
                                                                                                        <ModalDeleteUser
                                                                                                            master={inst}
                                                                                                            shared={true}
                                                                                                            fontSize='12px'
                                                                                                            idUser={idUser?.idUser}
                                                                                                            type='master'
                                                                                                        />
                                                                                                    </span>
                                                                                                )}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    ))}
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    ))}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                        </span>
                    )}
                </>
            )}
        </div>
    )
}
