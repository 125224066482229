import React, { useEffect, useState } from 'react'
import Master from 'assets/img/devices/Master.svg'
import { ImgAnalyzer, ArrowDown, Ccm1Down, Ccm2Down, Ccm4Up, Ccm2Up, Ccm4Down, Ccm3Down } from 'utils/icon-centrocontrol'

export const DeviceVisualizer = ({ device, direction }) => {
    const [imgDevice, setImgDevice] = useState(null)

    const DeviceSee = () => {
        switch (device) {
            case Number(process.env.REACT_APP_CCMASTER):
                setImgDevice(Master)
                break
            case Number(process.env.REACT_APP_CCM1):
                setImgDevice(<Ccm1Down />)
                break
            case Number(process.env.REACT_APP_CCM2):
                if (direction === 0) {
                    setImgDevice(<Ccm2Up />)
                } else {
                    setImgDevice(<Ccm2Down />)
                }
                break
            case Number(process.env.REACT_APP_CCM3):
                setImgDevice(<Ccm3Down />)
                break
            case Number(process.env.REACT_APP_CCM4):
                if (direction === 0) {
                    setImgDevice(<Ccm4Up />)
                } else {
                    setImgDevice(<Ccm4Down />)
                }
                break
            case Number(process.env.REACT_APP_INVERSOR):
                setImgDevice(null)
                break
            case Number(process.env.REACT_APP_ANALIZADOR):
                setImgDevice(<ImgAnalyzer />)
                break
            default:
                break
        }
    }

    useEffect(() => {
        DeviceSee()
    }, [device])

    return <span>{imgDevice}</span>
}

export const ArrowDirection = ({ direction }) => {
    const [res, setRes] = useState(null)
    const arrow = () => {
        switch (direction) {
            case 1:
                setRes(<ArrowDown />)
                break
            case 0:
                setRes(null)
                break
            default:
                break
        }
    }

    useEffect(() => {
        arrow()
    }, [direction])

    return <span>{res}</span>
}
