import React, { useEffect, useState } from 'react'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import { MenuSelector } from 'components/MenuSelector/MenuSelector'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { alarmas, valueMax, urls } from 'utils/constant'
import { IconAlarm } from 'utils/icon-centrocontrol'
import { ConfiguracionInformes } from '../Informes/ConfiguracionInformes'
import { FormEditarTramo } from './FormEditarTramo'
import { FormEditar } from './FormEditar'

/**
 * @param {{ token: string, setActualizarAlarma: Function, configuracion: {}, modalEditar: Boolean, setModalEditar: Function, data: {}[] }}
 * @param token Token necesario de la llamada
 * @param setActualizarAlarma Funcion apara cambiar el estado de actualizar las alarmas
 * @param configuracion Configuracion de la alarma que se desea eliminar
 * @param modalEditar Estado que indica si el modal esta abierto o cerrado
 * @param setModalEditar Funcion apara cambiar el estado del modal
 * @param data Configuraciones de las alarmas de las instalaciones seleccionadas
 * @returns devuelve la tabla con las configuraciones de las alarmas
 */
export const EditarAlarma = ({ setActualizarAlarma, token, configuracion, modalEditar, setModalEditar, data }) => {
    const dispatch = useDispatch()
    const [dispositivoSeleccionado, setDispositivoSeleccionado] = useState([])
    const [dispositivoNoSeleccionado, setDispositivoNoSeleccionado] = useState([])
    const [todosDispositivos, setTodosDispositivos] = useState([])
    const [habilitado, setHabilitado] = useState(configuracion?.enabled)
    const [tiempoAlarma, setTiempoAlarma] = useState(configuracion?.threshold_time)
    const [tiempoMinimo, setTiempoMinimo] = useState(0)
    const [tiempoMaximo, setTiempoMaximo] = useState(0)
    const [loading, setLoading] = useState(false)
    const [permissions, setPermissions] = useState()
    const [activeTab, setActiveTab] = useState(0)
    const [objAlarmTramo, setObjAlarmTramo] = useState({
        enable: configuracion.enabled,
        type_alarm: 0,
        variable_name: configuracion?.variable,
        upper_value: valueMax === configuracion?.upper_value ? '' : configuracion?.upper_value,
        lower_value: valueMax === configuracion?.lower_value ? '' : configuracion?.lower_value,
        expected_value: valueMax === configuracion?.expected_value ? '' : configuracion?.expected_value
    })
    const [validateFormTramo, setValidateFormTramo] = useState(false)

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab)
    }

    const seleccionarPermisions = (permissions) => {
        setPermissions(permissions)
    }

    /**
     * @returns Edita la configuracion seleccionada
     */
    const fetchEditarAlarma = async () => {
        setLoading(true)
        let status
        let objUpdate = {}
        let objDelete = {}
        let body
        let url
        let method
        if (configuracion?.alarm_id === alarmas.tramo) {
            let objectTramofinal = { ...objAlarmTramo }
            dispositivoSeleccionado?.forEach((dispo) => {
                if (objUpdate[dispo?.instalacion]) {
                    objUpdate[dispo?.instalacion].push({ serial: dispo?.numserie, id_alarm: dispo.id_alarm[0] })
                } else {
                    objUpdate[dispo?.instalacion] = [{ serial: dispo?.numserie, id_alarm: dispo.id_alarm[0] }]
                }
            })
            dispositivoNoSeleccionado?.forEach((dispo) => {
                if (objDelete[dispo?.instalacion]) {
                    objDelete[dispo?.instalacion].push({ serial: dispo?.numserie, id_alarm: dispo?.id_alarm?.[0] })
                } else {
                    objDelete[dispo?.instalacion] = [{ serial: dispo?.numserie, id_alarm: dispo?.id_alarm?.[0] }]
                }
            })
            method = 'POST'
            url = 'alarms/updateSectionAlarm'
            body = {
                id_alarm: configuracion.alarm_id,
                id_type_device: configuracion.device_type,
                enabled: habilitado,
                update: objUpdate,
                delete: objDelete
            }
            if (objectTramofinal?.upper_value === '') {
                objectTramofinal.upper_value = valueMax
            }
            if (objectTramofinal?.lower_value === '') {
                objectTramofinal.lower_value = valueMax
            }
            if (objectTramofinal?.expected_value === '') {
                objectTramofinal.expected_value = valueMax
            }
            body.obj_tramo = objectTramofinal
        } else {
            dispositivoSeleccionado.forEach((dispo) => {
                if (objUpdate[dispo.instalacion]) {
                    objUpdate[dispo.instalacion].push(dispo.numserie)
                } else {
                    objUpdate[dispo.instalacion] = [dispo.numserie]
                }
            })
            let objDelete = {}
            dispositivoNoSeleccionado.forEach((dispo) => {
                if (objDelete[dispo.instalacion]) {
                    objDelete[dispo.instalacion].push(dispo.numserie)
                } else {
                    objDelete[dispo.instalacion] = [dispo.numserie]
                }
            })
            method = 'PATCH'
            url = 'alarms/updateAlarms'
            body = {
                id_alarm: configuracion.alarm_id,
                id_type_device: configuracion.device_type,
                enabled: habilitado,
                threshold_time: tiempoAlarma,
                update: objUpdate,
                delete: objDelete
            }
        }
        try {
            const res = await fetch(urls.scada + url, { method: method, headers: headersScada(token), body: JSON.stringify(body) })
            status = res.status
            if (res.status === 200) {
                dispatch(createToast({ status: status, message: 'Alarma editada correctamente', reload: false }))
                setModalEditar(false)
                setActualizarAlarma((current) => !current)
                return true
            } else {
                dispatch(createToast({ status: status, message: 'Error al editar la alarma', reload: false }))
                setModalEditar(false)
                setActualizarAlarma((current) => !current)
                return false
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        } finally {
            setLoading(false)
        }
    }

    const fetchEditConfigInformAlarms = async () => {
        setLoading(true)
        let status
        let obj = {}
        dispositivoSeleccionado?.forEach((ele) => {
            if (ele?.numseriePadre === undefined && ele?.instalacion === undefined) {
                obj[ele.numserie] = [ele?.numserie]
            } else if (obj?.[ele?.numseriePadre]) {
                obj[ele?.numseriePadre].push(ele?.numserie)
            } else if (obj?.[ele?.instalacion]) {
                obj[ele?.instalacion].push(ele?.numserie)
            } else if (ele?.numseriePadre !== undefined) {
                obj[ele?.numseriePadre] = [ele?.numserie]
            } else {
                obj[ele?.instalacion] = [ele?.numserie]
            }
        })
        let body = {
            numserie: obj,
            id_alarm: configuracion?.alarm_id,
            permissions: permissions
        }
        try {
            const res = await fetch(urls.scada + 'alarms/updateSharedAlarm', {
                method: 'PATCH',
                headers: headersScada(token),
                body: JSON.stringify(body)
            })
            status = res.status
            if (res.status === 200) {
                dispatch(createToast({ status: status, message: 'Informe de alarma editado correctamente', reload: false }))
                setModalEditar(false)
                setActualizarAlarma((current) => !current)
                return true
            } else {
                dispatch(createToast({ status: status, message: 'Error al editar el informe de alarma', reload: false }))
                setModalEditar(false)
                setActualizarAlarma((current) => !current)
                return false
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        } finally {
            setLoading(false)
        }
    }

    const functSave = () => {
        if (activeTab === 0) {
            fetchEditarAlarma()
        } else {
            fetchEditConfigInformAlarms()
        }
    }

    useEffect(() => {
        let alarmaPadre = data?.find(
            (ele) =>
                Number(configuracion?.numInstalacion) === Number(ele?.numserie?.[0]) &&
                Number(ele?.numInstalacion) === Number(configuracion?.numInstalacion)
        )
        if (alarmaPadre && alarmaPadre?.numserie?.[0] !== configuracion?.numserie?.[0]) {
            setTiempoMinimo(alarmaPadre?.threshold_time)
        } else {
            let alarmaHijo = data?.filter(
                (ele) =>
                    Number(configuracion?.numInstalacion) !== Number(ele?.numserie?.[0]) &&
                    Number(ele?.numInstalacion) === Number(configuracion?.numInstalacion)
            )
            let time
            if (alarmaPadre) {
                alarmaHijo?.forEach((ele) => {
                    if (time === undefined || time > ele?.threshold_time) {
                        time = ele?.threshold_time
                    }
                })
                setTiempoMaximo(time)
            }
        }
        let objDispo = []
        configuracion?.numserie?.forEach((num, index) => {
            objDispo?.push({
                numserie: num,
                nick: configuracion?.nick?.[index],
                instalacion: configuracion?.numInstalacion,
                nickMaster: configuracion?.instalacion,
                posicion: configuracion?.mabt_output?.[index],
                id_alarm: configuracion?.id_alarm
            })
        })
        setDispositivoSeleccionado(objDispo)
        setTodosDispositivos(objDispo)
    }, [])

    return (
        <Modal
            maxWidth={activeTab === 1 ? 'md' : undefined}
            open={modalEditar}
            setOpen={setModalEditar}
            title='Editar alarma'
            iconTitle={<IconAlarm fill='black' />}
            loading={loading}
            functSave={functSave}
            disabled={
                dispositivoSeleccionado?.length === 0 ||
                (configuracion?.alarm_id === alarmas.tramo && !validateFormTramo) ||
                (configuracion?.alarm_id !== alarmas.tramo && tiempoAlarma >= tiempoMaximo && tiempoMaximo > 0) ||
                (configuracion?.alarm_id !== alarmas.tramo && tiempoMinimo >= tiempoAlarma)
            }
        >
            <Grid container className='mb-1 pl-4 pr-4'>
                <MenuSelector options={[{ title: 'Alarma' }, { title: 'Notificaciones' }]} activeTab={activeTab} onClick={toggle} />
            </Grid>
            {activeTab === 0 && (
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 pl-2 pr-2'>
                    <FormEditar
                        tipoAlarma={configuracion.alarm_id}
                        tipoDispositivo={configuracion.device_type}
                        todosDispositivos={todosDispositivos}
                        dispositivoSeleccionado={dispositivoSeleccionado}
                        setDispositivoNoSeleccionado={setDispositivoNoSeleccionado}
                        setDispositivoSeleccionado={setDispositivoSeleccionado}
                        tiempoAlarma={tiempoAlarma}
                        setTiempoAlarma={setTiempoAlarma}
                        tiempoMinimo={tiempoMinimo}
                        habilitado={habilitado}
                        setHabilitado={setHabilitado}
                        tiempoMaximo={tiempoMaximo}
                    />
                    {configuracion.alarm_id === alarmas.tramo && (
                        <FormEditarTramo
                            setValidateFormTramo={setValidateFormTramo}
                            objAlarmTramo={objAlarmTramo}
                            setObjAlarmTramo={setObjAlarmTramo}
                            tipoDispositivo={configuracion.device_type}
                            habilitado={habilitado}
                            setHabilitado={setHabilitado}
                        />
                    )}
                </Grid>
            )}
            {activeTab === 1 && (
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 pl-4 pr-4'>
                    <ConfiguracionInformes
                        seleccionarPermisions={seleccionarPermisions}
                        todosDispositivos={todosDispositivos}
                        dispositivoSeleccionado={dispositivoSeleccionado}
                        tipoAlarma={configuracion.alarm_id}
                        token={token}
                    />
                </Grid>
            )}
        </Modal>
    )
}
