import moment from 'moment'

/**
 * @param {[]} datos array de datos a tranformar
 * @param {string} tipo define que tipo de datos trae el array
 * @returns devuelve un array con los datos convertidos al estandar
 */
export const convertirDatosMabt = (datos, tipo, setTotal) => {
    let variable = datos

    variable?.sort((a, b) => {
        const aOutput = a['posicion']
        const bOutput = b['posicion']
        if (!aOutput) {
            return 1
        }
        if (!bOutput) {
            return -1
        }
        if (aOutput > bOutput) {
            return 1
        }
        if (aOutput < bOutput) {
            return -1
        }
        return 0
    })
    let datosTransformados = []
    let val = 0
    datos?.forEach((dato) => {
        if (tipo === 'preact') {
            val += Math.abs(dato?.pre1) + Math.abs(dato?.pre2) + Math.abs(dato?.pre3)
            datosTransformados.push({
                position: dato?.posicion,
                nick: dato?.nick,
                dato1: dato?.pre1,
                dato2: dato?.pre2,
                dato3: dato?.pre3,
                datetime: dato?.datetime,
                numserie: dato?.numserie,
                id_tipo_dispositivo: 21
            })
        } else if (tipo === 'pact') {
            val += Math.abs(dato?.pac1) + Math.abs(dato?.pac2) + Math.abs(dato?.pac3)
            datosTransformados.push({
                position: dato?.posicion,
                nick: dato?.nick,
                dato1: dato?.pac1,
                dato2: dato?.pac2,
                dato3: dato?.pac3,
                datetime: dato?.datetime,
                numserie: dato?.numserie,
                id_tipo_dispositivo: 21
            })
        } else if (tipo === 'tension') {
            val += Math.abs(dato?.v1) + Math.abs(dato?.v2) + Math.abs(dato?.v3)
            datosTransformados.push({
                position: dato?.posicion,
                nick: dato?.nick,
                dato1: dato?.v1,
                dato2: dato?.v2,
                dato3: dato?.v3,
                datetime: dato?.datetime,
                numserie: dato?.numserie,
                id_tipo_dispositivo: 21
            })
        } else if (tipo === 'corriente') {
            val += Math.abs(dato?.i1) + Math.abs(dato?.i2) + Math.abs(dato?.i3)
            datosTransformados.push({
                position: dato?.posicion,
                nick: dato?.nick,
                dato1: dato?.i1,
                dato2: dato?.i2,
                dato3: dato?.i3,
                datetime: dato?.datetime,
                numserie: dato?.numserie,
                id_tipo_dispositivo: 21
            })
        }
    })
    setTotal(val)
    return datosTransformados
}

export const cargarDatosGrafica = (dato) => {
    let fechas = []
    dato?.categories?.forEach((element) => {
        fechas?.push(moment(element, 'DD-MM-YYYY HH:mm')?.format('HH:mm'))
    })
    let obj = { categories: fechas, data: dato?.data }
    return obj
}

export const diccionarioConversionMabtHistorico = {
    nick: 'Salida',
    fecha: 'Fecha',
    datetime: 'Fecha',
    pac1: 'Potencia Activa R',
    pac2: 'Potencia Activa S',
    pac3: 'Potencia Activa T',
    pre1: 'Potencia Reactiva R',
    pre2: 'Potencia Reactiva S',
    pre3: 'Potencia Reactiva T',
    i1: 'Corriente R',
    i2: 'Corriente S',
    i3: 'Corriente T',
    v1: 'Tensión R',
    v2: 'Tensión S',
    v3: 'Tensión T',
    pac1_max: 'Potencia Activa R Max',
    pac1_min: 'Potencia Activa R Min',
    pac2_max: 'Potencia Activa S Max',
    pac2_min: 'Potencia Activa S Min',
    pac3_max: 'Potencia Activa T Max',
    pac3_min: 'Potencia Activa T Min',
    i1_max: 'Corriente R Max',
    i2_max: 'Corriente S Max',
    i3_max: 'Corriente T Max',
    v1_max: 'Tensión R Max',
    v2_max: 'Tensión S Max',
    v3_max: 'Tensión T Max',
    ereactq1dia: 'Energía Reactiva 1',
    ereactq2dia: 'Energía Reactiva 2',
    ereactq3dia: 'Energía Reactiva 3',
    ereactq4dia: 'Energía Reactiva 4',
    eaconsdia: 'Energía Importada',
    eagendia: 'Energía Exportada'
}

export const diccionarioConversionMabt = {
    nick: 'Salida',
    fecha: 'Fecha',
    datetime: 'Fecha',
    pac1: 'Potencia Activa R',
    pac2: 'Potencia Activa S',
    pac3: 'Potencia Activa T',
    pre1: 'Potencia Reactiva R',
    pre2: 'Potencia Reactiva S',
    pre3: 'Potencia Reactiva T',
    i1: 'Corriente R',
    i2: 'Corriente S',
    i3: 'Corriente T',
    v1: 'Tensión R',
    v2: 'Tensión S',
    v3: 'Tensión T',
    pac1_max: 'Potencia Activa R Max',
    pac1_min: 'Potencia Activa R Min',
    pac2_max: 'Potencia Activa S Max',
    pac2_min: 'Potencia Activa S Min',
    pac3_max: 'Potencia Activa T Max',
    pac3_min: 'Potencia Activa T Min',
    i1_max: 'Corriente R Max',
    i2_max: 'Corriente S Max',
    i3_max: 'Corriente T Max',
    v1_max: 'Tensión R Max',
    v2_max: 'Tensión S Max',
    v3_max: 'Tensión T Max',
    ereactq1dia: 'Energía Reactiva 1',
    ereactq2dia: 'Energía Reactiva 2',
    ereactq3dia: 'Energía Reactiva 3',
    ereactq4dia: 'Energía Reactiva 4'
}

const convertirObjeto = (objeto, diccionario, hoy, daily, controlador) => {
    let objetoConvertido = {}
    for (const atributo in diccionario) {
        if (objeto.hasOwnProperty(atributo)) {
            switch (atributo) {
                case 'datetime':
                    if ((!hoy && daily !== 'hours') || (hoy && daily === 'hours')) {
                        objetoConvertido['Fecha'] = objeto[atributo]
                    } else if (!hoy && daily === 'hours') {
                        objetoConvertido['Fecha'] += ` ${objeto[atributo]}`
                    } else if (hoy && objeto['fecha'] === undefined) {
                        objetoConvertido['Fecha'] = objeto[atributo]
                    }
                    break
                case 'fecha':
                    if (!hoy && daily === 'hours') {
                        objetoConvertido['Fecha'] = objeto[atributo]
                    } else if (hoy && daily === 'days') {
                        objetoConvertido['Fecha'] = objeto[atributo]
                            ? moment(objeto[atributo], 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')
                            : '-'
                    }
                    break
                case 'nick':
                    objetoConvertido[controlador ? 'Nombre' : diccionario[atributo]] = objeto[atributo]
                        ? objeto[atributo]
                        : 'Sin nombre ' + (objeto['mabt_output'] ? objeto['mabt_output'] : '')
                    break
                case 'numserie':
                    objetoConvertido[diccionario[atributo]] = objeto[atributo] === null ? '-' : objeto[atributo]
                    break
                default:
                    objetoConvertido[diccionario[atributo]] = objeto[atributo] === null ? '-' : objeto[atributo].toFixed(2)
                    break
            }
        }
    }
    return objetoConvertido
}

export const cargarDatosTabla = (arrayOriginal, diccionario, hoy, daily, controlador) => {
    return arrayOriginal.map((objeto) => {
        return convertirObjeto(objeto, diccionario, hoy, daily, controlador)
    })
}

export const convertirHistoricoEnergiaGrafica = (historico, tipo) => {
    const datosTransformados = { categories: [], data: [] }
    switch (tipo) {
        case 'energia':
            const labelsEnergy = historico?.map((objeto) => objeto.datetime)
            const energiaConsumida = historico?.map((objeto) => objeto.eaconsdia)
            const energiaGenerada = historico?.map((objeto) => objeto.eagendia)
            datosTransformados.categories = labelsEnergy
            datosTransformados.data = [
                { name: 'Energía Importada', data: energiaConsumida },
                { name: 'Energía Exportada', data: energiaGenerada }
            ]
            break
        case 'energiaConsumida':
            const labelsEnergyCons = historico?.map((objeto) => objeto?.map((obj) => obj?.datetime))
            datosTransformados.categories = labelsEnergyCons?.map((subarray) => subarray?.[0])
            datosTransformados.data = transformarArray(historico, 'eaconsdia')
            break
        case 'energiaGenerada':
            const labelsEnergyGenerated = historico?.map((objeto) => objeto?.map((obj) => obj?.datetime))
            datosTransformados.categories = labelsEnergyGenerated?.map((subarray) => subarray?.[0])
            datosTransformados.data = transformarArray(historico, 'eagendia')
            break
        case 'vtn':
            const labelsVtn = historico?.map((objeto) => objeto?.map((obj) => obj?.datetime))
            datosTransformados.categories = labelsVtn?.map((subarray) => subarray?.[0])
            datosTransformados.data = transformarArray(historico, 'vtn')
            break
        default:
            break
    }
    return datosTransformados
}

function transformarArray(datos, atributo) {
    const arryTransformado = []
    datos.forEach((obj, index) => {
        obj.forEach((objInterno, indexInterno) => {
            if (index === 0) {
                arryTransformado.push({ name: objInterno.nick, data: [objInterno[atributo]] })
            } else {
                arryTransformado[indexInterno].data.push(objInterno[atributo])
            }
        })
    })
    return arryTransformado
}

export const convertirHistoricoGraficaVtn = (historico) => {
    const labels = historico.map((objeto) => objeto.datetime)
    const vtnMax = historico.map((objeto) => objeto.vtn_max)
    const vtnMin = historico.map((objeto) => objeto.vtn_min)
    const datosTransformados = {
        categories: labels,
        data: [
            {
                name: 'VTN Max',
                data: vtnMax
            },
            {
                name: 'VTN Min',
                data: vtnMin
            }
        ]
    }
    return datosTransformados
}

export const datoNull = (dato) => {
    return dato === null || dato === undefined ? '-' : typeof dato === 'number' ? dato.toFixed(2) : dato
}

export const datoNullPercent = (dato) => {
    return dato === null || dato === undefined ? '-' : typeof dato === 'number' ? dato.toFixed(1) : dato
}

export const calcularPorcentajeFila = (datos, dato, total) => {
    let porcentaje = 0
    datos?.forEach((dat) => {
        porcentaje += Math.abs(dat?.[dato])
    })
    porcentaje = (porcentaje / total) * 100
    return porcentaje >= 0 ? (porcentaje === 100 ? porcentaje : porcentaje?.toFixed(1)) : '-'
}

export const calcularPorcentajeColumna = (columna, total) => {
    let porcentaje = Math.abs(columna?.dato1) + Math.abs(columna?.dato2) + Math.abs(columna?.dato3)
    porcentaje = (porcentaje / total) * 100
    return porcentaje >= 0 ? (porcentaje === 100 ? porcentaje : porcentaje.toFixed(1)) : '-'
}

export const calcularSumaFila = (datos, dato) => {
    let suma = 0
    datos.forEach((dat) => {
        suma += dat[dato]
    })
    return suma
}

export const formatLabel = (labels) => {
    let res = labels?.length > 0 && labels?.map((lab) => lab?.split(' ')[1])
    return res
}
