import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, FormControl, Typography, Avatar } from '@mui/material'
import { GlobalContext } from 'context/GlobalContext'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { DropZone } from 'components/DropZone/DropZone'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl, headersCentroControlImagen } from 'services/headers'
import { IconoProyectos, IconNewObjectBig, IconDeleteAlarm, IconEditPencil, IconArrowAccordion } from 'utils/icon-centrocontrol'
import { validatorInput } from 'utils/ValidatorForm'
import { categories, positions } from 'utils/Utils'
import { urls } from 'utils/constant'
import 'views/Principal/StylePrincipal.scss'
import '../ProjectsStyle.scss'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const NewProject = ({ loading, setLoading, setOpen, open }) => {
    const [visible, setVisible] = useState(false)
    const navigate = useNavigate()
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [nick, setNick] = useState('')
    const [nameProject, setNameProject] = useState('')
    const [categoryProject, setCategoryProject] = useState()
    const { projectsCompleto } = useContext(GlobalContext)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [validNameProyect, setValidNameProject] = useState(false)
    const [validNameGroup, setValidNameGroup] = useState(false)
    const [listLogos, setListLogos] = useState(['logo1'])
    const [files, setFiles] = useState({})
    const [logos, setLogos] = useState({})
    const [keyLogos, setKeyLogos] = useState('logos')
    const [position, setPosition] = useState([-1])
    const [showDropZone, setShowDropZone] = useState([true, true, true])

    //Ordena alfabeticamente el array de las categoías por la propiedad 'nick' (array en utils)
    categories.sort((a, b) => a.nick.localeCompare(b.nick))

    //Si no ha escrito nada o el nombre ya existe, se desactiva el boton
    const validateProject = () =>
        nameProject && categoryProject !== undefined && nick.length && !visible && !validNameGroup && !validNameProyect

    //Valida si el nombre del proyecto ya existe en la base de datos y avisa al usuario
    const validateNickProject = () => {
        if (projectsCompleto && projectsCompleto?.length > 0 && selectedCategory !== 8) {
            const normalizedNick = nameProject?.toLowerCase().trim()
            const foundProject = projectsCompleto?.find((prj) => prj?.nick?.toLowerCase()?.trim() === normalizedNick)
            return foundProject
        }
        return false
    }

    const handleChangeProject = (e) => {
        setNameProject(e)
        setValidNameProject(validatorInput(e))
    }

    const handleSelectedEnerSim = (project) => {
        navigate(`/admin/inicio/${project}/enerSIM`)
    }

    const handleSelectedProject = (project) => {
        let path
        if (categoryProject !== 1) {
            path = `/admin/inicio/${project}/${categoryProject}`
        } else {
            path = `/admin/inicio/${project}/enerSIM`
        }
        navigate(path)
    }

    const enerSimCategory = selectedCategory === 8

    const handleChangeNick = (e) => {
        setNick(e)
        setValidNameGroup(validatorInput(e))
    }

    const createProject = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `project`, {
            method: 'POST',
            headers: headersCentroControl(token),
            body: JSON.stringify({
                nick: nameProject,
                type: selectedCategory,
                permissions: 'admin',
                nombreGrupo: nick
            })
        })
            .then((res) => {
                status = res.status
                return res.json()
            })
            .then((data) => {
                if (status === 200) {
                    handleSelectedProject(data.body.project)
                }
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                fetchAddLogo(data?.body.project)
            })
            .catch((err) => console.error('ERROR. API Scada', err))
    }

    const createEnerSim = () => {
        setLoading(true)
        let status
        let message
        fetch(urls.ccontrol + `createEnerSimProjectCC`, {
            method: 'POST',
            headers: headersCentroControl(token),
            body: JSON.stringify({
                nick: 'enerSIM',
                type: '8',
                permissions: 'admin',
                nombreGrupo: 'enerSIM'
            })
        })
            .then((res) => {
                status = res.status
                if (res.status === 200) {
                    message = 'Proyecto creado correctamente'
                    return res.json()
                } else if (res.status === 500) {
                    message = 'Ya existe un proyecto enerSIM'
                    return res.json()
                } else {
                    message = 'Error al crear el proyecto'
                    throw res
                }
            })
            .then((data) => {
                fetchAddLogo(data?.body.project)
                dispatch(createToast({ status: status, message: message, reload: true }))
                handleSelectedEnerSim(data?.body.project)
            })
            .catch((err) => console.error('ERROR. API Scada', err))
            .finally(() => {
                setLoading(false)
            })
    }

    const handleFiles = (logo, file) => {
        setKeyLogos(file)
        setFiles((prevEstado) => ({ ...prevEstado, [logo]: file }))
    }

    const handlePosition = (ind, pos) => {
        const positionArray = [...position]
        positionArray[ind] = pos
        setPosition(positionArray)
    }

    const handleShowDropZone = (ind) => {
        const newShowDropZone = [...showDropZone]
        newShowDropZone[ind] = !newShowDropZone[ind]
        setShowDropZone(newShowDropZone)
    }

    const fetchAddLogo = async (projectNew) => {
        if (Object?.entries(files)?.length > 0) {
            const formData = new FormData()
            let index = 0
            Object?.entries(files)?.forEach(([clave, logo]) => {
                if (logo[0] && position[index] !== -1) {
                    formData.append('logos', logo[0])
                    formData.append(clave, position[index])
                }
                index++
            })
            formData.append('id_project', projectNew)
            try {
                const res = await fetch(urls.ccontrol + 'logo', {
                    method: 'POST',
                    headers: headersCentroControlImagen(token),
                    body: formData
                })
                if (res.status === 200) {
                    let json = await res.json()
                    setLogos(json)
                } else {
                    return false
                }
            } catch (err) {
                console.error('ERROR. API Centro de Control', err)
            }
        } else {
            setOpen(false)
        }
    }

    const addListLogo = () => {
        let index = listLogos.length + 1
        const positionArray = [...position]
        positionArray[listLogos.length] = -1
        setPosition(positionArray)
        setListLogos((prev) => [...prev, 'logo' + index])
    }

    const deleteListLogo = (logo, index) => {
        if (listLogos?.length === 1) {
            setListLogos(['logo1'])
            setFiles({})
            setPosition([-1])
            setShowDropZone([true, true, true])
        } else {
            const logosArray = []
            const positionArray = [...position]
            const objFiles = Object.assign({}, files)
            let numLogo = logo.split('logo')[1]
            let numLogos = Object?.keys(objFiles)?.length
            if (numLogos === 1) {
                logosArray.push('logo1')
            } else {
                for (let i = 1; i < numLogos; i++) {
                    logosArray.push('logo' + i)
                }
            }
            for (let i = Number(numLogo); i < numLogos; i++) {
                let value = i + 1
                if (objFiles['logo' + value]) {
                    objFiles['logo' + i] = objFiles['logo' + value]
                }
            }
            if (numLogos > 1) {
                delete objFiles['logo' + numLogos]
            }
            positionArray.splice(index, 1)
            setPosition(positionArray)
            setListLogos(logosArray)
            setFiles(objFiles)
            const newShowDropZone = [...showDropZone]
            newShowDropZone[index] = true
            setShowDropZone(newShowDropZone)
        }
    }

    const seeWeb = (e) => {
        if (enerSimCategory) {
            createEnerSim()
        } else {
            createProject()
        }
    }

    const handleOpen = () => {
        setVisible(false)
        setNameProject('')
        setNick('')
        setCategoryProject()
        setOpen((current) => !current)
        setSelectedCategory(null)
        setValidNameProject(false)
        setValidNameGroup(false)
        setListLogos(['logo1'])
        setFiles({})
        setPosition([-1])
        setShowDropZone([true, true, true])
    }

    const handleChange = (e, categoryValue) => {
        setSelectedCategory(categoryValue)
        setCategoryProject(e.target.value)
    }

    const enerSimProjectExists = () => {
        const enerSimCategoryProject = projectsCompleto?.find((prj) => prj?.category === 'enerSIM')
        return Boolean(enerSimCategoryProject)
    }

    const isEnerSimProjectExisting = enerSimProjectExists()

    return (
        <Modal
            open={open}
            setOpen={handleOpen}
            functSave={(e) => seeWeb(e)}
            disabled={!enerSimCategory && !validateProject()}
            iconTitle={<IconoProyectos height='23' width='23' fill='#FF8A1F' />}
            title={'Nuevo proyecto'}
            loading={loading}
            buttonAction='Crear'
        >
            <Grid container className='container-shared-cede-modal-padding'>
                <Grid item xs={12} md={12} lg={12} xl={12} className='pb-3'>
                    <div className='pb-1'>
                        <small className='color-black'>Nombre del proyecto:</small>
                    </div>
                    <InputStyled
                        value={enerSimCategory ? 'enerSIM' : nameProject}
                        onChange={handleChangeProject}
                        fullWidth={true}
                        onBlur={() => {
                            setVisible(validateNickProject())
                        }}
                        maxLength={64}
                        error={enerSimCategory ? '' : validNameProyect || visible}
                        errorMessage={visible ? 'Este nombre ya está asignado' : 'Caracteres no permitidos'}
                        disabled={enerSimCategory}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='pb-3'>
                    <FormControl fullWidth variant='outlined' size='small'>
                        <div className='pb-1'>
                            <small className='color-black'>Selecciona una categoría:</small>
                        </div>
                        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                            {categories.map((category, index) => (
                                <Grid item key={category.value} className={`pt-2 pb-2 ${index % 2 === 0 ? 'shaded' : 'not-shaded'}`}>
                                    <Grid container sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <Grid item xs={1} className='pl-2'>
                                            {category.emoticon}
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography className='font-size-12'>{category.nick}</Typography>
                                        </Grid>
                                        <Grid item xs={1} style={{ stroke: selectedCategory === category.value ? '#FF8A1F' : '#fff' }}>
                                            <SwitchStyled
                                                className='switch-small'
                                                checked={selectedCategory === category.value}
                                                onChange={(event) => handleChange(event, category.value)}
                                                disabled={category.value === 8 && isEnerSimProjectExisting}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </FormControl>
                </Grid>
                <Grid item xs={12} className='pb-3'>
                    <div className='pb-1'>
                        <small className='color-black'>Nombre del grupo:</small>
                    </div>
                    <InputStyled
                        value={enerSimCategory ? 'enerSIM' : nick}
                        onChange={handleChangeNick}
                        fullWidth={true}
                        onBlur={validateNickProject}
                        maxLength={64}
                        error={enerSimCategory ? '' : validNameGroup}
                        disabled={enerSimCategory}
                    />
                </Grid>
                {listLogos.map((logo, ind) => {
                    return (
                        <Grid key={ind} item xs={12} md={12} lg={12} xl={12} className='pb-3'>
                            <FormControl fullWidth variant='outlined' size='small' className='pb-2'>
                                <Grid container>
                                    <Grid item xs={6} md={6} lg={6} xl={6} className='pb-1'>
                                        <small className='color-black'>Selecciona la posición ({`logo ${ind + 1}`}):</small>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} xl={6} className='pb-1 flex-center-end'>
                                        {Object?.entries(files)?.length > 0 && (
                                            <>
                                                {showDropZone[ind] === true ? (
                                                    <span
                                                        className={`button-accordion-project rotate-button-acordion mr-1 border-1 box-shadow-none`}
                                                        onClick={() => handleShowDropZone(ind)}
                                                    >
                                                        <IconArrowAccordion className='accordion-icon' stroke='#FF8A1F' />
                                                    </span>
                                                ) : (
                                                    <ButtonStyledIcon
                                                        padding={'0 !important'}
                                                        className='boton-edit-alarm mr-1'
                                                        title='Editar'
                                                        onClick={() => handleShowDropZone(ind)}
                                                    >
                                                        <IconEditPencil className='boton-edit-hijo' />
                                                    </ButtonStyledIcon>
                                                )}
                                                <ButtonStyledIcon
                                                    padding={'0 !important'}
                                                    className='boton-delete-alarm'
                                                    title='Eliminar imagen'
                                                    onClick={() => {
                                                        deleteListLogo(logo, ind)
                                                    }}
                                                >
                                                    <IconDeleteAlarm className='boton-delete-hijo' />
                                                </ButtonStyledIcon>
                                            </>
                                        )}
                                    </Grid>
                                    {positions.map((pos, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={5} className={`flex-center justify-start pt-2 pb-2 shaded`}>
                                                <Typography className='font-size-12 pl-2'>{pos.position}</Typography>
                                            </Grid>
                                            <Grid item xs={1} className={`flex-center justify-start pt-2 pb-2 shaded`}>
                                                <SwitchStyled
                                                    className='switch-small'
                                                    checked={position[ind] === pos.value}
                                                    onChange={(e) => handlePosition(ind, pos.value)}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </FormControl>
                            {showDropZone[ind] && (
                                <>
                                    <div className='pb-1'>
                                        <small className='color-black'>Subir logo:</small>
                                    </div>
                                    {!loading && logos && showDropZone[ind] && (
                                        <DropZone
                                            key={logo + ind}
                                            onFileUpload={(file) => {
                                                handleFiles(logo, file)
                                            }}
                                            acceptedExtensions={['image/jpeg', 'image/jpg', 'image/png']}
                                            maxFilesAcept={1}
                                            disabled={Object.keys(logos).length === 3}
                                            messageZone='Arrastra y suelta archivos .jpg, .jpeg o .png aquí, o haz clic para seleccionar archivos (El archivo no puede superar los 100kb)'
                                            maxSize={100}
                                            titleFiles='Nombre del fichero:'
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                    )
                })}
                {Object?.entries(files)?.length > 0 && (
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <h4 className='color-black'>Previsualización logo:</h4>
                        </div>
                        <div className='flex-space-between' key={keyLogos}>
                            {Object?.entries(files)?.map(([clave, logo]) => {
                                return (
                                    <div>
                                        <h5 className='color-black'>Logo {clave.split('logo')[1]}:</h5>
                                        {logo[0] && (
                                            <Avatar
                                                sx={{
                                                    bgcolor: 'white',
                                                    maxWidth: '10em',
                                                    width: 'auto',
                                                    height: '5em',
                                                    border: '1px solid #cbcbcb',
                                                    borderRadius: '0'
                                                }}
                                            >
                                                <img
                                                    className='pt-2 pb-2 pr-3 pl-3'
                                                    style={{ maxWidth: '10em', height: '5em' }}
                                                    src={URL.createObjectURL(logo[0])}
                                                    alt='logo'
                                                />
                                            </Avatar>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <div className='avatar-container'>
                    {listLogos?.length < 3 && (
                        <div
                            title='Subir imagen'
                            className={`${files['logo' + listLogos?.length] === undefined || position[listLogos?.length - 1] === -1 ? 'button-add-disabled' : 'button-add'} mb-3 mt-2`}
                            onClick={() =>
                                files['logo' + listLogos?.length] === undefined || position[listLogos?.length - 1] === -1
                                    ? {}
                                    : addListLogo()
                            }
                        >
                            <IconNewObjectBig
                                className={`${files['logo' + listLogos?.length] === undefined || position[listLogos?.length - 1] === -1 ? 'icon-color-enerclic-disabled' : ''} icon-hover-with-text`}
                            />
                        </div>
                    )}
                </div>
            </Grid>
        </Modal>
    )
}
