import React, { useEffect, useState } from 'react'
import { Modal } from 'components/Modal/Modal'
import { ManualControl } from 'views/Scada/CcMaster/tabs/ManualControl/ManualControl'
import { TableManualControl } from 'views/Scada/CcMaster/tabs/ManualControl/TableManualControl'
import { Grid } from '@mui/material'
import { urls } from 'utils/constant'
import { headersScada } from 'services/headers'
import { Loader } from 'components/Loader/Loader'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'

export const ModalCalendar = ({
    setTableManual,
    manualControl,
    data,
    open,
    setOpen,
    loading,
    setLoading,
    checkedInternal,
    loadingAlias,
    setLoadingAlias,
    error,
    setError,
    index,
    setIndex,
    isMobile,
    idCcMaster,
    pulse,
    setPulse,
    setData,
    keySwitch,
    setKeySwitch,
    loadingOn,
    setLoadingOn,
    setUpdateCalendar,
    updateCalendar,
    centroControl
}) => {
    const token = sessionStorage?.token
    const [relay, setRelay] = useState()
    const [loadingRelay, setLoadingRelay] = useState(false)

    const fetchRelay = () => {
        setRelay([])
        setLoadingRelay(true)
        fetch(urls.scada + `calendarRelay/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'GET', headers: headersScada(token) })
            .then((res) => {
                if (res.ok) {
                } else {
                }
                return res.json()
            })
            .then((data) => {
                setLoadingRelay(false)
                setRelay(data)
            })
            .catch((err) => console.error('ERROR. API Scada', err))
    }

    const reloadRelay = () => {
        fetchRelay()
    }

    useEffect(() => {
        fetchRelay()
    }, [])

    return (
        <>
            <Modal open={open} setOpen={setOpen} maxWidth='xl' footer=' ' className='pb-3' title='Editar on/off Master'>
                {loadingRelay ? (
                    <Grid items xs={12} className='d-flex justify-content-center align-items-center mt-5'>
                        <Loader />
                    </Grid>
                ) : relay?.length > 1 ? (
                    <Grid items xs={12}>
                        <span className='d-flex justify-content-center align-items-center mb-5'>
                            <ManualControl
                                setTableManual={setTableManual}
                                centroControl={centroControl}
                                manualControl={manualControl}
                                dataRelay={data}
                                loadingAlias={loadingAlias}
                                setLoadingAlias={setLoadingAlias}
                                error={error}
                                keySwitch={keySwitch}
                                setKeySwitch={setKeySwitch}
                                setPulse={setPulse}
                                pulse={pulse}
                                checkedInternal={checkedInternal}
                                index={index}
                                setIndex={setIndex}
                                loadingOn={loadingOn}
                                setLoadingOn={setLoadingOn}
                                isMobile={isMobile}
                                idCcMaster={idCcMaster}
                                data={relay}
                                loading={loading}
                                setLoading={setLoading}
                                setUpdateCalendar={setUpdateCalendar}
                                setData={setData}
                                updateDevice={updateCalendar}
                            />
                        </span>
                        <TableManualControl
                            dataRelay={data}
                            pulse={pulse}
                            loadingAlias={loadingAlias}
                            centroControl={centroControl}
                            setLoadingAlias={setLoadingAlias}
                            error={error}
                            setError={setError}
                            setPulse={setPulse}
                            keySwitch={keySwitch}
                            setKeySwitch={setKeySwitch}
                            checkedInternal={checkedInternal}
                            loadingOn={loadingOn}
                            index={index}
                            setIndex={setIndex}
                            setLoadingOn={setLoadingOn}
                            idCcMaster={idCcMaster}
                            loading={loading}
                            setLoading={setLoading}
                            data={relay}
                            setUpdateCalendar={setUpdateCalendar}
                        />
                    </Grid>
                ) : (
                    <Grid items xs={12}>
                        <Grid items xs={12} className='d-flex justify-content-center align-items-center flex-column mt-6 mb-3'>
                            <h2>El máster no está conectado</h2>
                            <ButtonStyled onClick={() => reloadRelay()}>Intentar Conectar</ButtonStyled>
                        </Grid>
                    </Grid>
                )}
            </Modal>
        </>
    )
}
