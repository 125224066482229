// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:hover {
  border-color: #2196f3;
}

.rejectFilesCont:hover {
  border-color: #ff1744 !important;
}

.acceptFiles {
  border-color: #00e676;
}

.rejectFiles {
  border-color: #ff1744;
}`, "",{"version":3,"sources":["webpack://./src/components/DropZone/DropZone.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;EACA,oBAAA;EACA,yBAAA;EACA,cAAA;EACA,aAAA;EACA,oCAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,gCAAA;AACJ;;AACA;EACI,qBAAA;AAEJ;;AACA;EACI,qBAAA;AAEJ","sourcesContent":[".dropzone {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    border-color: #eeeeee;\n    border-style: dashed;\n    background-color: #fafafa;\n    color: #bdbdbd;\n    outline: none;\n    transition: border 0.24s ease-in-out;\n}\n\n.dropzone:hover {\n    border-color: #2196f3;\n}\n\n.rejectFilesCont:hover {\n    border-color: #ff1744 !important;\n}\n.acceptFiles {\n    border-color: #00e676;\n}\n\n.rejectFiles {\n    border-color: #ff1744;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
