import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { ButtonModal } from 'components/Buttons/ButtonModal'
import { LogoEnerclic, LogoMinerva } from 'utils/icon-centrocontrol'
import { ImageRotator } from './imageRotator'
import './styleNotFound.scss'

export const NotFound = () => {
    return (
        <span className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
            <div>
                <h1 style={{ fontSize: '126px' }}>404</h1>
                <h2> Página no encontrada, por favor, inténtelo de nuevo más tarde</h2>
            </div>
        </span>
    )
}
class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.hasError) {
            let div = document.getElementById('webpack-dev-server-client-overlay')
            if (div) {
                div.parentNode.removeChild(div)
            }
            let prevUrl = window.location.href
            let link = null
            if (prevUrl.includes('admin')) {
                link = `/projects/inicio`
            } else if (prevUrl.includes('projects')) {
                link = `/auth/login`
            }

            return (
                <Grid container className='container-not-found flex-center justify-center'>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='flex-center justify-center flex-column align-items-center'>
                        <Grid item xs={10} lg={6} className='mt-2 mb-2'>
                            <ImageRotator style={{ maxWidth: '100%', maxHeight: '450px', marginBottom: '15px' }} />
                        </Grid>
                        <LogoMinerva width='150' height='68' />
                        <Grid item xs={10} lg={12} className='mt-2 mb-2'>
                            <p className='text-not-found'>Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.</p>
                            <p className='text-not-found '>
                                Si el error persiste, por favor, póngase en contacto con nuestro email de ayuda y soporte:{' '}
                                <b>soporte@enerclic.es</b>
                            </p>
                        </Grid>
                        <Grid item xs={8} className='d-flex alig-items-center justify-content-center mt-4 mb-3'>
                            <a
                                style={{ textDecoration: 'none !important' }}
                                className='button-return-notFound'
                                href={prevUrl.includes('scada') || prevUrl.includes('login') ? undefined : link}
                                onClick={(e) => {
                                    if (prevUrl.includes('scada') || prevUrl.includes('login')) {
                                        e.preventDefault()
                                        window.location.reload()
                                    }
                                }}
                            >
                                <ButtonModal
                                    fullWidth={true}
                                    className='button-ok-modal'
                                    onClick={(e) => {
                                        if (!(prevUrl.includes('scada') || prevUrl.includes('login'))) {
                                            this.scadaRefresh(prevUrl)
                                        }
                                    }}
                                >
                                    {prevUrl.includes('scada') || prevUrl.includes('login') ? 'Actualizar' : 'Volver'}
                                </ButtonModal>
                            </a>
                            <ButtonModal fullWidth={true} className='button-cancel-modal'>
                                <a href='mailto:soporte@enerclic.es'>Soporte Técnico</a>
                            </ButtonModal>
                        </Grid>
                        <LogoEnerclic width='100' height='35' />
                    </Grid>
                </Grid>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary

export const NotFoundVerifi = () => {
    return (
        <Grid container className='container-not-found flex-center justify-center'>
            <Grid item xs={12} md={12} lg={12} xl={12} className='flex-center justify-center flex-column align-items-center'>
                <Grid item xs={10} lg={6} className='mt-2 mb-2'>
                    <ImageRotator style={{ maxWidth: '100%', maxHeight: '450px', marginBottom: '15px' }} />
                </Grid>
                <LogoMinerva width='150' height='68' />
                <Grid item xs={10} lg={12} className='mt-2 mb-2'>
                    <p className='text-not-found'>Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.</p>
                    <p className='text-not-found '>
                        Si el error persiste, por favor, póngase en contacto con nuestro email de ayuda y soporte:{' '}
                        <b>soporte@enerclic.es</b>
                    </p>
                </Grid>
                <LogoEnerclic width='100' height='35' />
            </Grid>
        </Grid>
    )
}
