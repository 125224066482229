import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from './TableDataCalendarCCMasterStyle'
import { DownloadTablePDF } from './DownloadTablePDF'

export const TableDataCalendar = ({ relayOneData, relayTwoData, selectedMaster, dates }) => {
    return (
        <Document>
            <Page size='A4' orientation='landscape' style={styles.page}>
                {relayOneData?.length > 0 ? (
                    <DownloadTablePDF master={selectedMaster} title={'Relay 1'} dates={dates} data={relayOneData} />
                ) : null}
                {relayTwoData?.length > 0 ? (
                    <DownloadTablePDF master={selectedMaster} title={'Relay 2'} dates={dates} data={relayTwoData} />
                ) : null}
                {relayOneData?.length === 0 && relayTwoData?.length === 0 && (
                    <View style={styles.noDataContainer}>
                        <Text style={styles.noDataText}>No hay datos disponibles</Text>
                    </View>
                )}
            </Page>
        </Document>
    )
}
