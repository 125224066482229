// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circleFather {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 43% !important;
}
.circleFather .circle {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circleFather .circle canvas {
  width: 150px !important;
  height: 150px !important;
}

@media (max-width: 500px) {
  .circleFather .circle {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 70%;
  }
  .circleFather .circle canvas {
    width: 1em !important;
    height: 1em !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/SelfConsumption/GraphsUtils/circle.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,2BAAA;AACJ;AACI;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACR;AAAQ;EACI,uBAAA;EACA,wBAAA;AAEZ;;AAGA;EAEQ;IACI,aAAA;IACA,aAAA;IACA,mBAAA;IACA,uBAAA;IACA,gBAAA;EADV;EAEU;IACI,qBAAA;IACA,sBAAA;EAAd;AACF","sourcesContent":[".circleFather {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 43% !important;\n\n    .circle {\n        height: 100px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        canvas {\n            width: 150px !important;\n            height: 150px !important;\n        }\n    }\n}\n\n@media (max-width: 500px) {\n    .circleFather {\n        .circle {\n            height: 100px;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            margin-left: 70%;\n            canvas {\n                width: 1em !important;\n                height: 1em !important;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
