import React, { useState, useEffect } from 'react'
import { Grid, TextField } from '@mui/material'
import { urls } from 'utils/constant'
import InputStyled from 'components/Input/InputStyled'
import { validatorInput } from 'utils/ValidatorForm'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { headersCentroControl } from 'services/headers'
import { convertDatetime } from 'utils/Utils'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

export const ModalInfoInverter = ({ modal, setModal, ...props }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [notes, setNotes] = useState()
    const [loading, setLoading] = useState(false)
    const [alias, setAlias] = useState()
    const [numSerie, setNumSerie] = useState()
    const [firmware, setFirmware] = useState()
    const [date, setDate] = useState()

    const [isDisabled, setIsDisabled] = useState(true)
    const [validNameDevice, setValidNameDevice] = useState(false)
    const [validNotes, setValidNotes] = useState(false)
    const [originalData, setOriginalData] = useState({})

    const toggle = () => setModal(!modal)

    const handleUpdate = () => {
        patchData()
    }

    const handleChangeNick = (e) => {
        setAlias(e)
        setValidNameDevice(validatorInput(e))
    }

    const handleChangeNotes = (e) => {
        if (props?.devices?.data?.currentData?.notes !== undefined) {
            setNotes(props?.devices?.data?.currentData?.notes === null && e === '' ? null : e)
        } else {
            setNotes(props?.devices?.notes === null && e === '' ? null : e)
        }
        setValidNotes(validatorInput(e))
    }

    useEffect(() => {
        if (alias && !validNameDevice && !validNotes) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [alias, validNameDevice, validNotes])

    useEffect(() => {
        setValidNameDevice(false)
        setValidNotes(false)
    }, [modal])

    useEffect(() => {
        const getData = async ({ numserie, id_padre, master_serial }) => {
            let url

            if (master_serial) {
                url = `userInfoDeviceChildren/${numserie}/${master_serial}`
            } else if (id_padre) {
                url = `userInfoDeviceChildren/${numserie}/${id_padre}`
            } else if (numserie) {
                url = `userInfoDeviceChildren/${numserie}/${numserie}`
            } else {
                console.error('No se proporcionaron las variables necesarias.')
            }

            try {
                const res = await fetch(urls.ccontrol + url, {
                    method: 'GET',
                    headers: headersCentroControl(token)
                })
                if (res.ok) {
                    const data = await res.json()
                    setAlias(data.nick)
                    setNotes(data.notes)
                    setFirmware(data.FW_version)
                    setDate(convertDatetime(data.fecha_sync))
                    setNumSerie(data.numserie)
                    setOriginalData({ ...data })
                } else {
                    console.error('Error')
                }
            } catch (error) {
                console.error('Error en la solicitud')
            }
        }
        getData({
            numserie: props?.device?.[0]?.numserie,
            id_padre: props?.device?.[0]?.id_padre,
            master_serial: props?.device?.[0]?.master_serial
        })
    }, [props.device, modal])

    const patchData = async () => {
        setLoading(true)
        let status
        let url = `updateDeviceInfo/${props?.device?.[0]?.numserie}`
        if (props.device?.[0].cloud) {
        } else if (props.device[0].master_serial) {
            url += `?idCCMaster=${props.device[0].master_serial}`
        } else if (props.device[0].id_padre) {
            url += `?idCCMaster=${props.device[0].id_padre}`
        }
        fetch(urls.scada + url, {
            method: 'PATCH',
            headers: headersScada(token),
            body: JSON.stringify({
                nick: alias,
                notes: notes
            })
        })
            .then((res) => {
                setLoading(false)
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                toggle()
            })
            .catch((err) => {
                toggle()
                console.error('ERROR. API Scada', err)
            })
    }

    const original = {
        alias: originalData?.nick,
        notes: originalData.notes
    }

    useEffect(() => {
        const OriginalNotesValue = original?.notes === '' ? null : original?.notes
        const notesValue = notes === '' ? null : notes

        if (originalData && alias && !validNameDevice && !validNotes && (original?.alias !== alias || OriginalNotesValue !== notesValue)) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [alias, notes, original, originalData])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                setOpen={setModal}
                open={modal}
                title={`Información del dispositivo "${originalData?.nick}"`}
                functSave={handleUpdate}
                loading={loading}
                disabled={isDisabled}
            >
                <Grid container className='pr-5 pl-5 mt-3'>
                    <Grid className='pr-2 pb-3' item xs={12} md={4} lg={4} xl={4}>
                        <div className='pb-1'>
                            <small className='color-black'>Número de serie:</small>
                        </div>
                        <InputStyled value={numSerie} disabled={true} fullWidth={true} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4} className='pr-2'>
                        <div className='pb-1 '>
                            <small className='color-black'>Fecha de registro:</small>
                        </div>
                        <InputStyled value={date} disabled={true} fullWidth={true} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <div className='pb-1 '>
                            <small className='color-black'>Firmware de CCM:</small>
                        </div>
                        <InputStyled value={firmware ? firmware : '-'} disabled={true} fullWidth={true} />
                    </Grid>
                    <Grid className='pb-3' item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className='color-black'>Nombre del dispositivo:</small>
                        </div>
                        <InputStyled value={alias} onChange={handleChangeNick} fullWidth={true} error={validNameDevice} maxLength={100} />
                    </Grid>
                    <Grid className='pb-3' item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className='color-black'>Notas:</small>
                        </div>
                        <TextField
                            fullWidth
                            className='style-textfield-text'
                            id='outlined-multiline-static'
                            multiline
                            rows='4'
                            value={notes}
                            error={validNotes}
                            helperText={validNotes ? 'Caracteres no permitidos' : ''}
                            onChange={(e) => handleChangeNotes(e.target.value)}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
