import React, { useState, useEffect } from 'react'
import { Pie } from 'react-chartjs-2'
import { dataFormat, filterList } from './GraphUtils'
import { Chart } from './LineChart'

export const GraphConstructor = (props) => {
    const [dataPrueba, setDataPrueba] = useState()
    const [graph, setGraph] = useState(<Chart />)
    const [loading, setLoading] = useState(false)

    const LineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
            radius: {
                duration: 400,
                easing: 'linear',
                loop: (context) => context.active
            }
        },
        hoverRadius: 6,
        hoverOpacity: 0.6,
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
        scales: {
            y: {
                // min: -1,
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        size: 9
                    },
                    callback: function (value) {
                        if (props?.nombre?.[props?.nombre?.length - 1] === 'Battery' && props?.nombre?.[0] === 'Carga batería (%)') {
                            return value.toFixed(2) + ' %'
                        } else if (props?.nombre?.[props?.nombre?.length - 1] === 'Battery' && props?.nombre?.[0] === 'Corriente (A)') {
                            return value.toFixed(2) + ' A'
                        } else if (props?.nombre?.[props?.nombre?.length - 1] === 'Battery' && props?.nombre?.[0] === 'Tensión (V)') {
                            return value.toFixed(2) + ' V'
                        } else if (props?.nombre?.[0].includes('h') === false) {
                            return value.toFixed(2) + ' kW'
                        } else {
                            return value.toFixed(2) + ' kWh'
                        }
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 12
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                },
                display: true,
                position: 'bottom',
                align: 'center'
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || ''
                        if (label) {
                            label += ' : '
                        }
                        if (context.parsed.y !== null) {
                            if (context.dataset.label === 'Carga batería (%)') {
                                return (label += new Intl.NumberFormat().format(context.parsed.y) + ' %')
                            } else if (context.dataset.label === 'Tensión (V)') {
                                return (label += new Intl.NumberFormat().format(context.parsed.y) + ' V')
                            } else if (context.dataset.label === 'Corriente (A)') {
                                return (label += new Intl.NumberFormat().format(context.parsed.y) + ' A')
                            } else if ((props?.nombre?.[0]).includes('h') === false) {
                                return (label += new Intl.NumberFormat().format(context.parsed.y) + ' kW')
                            } else {
                                return (label += new Intl.NumberFormat().format(context.parsed.y) + ' kWh')
                            }
                        }
                        return label
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    speed: 0.5,
                    threshold: 11
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1
                    },
                    mode: 'x'
                }
            }
        }
    }

    const batteryLineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
            radius: {
                duration: 400,
                easing: 'linear',
                loop: (context) => context.active
            }
        },
        hoverRadius: 6,
        hoverOpacity: 0.6,
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    offset: true,
                    borderColor: '#8c8c8c', // Cambia el color del borde del eje Y
                    borderWidth: 3
                },

                ticks: {
                    font: {
                        size: 12
                    },
                    callback: function (value) {
                        if (props?.nombre?.[0].includes('h') === false) {
                            return value.toFixed(2) + ' kW'
                        } else {
                            return value.toFixed(2) + ' kWh'
                        }
                    }
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                    borderColor: '#8c8c8c', // Cambia el color del borde del eje Y
                    borderWidth: 3,
                    borderDash: [5, 5]
                },
                min: 0,
                max: 100,
                ticks: {
                    font: {
                        size: 12,
                        color: '#8c8c8c'
                    },

                    callback: function (value) {
                        return value.toFixed(2) + ' %'
                    },
                    border: {
                        display: true,
                        color: 'red',
                        width: 10
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 9
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                },
                display: true,
                position: 'bottom',
                align: 'center'
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || ''
                        if (label) {
                            label += ' : '
                        }
                        if (context.parsed.y !== null) {
                            if (context.dataset.label === 'Carga batería (%)') {
                                return (label += new Intl.NumberFormat().format(context.parsed.y) + ' %')
                            } else if ((props?.nombre?.[0]).includes('h') === false) {
                                return (label += new Intl.NumberFormat().format(context.parsed.y) + ' kW')
                            } else {
                                return (label += new Intl.NumberFormat().format(context.parsed.y) + ' kWh')
                            }
                        }
                        return label
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    speed: 0.5,
                    threshold: 11
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1
                    },
                    mode: 'x'
                }
            }
        }
    }

    const [dataPruebaPower, setDataPruebaPower] = useState()
    const [selectedDvice, setSelectedDevice] = useState()
    const [nicks, setNicks] = useState()

    useEffect(() => {
        setLoading(!loading)
    }, [])

    const nSerie = (serie) => {
        if (props?.nombre !== 'Potencias Fases (kW)') {
            let res = []
            for (let i = 0; i < serie?.length; i++) {
                if (serie?.[i]?.data?.numserie !== undefined && res.includes(serie?.[i]?.data?.numserie) === false) {
                    res.push(serie?.[i]?.data?.numserie)
                }
            }
            return res
        }
    }

    const noRepeatNicks = (serieData, serieNum) => {
        if (props?.nombre !== 'Potencias Fases (kW)') {
            let res = []
            let aux = 0
            for (let i = 0; i < serieData?.length && aux < serieNum?.length; i++) {
                if (serieData?.[i]?.data?.numserie === serieNum?.[aux]) {
                    if (serieData?.[i]?.data?.nick) {
                        if (serieData?.[i]?.data?.nick !== undefined && serieData?.[i]?.data?.nick !== null) {
                            res.push(serieData?.[i]?.data?.nick)
                            aux = aux + 1
                        }
                    } else if (serieData?.[i]?.data?.[0]?.nick) {
                        if (serieData?.[i]?.data?.[0]?.nick !== undefined && serieData?.[i]?.data?.[0]?.nick !== null) {
                            res.push(serieData?.[i]?.data?.[0]?.nick)
                            aux = aux + 1
                        }
                    }
                } else if (serieData?.[i]?.data?.numserie !== serieNum?.[aux] && serieData?.[i]?.data?.numserie === serieNum?.[aux + 1]) {
                    res.push(null)
                    aux = aux + 1
                    if (serieData?.[i]?.data?.nick !== undefined && serieData?.[i]?.data?.nick !== null) {
                        res.push(serieData?.[i]?.data?.nick)
                        aux = aux + 1
                    }
                }
            }
            return res
        }
    }

    const nicksExtractor = (serie, name, time) => {
        if (props?.nombre !== 'Potencias Fases (kW)') {
            let nSeries = []
            let nNicks = []
            if (time === 'hours' || time === 'days' || time === 'years' || time === 'months') {
                nSeries = nSerie(serie)
                nNicks = noRepeatNicks(serie, nSeries)
            } else {
                if (serie?.[0]?.data?.nick !== undefined) {
                    for (let i = 0; i < serie?.length; i++) {
                        nNicks.push(serie?.[i]?.data?.nick)
                    }
                }
            }
            setNicks({
                nicks: nNicks,
                nSeries: nSeries,
                name: name,
                daily: props?.daily
            })
        }
    }

    useEffect(() => {
        if (props?.nombre !== 'Potencias Fases (kW)') {
            nicksExtractor(props?.historicalData, props?.nombre, props?.daily)
            setDataPruebaPower(dataFormat(props?.props, nicks, props?.graphStyle, props?.updatedList))
            setDataPrueba(dataFormat(props?.historicalData, nicks, props?.graphStyle, props?.updatedList))
        }
    }, [loading, props?.powerData])

    const [filtered, setFiltered] = useState()

    useEffect(() => {
        if (props?.nombre !== 'Potencias Fases (kW)') {
            if (props?.updatedList !== undefined) {
                setFiltered(props?.updatedList)
            }
        }
    }, [props?.updatedList])

    useEffect(() => {
        let aux
        setSelectedDevice(null)
        setSelectedDevice(props?.activeTabDevice)
        if (selectedDvice === 0) {
            aux = filterList(dataPruebaPower, filtered)
            let aux1 =
                aux.datasets !== undefined &&
                aux.datasets.length > 0 &&
                nicks?.name?.[nicks?.name?.length - 1] !== 'Battery' &&
                aux.datasets.filter((element) => element.label === 'Carga batería (%)')
            setGraph(
                <Chart
                    className={'chart'}
                    dataFilter={filterList(aux, filtered)}
                    LineOptions={aux1 !== undefined && aux1.length > 0 ? batteryLineOptions : LineOptions}
                    graphStyle={props?.graphStyle}
                    daily={props?.daily}
                />
            )
        } else if (selectedDvice === 1 || selectedDvice === 2) {
            if (props?.nombre?.[props?.nombre?.length - 1]?.includes('General')) {
                setGraph(
                    <Chart
                        dataFilter={filterList(dataPruebaPower, filtered)}
                        LineOptions={LineOptions}
                        graphStyle={props?.graphStyle}
                        daily={props?.daily}
                    />
                )
            } else {
                if (dataPruebaPower?.datasets?.[0]?.label === 'Batería (kW)' || dataPruebaPower?.datasets?.[0]?.label === 'Corriente (A)') {
                    let aux = filterList(dataPruebaPower, filtered)
                    if (aux.datasets[0]) {
                        if (aux.datasets[0].label === 'Batería (kW)') {
                            aux.datasets[0].label = 'Potencia (kW)'
                        }
                    }
                    setGraph(
                        <Chart
                            dataFilter={filterList(aux, filtered)}
                            LineOptions={LineOptions}
                            graphStyle={props?.graphStyle}
                            daily={props?.daily}
                        />
                    )
                } else {
                    setGraph(
                        <Chart
                            dataFilter={filterList(dataPrueba, filtered)}
                            LineOptions={LineOptions}
                            graphStyle={props?.graphStyle}
                            daily={props?.daily}
                        />
                    )
                }
            }
        } else if (selectedDvice === 3) {
            setGraph(
                <Chart
                    dataFilter={filterList(dataPruebaPower, filtered)}
                    LineOptions={LineOptions}
                    graphStyle={props?.graphStyle}
                    daily={props?.daily}
                />
            )
        } else if (selectedDvice === 4) {
            setGraph(
                <Chart
                    dataFilter={filterList(props?.dataFilter, props?.updatedList)}
                    LineOptions={LineOptions}
                    graphStyle={props?.graphStyle}
                    daily={props?.daily}
                />
            )
        } else if (props?.nombre?.[0] === 'solar' || props?.nombre?.[0] === 'consumoRadial' || props?.nombre?.[0] === 'redRadial') {
            setGraph(
                <Chart
                    dataFilter={filterList(dataPruebaPower, filtered)}
                    className={props?.className}
                    LineOptions={LineOptions}
                    graphStyle={props?.graphStyle}
                    daily={props?.daily}
                />
            )
        }
    }, [props, dataPrueba, dataPruebaPower, loading, filtered, props?.updatedList])

    useEffect(() => {
        setLoading(!loading)
    }, [props])

    return <>{graph}</>
}

/**
 *
 * @param {{datos: number[] || string []}}
 * @param crea la gráfica que se mostrará en el resumen de la pantalla principal
 * @returns Devuelve la gráfica circular (pie) que hay en la pantalla principal
 */
export const PieChart = ({ datos }) => {
    const [data, setData] = useState()

    useEffect(() => {
        setData({
            datasets: [
                {
                    data: datos,
                    backgroundColor: ['#35A151', '#C2DBB3', '#3B9FD1'],
                    hoverBackgroundColor: ['#35A151', '#C2DBB3', '#3B9FD1']
                }
            ]
        })
    }, [datos])

    return (
        <>
            {data && (
                <div style={{ width: '150px', height: '150px' }}>
                    <Pie data={data} />
                </div>
            )}
        </>
    )
}
