import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { headersScada } from 'services/headers'
import { alarmas, urls } from 'utils/constant'
import { FooterCcMabt } from './FooterCcMabt'
import { TableCcMabt } from './TableCcMabt'
import { HeadCcMabt } from './HeadCcMabt'

export const CcMabt = ({ mabt, token, idProject, masterPermission }) => {
    const alarma = useSelector((state) => state.alarma)
    const objetoEncontrado = alarma.history.find(
        (objeto) => Number(objeto?.numserie) === Number(mabt?.numserie) || Number(objeto?.numserie) === Number(mabt?.id_padre)
    )
    const alarms = objetoEncontrado?.alarms?.filter((alarm) => Number(alarm.numserie) === Number(mabt?.numserie))
    const alarmComunication = masterPermission?.[mabt?.numserie]?.alarms?.message
        ? {}
        : alarms?.find((ele) => ele?.alarm_id === alarmas?.conexionDataLogger) ||
          masterPermission?.[mabt?.numserie]?.alarms?.message?.find((ele) => ele?.alarm_id === alarmas?.conexionDataLogger)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    const fetchInfoInstalations = async () => {
        try {
            const res = await fetch(urls.scada + `getDataCardMabt/${mabt?.numserie}?idCCMaster=${mabt?.numserie}`, {
                method: 'GET',
                headers: headersScada(token)
            })
            let json = await res.json()
            setData(json)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.error(err)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchInfoInstalations()
    }, [])

    return (
        <Grid item xs={12} md={4} lg={3} xl={2} className='card-device-scada'>
            <div className='mx-2 card-style-prototype card-with-hover'>
                <Grid container className='p-1 height-full'>
                    <HeadCcMabt
                        data={[mabt]}
                        mabt={mabt}
                        permissions={masterPermission[mabt.numserie]}
                        idProject={idProject}
                        token={token}
                    />
                    <TableCcMabt datos={data} alarmComunication={alarmComunication} loading={loading} />
                    <FooterCcMabt datos={data} alarmComunication={alarmComunication} />
                </Grid>
            </div>
        </Grid>
    )
}
