import { useEffect, useState } from 'react'

const eventMapping = {
    onClick: 'click',
    onDoubleClick: 'dblclick'
}

const useGoogleMapMarker = ({ position, type, maps, map, events, title, ns, icon }) => {
    const [marker, setMarker] = useState()

    useEffect(() => {
        const marker = new maps.Marker({
            position,
            map,
            title,
            ns,
            icon
        })

        Object.keys(events).forEach((eventName) => marker.addListener(eventMapping[eventName], events[eventName]))

        setMarker(marker)
    }, [])

    return marker
}
export default useGoogleMapMarker
