import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, TextField, Card } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { ButtonLogin } from 'components/Buttons/ButtonLogin'
import { Modal } from 'components/Modal/Modal'
import { apiUserNoAutenticated } from 'services/api'
import { validatorEmail, validatorPass } from 'utils/ValidatorForm'
import { keyRecaptcha } from 'utils/constant'
import { iniciarSesion } from 'views/User/Functions/Sesion'
import { RememberPassword } from '../RememberPassword/RememberPassword'
import '../styles.scss'

export const LoginForm = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [mensajeError, setMensajeError] = useState('')
    const [validMail, setValidMail] = useState(true)
    const [passwordShow, setPasswordShow] = useState(false)
    const [validPass, setValidPass] = useState(false)
    const validateForm = () => email.length > 0 && validMail && password.length > 0 && !validPass

    const handleReCaptchaVerify = () => {
        const value = Math.floor(Math.random() * 10)
        if (value >= 3) {
            asynLogin()
        } else {
            setOpen(true)
        }
    }

    const asynLogin = async () => {
        setLoading(true)
        try {
            const response = await apiUserNoAutenticated.post('auth/login', { email: email, pass: password })
            const data = response?.data
            if (response.status === 200) {
                if (data?.services?.includes(4)) {
                    document.authentication_formAcceso_integracion.token_minerva.value = data?.token
                    document.authentication_formAcceso_integracion.submit()
                } else {
                    iniciarSesion(email, data?.token, data)
                    sessionStorage.setItem('name', data?.name)
                    localStorage.setItem('lastLogin', data?.latestLogin)
                    if (data?.token) {
                        sessionStorage.setItem('token', data?.token)
                        sessionStorage.setItem('rol', data?.rol)
                        sessionStorage.setItem('email', email)
                        navigate(`/projects/inicio`)
                    }
                }
            } else if ([215].indexOf(data.status) > -1) {
                document.authentication_formAcceso.email.value = email
                document.authentication_formAcceso.pass.value = password
                document.authentication_formAcceso.submit()
            }
        } catch (err) {
            setMensajeError(err?.response?.data?.message)
            console.error('Error parsing error response:', err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    const handleAsynLogin = (e) => {
        e.preventDefault()
        handleReCaptchaVerify()
    }

    const validateEmail = () => {
        if (email.length > 0) {
            setValidMail(validatorEmail(email))
        }
    }

    const handleBlurMail = () => {
        validateEmail()
    }

    const validatePass = (e) => {
        setPassword(e)
        setValidPass(validatorPass(e))
    }

    const handleRecaptchaChange = (value) => {
        setOpen(false)
        if (value) {
            asynLogin()
        }
    }

    return (
        <>
            <form style={{ width: '100%' }} autoSave='off' onSubmit={handleAsynLogin}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <span className='input-login'>
                        <Grid item xs={12} sm={12} lg={6} xl={6} className='input-login-text'>
                            <TextField
                                required
                                placeholder='Email*'
                                type='email'
                                value={email}
                                name='email'
                                className='textfied-login'
                                onChange={(e) => setEmail(e.target.value)}
                                error={!validMail}
                                helperText={!validMail ? 'Email inválido' : ''}
                                onBlur={handleBlurMail}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} xl={6} className='input-login-text mb-2'>
                            <TextField
                                required
                                placeholder='Contraseña'
                                type={passwordShow ? 'text' : 'password'}
                                autoComplete='off'
                                className='textfied-login'
                                value={password}
                                error={validPass}
                                helperText={validPass ? 'Caracteres no permitidos' : ''}
                                name='password'
                                inputProps={{
                                    maxLength: 65
                                }}
                                InputProps={{
                                    endAdornment: passwordShow ? (
                                        <HtmlTooltip title={'Ocultar contraseña'}>
                                            <FontAwesomeIcon
                                                name='eye'
                                                onClick={() => setPasswordShow(false)}
                                                icon={faEyeSlash}
                                                className='font-size-lg pointer'
                                            />
                                        </HtmlTooltip>
                                    ) : (
                                        <HtmlTooltip title={'Mostrar contraseña'}>
                                            <FontAwesomeIcon
                                                name='eye'
                                                onClick={() => setPasswordShow(true)}
                                                icon={faEye}
                                                className='font-size-lg pointer'
                                            />
                                        </HtmlTooltip>
                                    )
                                }}
                                onChange={(e) => validatePass(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} xl={6} className='input-login-text'>
                            <small className='hoverAction mt-1 mb-1'>
                                <RememberPassword email={email} />
                            </small>
                        </Grid>
                    </span>
                    {mensajeError && (
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='d-flex justify-content-center'>
                            <Card
                                color='danger'
                                style={{
                                    padding: '5px',
                                    textAlign: 'center',
                                    backgroundColor: '#f75676',
                                    color: '#fff',
                                    marginTop: '15px',
                                    marginBottom: '0px',
                                    width: '50%'
                                }}
                            >
                                {mensajeError}
                            </Card>
                        </Grid>
                    )}
                    <div className='text-center mt-4 mb-4 d-flex justify-content-center'>
                        <Grid item xs={4} sm={4} lg={3} xl={3}>
                            <ButtonLogin disabled={!validateForm()} loading={loading}>
                                Entrar
                            </ButtonLogin>
                        </Grid>
                    </div>
                    <span>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='counter-separator'>
                            <span className='line-separator-login'></span>
                            <span className='rounded-separator-login'></span>
                            <span className='line-separator-login'></span>
                        </Grid>
                    </span>
                </Grid>
            </form>
            <Modal open={open} head={true} footer={true}>
                <Grid xs={12} className='recaptcha-center mt-5 mb-5'>
                    <h2 id='recaptcha-modal'>Verificación reCAPTCHA</h2>
                    <ReCAPTCHA sitekey={keyRecaptcha} onChange={handleRecaptchaChange} />
                </Grid>
            </Modal>
        </>
    )
}
