import React, { useEffect, useState } from 'react'
import { FormControl, Grid, MenuItem } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { SelectStyled } from 'components/Select/SelectStyled'
import InputStyled from 'components/Input/InputStyled'
import { validatorNumber, isValidNumber } from 'utils/ValidatorForm'
import { ModalConfigInyectionEms } from './Modal/ModalConfigInyectionEms'
import { configBreaker, logicalSelfConsumption } from './constatEMS'

export const InyectionEms = ({ configEms, setConfigEms, master }) => {
    const [inyectionEnable, setInyectionEnable] = useState(configEms?.self_consumption?.enable)
    const [energyExport, setEnergyExport] = useState(configEms?.self_consumption?.enable_power_to_export)
    const [logic, setLogic] = useState(configEms?.self_consumption?.mode)
    const [disabled, setDisabled] = useState(true)
    const [validTempOff, setValidTempOff] = useState(false)
    const [validTempRec, setValidTempRec] = useState(false)
    const [validPotExp, setValidPotExp] = useState(false)
    const [potExp, setPotExp] = useState(configEms?.self_consumption?.power_to_export)
    const [tempRec, setTempRec] = useState(configEms?.self_consumption?.recon_time)
    const [tempOff, setTempOff] = useState(configEms?.self_consumption?.rele_time)
    const [relayEmergency, setRelayEmergency] = useState(configEms?.self_consumption?.rele_used)
    const [relayConfig, setRelayConfig] = useState(configEms?.self_consumption?.rele_conf)
    const [open, setOpen] = useState(false)

    const handlePotExp = (valor) => {
        setPotExp(valor)
        setValidPotExp(!isValidNumber(valor))
    }

    const handleTempOff = (valor) => {
        setTempOff(valor)
        setValidTempOff(validatorNumber(valor))
    }

    const handleTempRec = (valor) => {
        setTempRec(valor)
        setValidTempRec(validatorNumber(valor))
    }

    const compareOptions = () => {
        let change = true
        if (configEms?.self_consumption?.enable !== inyectionEnable) {
            change = false
        } else if (
            configEms?.self_consumption?.mode !== logic ||
            configEms?.self_consumption?.enable_power_to_export !== energyExport ||
            configEms?.self_consumption?.power_to_export !== Number(potExp) ||
            configEms?.self_consumption?.rele_used !== relayEmergency ||
            configEms?.self_consumption?.rele_conf !== relayConfig ||
            configEms?.self_consumption?.rele_time !== Number(tempOff) ||
            configEms?.self_consumption?.recon_time !== Number(tempRec)
        ) {
            change = false
        }
        setDisabled(change)
    }

    useEffect(() => {
        compareOptions()
    }, [inyectionEnable, logic, energyExport, tempOff, tempRec, potExp, relayEmergency, relayConfig, configEms])

    return (
        <Grid container className='ml-3 mb-4'>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center'>
                <SwitchStyled
                    id='inyection0'
                    className='switch-small'
                    checked={inyectionEnable}
                    onClick={() => setInyectionEnable(!inyectionEnable)}
                />
                <label className='ml-2 mb-0' htmlFor='inyection0'>
                    {' '}
                    Inyeccion 0{' '}
                </label>
            </Grid>
            <Grid item xs={5} md={5} lg={5} xl={5} className='pr-5'>
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center justify-space-between'>
                    <h4 className='ml-0'>Lógica para hacer Autoconsumo</h4>
                    <FormControl size='small' className='width-75'>
                        <SelectStyled
                            value={logic}
                            onChange={(e) => setLogic(e.target.value)}
                            defaultValue={configEms?.self_consumption?.mode}
                        >
                            {logicalSelfConsumption?.map((prm, i) => {
                                return (
                                    <MenuItem value={prm?.value} key={i}>
                                        {prm?.name}
                                    </MenuItem>
                                )
                            })}
                        </SelectStyled>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center justify-space-between'>
                    <h4 className='mb-0' htmlFor='energyexport'>
                        {' '}
                        Habilitar energía para exportar{' '}
                    </h4>
                    <SwitchStyled
                        id='energyexport'
                        className='switch-small'
                        checked={energyExport}
                        onClick={() => setEnergyExport(!energyExport)}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center'>
                    {energyExport && (
                        <span className='section-modal-edit-mp input-mp-modal'>
                            <h4 className='ml-0'>Potencia de exportación (W)</h4>
                            <InputStyled value={potExp} onChange={handlePotExp} error={validPotExp} />
                        </span>
                    )}
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center justify-space-between'>
                    <div>
                        <h4 className='ml-0'>Activar Relé de emergencia</h4>
                        <label>Configurar circuito de Emergencia</label>
                    </div>
                    <SwitchStyled
                        id='relayemergency'
                        className='switch-small'
                        checked={relayEmergency}
                        onClick={() => setRelayEmergency(!relayEmergency)}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center align-items-baseline'>
                    {relayEmergency && (
                        <FormControl size='small' className='width-100'>
                            <SelectStyled
                                value={relayConfig}
                                defaultValue={configEms?.self_consumption?.rele_conf}
                                onChange={(e) => setRelayConfig(e.target.value)}
                            >
                                {configBreaker?.map((prm, i) => {
                                    return (
                                        <MenuItem value={prm?.value} key={i}>
                                            {prm?.name}
                                        </MenuItem>
                                    )
                                })}
                            </SelectStyled>
                        </FormControl>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={7} md={7} lg={7} xl={7} className='pl-5'>
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center align-items-baseline'>
                    <div className='w-30 pr-3'>
                        <h4 className='ml-0'>Tiempo de apagado</h4>
                        <label>Tiempo para apagar el sistema de inyección si algun dispositivo no responde (segundos)</label>
                    </div>
                    <InputStyled value={tempOff} onChange={handleTempOff} error={validTempOff} />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center align-items-baseline'>
                    <div className='w-30 pr-3'>
                        <h4 className='ml-0'>Reconectar</h4>
                        <label>Tiempo para reconectarse después de haberse apagado (segundos)</label>
                    </div>
                    <InputStyled value={tempRec} onChange={handleTempRec} error={validTempRec} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='button-apply-table d-flex justify-content-flex-end mt-3'>
                <ButtonStyled size='small' color='#fff' background='#FF8A1F' disabled={disabled} onClick={() => setOpen(true)}>
                    Aplicar
                </ButtonStyled>
            </Grid>
            {open && (
                <ModalConfigInyectionEms
                    master={master}
                    open={open}
                    setOpen={setOpen}
                    configEms={configEms}
                    tempOff={tempOff}
                    inyectionEnable={inyectionEnable}
                    setConfigEms={setConfigEms}
                    energyExport={energyExport}
                    tempRec={tempRec}
                    potExp={potExp}
                    relayEmergency={relayEmergency}
                    relayConfig={relayConfig}
                    setInyectionEnable={setInyectionEnable}
                    logicSelf={logic}
                />
            )}
        </Grid>
    )
}
