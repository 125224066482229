import React from 'react'
import { Grid } from '@mui/material'
import { ManagementUsers } from './UsersAdministration.jsx'
import './style.scss'

export const AdminView = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <ManagementUsers />
            </Grid>
        </Grid>
    )
}
