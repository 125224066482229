import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SpeedIcon from '@mui/icons-material/Speed'
import { Grid } from '@mui/material'
import { StepsForm } from 'components/StepsForm/StepsForm'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { apiCentroControl } from 'services/api'
import { validatorInput, validatorInputNumber } from 'utils/ValidatorForm'
import { FormCreateSensor } from '../Forms/FormCreateSensor'
import { addSensor, generateBodyCreate } from '../UtilsSensor'

export const ModalCreateSensor = ({ open, setOpen, numserieMabt, medidores, fetchGetAllSensors, dispositivo }) => {
    const steps = ['Nombre', 'Número de serie', 'Medidor', 'Posición', 'Localización', 'Confirmar', 'Añadir']
    const [body, setBody] = useState({
        nick: '',
        numserie: '',
        fecha_sync: '',
        latitud: '',
        longitud: '',
        address: '',
        numserie_medidor: '',
        numserie_mabt: Number(numserieMabt),
        position: -1,
        fase: 'R'
    })
    const [errorInstal, setErrorInstal] = useState({})
    const [positions, setPositions] = useState([])
    const [loading, setLoading] = useState(false)
    const [first, setFirst] = useState(true)
    const [errors, setErrors] = useState({})
    const [step, setStep] = useState(0)
    const dispatch = useDispatch()

    const handleClose = () => {
        setOpen(false)
    }

    const handleBack = () => {
        setStep(step - 1)
        setErrors({})
    }

    const handleNext = () => {
        setStep(step + 1)
        validateNextBody(step + 1)
        if (step + 1 === 3) {
            fetchGetAllPositionsMed()
        }
    }

    const validateNextBody = (stepForm) => {
        let errorsBody = {}
        switch (stepForm) {
            case 1:
                if (body?.numserie?.length > 0 && validatorInputNumber(body?.numserie)) {
                    errorsBody.first = 'disabled'
                    setErrors(errorsBody)
                    onBlurNumserie()
                } else if (!validatorInputNumber(body?.numserie)) {
                    errorsBody.numserie = 'Caracteres no permitidos, el numero de serie solo puede contiene dígitos'
                } else if (body?.numserie?.length === 0) {
                    errorsBody.first = 'disabled'
                }
                break
            case 2:
                if (medidores?.length === 0) {
                    errorsBody.numserie_medidor = 'No existen salidas disponibles'
                } else if (body?.numserie_medidor?.length === 0) {
                    errorsBody.first = 'disabled'
                }
                break
            case 3:
                if (body?.position === -1) {
                    errorsBody.first = 'disabled'
                }
                break
            case 4:
                if (body?.latitud === '' || body?.longitud === '' || body?.address === '') {
                    errorsBody.first = 'disabled'
                }
                break
            default:
                break
        }
        setErrors(errorsBody)
    }

    const validateBody = (stepForm) => {
        let errorsBody = {}
        if (first) {
            errorsBody.first = 'disabled'
            setFirst(false)
        } else {
            switch (stepForm) {
                case 0:
                    if (validatorInput(body?.nick)) {
                        errorsBody.nick = 'Caracteres no permitidos'
                    } else if (body?.nick?.length === 0) {
                        errorsBody.nick = 'Este campo es obligatorio'
                    }
                    break
                case 1:
                    if (!validatorInputNumber(body?.numserie)) {
                        errorsBody.numserie = 'Caracteres no permitidos, el numero de serie solo puede contiene dígitos'
                    } else if (body?.numserie?.length === 0) {
                        errorsBody.numserie = 'Este campo es obligatorio'
                    } else if (body?.numserie?.length > 0 && validatorInputNumber(body?.numserie)) {
                        errorsBody.first = 'disabled'
                    }
                    break
                case 4:
                    if (body?.latitud === '' || body.longitud === '' || body?.address === '') {
                        errorsBody.address = 'Este campo es obligatorio'
                    }
                    break
                default:
                    break
            }
        }
        setErrors(errorsBody)
    }

    const onBlurNumserie = () => {
        if (body?.numserie && errors?.numserie === undefined) {
            fetchValidateNumserie()
        } else if (body?.numserie?.length === 0) {
            let errorsBody = { ...errors }
            errorsBody.numserie = 'Este campo es obligatorio'
            setErrors(errorsBody)
        }
    }

    const fetchCreateSensors = async () => {
        setLoading(true)
        try {
            const result = await apiCentroControl.post('createLineSensor', [generateBodyCreate(body)])
            if (result?.status === 200) {
                setOpen(false)
                fetchGetAllSensors()
                addSensor(body?.numserie_medidor, body?.numserie, dispositivo)
                dispatch(createToast({ status: result.status, message: 'Sensor instalado correctamente', reload: false }))
            }
        } catch (err) {
            dispatch(createToast({ status: 400, message: 'Ha habido un fallo, por favor espere y vuélvalo a intentar', reload: false }))
            console.error('ERROR. API Centro Control', err)
        }
        setLoading(false)
    }

    const fetchGetAllPositionsMed = async () => {
        try {
            const result = await apiCentroControl.post('sensorPosition', { numserie: body?.numserie_medidor })
            if (result?.status === 200) {
                setPositions(result?.data)
                if (result?.data?.length === 0) {
                    let errorsBody = {}
                    errorsBody.position = 'No hay posiciones disponibles para esta salida'
                    setErrors(errorsBody)
                }
            }
        } catch (err) {
            let errorsBody = {}
            errorsBody.position = 'No se han podido obtener las posiciones disponibles del medidor'
            setErrors(errorsBody)
            setPositions([])
            console.error('ERROR. API Centro Control', err)
        }
    }

    const fetchValidateNumserie = async () => {
        try {
            const result = await apiCentroControl.post('canInstall', { numserie: body?.numserie })
            if (result?.status === 200) {
                setErrorInstal({})
            }
        } catch (err) {
            let errorsBody = {}
            errorsBody = { ...errors }
            errorsBody.numserie = 'El numero de serie introducido no es válido'
            setErrorInstal(errorsBody)
        }
    }

    useEffect(() => {
        validateBody(step)
    }, [body])

    useEffect(() => {
        if (step === 1) {
            setErrors(errorInstal)
        }
    }, [errorInstal])

    return (
        <Modal
            fullWidth={true}
            open={open}
            setOpen={setOpen}
            title='Nuevo sensor de línea'
            iconTitle={<SpeedIcon />}
            loading={loading}
            disabled={Object?.keys(errors)?.length !== 0}
            functSave={step < 6 ? handleNext : fetchCreateSensors}
            functCancel={step === 0 ? handleClose : handleBack}
            funtCancelButton={handleClose}
            buttonAction={step === 5 ? 'Confirmar' : step === 6 ? 'Añadir' : 'Siguiente'}
            buttonCancel={step !== 0 && 'Atrás'}
        >
            <Grid container className='pr-5 pl-5 pt-3'>
                <FormCreateSensor
                    onBlurNumserie={onBlurNumserie}
                    medidores={medidores}
                    positions={positions}
                    step={step}
                    body={body}
                    setBody={setBody}
                    errors={errors}
                    validateBody={validateBody}
                />
            </Grid>
            <Grid container className='container-shared-cede-modal'>
                <StepsForm steps={steps} step={step} />
            </Grid>
        </Modal>
    )
}
