import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { PrincipalIpMeter } from 'views/Principal/IP-Meter/PrincipalIpMeter'
import { PrincipalSim } from 'views/Principal/Enersim/PrincipalSIM'
import { Profile } from 'views/User/Profile/Profile'
import { AdminView } from 'views/Admin/Admin'
import { Index } from 'views/Admin/Index'

export const Admin = () => {
    const token = sessionStorage?.token

    return (
        <div className='d-flex' id='adminLayout'>
            <div className='main-content' style={{ margin: 0, flex: '1', width: '100%' }}>
                <Routes>
                    <Route
                        path='inicio/:id/enerSIM'
                        element={!localStorage.getItem(token) ? <Navigate to='/auth/login' replace /> : <PrincipalSim />}
                        key='enerSIM'
                    />
                    <Route
                        path='inicio/:id/:type'
                        element={!localStorage.getItem(token) ? <Navigate to='/auth/login' replace /> : <Index />}
                        key='inicioType'
                    />
                    <Route
                        path='perfil'
                        element={!localStorage.getItem(token) ? <Navigate to='/auth/login' replace /> : <Profile />}
                        key='Perfil'
                    />
                    <Route
                        path='inicio/:id/1'
                        element={!localStorage.getItem(token) ? <Navigate to='/auth/login' replace /> : <PrincipalSim />}
                        key='PrincipalSim'
                    />
                    <Route
                        path='devices'
                        element={!localStorage.getItem(token) ? <Navigate to='/auth/login' replace /> : <PrincipalIpMeter />}
                        key='PrincipalIpMeter'
                    />
                    <Route
                        path='users'
                        element={!localStorage.getItem(token) ? <Navigate to='/auth/login' replace /> : <AdminView />}
                        key='Administrador'
                    />
                </Routes>
            </div>
        </div>
    )
}
