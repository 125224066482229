import React from 'react'
import { Line } from 'react-chartjs-2'
import { callBallGraphs } from 'views/Scada/SelfConsumption/utilsSelfConsumption'

export const GraphsLine = ({ data, options, labels, battery }) => {
    const LineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
            radius: {
                duration: 400,
                easing: 'linear',
                loop: (context) => context.active
            }
        },
        hoverRadius: 6,
        hoverOpacity: 0.6,
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    offset: true,
                    borderColor: '#8c8c8c', // Cambia el color del borde del eje Y
                    borderWidth: 3
                },
                ticks: {
                    font: {
                        size: 12
                    },
                    callback: function (value) {
                        return value.toFixed(2) + (data?.[0]?.unit ?? data?.[0]?.unit ?? ' ')
                    }
                }
            },
            y1: {
                type: 'linear',
                display: battery ? true : false,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                    borderColor: '#8c8c8c', // Cambia el color del borde del eje Y
                    borderWidth: 3,
                    borderDash: [5, 5]
                },
                min: 0,
                max: 100,
                ticks: {
                    font: {
                        size: 12,
                        color: '#8c8c8c'
                    },
                    callback: function (value, context) {
                        return value.toFixed(0) + ' %'
                    },
                    border: {
                        display: true,
                        color: 'red',
                        width: 10
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 10
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                },
                display: true,
                position: 'bottom',
                align: 'center'
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label
                        return (
                            label +
                            ' : ' +
                            new Intl.NumberFormat().format(context.parsed.y) +
                            (context.dataset.unit ? context.dataset.unit : callBallGraphs(label))
                        )
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    speed: 0.5
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1
                    },
                    mode: 'x'
                }
            }
        }
    }

    const dataFilter = {
        labels,
        datasets: data
    }

    return (
        <div>
            <Line style={{ width: '100%', height: '20em' }} data={dataFilter} options={options ? options : LineOptions} />
        </div>
    )
}
