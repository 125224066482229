// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell-with-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}`, "",{"version":3,"sources":["webpack://./src/views/Configuracion/Installation/TableInstall/TableConfigInstallation.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,kCAAA;EACA,8BAAA;AACJ","sourcesContent":[".cell-with-center {\n    display: flex !important;\n    justify-content: center !important;\n    align-items: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
