import { createSlice } from '@reduxjs/toolkit'

export const refreshDeviceSlice = createSlice({
    name: 'refreshDevice',
    initialState: {},
    reducers: {
        updateRefreshDevice: (state, action) => {
            state[action.payload?.numserie] = action.payload?.refresh
        }
    }
})

export const { updateRefreshDevice } = refreshDeviceSlice.actions

export default refreshDeviceSlice.reducer
