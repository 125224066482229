import React from 'react'
import { Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import { TableRow } from 'semantic-ui-react'
import { StyledTableCell } from 'components/Table/TableStyle'
import { toKiloW } from 'utils/functionsforGraphs'
import { formatDate } from 'utils/Utils'

export const TablePhotoSelf = ({ data, headerTable, currentData, devicesSelect }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                <TableHead style={{ borderBottom: '5px solid #FF8A1F', borderRadius: '50px' }}>
                    <TableRow>
                        {headerTable &&
                            headerTable.map((elem) => {
                                return <StyledTableCellHead align='center'>{elem}</StyledTableCellHead>
                            })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.map((elem, key) => {
                            return (
                                <TableRow key={key}>
                                    {devicesSelect.includes(elem?.numserie) && (
                                        <>
                                            <StyledTableCell align='center'>{elem?.nick}</StyledTableCell>
                                            <StyledTableCell align='center'>
                                                {data && currentData ? formatDate(elem?.datetime) : elem?.datetime}
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>{data && toKiloW(elem?.eagendia)}</StyledTableCell>
                                            {data && currentData && <StyledTableCell> {toKiloW(elem?.pact)}</StyledTableCell>}
                                        </>
                                    )}
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
