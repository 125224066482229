import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { Modal } from 'components/Modal/Modal'
import { EnerSim } from 'utils/icon-centrocontrol'
import { convertDatetime } from 'utils/Utils'
import { urls } from 'utils/constant'
import { headersCentroControl } from 'services/headers'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'
import './modalSim.scss'

export const ModalSwitchOffSim = ({ open, setOpen, enerSimData }) => {
    const token = sessionStorage?.token
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [isSwitchActive, setIsSwitchActive] = useState(false)
    const [imei, setImei] = useState()
    const [name, setName] = useState()
    const [date, setDate] = useState()
    const dispatch = useDispatch()

    const deactivateEnerSim = async () => {
        let status
        let message
        try {
            const res = await fetch(urls.ccontrol + 'siminfo', {
                method: 'DELETE',
                headers: headersCentroControl(token),
                body: JSON.stringify({ numserie_sim: enerSimData?.numserie_sim })
            })

            if (res.ok) {
                message = 'EnerSIM desactivada correctamente'
            } else {
                message = 'Error al desactivar la EnerSIM'
            }
            status = res.satus
            dispatch(createToast({ status: status, message: message, reload: false }))
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        }
    }

    const handleSwitchToggle = () => {
        setIsButtonDisabled(!isButtonDisabled)
        setIsSwitchActive(!isSwitchActive)
    }

    useEffect(() => {
        setIsButtonDisabled(true)
        setIsSwitchActive(false)
        setImei(enerSimData?.numserie_sim)
        setName(enerSimData?.installationName)
        setDate(enerSimData?.datetime)
    }, [enerSimData, open])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                open={open}
                setOpen={setOpen}
                title={`Dar de baja enerSim`}
                iconTitle={<EnerSim />}
                functSave={deactivateEnerSim}
                disabled={isButtonDisabled}
            >
                <Grid container className='pl-5 pr-5 mt-2'>
                    <Grid className='pb-3 flex-center' item xs={12}>
                        <small className='color-black'>Nombre:</small>
                        <small className='color-grey pl-2'>{name ? name : '-'}</small>
                    </Grid>
                    <Grid className='pb-3 flex-center' item xs={12}>
                        <small className='color-black'>IMEI:</small>
                        <small className='color-grey pl-2'>{imei ? imei : '-'}</small>
                    </Grid>
                    <Grid className='pb-3 flex-center' item xs={12}>
                        <small className='color-black'>Fecha de alta:</small>
                        <small className='color-grey pl-2'>{convertDatetime(date)}</small>
                    </Grid>
                    <Grid className='pb-3 flex-center' item xs={12}>
                        <small className='color-black'>Fecha de baja:</small>
                        <small className='warning-switch-off-text pl-2'>-- a partir de esta fecha su tarjeta quedará inactiva</small>
                    </Grid>
                    <Grid className='pb-3 flex-center' item xs={12}>
                        <small className='color-black pr-2'>Desactivar</small>
                        <SwitchStyled checked={isSwitchActive} onClick={handleSwitchToggle} className='switch-small' />
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
