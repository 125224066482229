// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-historical-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.calendar-historical-calendar {
  padding: 15px 15px 0px 0px;
  display: flex;
  justify-content: flex-end;
}

.table-historical-calendar {
  margin: 15px 15px 15px 0px;
}`, "",{"version":3,"sources":["webpack://./src/views/Configuracion/Calendar/StyleCalendar.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAHJ;;AAMA;EACI,0BAAA;EACA,aAAA;EACA,yBAAA;AAHJ;;AAMA;EACI,0BAAA;AAHJ","sourcesContent":["//////////////////////////// HISTORICAL CALENDAR CC\n///\n///\n///\n.calendar-historical-flex {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 30px 0px;\n}\n\n.calendar-historical-calendar {\n    padding: 15px 15px 0px 0px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.table-historical-calendar {\n    margin: 15px 15px 15px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
