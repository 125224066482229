import React, { useState, useEffect } from 'react'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconWarning } from 'utils/icon-centrocontrol'
import { toKiloW } from 'utils/functionsforGraphs'
import { dispositivos, monofasic } from 'utils/constant'
import { Chart } from '../../../SelfConsumption/GraphsUtils/LineChart'
import './PhasesActivepower.scss'

export const TotalActivepower = ({ data, sinConectar }) => {
    const pact = data?.id_tipo_dispositivo === dispositivos.ccmabt ? data?.pac1 + data?.pac2 + data?.pac3 : data?.pact
    const [seriesData, setSeriesData] = useState([])
    const [graph, setGraph] = useState()
    const [negativeGraph, setNegativeGraph] = useState()
    const monofasico = monofasic?.includes(data?.id_tipo_dispositivo)
    const hiredPotency =
        data?.id_tipo_dispositivo === dispositivos.ccmabt
            ? data?.max_iac
                ? (data?.max_iac * 230 * 3) / 1000
                : null
            : data?.potencia_contratada
    const hiredPowerToKw = hiredPotency !== ' - ' && hiredPotency !== undefined ? hiredPotency?.toFixed(2) : ' - '

    const pactValue = (value) => {
        if (data?.id_tipo_dispositivo === dispositivos.ccmabt) {
            if (data?.max_iac >= 0 && value) {
                let aux = (value * 100) / ((data?.max_iac * 230 * 3) / 1000)
                if (aux > 100) {
                    aux = 100
                }
                return aux.toFixed(1)
            } else {
                return '0.0'
            }
        }
        if (data?.potencia_contratada >= 0 && value) {
            let aux = (value * 100) / data?.potencia_contratada
            if (aux > 100) {
                aux = 100
            }
            return aux.toFixed(1)
        } else {
            return '0.0'
        }
    }

    const dataFilterPositive = (data, id) => {
        let power = data
        if (data === undefined || data === null || data <= 0 || sinConectar) {
            power = 0
        }
        let rest = 100 - power
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [power, rest],
                    backgroundColor: ['#3B9FD1', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: -90,
                    borderWidth: 2,
                    cutout: '80',
                    radius: '100%'
                }
            ]
        }
        return res
    }

    const dataFilterNegative = (data, id) => {
        let power = data
        if (data === undefined || data === null || data >= 0 || sinConectar) {
            power = 0
        }
        power = Math.abs(power)
        let rest = 100 - power
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [power, rest],
                    backgroundColor: ['#B4DA9D', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: 90,
                    borderWidth: 2,
                    cutout: '80',
                    radius: '100%'
                }
            ]
        }
        return res
    }

    useEffect(() => {
        if (pact && !sinConectar && data?.potencia_contratada !== null) {
            setSeriesData([pactValue(pact)])
        } else {
            setSeriesData([0])
        }
    }, [data?.potencia_contratada])

    useEffect(() => {
        setGraph(<Chart dataFilter={dataFilterPositive(Number(seriesData[0]), 1)} graphStyle={'doughnut'} />)
        setNegativeGraph(<Chart dataFilter={dataFilterNegative(Number(seriesData[0]), 2)} graphStyle={'doughnut'} />)
    }, [data, seriesData])

    return (
        <div className='container-center-column'>
            {graph && negativeGraph && (
                <>
                    <div className='graph-container'>
                        <span className={`power-text ${monofasico ? 'power-text-monofasico-mt' : 'power-text-default-mt'}`}>0 kW</span>
                        <div className='graph-column-container'>
                            {monofasico && (
                                <span className='text-align-center'>
                                    <h3 className='text-center-bold-title'>Potencia activa</h3>
                                </span>
                            )}
                            <div className='graph-wrapper'>{graph}</div>
                            <div
                                className={`graph-content-overlay ${monofasico ? 'graph-overlay-padding-monofasico' : 'graph-overlay-padding-default'}`}
                            >
                                <p>{data?.potencia_contratada !== null && <>{!sinConectar ? toKiloW(pact) + ' kW' : '-'}</>}</p>
                                <p>
                                    {hiredPotency === null ? (
                                        <span>
                                            <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                <IconWarning width={20} height={20} />
                                            </HtmlTooltip>
                                        </span>
                                    ) : pact !== ' - ' && pact && !sinConectar ? (
                                        pactValue(pact) + '%'
                                    ) : (
                                        ' 0.0 %'
                                    )}
                                </p>
                            </div>
                            <div className='negative-graph-wrapper'>{negativeGraph}</div>
                        </div>
                        <span className={`hired-power-text ${monofasico ? 'power-text-monofasico-mt' : 'power-text-default-mt'}`}>
                            {hiredPowerToKw} kW
                        </span>
                    </div>
                </>
            )}
        </div>
    )
}
