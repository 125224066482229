import React, { useEffect, useRef, useState } from 'react'
import { IconButton, Table, Tooltip, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StyledTableCell, StyledTableCellOrdenado, StyledTableRow } from 'components/Table/TableStyle'
import { dispositivos } from 'utils/constant'
import { nombreDispositivo, nombreAlarma, ordenarDatos, breakMin } from 'utils/Utils'
import { ComentarioAlarma } from '../Comentario/ComentarioAlarma'
import { VisuzalizacionAlarma } from '../ShowHideAlarm/VisualizacionAlarma'

/**
 * @param {{ token: string, filas: Number, setFilas: Function, historico: Boolean, data: {}[], setData: Function, visible: Boolean, enviarFormulario: VoidFunction }}
 * @param token Token necesario de la llamada
 * @param filas Estado que guarda el numero de filas a mostrar en la tabla
 * @param setFilas Funcion apara cambiar el estado de las filas
 * @param historico Estado que guarda la informacion de la historico
 * @param data Estado que guarda la informacion de las alarmas activas o historicas
 * @param setData Funcion apara cambiar el estado de data
 * @param visible Estado que indica si se muestran las alarmas visibles o las ocultas
 * @param enviarFormulario Funcion para seleccionar el tipo de dispositivo y el dispositivo
 * @returns devuelve la tabla con las alarmas
 */
export const TablaAlarms = ({ token, filas, setFilas, historico, data, setData, visible, enviarFormulario }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })
    const [modalComentario, setModalComentario] = useState(false)
    const [modalVisualizacion, setModalVisualizacion] = useState(false)
    const [index, setIndex] = useState()
    const [alarma, setAlarma] = useState(false)
    const [tableHeight, setTableHeight] = useState(0)
    const [isVisible, setIsVisible] = useState(false)
    const [hasMore, setHasMore] = useState(data?.length <= filas ? false : true)
    const tableRef = useRef(null)
    const divRef = useRef(null)

    const abrirModalComentario = fila => {
        setAlarma(fila)
        setModalComentario(true)
    }

    const abrirModalVisualizacion = (fila, index) => {
        setIndex(index)
        setAlarma(fila)
        setModalVisualizacion(true)
    }

    const timeoutHandler = () => {
        if (isVisible) {
            setIsVisible(false)
            if (filas + 10 < data?.length) {
                setFilas(filas + 10)
            } else if (filas <= data?.length) {
                setFilas(data?.length)
                setHasMore(false)
            }
        }
    }

    const handleScroll = () => {
        const element = divRef?.current?.getBoundingClientRect()?.bottom - divRef?.current?.getBoundingClientRect()?.top - 30
        const currentPosition = document?.documentElement?.scrollTop + window?.innerHeight
        if (currentPosition >= element && hasMore) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        if (tableRef.current) {
            setTableHeight(tableRef.current.offsetHeight)
        }
    }, [filas])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div className='d-flex'>
            <TableContainer>
                <InfiniteScroll
                    style={{ minHeight: tableHeight + 30 }}
                    dataLength={filas}
                    next={timeoutHandler()}
                    hasMore={hasMore}
                    loader={!isVisible && data?.length > 0 && <h4 className='flex justify-center'>Cargando más alarmas...</h4>}
                >
                    {!data || data?.length === 0 ? (
                        <h4 className='flex justify-center mt-3'>No hay ninguna alarma</h4>
                    ) : (
                        <Table ref={tableRef} sx={{ minWidth: 700 }} aria-label='customized table'>
                            <TableHead className='table-head'>
                                <TableRow>
                                    {!historico && <StyledTableCell align='center' />}
                                    <StyledTableCellOrdenado
                                        align='center'
                                        celda={'instalacion'}
                                        funcion={() => {
                                            ordenarDatos(data, sortConfig, setSortConfig, 'instalacion')
                                        }}
                                        sortConfig={sortConfig}
                                    >
                                        Instalación
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado
                                        align='center'
                                        celda={'nick'}
                                        funcion={() => {
                                            ordenarDatos(data, sortConfig, setSortConfig, 'nick')
                                        }}
                                        sortConfig={sortConfig}
                                    >
                                        Dispositivo
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado
                                        align='center'
                                        celda={'device_type'}
                                        funcion={() => {
                                            ordenarDatos(data, sortConfig, setSortConfig, 'device_type')
                                        }}
                                        sortConfig={sortConfig}
                                    >
                                        Tipo Dispositivo
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado
                                        align='center'
                                        celda={'alarm_id'}
                                        funcion={() => {
                                            ordenarDatos(data, sortConfig, setSortConfig, 'alarm_id')
                                        }}
                                        sortConfig={sortConfig}
                                    >
                                        Tipo Alarma
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado
                                        align='center'
                                        celda={'datetime_active'}
                                        funcion={() => {
                                            ordenarDatos(data, sortConfig, setSortConfig, 'datetime_active')
                                        }}
                                        sortConfig={sortConfig}
                                    >
                                        Fecha activación
                                    </StyledTableCellOrdenado>
                                    {historico && (
                                        <>
                                            <StyledTableCellOrdenado
                                                align='center'
                                                celda={'datetime_solved'}
                                                funcion={() => {
                                                    ordenarDatos(data, sortConfig, setSortConfig, 'datetime_solved')
                                                }}
                                                sortConfig={sortConfig}
                                            >
                                                Fecha finalización
                                            </StyledTableCellOrdenado>
                                            <StyledTableCellOrdenado
                                                align='center'
                                                celda={'duration'}
                                                funcion={() => {
                                                    ordenarDatos(data, sortConfig, setSortConfig, 'duration')
                                                }}
                                                sortConfig={sortConfig}
                                            >
                                                Duración
                                            </StyledTableCellOrdenado>
                                        </>
                                    )}
                                    <StyledTableCellOrdenado
                                        align='center'
                                        celda={'comment'}
                                        funcion={() => {
                                            ordenarDatos(data, sortConfig, setSortConfig, 'comment')
                                        }}
                                        sortConfig={sortConfig}
                                    >
                                        Comentario
                                    </StyledTableCellOrdenado>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.length > 0 &&
                                    data?.slice(0, filas).map((alarma, i) => {
                                        if ((alarma.hide === 0 && visible) || (!visible && alarma.hide === 1) || historico) {
                                            return (
                                                <StyledTableRow style={{ padding: '0px', margin: '0px' }} key={i + 'tablaAlarm'}>
                                                    {!historico && (
                                                        <StyledTableCell align='left' style={{ maxWidth: '2em' }}>
                                                            <Tooltip title={visible ? 'Ocultar' : 'Desvelar'}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        abrirModalVisualizacion(alarma, i)
                                                                    }}
                                                                >
                                                                    {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </Tooltip>
                                                        </StyledTableCell>
                                                    )}
                                                    <StyledTableCell align='left'>{alarma?.instalacion}</StyledTableCell>
                                                    <StyledTableCell align='left'>
                                                        <a
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                            onClick={e => enviarFormulario(e, alarma)}
                                                        >
                                                            {alarma?.nick
                                                                ? alarma?.nick
                                                                : alarma?.device_type === dispositivos.ccmabtv
                                                                  ? 'VTN'
                                                                  : 'Sin nombre' +
                                                                    (alarma['mabt_output'] ? ' ' + alarma['mabt_output'] : '')}
                                                        </a>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left'>{nombreDispositivo(alarma?.device_type)}</StyledTableCell>
                                                    <StyledTableCell align='left'>{nombreAlarma(alarma?.alarm_id)}</StyledTableCell>
                                                    <StyledTableCell align='left'>{alarma?.datetime_active}</StyledTableCell>
                                                    {historico && (
                                                        <>
                                                            <StyledTableCell align='left'>
                                                                {alarma?.datetime_solved ? (
                                                                    alarma.datetime_solved
                                                                ) : (
                                                                    <Tooltip title='Alarma Activa'>
                                                                        <IconButton>
                                                                            <ArrowForwardIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left'>
                                                                {alarma?.duration === null ? '-' : breakMin(alarma.duration)}
                                                            </StyledTableCell>
                                                        </>
                                                    )}
                                                    <StyledTableCell align='left'>
                                                        <Tooltip title='Ver Comentarios'>
                                                            <IconButton
                                                                onClick={() => {
                                                                    abrirModalComentario(alarma)
                                                                }}
                                                            >
                                                                <FeedOutlinedIcon
                                                                    sx={{
                                                                        color:
                                                                            alarma.comment === 1 || alarma.commentPriv > 0
                                                                                ? 'rgb(245, 156, 47)'
                                                                                : '#525f7f',
                                                                        fontSize: 22,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                            </TableBody>
                        </Table>
                    )}
                </InfiniteScroll>
            </TableContainer>
            <div ref={divRef}></div>
            {modalComentario && (
                <ComentarioAlarma alarma={alarma} modalComentario={modalComentario} setModalComentario={setModalComentario} token={token} />
            )}
            {modalVisualizacion && (
                <VisuzalizacionAlarma
                    data={data}
                    setData={setData}
                    alarma={alarma}
                    index={index}
                    modalVisualizacion={modalVisualizacion}
                    setModalVisualizacion={setModalVisualizacion}
                    token={token}
                />
            )}
        </div>
    )
}
