import { createSlice } from '@reduxjs/toolkit'

export const mapSlice = createSlice({
    name: 'map',
    initialState: [],
    reducers: {
        clearMap: (state, action) => {
            return []
        },
        updateMap: (state, action) => {
            return action.payload
        }
    }
})

export const { clearMap, updateMap } = mapSlice.actions

export default mapSlice.reducer
