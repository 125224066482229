import React, { useContext, useState, useEffect } from 'react'
import { GlobalContext } from 'context/GlobalContext.jsx'
import { Grid } from '@mui/material'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import GradeIcon from '@mui/icons-material/Grade'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { DropdownProject } from 'views/Project/MenuProject/DropdownProject'
import { UserPermission } from 'views/User/Functions/userPermission'
import { headersCentroControl } from 'services/headers'
import { IconData, IconAlarm, IconAlarmWith, IconControl, IconCT, IconDevice, IconMap, IconWarning } from 'utils/icon-centrocontrol'
import { ErrorDevices, iconoCategoria } from 'utils/Utils'
import { CategoryType } from 'utils/MiddleWareProjects'
import { urls } from 'utils/constant'
import { DigitalOutputs } from 'utils/icon-centrocontrol'
import { instalaciones } from 'utils/constant'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { clearFilterAlarm } from 'reduxSlice/filterAlarmSlice'

export const MenuCentroControl = ({ activeTab, setActiveTab }) => {
    const token = sessionStorage?.token
    const { actualProject } = useContext(GlobalContext)
    const [fav, setFav] = useState(false)
    const [masterError, setMasterError] = useState()
    const infoInstal = useSelector((state) => state.infoInstal)
    const dispatch = useDispatch()

    const handleActive = (tab) => {
        setActiveTab(tab)
        dispatch(clearFilterAlarm())
    }

    const fetchFavProjects = async () => {
        try {
            const res = await fetch(urls.ccontrol + 'userSettings', { method: 'GET', headers: headersCentroControl(token) })
            if (res.status === 200) {
                let json = await res.json()
                if (json?.project_fav?.length > 0 && json?.project_fav?.includes(actualProject?.id_project)) {
                    setFav(true)
                } else {
                    setFav(false)
                }
            } else {
                setFav(false)
            }
            return true
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        }
    }

    const fetchAddDeleteFavProjects = async () => {
        setFav(!fav)
        try {
            await fetch(urls.ccontrol + 'userSettings', {
                method: fav ? 'DELETE' : 'POST',
                headers: headersCentroControl(token),
                body: JSON.stringify({ project_fav: actualProject?.id_project })
            })
            return true
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        }
    }

    useEffect(() => {
        if (actualProject?.id_project) {
            fetchFavProjects()
            window.document.title = `${actualProject?.nick} - ${CategoryType(actualProject?.category)}`
        }
    }, [actualProject])

    useEffect(() => {
        let errors = ErrorDevices(actualProject, infoInstal)
        errors = errors.filter((master) => master.error !== 0)
        setMasterError(errors)
    }, [infoInstal])

    return (
        <Grid container className='card-centro-control navbar-centro-control scada-navbar-centro-control'>
            <Grid item xs={12} md={3} lg={3} xl={3} className='card-project-cc pr-0'>
                <Grid container columns={20} className='title-detail'>
                    <Grid item xs={4} md={3} lg={3} xl={3} className='flex justify-center'>
                        {iconoCategoria(actualProject?.category)}
                        <DropdownProject project={actualProject} admin={true} permissions={actualProject?.permissions} />
                    </Grid>
                    <Grid item xs={16} md={17} lg={17} xl={17} className='flex-center'>
                        <a
                            className='pl-0 flex'
                            onClick={() => {
                                fetchAddDeleteFavProjects()
                            }}
                        >
                            {fav ? <GradeIcon className='color-enerclic' /> : <StarOutlineIcon />}
                        </a>
                        {actualProject?.nick?.length > 22 ? (
                            <HtmlTooltip title={<span> {actualProject?.nick} </span>}>
                                {actualProject?.nick?.substr(0, 19) + '...'}
                            </HtmlTooltip>
                        ) : (
                            actualProject?.nick
                        )}
                    </Grid>
                    <Grid item xs={4} md={3} lg={3} xl={3} />
                    <Grid item xs={16} md={17} lg={17} xl={17} className='subtitle-project-cc'>
                        <p className='project-category-name'> {CategoryType(actualProject?.category)}</p>
                        <UserPermission project={actualProject} className='subtitle-user-permissions' />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9} lg={9} xl={9} className='box-activeTab card-list-tab'>
                <span
                    onClick={() => {
                        handleActive(0)
                    }}
                    className={activeTab === 0 ? 'list-item-icon active-button' : 'list-item-icon'}
                >
                    <IconMap fill={activeTab === 0 ? '#FF8A1F' : '#545454'} />
                    <p className='title-index-header-cc'>Mapa</p>
                </span>
                <span
                    onClick={() => {
                        handleActive(1)
                    }}
                    className={activeTab === 1 ? 'list-item-icon active-button' : 'list-item-icon'}
                >
                    {actualProject?.category !== 'mabt' ? (
                        <IconDevice fill={activeTab === 1 ? '#FF8A1F' : '#545454'} />
                    ) : (
                        <IconCT color={activeTab === 1 ? '#FF8A1F' : '#545454'} />
                    )}
                    <p className='title-index-header-cc'>Instalaciones</p>
                </span>
                <span
                    onClick={() => {
                        handleActive(2)
                    }}
                    className={activeTab === 2 ? 'list-item-icon active-button' : 'list-item-icon'}
                >
                    <IconData fill={activeTab === 2 ? '#FF8A1F' : '#545454'} />
                    <p className='title-index-header-cc'> Datos</p>
                </span>
                <span
                    onClick={() => {
                        handleActive(3)
                    }}
                    className={activeTab === 3 ? 'list-item-icon active-button' : 'list-item-icon'}
                >
                    {actualProject?.alarmCount > 0 ? (
                        <IconAlarmWith width='25' height='25' color={activeTab === 3 ? '#FF8A1F' : '#545454'} />
                    ) : (
                        <IconAlarm fill={activeTab === 3 ? '#FF8A1F' : '#545454'} />
                    )}
                    <p className='title-index-header-cc'>Alarmas</p>
                </span>
                {actualProject?.category !== instalaciones?.mabt && (
                    <>
                        <span
                            onClick={() => {
                                handleActive(5)
                            }}
                            className={activeTab === 5 ? 'list-item-icon active-button' : 'list-item-icon'}
                        >
                            <DigitalOutputs fill={activeTab === 5 ? '#FF8A1F' : '#545454'} />
                            <p className='title-index-header-cc mr-1 mb-0'>Salidas digitales</p>
                        </span>
                    </>
                )}
                <span
                    onClick={() => {
                        handleActive(4)
                    }}
                    className={activeTab === 4 ? 'list-item-icon active-button' : 'list-item-icon'}
                >
                    <IconControl fill={activeTab === 4 ? '#FF8A1F' : '#545454'} />
                    <p className='title-index-header-cc mr-1 mb-0'>Configuración</p>
                    {masterError?.length > 0 && (
                        <HtmlTooltip title={<span>Revise su configuración.</span>}>
                            <IconWarning width={20} height={20} />
                        </HtmlTooltip>
                    )}
                </span>
            </Grid>
        </Grid>
    )
}
