// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#lista {
  list-style-type: none !important;
}

#lista ul {
  padding: 0;
  margin: 0;
  position: relative;
}

#lista ul li {
  list-style-type: none;
  border-left: 2px solid #ccc;
  margin-left: 2em;
}

#lista li div {
  padding-left: 1em;
  position: relative;
  padding-top: 1.2rem;
}

#lista li div::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2px;
  bottom: 50%;
  width: 0.75em;
  border-top: 0 none transparent;
  border-right: 0 none transparent;
  padding-top: 2rem;
}

#lista ul > li:last-child {
  border-left: 2px solid transparent;
}

#lista div {
  cursor: pointer;
}

#lista span {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/views/Admin/style.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;;AAEA;EACI,UAAA;EACA,SAAA;EACA,kBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,2BAAA;EACA,gBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,gCAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kCAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":["#lista {\n    list-style-type: none !important;\n}\n\n#lista ul {\n    padding: 0;\n    margin: 0;\n    position: relative;\n}\n\n#lista ul li {\n    list-style-type: none;\n    border-left: 2px solid #ccc;\n    margin-left: 2em;\n}\n\n#lista li div {\n    padding-left: 1em;\n    position: relative;\n    padding-top: 1.2rem;\n}\n\n#lista li div::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: -2px;\n    bottom: 50%;\n    width: 0.75em;\n    border-top: 0 none transparent;\n    border-right: 0 none transparent;\n    padding-top: 2rem;\n}\n\n#lista ul > li:last-child {\n    border-left: 2px solid transparent;\n}\n\n#lista div {\n    cursor: pointer;\n}\n\n#lista span {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
