export const cabeceraMabt = [
    ['nick', 'Salida'],
    ['datetime', 'Fecha'],
    ['pacTotal', 'P. Activa Total'],
    ['pac1', 'P. Activa R'],
    ['pac2', 'P. Activa S'],
    ['pac3', 'P. Activa T'],
    ['pac1_max', 'P. Activa R Max'],
    ['pac1_min', 'P. Activa R Min'],
    ['pac2_max', 'P. Activa S Max'],
    ['pac2_min', 'P. Activa S Min'],
    ['pac3_max', 'P. Activa T Max'],
    ['pac3_min', 'P. Activa T Min'],
    ['pre1', 'P. Reactiva R'],
    ['pre2', 'P. Reactiva S'],
    ['pre3', 'P. Reactiva T'],
    ['i1', 'Corriente R'],
    ['i2', 'Corriente S'],
    ['i3', 'Corriente T'],
    ['v1', 'Tension R'],
    ['v2', 'Tension S'],
    ['v3', 'Tension T'],
    ['i1_max', 'Corriente R Max'],
    ['i2_max', 'Corriente S Max'],
    ['i3_max', 'Corriente T Max'],
    ['v1_max', 'Tension R Max'],
    ['v2_max', 'Tension S Max'],
    ['v3_max', 'Tension T Max'],
    ['ereactq1dia', 'Energía Reactiva 1'],
    ['ereactq2dia', 'Energía Reactiva 2'],
    ['ereactq3dia', 'Energía Reactiva 3'],
    ['ereactq4dia', 'Energía Reactiva 4'],
    ['vtn', 'VTN'],
    ['datetime_vtn_max', 'Momento VTN Máximo'],
    ['vtn_max', 'VTN Máximo'],
    ['datetime_vtn_min', 'Momento VTN Mínimo'],
    ['vtn_min', 'VTN Mínimo'],
    ['eaconsdia', 'Energía Importada (kWh)'],
    ['eagendia', 'Energía Exportada (kWh)']
]

export const cabeceraMabtYesterday = [
    ['nick', 'Salida'],
    ['datetime', 'Fecha'],
    ['pac_max', 'P. Max Total'],
    ['pac_min', 'P. Min Total'],
    ['pac1', 'P. Activa R'],
    ['pac2', 'P. Activa S'],
    ['pac3', 'P. Activa T'],
    ['pac1_max', 'P. Activa R Max'],
    ['pac1_min', 'P. Activa R Min'],
    ['pac2_max', 'P. Activa S Max'],
    ['pac2_min', 'P. Activa S Min'],
    ['pac3_max', 'P. Activa T Max'],
    ['pac3_min', 'P. Activa T Min'],
    ['preacTotal', 'P. Reactiva Total'],
    ['pre1', 'P. Reactiva R'],
    ['pre2', 'P. Reactiva S'],
    ['pre3', 'P. Reactiva T'],
    ['i1', 'Corriente R'],
    ['i2', 'Corriente S'],
    ['i3', 'Corriente T'],
    ['v1', 'Tension R'],
    ['v2', 'Tension S'],
    ['v3', 'Tension T'],
    ['i1_max', 'Corriente R Max'],
    ['i2_max', 'Corriente S Max'],
    ['i3_max', 'Corriente T Max'],
    ['v1_max', 'Tension R Max'],
    ['v2_max', 'Tension S Max'],
    ['v3_max', 'Tension T Max'],
    ['ereactq1dia', 'Energía Reactiva 1'],
    ['ereactq2dia', 'Energía Reactiva 2'],
    ['ereactq3dia', 'Energía Reactiva 3'],
    ['ereactq4dia', 'Energía Reactiva 4'],
    ['vtn', 'VTN'],
    ['datetime_vtn_max', 'Momento VTN Máximo'],
    ['vtn_max', 'VTN Máximo'],
    ['datetime_vtn_min', 'Momento VTN Mínimo'],
    ['vtn_min', 'VTN Mínimo'],
    ['eaconsdia', 'Energía Importada (kWh)'],
    ['eagendia', 'Energía Exportada (kWh)']
]

export const cabecera = [
    ['nick', 'Nombre'],
    ['datetime', 'Fecha'],
    ['eaconsdia', 'Energia Importada (kWh)'],
    ['eagendia', 'Energia Exportada (kWh)'],
    ['pacTotal', 'Potencia Total (kW)'],
    ['tdhi', 'Distorsión Armónica Corriente (%)'],
    ['tdhi1', 'Distorsión Armónica Corriente L1 (%)'],
    ['tdhi2', 'Distorsión Armónica Corriente L2 (%)'],
    ['tdhi3', 'Distorsión Armónica Corriente L3 (%)'],
    ['tdhv', 'Distorsión Armónica Voltaje (%)'],
    ['tdhv1', 'Distorsión Armónica Voltaje L1 (%)'],
    ['tdhv2', 'Distorsión Armónica Voltaje L2 (%)'],
    ['tdhv3', 'Distorsión Armónica Voltaje L3 (%)']
]

export const cabeceraConstYesterday = [
    ['nick', 'Nombre'],
    ['datetime', 'Fecha'],
    ['eaconsdia', 'Energia Importada (kWh)'],
    ['eagendia', 'Energia Exportada (kWh)'],
    ['pact_max', 'Potencia Máxima (kW)'],
    ['pact_min', 'Potencia Mínima (kW)']
    // ['tdhi', 'Distorsión Armónica Corriente'],
    // ['tdhi1', 'Distorsión Armónica Corriente L1'],
    // ['tdhi2', 'Distorsión Armónica Corriente L2'],
    // ['tdhi3', 'Distorsión Armónica Corriente L3'],
    // ['tdhv', 'Distorsión Armónica Voltaje'],
    // ['tdhv1', 'Distorsión Armónica Voltaje L1'],
    // ['tdhv2', 'Distorsión Armónica Voltaje L2'],
    // ['tdhv3', 'Distorsión Armónica Voltaje L3']
]
