import { Switch } from '@mui/material'
import { styled } from '@mui/system'

export const SwitchStyled = styled(Switch)(({ theme, className }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#FF8A1F',
        opacity: 0.9,
        '& + .MuiSwitch-track': {
            backgroundColor: '#FF8A1F',
            opacity: 1
        }
    },
    '.MuiSwitch-thumb': {
        padding: className.includes('switch-extra-small') ? '6px' : '10px'
    },
    checked: {},
    track: {},
    defaultChecked: {}
}))
