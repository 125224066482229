import { createSlice } from '@reduxjs/toolkit'

export const toastSlice = createSlice({
    name: 'toast',
    initialState: {
        reload: true,
        action: true,
        status: '',
        message: ''
    },
    reducers: {
        createToast: (state, action) => {
            state.reload = action.payload.reload
            state.action = !state.action
            state.status = action.payload.status
            state.message = action.payload.message
        }
    }
})

export const { createToast } = toastSlice.actions
export default toastSlice.reducer
