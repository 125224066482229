// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-solid {
  bottom: 0px;
  width: 100%;
  left: 0px;
  background-color: #e9eef0;
  height: 5em !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-sticky {
  position: sticky;
}

.enerclic-name {
  color: #e9521e !important;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.current-version {
  color: #545454;
  text-align: end;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.copy-date {
  margin-bottom: 0px;
  font-size: 16px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/views/Footer/styleFooter.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,SAAA;EACA,yBAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;AACJ","sourcesContent":[".footer-solid {\n    bottom: 0px;\n    width: 100%;\n    left: 0px;\n    background-color: #e9eef0;\n    height: 5em !important;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.footer-sticky {\n    position: sticky;\n}\n\n.enerclic-name {\n    color: #e9521e !important;\n    font-family: Montserrat;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: bold;\n    line-height: normal;\n}\n\n.current-version {\n    color: #545454;\n    text-align: end;\n    font-family: Montserrat;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}\n\n.copy-date {\n    margin-bottom: 0px;\n    font-size: 16px;\n    font-family: Montserrat;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: bold;\n    line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
