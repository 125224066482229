import React, { useState } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { StyledTableCellOrdenado, StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { ordenarDatos } from 'utils/Utils'
import './SensorLine.scss'

export const TableSensorScada = ({ data, hoy }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })

    return (
        <Table aria-label="customized table">
            <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                <TableRow>
                    <StyledTableCellOrdenado align="center" celda={'nick'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'nick') }} sortConfig={sortConfig}>Nombre</StyledTableCellOrdenado>
                    <StyledTableCellOrdenado align="center" celda={'fecha'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'fecha') }} sortConfig={sortConfig}>Fecha</StyledTableCellOrdenado>
                    {hoy && <StyledTableCellOrdenado align="center" celda={'v1'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'v1') }} sortConfig={sortConfig}>Tensión R (V)</StyledTableCellOrdenado>}
                    {hoy && <StyledTableCellOrdenado align="center" celda={'v2'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'v2') }} sortConfig={sortConfig}>Tensión S (V)</StyledTableCellOrdenado>}
                    {hoy && <StyledTableCellOrdenado align="center" celda={'v3'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'v3') }} sortConfig={sortConfig}>Tensión T (V)</StyledTableCellOrdenado>}
                    <StyledTableCellOrdenado align="center" celda={'v1_max'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'v1_max') }} sortConfig={sortConfig}>Tensión R Max (V)</StyledTableCellOrdenado>
                    <StyledTableCellOrdenado align="center" celda={'v2_max'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'v2_max') }} sortConfig={sortConfig}>Tensión S Max (V)</StyledTableCellOrdenado>
                    <StyledTableCellOrdenado align="center" celda={'v3_max'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'v3_max') }} sortConfig={sortConfig}>Tensión T Max (V)</StyledTableCellOrdenado>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((row, i) => {
                    return (
                        <StyledTableRow key={i + 'tableSensorTension'}>
                            <StyledTableCell align="left">
                                {row?.nick}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {row?.fecha}
                            </StyledTableCell>
                            {hoy &&
                                <StyledTableCell align="left">
                                    {row?.v1}
                                </StyledTableCell>
                            }
                            {hoy &&
                                <StyledTableCell align="left">
                                    {row?.v2}
                                </StyledTableCell>
                            }
                            {hoy &&
                                <StyledTableCell align="left">
                                    {row?.v3}
                                </StyledTableCell>
                            }
                            <StyledTableCell align="left">
                                {row?.v1_max}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {row?.v2_max}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {row?.v3_max}
                            </StyledTableCell>
                        </StyledTableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}