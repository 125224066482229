import React, { useEffect, useState } from 'react'
import { Loader } from 'components/Loader/Loader'
import { GraphConstructor } from 'views/Scada/SelfConsumption/GraphsUtils/GraphConstructor'
import 'views/Scada/SelfConsumption/GraphsUtils/circle.scss'
import { Iconhome } from 'utils/icon-centrocontrol'

export const ConsumptionGraphs = ({ daily, data, loading }) => {
    const [constructor, setConstructor] = useState(<GraphConstructor />)

    useEffect(() => {
        let autoconsumo = [data?.produccion?.pact, data?.red?.pact]
        if (
            (data?.produccion?.pact === null || data?.produccion?.pact === 0 || data?.produccion?.pact === undefined) &&
            (data?.red?.pact === null || data?.red?.pact === 0 || data?.red?.pact === undefined)
        ) {
            autoconsumo.push(100)
        }
        const dataInfo = [
            {
                data: {
                    categories: [],
                    data: [{ autoconsumo: autoconsumo }]
                }
            }
        ]
        setConstructor(
            <GraphConstructor
                className='doughnut-style'
                daily={daily}
                nombre={['consumoRadial']}
                props={dataInfo}
                graphStyle={'doughnut'}
            />
        )
    }, [data])

    return (
        <>
            {loading && data ? (
                <Loader />
            ) : (
                <div className='flex items-center relative'>
                    {constructor}
                    <Iconhome className='img-style-consumption' />
                </div>
            )}
        </>
    )
}
