import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, TextField } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
// import MapDinamic from 'components/Map/MapDinamic'
import { Modal } from 'components/Modal/Modal'
import Map from 'components/Mapa/Map'
import { createToast } from 'reduxSlice/toastSlice'
import { apiCentroControl, apiScada } from 'services/api'
import { validatorInput } from 'utils/ValidatorForm'
import { dispositivos } from 'utils/constant'
import { convertDatetime } from 'utils/Utils'

export const ModalViewInfoCCMasters = ({ firstModal, setFirstModal, master, scada, data }) => {
    const [loading, setLoading] = useState(false)
    const [newNick, setNewNick] = useState()
    const [newNotes, setNewNotes] = useState()
    const [address, setAddress] = useState('')
    const [lat, setLat] = useState()
    const [lng, setLng] = useState()
    const [devices, setDevices] = useState()
    const [isDisabled, setIsDisabled] = useState(true)
    const [isValidNick, setIsValidNick] = useState(false)
    const [isValidNote, setIsValidNote] = useState(false)
    const dispatch = useDispatch()

    const getDevices = async () => {
        setLoading(true)
        if (master) {
            try {
                const result = await apiCentroControl.get(`userInfoDevice/${master?.numserie}`)
                if (result.status === 200) {
                    setDevices(result?.data)
                }
            } catch (err) {
                console.error('ERROR. API Centro de Control', err)
            }
        }
        setLoading(false)
    }

    const patchCcMaster = async () => {
        setLoading(true)
        let url = `updateDeviceInfo/${master?.numserie}`
        if (
            Number(devices?.id_tipo_dispositivo) === dispositivos.ccmaster ||
            devices?.id_tipo_dispositivo === dispositivos.ccmabt ||
            (master?.inst_type === 1 && [2, 4]?.includes(devices?.id_tipo_dispositivo)) ||
            (scada && [2, 4]?.includes(devices?.id_tipo_dispositivo)) ||
            (data?.[0]?.inst_type === 1 && [2, 4]?.includes(data?.[0]?.id_tipo_dispositivo))
        ) {
            url += `?idCCMaster=${master?.numserie}`
        }
        let body = {
            nick: newNick,
            notes: newNotes,
            latitud: lat,
            longitud: lng,
            address: address
        }
        if (
            (scada && [2, 4]?.includes(devices?.id_tipo_dispositivo)) ||
            (master?.inst_type === 1 && [2, 4]?.includes(devices.id_tipo_dispositivo)) ||
            (data?.[0]?.inst_type === 1 && [2, 4]?.includes(data?.[0]?.id_tipo_dispositivo))
        ) {
            body.kit = true
        }
        try {
            const result = await apiScada.patch(url, body)
            if (result.status === 200) {
                setFirstModal(!firstModal)
                dispatch(createToast({ status: 200, message: result?.data?.message, reload: true }))
            }
        } catch (err) {
            dispatch(createToast({ status: 400, message: err?.response?.data?.message, reload: false }))
            console.error('ERROR. API Centro de Control', err)
        } finally {
            setLoading(false)
        }
    }

    const onSelectMarker = (lat, lng, adres) => {
        setLat(lat)
        setLng(lng)
        setAddress(adres)
    }

    const handleChangeNick = (e) => {
        setNewNick(e)
        setIsValidNick(validatorInput(e))
    }

    const handleChangeNotes = (e) => {
        setNewNotes(e === '' ? null : e)
        setIsValidNote(validatorInput(e))
    }

    useEffect(() => {
        getDevices()
    }, [master, firstModal])

    useEffect(() => {
        setIsValidNick(false)
        setIsValidNote(false)
        setNewNotes(devices?.notes)
        setAddress(devices?.address)
        setNewNick(devices?.nick)
        setLat(Number(devices?.latitud))
        setLng(Number(devices?.longitud))
    }, [firstModal, devices])

    useEffect(() => {
        if (
            newNick &&
            !isValidNick &&
            !isValidNote &&
            (newNick !== master?.nick || address !== devices?.address || (newNotes !== undefined && newNotes !== devices?.notes))
        ) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [newNick, newNotes, address, isValidNick, isValidNote])

    return (
        <Modal
            open={firstModal}
            setOpen={setFirstModal}
            onClick={(e) => e.stopPropagation()}
            functSave={patchCcMaster}
            loading={loading}
            disabled={isDisabled}
            title='Información'
        >
            <Grid container className='pr-5 pl-5'>
                <Grid className='pr-2 pb-3' item xs={12} md={6} lg={6} xl={6}>
                    <div className='pb-1'>
                        <small className='color-black'>Número de serie:</small>
                    </div>
                    <InputStyled value={master?.numserie} fullWidth={true} disabled={true} maxLength={64} />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div className='pb-1'>
                        <small className='color-black'>Nombre del dispositivo:</small>
                    </div>
                    <InputStyled error={isValidNick} value={newNick} fullWidth={true} onChange={handleChangeNick} maxLength={64} />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='mb-2 text-align-left'>
                    <div className='pb-1'>
                        <small className='color-black'>Dirección:</small>
                    </div>
                    {address !== undefined && (
                        <Map center={{ lat: lat, lng: lng }} onSelectMarker={onSelectMarker} zoom={4} address={address} utmShow={false} />
                    )}
                </Grid>
                <Grid className='pr-2 pb-3' item xs={6} md={6} lg={6} xl={6}>
                    <div className='pb-1'>
                        <small className='color-black'>Fecha de registro:</small>
                    </div>
                    <InputStyled
                        value={
                            devices?.fecha_sync
                                ? convertDatetime(devices?.fecha_sync)
                                : data?.[0]?.fecha_sync
                                  ? convertDatetime(data?.[0]?.fecha_sync)
                                  : ''
                        }
                        disabled={true}
                        fullWidth={true}
                        maxLength={64}
                    />
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <div className='pb-1'>
                        <small className='color-black'>Firmware:</small>
                    </div>
                    <InputStyled value={devices?.FW_version ? devices?.FW_version : '-'} disabled={true} fullWidth={true} maxLength={64} />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div className='pb-1'>
                        <small className='color-black'>Notas:</small>
                    </div>
                    <TextField
                        fullWidth
                        error={isValidNote}
                        helperText={isValidNote && 'Caracteres no permitidos'}
                        className='style-textfield-text'
                        id='outlined-multiline-static'
                        onChange={(e) => handleChangeNotes(e.target.value)}
                        multiline
                        rows='4'
                        value={newNotes}
                        variant='outlined'
                    />
                </Grid>
                {/* <MapDinamic /> */}
            </Grid>
        </Modal>
    )
}
