import React from 'react'
import { Draggable } from '@hello-pangea/dnd'
import './ModalMoveAllDevices.scss'

const Item = ({ text, index }) => {
    return (
        <Draggable draggableId={text} index={index}>
            {(provided) => (
                <div className='styled-item' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {text}
                </div>
            )}
        </Draggable>
    )
}

export default Item
