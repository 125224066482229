import React from 'react'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'

export const DeleteLogo = ({ open, setOpen, fetchDeleteLogo, loading }) => {
    return (
        <Modal open={open} setOpen={setOpen} functSave={fetchDeleteLogo} loading={loading} title='Eliminar logo' buttonAction='Eliminar'>
            <Grid container className='text-center mt-2'>
                <h4 className='font-weight-lighter' style={{ fontSize: '1.2rem' }}>
                    ¿Deseas eliminar el logo?
                </h4>
            </Grid>
            <hr className='m-0' />
        </Modal>
    )
}
