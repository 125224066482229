import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { MenuSelector } from 'components/MenuSelector/MenuSelector'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { IconAlarm } from 'utils/icon-centrocontrol'
import { urls, alarmas } from 'utils/constant.jsx'
import { createToast } from 'reduxSlice/toastSlice'
import { ConfiguracionInformes } from '../Informes/ConfiguracionInformes'
import { FormCrearTramo } from './FormCrearTramo'
import { FormCrear } from './FormCrear'

export const CrearConfigAlarms = ({
    setActualizarAlarma,
    token,
    open,
    setOpen,
    alarmasProyecto,
    dispositivosProyecto,
    dispositivosProyecto2,
    instalacioneSeleccionadas,
    alarmasPendientes
}) => {
    const dispatch = useDispatch()
    const [dispositivoSeleccionado, setDispositivoSeleccionado] = useState([])
    const [loadingConfig, setLoadingConfig] = useState(false)
    const [todosDispositivos, setTodosDispositivos] = useState([])
    const [alarmasActivas, setAlarmasActivas] = useState([])
    const [configuredAlarms, setConfiguredAlarms] = useState()
    const [tipoDispositivo, setTipoDispositivo] = useState()
    const [tipoAlarma, setTipoAlarma] = useState()
    const [tiempoAlarma, setTiempoAlarma] = useState(1)
    const [tiempoMinimo, setTiempoMinimo] = useState(0)
    const [tiempoMaximo, setTiempoMaximo] = useState(0)
    const [permissions, setPermissions] = useState()
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [objAlarmTramo, setObjAlarmTramo] = useState({
        enable: true,
        type_alarm: 0,
        variable_name: '',
        upper_value: undefined,
        lower_value: undefined,
        expected_value: undefined
    })
    const [validateFormTramo, setValidateFormTramo] = useState(false)

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab)
    }

    const fetchAddConfigAlarms = async () => {
        setLoading(true)
        let obj = {}
        dispositivoSeleccionado.forEach((ele) => {
            if (ele.numseriePadre === undefined) {
                obj[ele.numserie] = [ele.numserie]
            } else if (obj[ele.numseriePadre]) {
                obj[ele.numseriePadre].push(ele.numserie)
            } else {
                obj[ele.numseriePadre] = [ele.numserie]
            }
        })
        let body = {
            id_alarm: tipoAlarma,
            id_type_device: tipoDispositivo,
            devices: obj
        }
        if (tipoAlarma === alarmas.tramo) {
            body.enabled = objAlarmTramo.enable
            body.obj_tramo = objAlarmTramo
        } else {
            body.threshold_time = tiempoAlarma
            body.enabled = true
        }
        try {
            const res = await fetch(urls.scada + `alarms/${body?.obj_tramo ? 'createSectionAlarm' : 'createAlarm'}`, {
                method: 'POST',
                headers: headersScada(token),
                body: JSON.stringify(body)
            })
            if (res.status === 200) {
                const shared = await fetchAddConfigInformAlarms()
                return shared
            } else {
                return false
            }
        } catch (err) {
            console.error('Error', err)
        }
    }

    const fetchAddConfigInformAlarms = async () => {
        let obj = {}
        dispositivoSeleccionado.forEach((ele) => {
            if (ele.numseriePadre === undefined && ele.instalacion === undefined) {
                obj[ele.numserie] = [ele.numserie]
            } else if (obj[ele.numseriePadre]) {
                obj[ele.numseriePadre].push(ele.numserie)
            } else if (obj[ele.instalacion]) {
                obj[ele.instalacion].push(ele.numserie)
            } else if (ele.numseriePadre !== undefined) {
                obj[ele.numseriePadre] = [ele.numserie]
            } else {
                obj[ele.instalacion] = [ele.numserie]
            }
        })
        let dispositivosSelec = []
        dispositivoSeleccionado.forEach((ele) => {
            if (ele.instalacion !== undefined && !dispositivosSelec.includes(ele.instalacion)) {
                dispositivosSelec.push(ele.instalacion)
            } else if (ele.numseriePadre !== undefined && !dispositivosSelec.includes(ele.numseriePadre)) {
                dispositivosSelec.push(ele.numseriePadre)
            } else if (ele.instalacion === undefined && ele.numseriePadre === undefined) {
                dispositivosSelec.push(ele.numserie)
            }
        })
        let body = {
            numserie: obj,
            id_alarm: tipoAlarma,
            permissions: permissions
        }
        try {
            const res = await fetch(urls.scada + `alarms/createSharedAlarm${dispositivosSelec.length > 1 ? '?multiple=true' : ''}`, {
                method: 'POST',
                headers: headersScada(token),
                body: JSON.stringify(body)
            })
            if (res.status === 200) {
                return true
            } else {
                return false
            }
        } catch (err) {
            console.error('Error', err)
        }
    }

    const fetchDataConfigAlarms = async (alarm) => {
        setLoadingConfig(true)
        try {
            const res = await fetch(urls.scada + 'alarms/getConfiguredAlarms', {
                method: 'POST',
                headers: headersScada(token),
                body: JSON.stringify({ devices: instalacioneSeleccionadas })
            })
            if (res.status === 200) {
                let json = await res.json()
                setConfiguredAlarms(json)
                json = json.filter((ele) => ele.device_type === tipoDispositivo)
                json = json.filter((ele) => ele.alarm_id === alarm)
                let alarmasActiva = []
                json.forEach((ele) => {
                    alarmasActiva.push(ele.numserie)
                })
                if (alarmasActiva.length) {
                    setAlarmasActivas(alarmasActiva)
                } else {
                    selectDispositivos(alarm)
                }
            } else {
                return false
            }
        } catch (err) {
            console.error('Error', err)
        } finally {
            setLoadingConfig(false)
        }
    }

    const añadirConfiguracion = async () => {
        let status
        let message
        try {
            const create = await fetchAddConfigAlarms()
            if (create) {
                status = 200
                message = 'Configuracion añadida correctamente'
            } else {
                status = 400
                message = 'Error al agregar configuración'
            }
            setOpen(false)
            dispatch(createToast({ status: status, message: message, reload: true }))
            setActualizarAlarma((current) => !current)
        } catch (err) {
            console.error('Error', err)
        } finally {
            setLoading(false)
        }
    }

    const seleccionarTipoDispositivo = (event) => {
        setTipoAlarma()
        if (todosDispositivos.length > 0) {
            setTodosDispositivos([])
        }
        if (dispositivoSeleccionado.length > 0) {
            setDispositivoSeleccionado([])
        }
        setTipoDispositivo(event.target.value)
    }

    const seleccionarTipoAlarma = (event) => {
        setTipoAlarma(event.target.value)
        fetchDataConfigAlarms(event.target.value)
    }

    const seleccionarPermisions = (permissions) => {
        setPermissions(permissions)
    }

    //Busca las alarmas de los hijos y de los padres para setear el tiempo minimo o maximo que puedes asignarle a una nueva alarma
    const encontrarAlarmaMinima = (array) => {
        let activaPadre = []
        let timePadre = 0
        let activaHijo = []
        let timeHijo = 0
        array?.forEach((ele) => {
            let configuredPadre = configuredAlarms?.find((element) => Number(element?.numserie) === Number(ele?.numseriePadre))
            let configuredHijo = configuredAlarms?.find(
                (element) =>
                    Number(element.numInstalacion) === Number(ele?.numserie) &&
                    Number(element?.numserie) !== Number(element?.numInstalacion)
            )
            if (configuredPadre) {
                activaPadre.push(configuredPadre)
                if (timePadre === 0 || timePadre > configuredPadre?.threshold_time) {
                    timePadre = configuredPadre?.threshold_time
                }
            }
            if (configuredHijo) {
                activaHijo.push(configuredHijo)
                if (timeHijo === 0 || timeHijo > configuredHijo?.threshold_time) {
                    timeHijo = configuredHijo?.threshold_time
                }
            }
        })
        setTiempoMaximo(timeHijo)
        setTiempoMinimo(timePadre)
        setTiempoAlarma(timePadre + 1)
    }

    const selectDispositivos = (alarm) => {
        let dispositivosSeleccionados = []
        dispositivosProyecto2?.forEach((ele) => {
            let fw
            if (ele?.id_tipo_dispositivo === 0) {
                fw = ele?.master_FW
            } else if (ele?.cloud === undefined) {
                let ccmaster = dispositivosProyecto2?.find((master) => master?.numserie === ele?.id_padre)
                fw = ccmaster?.master_FW
            }
            if (
                ele?.id_tipo_dispositivo === tipoDispositivo &&
                !dispositivosSeleccionados?.find((dis) => dis?.numserie === ele?.numserie)
            ) {
                dispositivosSeleccionados.push({
                    firmware: fw,
                    numserie: ele.numserie,
                    numseriePadre: ele?.id_padre
                        ? ele?.id_padre
                        : ele?.mabt_serial
                          ? ele?.mabt_serial
                          : ele?.master_serial
                            ? ele?.master_serial
                            : undefined,
                    nick: ele.nick,
                    nickMaster: ele?.nick_padre ? ele.nick_padre : ele?.nick,
                    posicion: ele?.mabt_output
                })
            }
            ele?.childrenDevices?.forEach((children) => {
                if (
                    children?.id_tipo_dispositivo === tipoDispositivo &&
                    !dispositivosSeleccionados?.find((dis) => dis?.numserie === children?.numserie)
                ) {
                    dispositivosSeleccionados.push({
                        firmware: fw,
                        numserie: children?.numserie,
                        nick: children?.nick,
                        numseriePadre: ele?.numserie,
                        nickMaster: ele?.nick_padre ? ele.nick_padre : ele?.nick,
                        posicion: children?.mabt_output
                    })
                }
                children?.childrenDevices?.forEach((child) => {
                    if (child?.id_tipo_dispositivo === tipoDispositivo) {
                        dispositivosSeleccionados.push({
                            firmware: fw,
                            numserie: child.numserie,
                            nick: child.nick,
                            numseriePadre: ele?.numserie,
                            nickMaster: ele?.nick_padre ? ele.nick_padre : ele?.nick,
                            posicion: child?.mabt_output
                        })
                    }
                })
            })
        })
        dispositivosSeleccionados.sort(function (a, b) {
            return a.numseriePadre >= b.numseriePadre
        })
        let dispos
        if (tipoAlarma === alarmas.tramo) {
            dispos = dispositivosSeleccionados?.filter((dispo) => dispo?.firmware >= '1.1.8')
        } else {
            dispos = dispositivosSeleccionados?.filter((dispo) => alarmasActivas.indexOf(dispo?.numserie) === -1)
        }
        if (alarm === alarmas.tramo) {
            dispos = dispos.filter((device) => device.numseriePadre !== undefined)
        }
        setDispositivoSeleccionado(dispos)
        setTodosDispositivos(dispos)
    }

    useEffect(() => {
        if (dispositivoSeleccionado.length > 0 && tipoAlarma !== undefined && tipoDispositivo !== undefined) {
            encontrarAlarmaMinima(dispositivoSeleccionado)
        } else if (tiempoMaximo !== 0 || tiempoMinimo !== 0) {
            setTiempoMaximo(0)
            setTiempoMinimo(0)
        }
    }, [dispositivoSeleccionado])

    useEffect(() => {
        if (alarmasActivas?.length) {
            selectDispositivos()
        }
    }, [alarmasActivas])

    return (
        <Modal
            maxWidth={activeTab === 1 ? 'md' : undefined}
            open={open}
            setOpen={setOpen}
            title='Nueva alarma'
            iconTitle={<IconAlarm fill='black' />}
            loading={loading}
            disabled={
                (tipoAlarma === alarmas.tramo && !validateFormTramo) ||
                tipoDispositivo === undefined ||
                tipoAlarma === undefined ||
                dispositivoSeleccionado.length === 0 ||
                (tiempoAlarma >= tiempoMaximo && tiempoMaximo > 0) ||
                tiempoMinimo >= tiempoAlarma ||
                tiempoAlarma === 0
            }
            functSave={añadirConfiguracion}
        >
            <Grid container className='mb-1 pl-4 pr-4'>
                <MenuSelector
                    options={[
                        { title: 'Alarma' },
                        {
                            title: 'Notificaciones',
                            disabled:
                                todosDispositivos.length === 0 ||
                                dispositivoSeleccionado.length === 0 ||
                                (tiempoAlarma >= tiempoMaximo && tiempoMaximo > 0) ||
                                tiempoMinimo >= tiempoAlarma ||
                                tiempoAlarma === 0
                        }
                    ]}
                    activeTab={activeTab}
                    onClick={toggle}
                />
            </Grid>
            {activeTab === 0 && (
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 pl-2 pr-2'>
                    <FormCrear
                        tipoAlarma={tipoAlarma}
                        tipoDispositivo={tipoDispositivo}
                        dispositivoSeleccionado={dispositivoSeleccionado}
                        seleccionarTipoDispositivo={seleccionarTipoDispositivo}
                        dispositivosProyecto={dispositivosProyecto}
                        alarmasProyecto={alarmasProyecto}
                        seleccionarTipoAlarma={seleccionarTipoAlarma}
                        todosDispositivos={todosDispositivos}
                        tiempoAlarma={tiempoAlarma}
                        setTiempoAlarma={setTiempoAlarma}
                        tiempoMinimo={tiempoMinimo}
                        tiempoMaximo={tiempoMaximo}
                        setDispositivoSeleccionado={setDispositivoSeleccionado}
                        alarmasPendientes={alarmasPendientes}
                        loadingConfig={loadingConfig}
                    />
                    {tipoAlarma === alarmas.tramo && (
                        <FormCrearTramo
                            setValidateFormTramo={setValidateFormTramo}
                            objAlarmTramo={objAlarmTramo}
                            setObjAlarmTramo={setObjAlarmTramo}
                            tipoAlarma={tipoAlarma}
                            tipoDispositivo={tipoDispositivo}
                        />
                    )}
                </Grid>
            )}
            <Grid item xs={12} md={12} lg={12} xl={12} className={`${activeTab === 1 ? 'show' : 'not-show'} mt-2 pl-4 pr-4`}>
                <ConfiguracionInformes
                    seleccionarPermisions={seleccionarPermisions}
                    todosDispositivos={dispositivoSeleccionado}
                    tipoAlarma={tipoAlarma}
                    token={token}
                />
            </Grid>
        </Modal>
    )
}
