import React, { useEffect, useState } from 'react'
import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { StyledTableCell, StyledTableCellHead } from 'components/Table/TableStyle'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { StyledTableRow } from 'components/Table/TableStyle'
import { Loader } from 'components/Loader/Loader'
import { formatMinCalendar, changeWednesday, WeekWednesday, FormatFecha } from 'utils/Utils'
import { IconDeleteAlarm, IconEditPencil } from 'utils/icon-centrocontrol'
import { ModalDeleteCalendarCcMaster } from 'views/Configuracion/Calendar/Modal/ModalDeleteCalendarCcMaster'
import { ModalPauseCalendarCcMaster } from 'views/Configuracion/Calendar/Modal/ModalPauseCalendarCcMaster'
import { ModalAddCalendarCcMaster } from 'views/Configuracion/Calendar/Modal/ModalAddCalendarCcMaster'
import { ModalEditGeneralControl } from 'views/Configuracion/Calendar/Modal/ModalEditGeneralControl'
import { ModalDownloadCalendar } from 'views/Configuracion/Calendar/Modal/ModalDownloadCalendar'
import '../CcMaster.scss'

export const CalendarCcMaster = ({ data, relay, setRelay, setUpdateCalendar, idCcMaster, centroControl, master, loading, setLoading }) => {
    const [body, setBody] = useState([])
    const [modalDelete, setModalDelete] = useState(false)
    const [modalPause, setModalPause] = useState(false)
    const [idEntry, setIdEntry] = useState([])
    const [openAdd, setOpenAdd] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [enableCheck, setEnableCheck] = useState(false)
    const [enableDelete, setEnableDelete] = useState(false)
    const [objetos, setObjetos] = useState([])
    const [masterEnable, setMasterEnable] = useState()
    const [config, setCofig] = useState()
    const [tableData, setTableData] = useState()
    const [dataItem, setDataItem] = useState([])
    const [dataCalendar, setDataCalendar] = useState({})
    const [open1, setOpen1] = useState(false)
    const [header, setHeader] = useState(true)
    const [masterCount, setMasterCount] = useState({})
    const [modalDownload, setModalDownload] = useState(false)

    const weeklyDayFuntion = (values) => {
        let dias = {
            domingo: 64,
            sabado: 32,
            viernes: 16,
            jueves: 8,
            miercoles: 4,
            martes: 2,
            lunes: 1
        }
        let total = values
        let arrayDias = []
        for (let [key, value] of Object.entries(dias)) {
            if (total - value >= 0) {
                total -= value
                arrayDias.push(key)
            }
        }
        return arrayDias
    }

    const handleModo = (code) => {
        if (code === 100 || code === 103) {
            return 'Normal'
        } else {
            return 'Pulso'
        }
    }

    const handleAction = (code) => {
        if (code === 100 || code === 101) {
            return 'Encendido'
        } else {
            return 'Apagado'
        }
    }

    const handleSun = (device) => {
        if (device?.sunset) {
            return 'Sunset | '
        } else if (device?.sunrise) {
            return 'Sunrise | '
        } else if (device?.hour && device.min) {
            return formatMinCalendar(device.hour + ':' + device.min) + ' | '
        } else {
            return ' '
        }
    }

    const handleWeekDay = (dev) => {
        let device = changeWednesday(dev)
        let newWeek = device?.map((day) => day?.charAt(0).toUpperCase()).reverse()
        return WeekWednesday(newWeek)
    }

    const handleProgramer = (device) => {
        if (device.type_date === 1 && (device.sunset || device.sunrise)) {
            return handleSun(device) + ' ' + FormatFecha(device.day)
        } else if (device?.type_date === 1 && device.min !== null) {
            return FormatFecha(device.day) + ' | ' + formatMinCalendar(device.hour + ':' + device.min)
        } else if (device?.type_date === 0 && (device?.sunset || device?.sunrise)) {
            return handleSun(device) + handleWeekDay(device.week)
        } else {
            return formatMinCalendar(device.hour + ':' + device.min) + ' | ' + handleWeekDay(device.week)
        }
    }

    const handleSelectAll = () => {
        setObjetos([])
        setIdEntry([])
        setEnableCheck(!enableCheck)
    }

    const handleDeleteAll = () => {
        if (enableCheck) {
            setEnableDelete(true)
        }
    }

    const handleCheckboxChange = (ID_entry, SN_device) => {
        const isSelected = idEntry.includes(ID_entry)
        const creaNuevoObjeto = (SN_device, ID_entry) => {
            return {
                SN_device,
                ID_entry: [ID_entry]
            }
        }
        const actualizaObjeto = (objetoExistente, ID_entry) => {
            return {
                ...objetoExistente,
                ID_entry: [...objetoExistente.ID_entry, ID_entry]
            }
        }
        if (isSelected) {
            setIdEntry(idEntry.filter((item) => item !== ID_entry))
            const nuevosObjetos = objetos.map((objeto) => {
                if (objeto.SN_device === SN_device) {
                    return {
                        ...objeto,
                        ID_entry: objeto.ID_entry.filter((item) => item !== ID_entry)
                    }
                }
                return objeto
            })
            setObjetos(nuevosObjetos)
        } else {
            setIdEntry([...idEntry, ID_entry])
            const objetoExistente = objetos.find((objeto) => objeto.SN_device === SN_device)
            if (objetoExistente) {
                const nuevosObjetos = objetos.map((objeto) => (objeto.SN_device === SN_device ? actualizaObjeto(objeto, ID_entry) : objeto))
                setObjetos(nuevosObjetos)
            } else {
                const nuevoObjeto = creaNuevoObjeto(SN_device, ID_entry)
                setObjetos([...objetos, nuevoObjeto])
            }
        }
    }

    useEffect(() => {
        if (objetos?.length === 0) {
            setHeader(true)
        } else {
            setHeader(false)
        }
    }, [objetos])

    const objetosFiltrados = () => {
        let filterObject = objetos.filter((objeto) => objeto.ID_entry.length > 0)
        setObjetos(filterObject)
    }

    useEffect(() => {
        let count = {}

        if (tableData?.length > 0) {
            tableData?.forEach((entry) => {
                const serialNumberMaster = entry?.SN_device
                if (serialNumberMaster in count) {
                    count[serialNumberMaster]++
                } else {
                    count[serialNumberMaster] = 1
                }
            })
        }
        setMasterCount(count)
    }, [tableData])

    useEffect(() => {
        setDataItem([])
        setDataCalendar([])
        data?.forEach((element) => {
            let weekDay = weeklyDayFuntion(element?.week_day)
            setDataItem((prev) => [
                ...prev,
                {
                    ID_output: element.ID_output,
                    alias: element.alias,
                    ID_entry: element.ID_entry,
                    start_date: element.start_date,
                    end_date: element.end_date,
                    enable: element.enable,
                    opti_mode: element.opti_mode,
                    internal_mode: element.internal_mode,
                    hour: element.hour,
                    min: element.min,
                    week_day: element?.week_day,
                    state: element?.state,
                    SN_device: Number(element.SN_device),
                    type_date: element?.type_date,
                    day: element?.day,
                    name_entry: relay?.[element?.ID_output]?.name,
                    sunset: element.sunset,
                    sunrise: element.sunrise,
                    min_offset: element?.min_offset,
                    week: weekDay,
                    nick_master: element?.nick_padre,
                    priority: element?.priority
                }
            ])
        })
    }, [data])

    useEffect(() => {
        if (dataItem) {
            const idsUnicos = new Set()
            // Filtrar el array para mantener solo objetos con ID_entry únicos
            const arraySinDuplicados = dataItem.filter((objeto) => {
                if (!idsUnicos.has(objeto.ID_entry)) {
                    idsUnicos.add(objeto.ID_entry)
                    return true
                }
                return false
            })
            setDataCalendar(arraySinDuplicados)
        }
    }, [dataItem])

    useEffect(() => {
        if (centroControl) {
            setTableData(dataItem)
        } else {
            setTableData(dataCalendar)
        }
    }, [dataCalendar])

    useEffect(() => {
        objetosFiltrados()
    }, [idEntry])

    return (
        <>
            <Grid item xs={12} className='mt-2 d-flex justify-content-end '>
                {data && data?.length > 0 && (
                    <span className='button-add-container mr-2'>
                        <ButtonStyled
                            className='buttonSeleccionar'
                            variant='contained'
                            type='submit'
                            onClick={() => setModalDownload(!modalDownload)}
                        >
                            <FontAwesomeIcon className='iconSeleccionar' icon={faFilePdf} style={{ marginRight: '5px' }} />
                            Descargar PDF
                        </ButtonStyled>
                    </span>
                )}
                {data && data?.length > 0 && (
                    <span className='button-add-container mr-2'>
                        <ButtonStyled className='buttonSeleccionar' variant='contained' type='submit' onClick={handleSelectAll}>
                            <FontAwesomeIcon
                                className='iconSeleccionar'
                                icon={faCheck}
                                style={{
                                    marginRight: '5px'
                                }}
                            />
                            Seleccionar varios
                        </ButtonStyled>
                    </span>
                )}
                {enableCheck && (
                    <span className='button-add-container mr-2'>
                        <ButtonStyled
                            className='boton-delete-calendar'
                            disabled={header}
                            title='Borrar'
                            type='submit'
                            onClick={handleDeleteAll}
                        >
                            <IconDeleteAlarm className='boton-delete-hijo' width='20' height='20' /> Borrar programaciones
                        </ButtonStyled>
                    </span>
                )}
                {enableCheck && (
                    <ModalDeleteCalendarCcMaster
                        body={objetos}
                        header={header}
                        modal={enableDelete}
                        action={handleDeleteAll}
                        setModal={setEnableDelete}
                        loading={loading}
                        setUpdateCalendar={setUpdateCalendar}
                    />
                )}
                <span className={`${tableData?.length > 0 ? 'button-add-container' : null}`} onClick={() => setOpenAdd(!openAdd)}>
                    <ModalAddCalendarCcMaster
                        masterCount={masterCount}
                        body={body}
                        idCcMaster={idCcMaster}
                        open1={open1}
                        data={data}
                        setOpen1={setOpen1}
                        relay={relay}
                        masterAll={master}
                        setRelay={setRelay}
                        loading={loading}
                        setBody={setBody}
                        setUpdateCalendar={setUpdateCalendar}
                        centroControl={centroControl}
                        setLoading={setLoading}
                    />
                </span>
            </Grid>
            {data && tableData?.length > 0 && !loading ? (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCellHead align='center'>Activo</StyledTableCellHead>
                                {centroControl && <StyledTableCellHead align='center'>Instalación</StyledTableCellHead>}
                                <StyledTableCellHead align='center'>Alias Salida</StyledTableCellHead>
                                <StyledTableCellHead align='center'>Salida</StyledTableCellHead>
                                <StyledTableCellHead align='center'>Modo</StyledTableCellHead>
                                <StyledTableCellHead align='center'>Acción</StyledTableCellHead>
                                <StyledTableCellHead align='center'>Programación</StyledTableCellHead>
                                <StyledTableCellHead align='center'></StyledTableCellHead>
                                {enableCheck && <StyledTableCellHead align='center'>Seleccionar</StyledTableCellHead>}
                                <StyledTableCellHead align='center'></StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData &&
                                tableData?.map((device, index) => {
                                    return (
                                        <StyledTableRow>
                                            <StyledTableCell>
                                                <SwitchStyled
                                                    id='habilitado'
                                                    className='switch-small'
                                                    checked={device?.enable}
                                                    onClick={() => {
                                                        setCofig(device)
                                                        setModalPause(true)
                                                        setMasterEnable(device?.SN_device)
                                                    }}
                                                />
                                            </StyledTableCell>
                                            {centroControl && (
                                                <StyledTableCell>
                                                    <p>{device?.nick_master}</p>
                                                </StyledTableCell>
                                            )}
                                            <StyledTableCell>
                                                <p>{device?.name_entry}</p>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <p>Relay {device?.ID_output + 1}</p>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <p>{handleModo(device.internal_mode)}</p>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <p>{handleAction(device.internal_mode)}</p>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <p>
                                                    {' '}
                                                    {handleProgramer(device)}{' '}
                                                    {device?.min_offset > 0 && ' | Desfase:' + device.min_offset + ' min'}
                                                </p>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {device.priority && <span className='priority-calendar priority-icon'>P</span>}
                                            </StyledTableCell>
                                            {enableCheck && (
                                                <StyledTableCell>
                                                    <TextField
                                                        type='checkbox'
                                                        className='checkAddCalendar'
                                                        checked={idEntry?.includes(device.ID_entry)}
                                                        onChange={() => {
                                                            handleCheckboxChange(device?.ID_entry, device?.SN_device.toString())
                                                        }}
                                                    />
                                                </StyledTableCell>
                                            )}
                                            <StyledTableCell className='d-flex justify-content-center align-items-center' key={index}>
                                                <ButtonStyledIcon
                                                    onClick={() => {
                                                        setCofig(device)
                                                        setOpenEdit(!openEdit)
                                                    }}
                                                    className='boton-edit-profile'
                                                >
                                                    <IconEditPencil className='color-icon' />
                                                </ButtonStyledIcon>
                                                <ButtonStyledIcon
                                                    disabled={enableCheck}
                                                    onClick={() => {
                                                        setCofig(device)
                                                        setModalDelete(true)
                                                        handleCheckboxChange(device?.ID_entry, device?.SN_device.toString())
                                                    }}
                                                    className='boton-edit-profile button-delete-calendar'
                                                >
                                                    <IconDeleteAlarm fill='#FF8A1F' className='color-icon' width='20' height='20' />
                                                </ButtonStyledIcon>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : data?.length === 0 && !loading ? (
                <>
                    <Grid item xs={12} className='d-flex justify-content-center align-items-center mt-3'>
                        <span>
                            <h4 align='center' className='mt-4'>
                                No hay ninguna programación configurada
                            </h4>
                        </span>
                    </Grid>
                </>
            ) : !data ? (
                <Grid item xs={12}>
                    <h2>Master no conectado</h2>
                </Grid>
            ) : (
                <Loader />
            )}
            {openEdit && (
                <ModalEditGeneralControl
                    body={body}
                    setLoading={setLoading}
                    setUpdateCalendar={setUpdateCalendar}
                    centroControl={centroControl}
                    setOpenEdit={setOpenEdit}
                    relay={relay}
                    setRelay={setRelay}
                    openEdit={openEdit}
                    loading={loading}
                    data={config}
                    tableData={tableData}
                    setUpdateDevice={setUpdateCalendar}
                    idCcMaster={idCcMaster ? idCcMaster : config?.SN_device}
                />
            )}
            {modalPause && (
                <ModalPauseCalendarCcMaster
                    modal={modalPause}
                    setModal={setModalPause}
                    idCcMaster={masterEnable ? masterEnable : idCcMaster}
                    data={config}
                    idEntry={config?.ID_entry}
                />
            )}
            {modalDelete && (
                <ModalDeleteCalendarCcMaster
                    body={objetos}
                    setUpdateCalendar={setUpdateCalendar}
                    loading={loading}
                    modal={modalDelete}
                    setModal={setModalDelete}
                />
            )}
            {modalDownload && (
                <ModalDownloadCalendar
                    modal={modalDownload}
                    setModal={setModalDownload}
                    tableData={tableData}
                    master={master}
                    setTableData={setTableData}
                />
            )}
        </>
    )
}
