import React from 'react'
import { FormControl, Grid, InputLabel, MenuItem } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { SelectStyled } from 'components/Select/SelectStyled'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { instalaciones, configPlantOption } from 'utils/constant'
import { IconWarning } from 'utils/icon-centrocontrol'
import { TableConfigPlant } from 'views/EMS/TableConfigPlant'
import { TableEditDataDevice } from '../EditDevice/TableEditDataDevice'
import { TerminalMQTT } from '../TerminalMQTT/terminalMQTT'
import './EditInstall.scss'
import { Loader } from 'components/Loader/Loader'

export const ConfigCCmasterInstall = ({
    data,
    setDisabledTable,
    centroControl,
    loadingTable,
    disabledTable,
    activeTab,
    dataEdit,
    loading,
    setDataEdit,
    configPlant,
    disabled,
    setDisabled,
    setConfigPlant,
    setActiveTab,
    dataConfig,
    setDataConfig,
    dataConfigInitial,
    setDataConfigInitial,
    setOpen,
    answer,
    setAnswer,
    setDataInitial,
    dataInitial
}) => {
    const handleDisable = (e) => {
        setConfigPlant(e)
        if (data?.inst_type === e) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    return (
        <Grid item xs={12} className='container-modal-edit-installation'>
            <>
                <Grid item xs={12} lg={6} className='edit-install-content'>
                    <Grid item xs={12} className='edit-install-content-data' style={{ paddingLeft: '30px' }}>
                        <div>
                            <h3>{data?.nick}</h3>
                        </div>
                        <div className='edit-install-content-VerNun'>
                            <p className='edit-install-content-bold'>FW:</p>
                            <p>{data?.version}v</p>
                        </div>
                        <div className='edit-install-content-VerNun'>
                            <p className='edit-install-content-bold'>Número de Serie:</p>
                            <p>{data?.numserie}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='edit-install-content-tabs'>
                        <div
                            selected={activeTab === 0}
                            onClick={() => {
                                setActiveTab(0)
                            }}
                        >
                            <span
                                className={`title-tab-config-icon ${activeTab === 0 ? 'list-item-icon active-button ' : 'list-item-icon'}`}
                            >
                                Configurar Planta
                                {data?.error === 1 && (
                                    <HtmlTooltip
                                        className='ml-1'
                                        title={<span>El tipo de instalación no coincide con el tipo de proyecto.</span>}
                                    >
                                        <IconWarning width={20} height={20} />
                                    </HtmlTooltip>
                                )}
                            </span>
                        </div>
                        <div
                            selected={activeTab === 1}
                            onClick={() => {
                                setActiveTab(1)
                            }}
                        >
                            <span className={`${activeTab === 1 ? 'list-item-icon active-button ' : 'list-item-icon'}`}>
                                Configurar Dispositivo
                                {data?.error === 2 && (
                                    <HtmlTooltip className='ml-1' title={<span>Hay un error en la configuración de su planta.</span>}>
                                        <IconWarning width={20} height={20} />
                                    </HtmlTooltip>
                                )}
                            </span>
                        </div>
                    </Grid>
                    {loading && <Loader />}
                    {!loadingTable && (
                        <Grid item xs={12} className='edit-install-content-table'>
                            {activeTab === 0 && (
                                <>
                                    <Grid className='select-config-plant-warning'>
                                        <FormControl sx={{ minWidth: 200, minHeight: 50 }} className='edit-install-content-select'>
                                            <InputLabel>Tipos de planta</InputLabel>
                                            <SelectStyled
                                                defaultValue={data?.inst_type}
                                                Select={true}
                                                value={configPlant}
                                                onChange={(e) => handleDisable(e.target.value)}
                                                autoWidth
                                                label='Tipos de planta'
                                            >
                                                {configPlantOption?.map((elm) => {
                                                    return <MenuItem value={elm?.id}>{elm?.name}</MenuItem>
                                                })}
                                            </SelectStyled>
                                        </FormControl>
                                        {data?.error === 1 && (
                                            <HtmlTooltip title={<span>Hay un error en su configuración. Por favor, revísela.</span>}>
                                                <IconWarning width={25} height={25} />
                                            </HtmlTooltip>
                                        )}
                                    </Grid>
                                    <TableConfigPlant
                                        autoconsumo={configPlant === instalaciones?.selfConsumption}
                                        configOrder={dataConfig}
                                        setConfigOrder={setDataConfig}
                                        dataConfigInitial={dataConfigInitial}
                                        setDataConfigInitial={setDataConfigInitial}
                                        data={data}
                                        setDisabled={setDisabledTable}
                                    />
                                </>
                            )}
                            {activeTab === 1 && (
                                <TableEditDataDevice
                                    centroControl={centroControl}
                                    setDataInitial={setDataInitial}
                                    dataInitial={dataInitial}
                                    master={data}
                                    setDataEdit={setDataEdit}
                                    dataEdit={dataEdit}
                                    answer={answer}
                                />
                            )}
                            {(activeTab === 0 || activeTab === 1) && (
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className='button-apply-table d-flex justify-content-flex-end mt-3'
                                >
                                    <ButtonStyled
                                        size='small'
                                        color='#fff'
                                        background='#FF8A1F'
                                        disabled={disabled && disabledTable}
                                        onClick={() => setOpen(true)}
                                    >
                                        Aplicar
                                    </ButtonStyled>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} lg={6} className='edit-install-content-terminal'>
                    <TerminalMQTT answer={answer} setAnswer={setAnswer} master={data?.numserie} numserieMaster={data?.numserie} />
                </Grid>
            </>
        </Grid>
    )
}
