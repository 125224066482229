import React, { createContext, useContext } from 'react'
import useGoogleMap from 'views/MapCentroControl/google/useGoogleMap'

const MapContext = createContext()

export const MapProvider = ({ children }) => {
    const { maps, map, mapRef, loading } = useGoogleMap({
        zoom: 5,
        center: { lat: 40, lng: 10 }
    })

    return <MapContext.Provider value={{ maps, map, mapRef, loading }}>{children}</MapContext.Provider>
}

export const useMapContext = () => useContext(MapContext)
