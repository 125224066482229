import React from 'react'
import { Button } from '@mui/material'

export const CalendarButton = ({ onClick, title, children, className, disabled, variant, size }) => {
    return (
        <Button onClick={onClick} className={className} variant={variant} disabled={disabled} size={size}>
            {title ? title : children}
        </Button>
    )
}
