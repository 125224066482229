import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Loader } from 'components/Loader/Loader'
import { apiCentroControl } from 'services/api'
import { dispositivos } from 'utils/constant'
import { IconMaintenance } from 'utils/icon-centrocontrol'
import { FiltroConfiguracionInstalaciones } from './FilterInstall/FiltroConfiguracionInstalaciones'
import { TableConfigInstallation } from './TableInstall/TableConfigInstallation'
import { MaintenanceMabtModal } from './ModalMaintance/MaintenanceMabtModal'
import { MaintenanceModal } from './ModalMaintance/MaintenanceModal'
import 'views/Alarms/styleAlarm.scss'

export const InstallationConfig = ({ activeTab, instalacioneSeleccionadas, masterError, dispositivo }) => {
    const [open, setOpen] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [selectedMasters, setSelectedMasters] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataInstall, setDataInstall] = useState([])
    const [filterMaster, setFilterMaster] = useState()
    const [permiAdmin, setPermiAdmin] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const bodyFetch = { numseries: masterError?.map((dev) => dev.numserie) }

    const fetchDataFirmware = async () => {
        try {
            const res = await apiCentroControl.post(`getFirmware`, bodyFetch)
            if (res?.status === 200) {
                setSelectedMasters(res.data)
            }
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        }
    }

    const fetchDataFwVersions = async (numserie) => {
        try {
            let res = await apiCentroControl.get(`getFWMABT/${numserie}`)
            if (res.status === 200) {
                setDataInstall(res?.data)
                return res.data
            }
        } catch (err) {
            console.error('ERROR. API centro de control', err)
        }
    }

    const adminDevice = async () => {
        let admin = selectedMasters?.some((dev) => dev?.permissions === 'admin' || dev?.permissions === 'instal')
        setPermiAdmin(admin)
    }

    const arrayMaster = async () => {
        setLoading(true)
        if (selectedMasters) {
            let result = await Promise.all(selectedMasters?.map((dev) => fetchDataFwVersions(dev?.numserie)))
            const resultStatus = result?.flatMap((array) => array)
            selectedMasters?.forEach((dev) => {
                const matchedDevice = masterError?.find((item) => dev?.numserie === item?.numserie)
                let mabtControler = selectedMasters?.filter((dev) => dev?.tipo === 'MABT')
                if (matchedDevice) {
                    dev.error = matchedDevice.error
                    dev.permissions = matchedDevice.permissions
                }
                if (mabtControler?.length > 0) {
                    resultStatus?.forEach((dev1) => {
                        if (dev?.numserie === dev1?.numserie) {
                            dev.version = dev1?.firmware
                        }
                    })
                }
            })
            adminDevice()
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchDataFirmware()
    }, [])

    useEffect(() => {
        arrayMaster()
    }, [selectedMasters])

    return (
        <div className='mt-2'>
            {activeTab === 3 && (
                <div className='table-responsive-md'>
                    {!loading && !permiAdmin && (
                        <h2 style={{ width: '100%', textAlign: 'center', marginTop: '2%' }}>No se encontraron dispositivos.</h2>
                    )}
                    {!loading && selectedMasters?.length > 0 && permiAdmin && (
                        <>
                            <Grid
                                item
                                className='boton-create-alarm'
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                            >
                                <FiltroConfiguracionInstalaciones data={selectedMasters} setFilterMaster={setFilterMaster} />

                                <div className='header-title'>
                                    <ButtonStyled className='button-with-text' onClick={() => setOpen(!open)}>
                                        <IconMaintenance className='icon-hover-with-text' />
                                        <p className='ml-2'>Mantenimiento</p>
                                    </ButtonStyled>
                                </div>

                                {open && dispositivo?.[0]?.id_tipo_dispositivo !== dispositivos?.ccmabt && (
                                    <MaintenanceModal setKeySwitch={setKeySwitch} open={open} setOpen={setOpen} data={selectedMasters} />
                                )}
                                {open && dispositivo?.[0]?.id_tipo_dispositivo === dispositivos?.ccmabt && (
                                    <MaintenanceMabtModal
                                        setKeySwitch={setKeySwitch}
                                        open={open}
                                        setOpen={setOpen}
                                        dispositivos={selectedMasters}
                                        setRefresh={setRefresh}
                                        refresh={refresh}
                                        data={dataInstall}
                                    />
                                )}
                            </Grid>
                            {filterMaster && (
                                <TableConfigInstallation
                                    instalacioneSeleccionadas={instalacioneSeleccionadas}
                                    modalEditar={modalEditar}
                                    setModalEditar={setModalEditar}
                                    filterMaster={filterMaster}
                                    keySwitch={keySwitch}
                                    data={filterMaster}
                                />
                            )}
                        </>
                    )}
                    {loading && (
                        <div className='justify-content-center'>
                            <Loader />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
