import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { Card } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { toKiloW } from 'utils/functionsforGraphs'
import { dispositivos } from 'utils/constant'
import { isMobile } from 'utils/Utils'
import { TensionGraph } from './TensionGraph'
import './PhasesTensionGraph.scss'

const PhasesTensionGraph = ({ loading, data, sinConectar }) => {
    const mobile = isMobile()
    const [t1, setT1] = useState(0)
    const [t2, setT2] = useState(0)
    const [t3, setT3] = useState(0)

    useEffect(() => {
        if (data?.v1 !== undefined) {
            setT1(!sinConectar ? toKiloW(data?.v1) : ' - ')
        }
        if (data?.v2 !== undefined) {
            setT2(!sinConectar ? toKiloW(data?.v2) : ' - ')
        }
        if (data?.v3 !== undefined) {
            setT3(!sinConectar ? toKiloW(data?.v3) : ' - ')
        }
    }, [data])

    return !loading ? (
        <Card style={{ boxShadow: '0px 0px 0px', padding: '30px 0px' }}>
            <Grid container>
                {!mobile && <Grid item xs={1} md={1} lg={2} xl={4} />}
                <Grid item xs={mobile ? 12 : 10} md={10} lg={8} xl={4}>
                    <Grid container style={{ height: '6.75em', borderBottom: '2px solid black' }}>
                        <Grid item xs={1} md={1} lg={1} xl={1} style={{ position: 'relative' }}>
                            <h2
                                style={{
                                    textAlign: 'start',
                                    color: '#FF8A1F',
                                    position: 'absolute',
                                    top: '3.1em',
                                    fontWeight: 'bold',
                                    fontSize: '21px'
                                }}
                            >
                                {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'R' : 'L1'}
                            </h2>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} xl={1} style={{ position: 'relative' }}>
                            <h2 style={{ position: 'absolute', top: '4em', width: '5em' }}>{t1} V</h2>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} xl={10} style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '-1.8em', width: '30em', right: '-7em' }}>
                                <TensionGraph value={!sinConectar ? t1 : 0} triphasic={true} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ position: 'relative' }}>
                            <p style={{ position: 'absolute', top: '3.5em', width: '30em', right: '-1.5em', fontWeight: 'bold' }}>220 V</p>
                            <p style={{ position: 'absolute', top: '3.5em', width: '30em', right: '-12em', fontWeight: 'bold' }}>250 V</p>
                        </Grid>
                    </Grid>
                </Grid>
                {!mobile && <Grid item xs={1} md={1} lg={2} xl={4} />}
            </Grid>
            <Grid container>
                {!mobile && <Grid item xs={1} md={1} lg={2} xl={4} />}
                <Grid item xs={mobile ? 12 : 10} md={10} lg={8} xl={4}>
                    <Grid container style={{ height: '9.8em', borderBottom: '2px solid black' }}>
                        <Grid item xs={1} md={1} lg={1} xl={1} style={{ position: 'relative' }}>
                            <h2
                                style={{
                                    textAlign: 'start',
                                    color: '#FF8A1F',
                                    position: 'absolute',
                                    top: '5.2em',
                                    fontWeight: 'bold',
                                    fontSize: '21px'
                                }}
                            >
                                {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'S' : 'L2'}
                            </h2>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} xl={1} style={{ position: 'relative' }}>
                            <h2 style={{ position: 'absolute', top: '6.5em', width: '5em' }}>{t2} V</h2>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} xl={10} style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '1.3em', width: '30em', right: '-7em' }}>
                                <TensionGraph value={!sinConectar ? t2 : 0} triphasic={true} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ position: 'relative' }}>
                            <p style={{ position: 'absolute', top: '5em', width: '30em', right: '-1.5em', fontWeight: 'bold' }}>220 V</p>
                            <p style={{ position: 'absolute', top: '5em', width: '30em', right: '-12em', fontWeight: 'bold' }}>250 V</p>
                        </Grid>
                    </Grid>
                </Grid>
                {!mobile && <Grid item xs={1} md={1} lg={2} xl={4} />}
            </Grid>
            <Grid container>
                {!mobile && <Grid item xs={1} md={1} lg={2} xl={4} />}
                <Grid item xs={mobile ? 12 : 10} md={10} lg={8} xl={4}>
                    <Grid container style={{ height: '9.8em', borderBottom: '2px solid black' }}>
                        <Grid item xs={1} md={1} lg={1} xl={1} style={{ position: 'relative' }}>
                            <h2
                                style={{
                                    textAlign: 'start',
                                    color: '#FF8A1F',
                                    position: 'absolute',
                                    top: '5.2em',
                                    fontWeight: 'bold',
                                    fontSize: '21px'
                                }}
                            >
                                {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'T' : 'L3'}
                            </h2>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} xl={1} style={{ position: 'relative' }}>
                            <h2 style={{ position: 'absolute', top: '6.5em', width: '5em' }}>{t3} V</h2>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} xl={10} style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '1.3em', width: '30em', right: '-7em' }}>
                                <TensionGraph value={!sinConectar ? t3 : 0} triphasic={true} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ position: 'relative' }}>
                            <p style={{ position: 'absolute', top: '5em', width: '30em', right: '-1.5em', fontWeight: 'bold' }}>220 V</p>
                            <p style={{ position: 'absolute', top: '5em', width: '30em', right: '-12em', fontWeight: 'bold' }}>250 V</p>
                        </Grid>
                    </Grid>
                </Grid>
                {!mobile && <Grid item xs={1} md={1} lg={2} xl={4} />}
            </Grid>
        </Card>
    ) : (
        <Loader />
    )
}

export default PhasesTensionGraph
