import React from 'react'
import Grid from '@mui/material/Grid'
import { dispositivos } from 'utils/constant'
import { ScadaMabtMedidor } from './ScadaMabtMedidor'
import { MabtHeaderCard } from './MabtHeaderCard'
import MenuMobile from './MenuMobile'

export const ScadaMabt = ({
    token,
    alarmas,
    selectMedidor,
    data,
    loadingData,
    medidor,
    devices,
    masterPermission,
    fechaVtn,
    datosLineas,
    mobile
}) => {
    devices?.childrenDevices?.sort((a, b) => {
        const aOutput = a['mabt_output']
        const bOutput = b['mabt_output']
        if (a?.id_tipo_dispositivo === dispositivos.ccmabtv || b?.id_tipo_dispositivo === dispositivos.ccmabtv) {
            if (a?.id_tipo_dispositivo === dispositivos.ccmabtv) {
                return -1
            }
            if (b?.id_tipo_dispositivo === dispositivos.ccmabtv) {
                return 1
            }
        } else {
            if (!aOutput) {
                return 1
            }
            if (!bOutput) {
                return -1
            }
        }
        if (aOutput > bOutput) {
            return 1
        }
        if (aOutput < bOutput) {
            return -1
        }
        return 0
    })

    return (
        <Grid container className='pt-3'>
            <Grid item xs={mobile ? 12 : 2} md={mobile ? 12 : 2} lg={mobile ? 12 : 2} xl={mobile ? 12 : 2}>
                {mobile ? (
                    <MenuMobile>
                        <Grid container>
                            {alarmas &&
                                devices?.childrenDevices.map((device) => {
                                    let dato = datosLineas.find((element) => element.numserie === device.numserie)
                                    let fecha = device?.id_tipo_dispositivo === dispositivos.ccmabtm ? dato?.datetime : fechaVtn
                                    let seleccionado = medidor.numserie
                                    return (
                                        <Grid xs={12} md={12} lg={12} xl={12}>
                                            <MabtHeaderCard
                                                masterPermission={masterPermission}
                                                device={device}
                                                alarmas={alarmas}
                                                fecha={fecha}
                                                selectMedidor={selectMedidor}
                                                data={data}
                                                loadingData={loadingData}
                                                seleccionado={seleccionado}
                                                scada={true}
                                            />
                                        </Grid>
                                    )
                                })}
                        </Grid>
                    </MenuMobile>
                ) : (
                    <Grid container>
                        {alarmas &&
                            devices?.childrenDevices.map((device) => {
                                let dato = datosLineas.find((element) => element.numserie === device.numserie)
                                let fecha = device.id_tipo_dispositivo === dispositivos.ccmabtm ? dato?.datetime : fechaVtn
                                let seleccionado = medidor.numserie
                                return (
                                    <Grid xs={12} md={12} lg={12} xl={12} className='mb-3'>
                                        <MabtHeaderCard
                                            masterPermission={masterPermission}
                                            device={device}
                                            alarmas={alarmas}
                                            fecha={fecha}
                                            selectMedidor={selectMedidor}
                                            data={data}
                                            loadingData={loadingData}
                                            seleccionado={seleccionado}
                                            scada={true}
                                        />
                                    </Grid>
                                )
                            })}
                    </Grid>
                )}
            </Grid>
            <Grid item xs={mobile ? 12 : 10} md={mobile ? 12 : 10} lg={mobile ? 12 : 10} xl={mobile ? 12 : 10} className='mt-2'>
                {!loadingData && (
                    <ScadaMabtMedidor
                        token={token}
                        device={medidor}
                        data={data}
                        devices={devices}
                        masterPermission={masterPermission}
                        idMaster={devices.numserie}
                        mabt={true}
                        loose={0}
                        activeTabDevicePrueba={3}
                    />
                )}
            </Grid>
        </Grid>
    )
}
