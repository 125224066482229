import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import moment from 'moment'
import { GlobalContext } from 'context/GlobalContext'
import { Loader } from 'components/Loader/Loader'
import { headersScada } from 'services/headers'
import { urls, dispositivos } from 'utils/constant'
import { translateSelf } from 'utils/Utils'
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import { Alarms } from 'views/Alarms/Alarms'
import { ScadaGeneralSelfConsumption } from './ScadaGeneralSelfConsumption'
import { ComponentSelfConsumption } from './ComponentSelfConsumption'
import { MenuScada } from '../../../components/MenuScada/MenuScada'
import { CardSelfConsumption } from './Cards/CardSelfConsumption'
import { scrollToTop } from './utilsSelfConsumption'
import { OnOff } from '../CcMaster/tabs/OnOff'

export const SelfConsumption = () => {
    const token = sessionStorage?.token
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    const { actualProject } = useContext(GlobalContext)
    const [start, setStart] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
    const [end, setEnd] = useState(moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }))
    const [daily, setDaily] = useState('days')
    const [activeTab, setActiveTab] = useState(0)
    const [devices, setDevices] = useState()
    const [devicesPlant, setDevicesPlant] = useState([])
    const [selfConsumption, setSelfConsumption] = useState('general')
    const [childrenType, setChildrenType] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [masterError, setMasterError] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingPlant, setLoadingPlant] = useState(true)
    let mobile = false
    const dispositivosChildren = devices?.childrenDevices.map((dev) => dev)
    window.document.title = actualProject.nick ? `Scada - ${actualProject.nick} - Autoconsumo` : `Scada - Autoconsumo`

    const fetchDevices = (idCCMaster) => {
        fetch(urls.scada + `getccm/${idCCMaster}?idCCMaster=${idCCMaster}`, { method: 'GET', headers: headersScada(token) })
            .then((res) => res.json())
            .then((data) => {
                setDevices({
                    ...data,
                    permissions: actualProject?.permissions,
                    error: actualProject?.error
                })
            })
            .catch((err) => console.error('ERROR. API Scada', err))
            .finally(() => {})
    }

    const fetchDevicesPlant = async (numserie) => {
        setLoadingPlant(true)
        try {
            const res = await fetch(urls.scada + `getccm/${numserie?.[0]?.numserie}?idCCMaster=${idCcMaster}`, {
                method: 'GET',
                headers: headersScada(token)
            })
            if (res.status === 200) {
                const json = await res.json()
                setDevicesPlant(json)
            } else return []
        } catch (error) {
            console.error('ERROR. API Scada', error)
        } finally {
            setLoadingPlant(false)
        }
    }

    const fetchCurrentData = (getSelfConsumption) => {
        if (getSelfConsumption) {
            fetch(urls.scada + `getCurrentData/${getSelfConsumption?.[0]?.numserie}?idCCMaster=${getSelfConsumption?.[0]?.master_serial}`, {
                method: 'GET',
                headers: headersScada(token)
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json()
                    } else {
                        return []
                    }
                })
                .then((data) => {
                    setCurrentData(data)
                })
                .catch((err) => {
                    console.error('ERROR. API Scada', err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const seeWeb = (type) => {
        scrollToTop()
        setLoading(true)
        setSelfConsumption(type)
        if (type === 'general') {
            setActiveTab(0)
        }
        let aux = devicesPlant?.[translateSelf(type)]?.map((item) => item.devices)?.flat()
        let arrayChildren = dispositivosChildren?.filter((dev) => aux?.includes(dev.numserie))
        setChildrenType(arrayChildren)
        setLoading(false)
    }

    useEffect(() => {
        if (actualProject) {
            fetchDevices(idCcMaster)
            setMasterError([
                {
                    nick: actualProject.nick,
                    numserie: actualProject.numserie,
                    permissions: actualProject.permissions,
                    shared: actualProject.shared,
                    error: actualProject.error
                }
            ])
        }
    }, [actualProject])

    useEffect(() => {
        if (devices) {
            const getSelfConsumption = devices?.childrenDevices?.filter((device) => device?.id_tipo_dispositivo === dispositivos.planta)
            fetchCurrentData(getSelfConsumption)
            fetchDevicesPlant(getSelfConsumption)
        }
    }, [devices])

    return (
        <div className='container-scada-principal'>
            {loading && <Loader />}
            {!loading && (
                <Grid container>
                    <Grid item xs={12} className='pl-3 pt-2 navbar-scada scada-navbar-centro-control'>
                        <MenuScada
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            master={devices}
                            currentData={currentData}
                            loose={0}
                            setSelfConsumption={setSelfConsumption}
                            selfConsumption={selfConsumption}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} className='scada-self-container'>
                        <Grid item xs={2} lg={2}>
                            <CardSelfConsumption
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                data={currentData}
                                isMobile={mobile}
                                seeWeb={seeWeb}
                                selfConsumption={selfConsumption}
                            />
                        </Grid>
                        <Grid item xs={11} lg={10}>
                            {activeTab === 0 && (
                                <ScadaGeneralSelfConsumption
                                    start={start}
                                    setStart={setStart}
                                    end={end}
                                    setEnd={setEnd}
                                    daily={daily}
                                    setDaily={setDaily}
                                    data={currentData}
                                    devices={devices}
                                />
                            )}
                            {activeTab === 1 && <Alarms instalacioneSeleccionadas={[devices]} dispositivos={dispositivosChildren} />}
                            {activeTab === 2 && (
                                <ConfiguracionList
                                    scadaSelf={true}
                                    instalacioneSeleccionadas={[devices]}
                                    dispositivo={[devices]}
                                    scada={true}
                                    masterError={masterError}
                                    autoconsumo={true}
                                />
                            )}
                            {activeTab === 4 && <OnOff activeTab={activeTab} isMobile={mobile} />}
                            {activeTab === 5 && (
                                <ComponentSelfConsumption
                                    devicesPlant={devicesPlant[translateSelf(selfConsumption)]}
                                    selfConsumption={selfConsumption}
                                    setActiveTab={setActiveTab}
                                    setSelfConsumption={setSelfConsumption}
                                    currentData={currentData}
                                    deviceChildren={childrenType}
                                    setLoading={setLoading}
                                    start={start}
                                    setStart={setStart}
                                    end={end}
                                    setEnd={setEnd}
                                    daily={daily}
                                    setDaily={setDaily}
                                    master={devices}
                                    loading={loadingPlant}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}
