// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapaConfiguracion-container {
  height: 22em;
}

.mapaConfiguracionFullHeight {
  height: 85vh;
}

.css-13cymwt-control:hover {
  border: 1px solid #ff8a1f !important;
}

.css-t3ipsp-control {
  border: 1px solid #ff8a1f !important;
  box-shadow: 0 0 0 1px #ff8a1f !important;
}

.autocomplete-selector {
  width: 100%;
  border: 0;
  height: 1.4375em;
  box-sizing: content-box;
}

.autocomplete-container {
  position: relative;
  border-radius: 4px;
  padding: 8.5px 14px !important;
  border: 1px solid #c0bbbb;
}
.autocomplete-container:hover {
  border: 1px solid #ff8a1f;
}

.clear-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  fill: #ff8a1f !important;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Mapa/styleMap.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAGI;EACI,oCAAA;AAAR;;AAIA;EACI,oCAAA;EACA,wCAAA;AADJ;;AAIA;EACI,WAAA;EACA,SAAA;EACA,gBAAA;EACA,uBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,kBAAA;EACA,8BAAA;EACA,yBAAA;AADJ;AAEI;EACI,yBAAA;AAAR;;AAIA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,eAAA;AADJ","sourcesContent":[".mapaConfiguracion-container {\n    height: 22em;\n}\n\n.mapaConfiguracionFullHeight {\n    height: 85vh;\n}\n\n.css-13cymwt-control {\n    &:hover {\n        border: 1px solid #ff8a1f !important;\n    }\n}\n\n.css-t3ipsp-control {\n    border: 1px solid #ff8a1f !important;\n    box-shadow: 0 0 0 1px #ff8a1f !important;\n}\n\n.autocomplete-selector {\n    width: 100%;\n    border: 0;\n    height: 1.4375em;\n    box-sizing: content-box;\n}\n\n.autocomplete-container {\n    position: relative;\n    border-radius: 4px;\n    padding: 8.5px 14px !important;\n    border: 1px solid #c0bbbb;\n    &:hover {\n        border: 1px solid #ff8a1f;\n    }\n}\n\n.clear-icon {\n    position: absolute;\n    top: 50%;\n    right: 8px;\n    transform: translateY(-50%);\n    width: 24px;\n    height: 24px;\n    fill: #ff8a1f !important;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
