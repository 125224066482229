import React, { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'
import { ToastResponse } from 'components/Toast/ToastResponse'
import { GlobalProvider } from 'context/GlobalContext'
import { MapProvider } from 'context/MapContext'
import Projects from 'routes/layouts/Projects'
import AuthLayout from 'routes/layouts/Auth'
import { Admin } from 'routes/layouts/Admin'
import store from 'reduxSlice/store'
import { RecaptchaPublic } from 'services/headers'
import { SelfConsumption } from 'views/Scada/SelfConsumption/SelfConsumption'
import { Photovoltaic } from 'views/Scada/Photovoltaic/Photovoltaic'
import { Consumption } from 'views/Scada/Consumption/Consumption'
import { MabtController } from 'views/Scada/MABT/MabtController'
import { ScadaEnerSim } from 'views/Scada/Enersim/ScadaEnerSim'
import { Lighting } from 'views/Scada/Lighting/Lighting'
import { ChangeLog } from 'views/ChangeLog/ChangeLog'
import MainLayout from 'views/Main/MainLayout'

export const App = () => {
    let div = document.getElementById('webpack-dev-server-client-overlay')
    let div2 = document.getElementById('webpack-dev-server-client-overlay-div')
    if (div) {
        div.parentNode.replaceChild(div)
    }
    if (div2) {
        div2.parentNode.replaceChild(div2)
    }
    if (sessionStorage?.tokenImpersonate) {
        sessionStorage.setItem('token', sessionStorage?.tokenImpersonate)
        sessionStorage.setItem('impersonate', true)
        sessionStorage.setItem('email', sessionStorage?.emailImpersonate)
        sessionStorage.removeItem('tokenImpersonate')
        sessionStorage.removeItem('emailImpersonate')
    }

    useEffect(() => {
        window.history.scrollRestoration = 'manual'
    }, [])

    return (
        <GoogleReCaptchaProvider reCaptchaKey={RecaptchaPublic}>
            <Provider store={store}>
                <GlobalProvider>
                    <MapProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path='/admin/*'
                                    element={
                                        <MainLayout centroControl={true}>
                                            <Admin />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/projects/*'
                                    element={
                                        <MainLayout centroControl={true}>
                                            <Projects />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/view/scada/:id'
                                    element={
                                        <MainLayout scada={true}>
                                            <ScadaEnerSim />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/scada/lighting/:id/:numserieMaster'
                                    element={
                                        <MainLayout scada={true}>
                                            <Lighting />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/scada/consumption/:id/:numserieMaster'
                                    element={
                                        <MainLayout scada={true}>
                                            <Consumption />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/scada/distribution/:id/:numserieMaster'
                                    element={
                                        <MainLayout scada={true}>
                                            <MabtController />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/scada/photovoltaic/:id/:numserieMaster'
                                    element={
                                        <MainLayout scada={true}>
                                            <Photovoltaic />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/scada/selfconsumption/:id/:numserieMaster'
                                    element={
                                        <MainLayout scada={true}>
                                            <SelfConsumption />
                                        </MainLayout>
                                    }
                                />
                                <Route path='/auth/*' element={<AuthLayout />} />
                                <Route
                                    path='/changelog'
                                    element={
                                        <MainLayout centroControl={true}>
                                            <ChangeLog />
                                        </MainLayout>
                                    }
                                />
                                <Route path='*' element={<Navigate to='/auth/login' replace />} />
                            </Routes>
                        </BrowserRouter>
                        <ToastResponse />
                    </MapProvider>
                </GlobalProvider>
            </Provider>
        </GoogleReCaptchaProvider>
    )
}
