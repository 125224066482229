import React, { useEffect, useRef, useState } from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { nombreDispositivo, nombreAlarma } from 'utils/Utils'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { capitalize } from 'utils/Capitalize'
import { IconNewObject, IconRefresh } from 'utils/icon-centrocontrol'
import { CrearConfigAlarms } from '../CreateAlarm/CrearConfigAlarms'
import { dispositivos, alarmasConfig } from 'utils/constant'
import 'views/Alarms/styleAlarm.scss'

/**
 * @param {{ alarmasProyecto: Number[][], dispositivosProyecto: Number[], tipoDispositivo: Number, setTipoDispositivo: Function, tipoAlarma: Number, setTipoAlarma: Function }}
 * @param alarmasProyecto Array
 * @param dispositivosProyecto Array con los tipos de dispositivos que tiene la instalacion
 * @param tipoDispositivo Estado que guarda la informacion del tipo de dispositivo
 * @param setTipoDispositivo Funcion para cambiar el estado de tipoDispositivo
 * @param tipoAlarma Estado que guarda la informacion del tipo de alarma
 * @param setTipoAlarma Funcion para cambiar el estado de tipoAlarma
 * @returns Crea el filtro de configuracion de alarmas
 */
export const FiltroConfiguracionAlarms = ({
    dispositivosCrearAlarma,
    alarmasProyecto,
    dispositivosProyecto,
    tipoDispositivo,
    setTipoDispositivo,
    tipoAlarma,
    setTipoAlarma,
    setActualizarAlarma,
    token,
    open,
    setOpen,
    instalacioneSeleccionadas,
    alarmasPendientes
}) => {
    const [keyDispositivo, setKeyDispositivo] = useState(Date.now() + 'dispositivo')
    const [keyAlarma, setKeyAlarma] = useState(Date.now() + 'alarma')
    let responsiveFiltro =
        window.innerWidth < 350
            ? 12
            : window.innerWidth < 390
              ? 10
              : window.innerWidth > 700
                ? 5
                : window.innerWidth > 600
                  ? 6
                  : window.innerWidth > 500
                    ? 8
                    : 9
    const filtroRef = useRef(null)
    let menuCentroControl = filtroRef?.current?.offsetWidth < 1750

    /**
     * @returns vacia los campos de los filtros
     */
    const vaciarFiltro = () => {
        if (tipoAlarma !== undefined || tipoDispositivo !== undefined) {
            setKeyDispositivo(Date.now() + 'dispositivo')
            setKeyAlarma(Date.now() + 'alarma')
            setTipoAlarma()
            setTipoDispositivo()
        }
    }

    useEffect(() => {
        if (filtroRef) {
            setKeyDispositivo(Date.now() + 'dispositivo')
        }
    }, [filtroRef])

    return (
        <Grid container spacing={2} columns={18} ref={filtroRef}>
            <Grid
                item
                xs={responsiveFiltro}
                md={menuCentroControl ? 5 : 4}
                lg={menuCentroControl ? 4 : 3}
                xl={window.innerWidth < 1700 || menuCentroControl ? 3 : 2}
            >
                <FormControl sx={{ minWidth: 180 }} size='small'>
                    <InputLabel id='select-tipo-dispositivo-input' style={{ color: '#8c8c8c' }}>
                        Tipo Dispositivo
                    </InputLabel>
                    <Select
                        key={keyDispositivo}
                        className='style-textfield-text tamano-select'
                        labelId='select-tipo-dispositivo-label'
                        id='select-tipo-dispositivo'
                        value={tipoDispositivo}
                        label='Tipo Dispositivo'
                        onChange={(e) => {
                            setTipoDispositivo(e.target.value)
                        }}
                    >
                        <MenuItem value={-1}>Todos</MenuItem>
                        {Object.values(dispositivos).map((dispositivo, index) => {
                            if (
                                alarmasProyecto[dispositivo] &&
                                dispositivosProyecto.indexOf(dispositivo) !== -1 &&
                                ((tipoAlarma !== undefined && alarmasProyecto[dispositivo].indexOf(tipoAlarma) !== -1) ||
                                    tipoAlarma === undefined ||
                                    tipoAlarma === -1)
                            ) {
                                return (
                                    <MenuItem key={index} value={dispositivo}>
                                        {capitalize(nombreDispositivo(dispositivo))}
                                    </MenuItem>
                                )
                            } else {
                                return null
                            }
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid
                item
                xs={responsiveFiltro}
                md={menuCentroControl ? 5 : 4}
                lg={menuCentroControl ? 4 : 3}
                xl={window.innerWidth < 1700 || menuCentroControl ? 3 : 2}
            >
                <FormControl sx={{ minWidth: 180 }} size='small'>
                    <InputLabel id='select-tipo-alarma-input' style={{ color: '#8c8c8c' }}>
                        Tipo Alarma
                    </InputLabel>
                    <Select
                        key={keyAlarma}
                        className='style-textfield-text tamano-select'
                        labelId='select-tipo-alarma-label'
                        id='select-tipo-alarma'
                        value={tipoAlarma}
                        label='alarma'
                        onChange={(e) => {
                            setTipoAlarma(e.target.value)
                        }}
                    >
                        <MenuItem value={-1}>Todos</MenuItem>
                        {Object.values(alarmasConfig).map((alarma, index) => {
                            if (
                                (tipoDispositivo !== undefined &&
                                    alarmasProyecto[tipoDispositivo] &&
                                    alarmasProyecto[tipoDispositivo].indexOf(alarma) !== -1) ||
                                tipoDispositivo === undefined ||
                                tipoDispositivo === -1
                            ) {
                                return (
                                    <MenuItem key={index} value={alarma}>
                                        {nombreAlarma(alarma)}
                                    </MenuItem>
                                )
                            } else {
                                return null
                            }
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2} md={1} lg={1} xl={1}>
                <ButtonStyledIcon className='boton-refresh' title='Refrescar' onClick={vaciarFiltro}>
                    <IconRefresh className='boton-refresh-hijo' />
                </ButtonStyledIcon>
            </Grid>
            <Grid
                item
                className='pr-0'
                xs={responsiveFiltro === 9 ? 16 : responsiveFiltro === 5 ? 6 : 18}
                md={menuCentroControl ? 7 : 9}
                lg={menuCentroControl ? 9 : 11}
                xl={window.innerWidth < 1700 || menuCentroControl ? 11 : 13}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonStyled
                        className='button-with-text'
                        onClick={() => setOpen(!open)}
                        disabled={alarmasPendientes && Object.keys(alarmasPendientes).length === 0}
                    >
                        <IconNewObject className='icon-hover-with-text' />
                        <p className='ml-2'>Nueva Alarma</p>
                    </ButtonStyled>
                </div>
                {open && (
                    <CrearConfigAlarms
                        setActualizarAlarma={setActualizarAlarma}
                        token={token}
                        instalacioneSeleccionadas={instalacioneSeleccionadas}
                        dispositivosProyecto2={dispositivosCrearAlarma}
                        open={open}
                        setOpen={setOpen}
                        master={dispositivos}
                        alarmasProyecto={alarmasProyecto}
                        dispositivosProyecto={dispositivosProyecto}
                        alarmasPendientes={alarmasPendientes}
                    />
                )}
            </Grid>
        </Grid>
    )
}
