import React from 'react'
import { FormControl, MenuItem, Select } from '@mui/material'

export const SelectorFechaV2 = ({ handleDate, date, endDate, dateType, setDateType, selectedButton }) => {
    const handleSelect = (value) => {
        const objDate = {
            start: date,
            end: endDate,
            daily: value
        }
        setDateType(value)
        handleDate(objDate)
    }

    return (
        <FormControl sx={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} size='small'>
            <Select
                className='tamano-select'
                style={{ height: '2.4em', maxWidth: '10em' }}
                id='select-agrupacion-fecha'
                value={dateType}
                onChange={(e) => {
                    handleSelect(e.target.value)
                }}
            >
                {['Hoy', 'Ayer'].includes(selectedButton) && <MenuItem value='hours'>Horas</MenuItem>}
                {['Hoy', 'Ayer', 'Mes', 'Rango'].includes(selectedButton) && <MenuItem value='days'>Días</MenuItem>}
                {['Mes', 'Año'].includes(selectedButton) && <MenuItem value='months'>Meses</MenuItem>}
                {['Año'].includes(selectedButton) && <MenuItem value='years'>Años</MenuItem>}
            </Select>
        </FormControl>
    )
}
