import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import { DropdownMaster } from 'views/Master/MenuMaster/DropdownMaster'
import { PopUpInfo } from 'components/PopUp/PopUpInfo'
import { OpenToScada } from 'utils/Utils'

export const HeadCcMabt = ({ data, mabt, permissions, idProject, token }) => {
    return (
        <>
            <Grid item xs={11} md={11} lg={11} xl={11} className='flex-start justify-start'>
                <div className='width-04em'>
                    <DropdownMaster token={token} data={data} master={mabt} permissions={permissions} />
                </div>
                <div className='pl-2'>
                    <Link
                        className='card-name-device flex'
                        to='#'
                        onMouseDown={(e) => {
                            if (e.button === 0 || e.button === 1) {
                                e.preventDefault()
                                let url = OpenToScada(3, idProject, mabt?.numserie)
                                window.open(url)
                            }
                        }}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <small className='name-mabt font-weight-bold p-0'>{mabt.nick}</small>
                    </Link>
                </div>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
                <PopUpInfo numserie={mabt?.numserie} nickdevice={mabt?.nick} />
            </Grid>
        </>
    )
}
