import React, { useState, useEffect } from 'react'

const TimeWeather = (props) => {
    const [dateTime, setDateTime] = useState(new Date())

    useEffect(() => {
        const id = setInterval(() => setDateTime(new Date()), 1000)
        return () => {
            clearInterval(id)
        }
    }, [])

    return <span className='text-white'>{` ${dateTime.toLocaleTimeString()}`}</span>
}
export default TimeWeather
