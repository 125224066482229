import React from 'react'
import { toKiloW } from 'utils/functionsforGraphs'
import './StyleConsumption.scss'

export const CardTriphasic = ({ data }) => {
    return (
        <>
            <tr className='card-scada-containter-background'>
                <td className='card-scada-containter-table-cell-l card-scada-containter-table-cell-l-title '>L1</td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.pact1)}</td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.i1)}</td>
                <td className='card-scada-containter-table-cell'>
                    {toKiloW(
                        data?.currentData?.v1 !== undefined && data?.currentData?.v1 !== null
                            ? data?.currentData?.v1
                            : data?.currentData?.vac1
                    )}
                </td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.pf1)}</td>
            </tr>
            <tr>
                <td className='card-scada-containter-table-cell-l card-scada-containter-table-cell-l-title '>L2</td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.pact2)}</td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.i2)}</td>
                <td className='card-scada-containter-table-cell'>
                    {toKiloW(
                        data?.currentData?.v2 !== undefined && data?.currentData?.v2 !== null
                            ? data?.currentData?.v2
                            : data?.currentData?.vac2
                    )}
                </td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.pf2)}</td>
            </tr>
            <tr className='card-scada-containter-background'>
                <td className='card-scada-containter-table-cell-l card-scada-containter-table-cell-l-title '>L3</td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.pact3)}</td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.i3)}</td>
                <td className='card-scada-containter-table-cell'>
                    {toKiloW(
                        data?.currentData?.v3 !== undefined && data?.currentData?.v3 !== null
                            ? data?.currentData?.v3
                            : data?.currentData?.vac3
                    )}
                </td>
                <td className='card-scada-containter-table-cell'>{toKiloW(data?.currentData?.pf3)}</td>
            </tr>
        </>
    )
}

export const SinglePhasic = ({ data }) => {
    return (
        <tr className='card-scada-containter-background'>
            <td className='card-scada-container-table-title'>Total</td>
            <td className='card-scada-container-table-sigle'>{toKiloW(data?.currentData?.pact)}</td>
            <td className='card-scada-container-table-sigle'>{toKiloW(data?.currentData?.it)}</td>
            <td className='card-scada-container-table-sigle'>
                {toKiloW(
                    data?.currentData?.v1 !== null && data?.currentData?.v1 !== undefined
                        ? data?.currentData?.v1
                        : data?.currentData?.v
                          ? data?.currentData?.v
                          : data?.currentData?.vac
                )}
            </td>
            <td className='card-scada-container-table-sigle'>
                {toKiloW(
                    data?.currentData?.pf1 !== null && data?.currentData?.pf1 !== undefined ? data?.currentData?.pf1 : data?.currentData?.pf
                )}
            </td>
        </tr>
    )
}
