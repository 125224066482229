import React, { useContext, useState } from 'react'
import { Grid } from '@mui/material'
import { urls } from 'utils/constant'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl } from 'services/headers'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'
import { GlobalContext } from 'context/GlobalContext'

export const ModalDeleteDeviceNew = ({ modal, setModal, ...props }) => {
    const dispatch = useDispatch()
    const token = sessionStorage?.token
    const { setUpdateDevice } = useContext(GlobalContext)
    const [secondModal, setSecondModal] = useState(false)
    const [thirdModal, setThirdModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const toggle = () => setModal(!modal)
    const toggle2 = () => setSecondModal(!secondModal)
    const toggle3 = () => setThirdModal(!thirdModal)

    const DeleteDevice = async () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `devicefromparent`, {
            method: 'DELETE',
            redirect: 'follow',
            headers: headersCentroControl(token),
            body: JSON.stringify({
                numserie: props?.devices?.numserie,
                idCCMaster: props?.permissions?.numserie
                    ? props?.permissions?.numserie
                    : props?.devices?.master_serial
                      ? props?.devices?.master_serial
                      : props?.devices?.mabt_serial
                        ? props?.devices?.mabt_serial
                        : props?.devices?.id_padre
            })
        })
            .then((res) => {
                if (res.ok) {
                    status = res.status
                    dispatch(createToast({ status: status, message: 'Dispositivo eliminado correctamente' }))
                    toggle()
                    toggle2()
                } else {
                    dispatch(createToast({ status: status, message: 'Error al eliminar el dispositivo' }))
                    toggle(false)
                    toggle3()
                }
            })
            .catch((err) => console.error('ERROR. API Centro de Control', err))
            .finally(() => {
                setLoading(false)
                setUpdateDevice((current) => !current)
            })
    }

    return (
        <>
            <Modal
                open={modal}
                setOpen={setModal}
                toggle={toggle}
                onClick={(e) => e.stopPropagation()}
                title={`Eliminar "${props.devices.nick}"`}
                buttonAction='Eliminar'
                functSave={DeleteDevice}
                loading={loading}
            >
                <Grid item xs={12} className='font-weight-lighter text-center'>
                    <div>
                        <span>Se perderán todos los históricos de este dispositivo.</span>
                        <h2 className='mt-2'>¿Deseas eliminar el dispositivo?</h2>
                    </div>
                </Grid>
            </Modal>
        </>
    )
}
