import { configureStore } from '@reduxjs/toolkit'
import refreshNavFooterReducer from './refreshNavFooterSlice'
import instalacionReducer from './instalacionSlice'
import treeDevicesReducer from './treeDevicesSlice'
import permissionReducer from './permissionSlice'
import loadingReducer from './loadingSlice'
import alarmaReducer from './alarmaSlice'
import toastReducer from './toastSlice'
import infoInstalReducer from './infoInstalSlice'
import filterAlarmReducer from './filterAlarmSlice'
import refreshDeviceReducer from './refreshDeviceSlice'
import logosReducer from './logosSlice'
import mapReducer from './mapSlice'

export default configureStore({
    reducer: {
        alarma: alarmaReducer,
        instalacion: instalacionReducer,
        refreshNavFooter: refreshNavFooterReducer,
        permission: permissionReducer,
        loading: loadingReducer,
        toast: toastReducer,
        treeDevices: treeDevicesReducer,
        infoInstal: infoInstalReducer,
        filterAlarm: filterAlarmReducer,
        refreshDevice: refreshDeviceReducer,
        map: mapReducer,
        logos: logosReducer
    }
})
