import React, { useState, useEffect } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Grid } from '@mui/material'
import { toKiloW, toKilowPercent } from 'utils/functionsforGraphs'

export const DataDevices = ({ device, data }) => {
    const [energy, setEnergy] = useState()
    const [percentagePower, setPercentagePower] = useState()
    const [hiredPower, setHiredPower] = useState()

    useEffect(() => {
        setHiredPower(toKiloW(data?.pact))
        setEnergy(toKiloW(data?.eaconsdia))
        if (typeof data?.pact === 'number' && typeof device?.potencia_contratada === 'number') {
            setPercentagePower(
                device?.potencia_contratada === 0 ? 0?.toFixed(1) : ((data?.pact * 100) / device?.potencia_contratada)?.toFixed(1)
            )
        }
    }, [data])

    return (
        <Grid container spacing={0}>
            <Grid
                item
                xs={6}
                md={6}
                xl={6}
                style={{
                    borderLeft: '1px solid #b7b7b7',
                    borderTop: '1px solid #b7b7b7',
                    borderRight: '1px solid #b7b7b7',
                    borderRadius: '2px 2px 0 0',
                    paddingLeft: '2px'
                }}
            >
                <small style={{ fontSize: '0.6em' }}>P (kW): </small>
                <small style={{ fontWeight: 'bold' }}>{hiredPower}</small>
            </Grid>
            <Grid
                item
                xs={6}
                md={6}
                xl={6}
                style={{ borderBottom: '1px solid #b7b7b7', borderRadius: '0 0 -2px -2px', paddingLeft: '2px' }}
            >
                <small style={{ fontSize: '0.6em' }}>E (kWh) : </small>
                <small style={{ fontWeight: 'bold' }}>{energy}</small>
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                xl={12}
                style={{
                    borderLeft: '1px solid #b7b7b7',
                    borderRight: '1px solid #b7b7b7',
                    borderBottom: '1px solid #b7b7b7',
                    padding: '3px 3px 0 3px',
                    borderRadius: '0 0 4px 4px'
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12} xl={12}>
                        <ProgressBar
                            style={{ height: '15px', marginBottom: 0, marginTop: '5px' }}
                            now={percentagePower}
                            variant={
                                percentagePower > 100
                                    ? 'danger'
                                    : percentagePower > 75
                                      ? 'warning'
                                      : percentagePower > 50
                                        ? 'yellow'
                                        : 'green'
                            }
                        />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2} className='mt-1'>
                        <div style={{ borderLeft: 'solid 1px #7a7a7a', borderTop: 'solid 1px #7a7a7a', height: '5px' }} />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2} className='mt-1'>
                        <div style={{ borderLeft: 'solid 1px #7a7a7a', borderTop: 'solid 1px #7a7a7a', height: '5px' }} />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2} className='mt-1'>
                        <div style={{ borderLeft: 'solid 1px #7a7a7a', borderTop: 'solid 1px #7a7a7a', height: '5px' }} />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2} className='mt-1'>
                        <div
                            style={{
                                borderRight: 'solid 1px #7a7a7a',
                                borderLeft: 'solid 1px #7a7a7a',
                                borderTop: 'solid 1px #7a7a7a',
                                height: '5px'
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2} className='mt-1'>
                        <div style={{ borderRight: 'solid 1px #7a7a7a', borderTop: 'solid 1px #7a7a7a', height: '5px' }} />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2} className='mt-1'>
                        <div style={{ borderRight: 'solid 1px #7a7a7a', borderTop: 'solid 1px #7a7a7a', height: '5px' }} />
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={4} md={4} xl={4} className='d-flex'>
                                <small style={{ fontSize: '0.6em', fontWeight: 'bold' }}>0 w</small>
                            </Grid>
                            <Grid item xs={4} md={4} xl={4}>
                                {percentagePower ? (
                                    <small className='d-flex justify-content-center' style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
                                        {toKilowPercent(percentagePower)} %
                                    </small>
                                ) : (
                                    <small
                                        className='d-flex justify-content-center'
                                        style={{ fontSize: '0.8em', fontWeight: 'bold' }}
                                    ></small>
                                )}
                            </Grid>
                            <Grid item xs={4} md={4} xl={4}>
                                <small className='d-flex justify-content-end' style={{ fontSize: '0.6em', fontWeight: 'bold' }}>
                                    {toKiloW(device?.potencia_contratada)} kW
                                </small>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
