// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuracion-box-derecha {
  height: 100%;
}

.inputTextoConfiguracionMabt {
  width: 21em;
}

.inputTextoConfiguracionMabt-edit {
  width: 18em;
}

.configuracion-box {
  padding-top: 3em;
}
.configuracion-box .titulo {
  border-bottom: 1px solid rgb(109, 106, 106);
  display: inline-block;
  width: max-content;
}
.configuracion-box .mapa {
  border-left: 1px solid rgb(109, 106, 106);
}

.configuracion-box-mabt .titulo {
  border-bottom: 1px solid rgb(109, 106, 106);
  display: inline-block;
  width: max-content;
}
.configuracion-box-mabt .mapa {
  border-left: 1px solid rgb(109, 106, 106);
}

.campo-container {
  padding-top: 3em;
}`, "",{"version":3,"sources":["webpack://./src/views/Configuracion/Mabt/ConfiguracionPlanta.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;AACI;EACI,2CAAA;EACA,qBAAA;EACA,kBAAA;AACR;AAEI;EACI,yCAAA;AAAR;;AAKI;EACI,2CAAA;EACA,qBAAA;EACA,kBAAA;AAFR;AAKI;EACI,yCAAA;AAHR;;AAOA;EACI,gBAAA;AAJJ","sourcesContent":[".configuracion-box-derecha {\n    height: 100%;\n}\n\n.inputTextoConfiguracionMabt {\n    width: 21em;\n}\n\n.inputTextoConfiguracionMabt-edit {\n    width: 18em;\n}\n\n.configuracion-box {\n    padding-top: 3em;\n\n    .titulo {\n        border-bottom: 1px solid rgb(109, 106, 106);\n        display: inline-block;\n        width: max-content;\n    }\n\n    .mapa {\n        border-left: 1px solid rgb(109, 106, 106);\n    }\n}\n\n.configuracion-box-mabt {\n    .titulo {\n        border-bottom: 1px solid rgb(109, 106, 106);\n        display: inline-block;\n        width: max-content;\n    }\n\n    .mapa {\n        border-left: 1px solid rgb(109, 106, 106);\n    }\n}\n\n.campo-container {\n    padding-top: 3em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
