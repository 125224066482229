// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-text {
  font-size: 0.75em;
}

.activation-text {
  font-size: 0.9em;
  color: #73bf45;
  font-weight: 600;
}

.status-text {
  font-size: 0.9em;
  font-weight: 600;
}
.status-text.no-activada {
  color: #e91e1e;
}
.status-text.activada {
  color: #73bf45;
}
.status-text.activacion-pendiente {
  color: #e91e1e;
}
.status-text.cancelada {
  color: #e91e1e;
}
.status-text.cancelacion-pendiente {
  color: #e91e1e;
}
.status-text.peticion-denegada {
  color: #e91e1e;
}
.status-text.preactivada {
  color: #73bf45;
}
.status-text.error {
  color: #e91e1e;
}

.color-grey {
  --tw-text-opacity: 1;
  color: rgb(87, 87, 87, var(--tw-text-opacity));
}

.warning-switch-off-text {
  --tw-text-opacity: 1;
  color: rgb(233, 30, 30, var(--tw-text-opacity));
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/views/Modals/ModalSim/modalSim.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,cAAA;EACA,gBAAA;AADJ;;AAMA;EACI,gBAAA;EACA,gBAAA;AAHJ;AAKI;EACI,cAAA;AAHR;AAKI;EACI,cAAA;AAHR;AAKI;EACI,cAAA;AAHR;AAKI;EACI,cAAA;AAHR;AAKI;EACI,cAAA;AAHR;AAKI;EACI,cAAA;AAHR;AAKI;EACI,cAAA;AAHR;AAKI;EACI,cAAA;AAHR;;AASA;EACI,oBAAA;EACA,8CAAA;AANJ;;AASA;EACI,oBAAA;EACA,+CAAA;EACA,gBAAA;AANJ","sourcesContent":["//modal add new sim\n\n.small-text {\n    font-size: 0.75em;\n}\n\n.activation-text {\n    font-size: 0.9em;\n    color: #73bf45;\n    font-weight: 600;\n}\n\n//modal edit info\n\n.status-text {\n    font-size: 0.9em;\n    font-weight: 600;\n\n    &.no-activada {\n        color: #e91e1e;\n    }\n    &.activada {\n        color: #73bf45;\n    }\n    &.activacion-pendiente {\n        color: #e91e1e;\n    }\n    &.cancelada {\n        color: #e91e1e;\n    }\n    &.cancelacion-pendiente {\n        color: #e91e1e;\n    }\n    &.peticion-denegada {\n        color: #e91e1e;\n    }\n    &.preactivada {\n        color: #73bf45;\n    }\n    &.error {\n        color: #e91e1e;\n    }\n}\n\n//modal switch off sim\n\n.color-grey {\n    --tw-text-opacity: 1;\n    color: rgb(87 87 87 / var(--tw-text-opacity));\n}\n\n.warning-switch-off-text {\n    --tw-text-opacity: 1;\n    color: rgb(233 30 30 / var(--tw-text-opacity));\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
