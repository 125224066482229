import axios from 'axios'
import {
    headersCentroControl,
    headersCentroControlSharedCede,
    headersScada,
    headersUsers,
    headersUsersLogin,
    headersCentroControlImagen
} from './headers'

export const apiCentroControl = axios.create({
    baseURL: process.env.REACT_APP_URL_CCONTROL
})

export const apiCentroControlLogo = axios.create({
    baseURL: process.env.REACT_APP_URL_CCONTROL
})

export const apiScada = axios.create({
    baseURL: process.env.REACT_APP_URL_SCADA
})

export const apiUserAutenticated = axios.create({
    baseURL: process.env.REACT_APP_URL_USERS
})

export const apiCentroControlNoAutenticated = axios.create({
    baseURL: process.env.REACT_APP_URL_CCONTROL,
    headers: headersCentroControlSharedCede
})

export const apiUserNoAutenticated = axios.create({
    baseURL: process.env.REACT_APP_URL_USERS,
    headers: headersUsersLogin
})

const addTokenInterceptor = (apiInstance, headersFunction) => {
    apiInstance.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem('token')
            if (token) {
                config.headers = {
                    ...headersFunction(token)
                }
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )
}

addTokenInterceptor(apiCentroControl, headersCentroControl)
addTokenInterceptor(apiCentroControlLogo, headersCentroControlImagen)
addTokenInterceptor(apiScada, headersScada)
addTokenInterceptor(apiUserAutenticated, headersUsers)
