import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { urls } from 'utils/constant.jsx'
import { headersCentroControl } from 'services/headers'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

export const ModalDeleteGroup = ({ groups, groupId, open, setOpen, toggle, project }) => {
    const navigate = useNavigate()
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [disable, setDisable] = useState(false)

    const handleRemove = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `group`, {
            method: 'DELETE',
            headers: headersCentroControl(token),
            body: JSON.stringify({ id_group: Number(groupId) })
        })
            .then((res) => {
                setLoading(false)
                status = res.status
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                toggle(data)
                if (project.groups.length === 1) {
                    navigate(`/projects/inicio`)
                }
            })
            .catch((err) => console.error('ERROR. API Centro de Control', err))
    }

    let subgroups = groups?.subgroups
    let devSubgroups = subgroups?.some((subgroup) => subgroup?.devices?.length > 0)

    useEffect(() => {
        if (groups?.devices.length > 0 || devSubgroups) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                maxWidth='sm'
                open={open}
                setOpen={setOpen}
                disabled={disable}
                title={`Eliminar grupo "${groups?.nick}"`}
                loading={loading}
                functSave={handleRemove}
                buttonAction='Eliminar'
            >
                <Grid item xs={12} className='text-center mt-2'>
                    {groups?.devices?.length > 0 || devSubgroups ? (
                        <h4 className='font-weight-lighter' style={{ fontSize: '1.2rem' }}>
                            <p>Tienes dispositivos en este grupo. Elimínalos antes.</p>
                        </h4>
                    ) : (
                        <Grid item xs={12} className='text-center mt-2'>
                            <span>
                                <h3 style={{ fontSize: '1.2rem' }} className='font-weight-lighter'>
                                    ¿Deseas eliminar este grupo?
                                </h3>
                                {project.groups.length === 1 && (
                                    <>
                                        <h5>
                                            {' '}
                                            Este es el último grupo del proyecto, si se elimina,
                                            <span className='information-text-move-device '> se eliminará el proyecto completo.</span>{' '}
                                        </h5>
                                    </>
                                )}
                            </span>
                        </Grid>
                    )}
                    <hr className='m-0' />
                </Grid>
            </Modal>
        </>
    )
}
