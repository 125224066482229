import { useLocation } from 'react-router-dom'
import './footer.scss'

export default function Footer({ className, companyLogos, copyrightText = 'Enerclic', version = '1.12.0' }) {
    const location = useLocation()
    const paramsURL = location?.pathname?.split('/')?.[1]

    return (
        <footer className={`${className} footer-style px-3 py-2`}>
            <div className='date-container'>
                <span className='year-text'>© {new Date().getFullYear()}</span>
                <span className='copyright-text'>{copyrightText}</span>
            </div>
            <div className='logos-container'>
                {companyLogos && companyLogos?.map((url, i) => <img src={url} key={i} className='image-container' alt='logo' />)}
            </div>
            <div className='version-container'>
                {paramsURL !== 'auth' ? <a onClick={() => window.open(`/changelog`)}>{version}</a> : version}
            </div>
        </footer>
    )
}
