// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-report-photo {
  margin-top: 30px !important;
}

.photo-general-selector {
  margin-top: 30px !important;
}

.calendar-photo-general {
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-scada-select-calendar > * {
  margin: 0px 15px;
}

.table-photo-general {
  margin-top: 30px !important;
}

.container-graphs-photovoltaic {
  margin: 30px 0px;
  padding-right: 30px;
}

.button-tab-graphs-photovoltaic {
  margin-bottom: 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/Photovoltaic/StylePhotovoltaic.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AAEA;EACI,2BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,2BAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ","sourcesContent":[".container-report-photo {\n    margin-top: 30px !important;\n}\n\n.photo-general-selector {\n    margin-top: 30px !important;\n}\n\n.calendar-photo-general {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.report-scada-select-calendar > * {\n    margin: 0px 15px;\n}\n\n.table-photo-general {\n    margin-top: 30px !important;\n}\n\n.container-graphs-photovoltaic {\n    margin: 30px 0px;\n    padding-right: 30px;\n}\n\n.button-tab-graphs-photovoltaic {\n    margin-bottom: 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
