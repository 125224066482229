// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
Deshabilita los botones flecha en el input tipo number del jsx del mismo nombre
*/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputDisabled:disabled {
  color: #000;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/views/Configuracion/Installation/EditDevice/EditDataDevice.scss"],"names":[],"mappings":"AAAA;;CAAA;AAGA;;EAEI,wBAAA;EACA,SAAA;AACJ;;AAEA;EACI,WAAA;EACA,UAAA;AACJ","sourcesContent":["/**\nDeshabilita los botones flecha en el input tipo number del jsx del mismo nombre\n*/\ninput[type='number']::-webkit-outer-spin-button,\ninput[type='number']::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n.inputDisabled:disabled {\n    color: #000;\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
