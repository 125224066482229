import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { nombreDispositivo, nombreAlarma } from 'utils/Utils'
import { Loader } from 'components/Loader/Loader'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { ComentarioPrivado } from './ComentarioPrivado'
import { ComentarioPublico } from './ComentarioPublico'
import { ComentarioNuevo } from './ComentarioNuevo'
import { urls } from 'utils/constant'

/**
 * @param {{ alarma: {id: Number, alarm_id: Number, instalacion: string, nick: string, device_type: Number, numserie: Number}, modalComentario: Boolean, setModalComentario: Function, token: string }}
 * @param alarma Alarma seleccionada
 * @param modalComentario Estado que indica si el modal esta abierto
 * @param setModalComentario Funcion para cambiar el estado del modal
 * @param token Token para hacer la llamada
 * @returns Devuelve el crud de los comentarios
 */
export const ComentarioAlarma = ({ alarma, modalComentario, setModalComentario, token }) => {
    const [comentarioPrivado, setCommentarioPrivado] = useState()
    const [comentarioPublico, setCommentarioPublico] = useState()
    const [loading, setLoading] = useState(true)
    const [nuevo, setNuevo] = useState(true)

    /**
     * @returns Devuelve los comentarios de la alarma
     */
    const fecthComentario = async () => {
        setLoading(true)
        let body = { device: alarma.numserie, id_alarm: alarma.id }
        try {
            const res = await fetch(urls.scada + 'alarms/getCommentsAlarm', {
                method: 'POST',
                headers: headersScada(token),
                body: JSON.stringify(body)
            })
            if (res.status === 200) {
                let json = await res.json()
                setCommentarioPrivado(json.filter((comentario) => comentario.public === 0))
                setCommentarioPublico(json.filter((comentario) => comentario.public === 1))
            } else {
                setCommentarioPrivado([])
                setCommentarioPublico([])
            }
            setLoading(false)
            return true
        } catch (err) {
            console.error('ERROR. API Scada', err)
        }
    }

    useEffect(() => {
        fecthComentario()
    }, [nuevo])

    return (
        <Modal
            open={modalComentario}
            setOpen={() => {
                setModalComentario(false)
            }}
            title='Comentario'
            loading={loading}
            footer={<></>}
        >
            <Grid container className='pr-5 pl-5'>
                <Grid className='pr-2 pb-3' item xs={12} md={6} lg={6} xl={6}>
                    <div className='pb-1'>
                        <small className='color-black'>Instalación:</small>
                    </div>
                    <InputStyled value={alarma.instalacion} disabled={true} fullWidth={true} />
                </Grid>
                <Grid className='pr-2' item xs={12} md={6} lg={6} xl={6}>
                    <div className='pb-1'>
                        <small className='color-black'>Dispositivo:</small>
                    </div>
                    <InputStyled value={alarma.nick} disabled={true} fullWidth={true} />
                </Grid>
                <Grid className='pr-2 pb-3' item xs={12} md={6} lg={6} xl={6}>
                    <div className='pb-1'>
                        <small className='color-black'>Tipo dispositivo:</small>
                    </div>
                    <InputStyled value={nombreDispositivo(alarma.device_type)} disabled={true} fullWidth={true} />
                </Grid>
                <Grid className='pr-2' item xs={12} md={6} lg={6} xl={6}>
                    <div className='pb-1'>
                        <small className='color-black'>Tipo alarma:</small>
                    </div>
                    <InputStyled value={nombreAlarma(alarma.alarm_id)} disabled={true} fullWidth={true} />
                </Grid>
                <Grid className='pb-3' item xs={12} md={12} lg={12} xl={12}>
                    {!loading && comentarioPrivado && (
                        <ComentarioPrivado
                            token={token}
                            comentarioPrivado={comentarioPrivado[0]}
                            setNuevo={setNuevo}
                            device={alarma.numserie}
                            id_alarm={alarma.id}
                        />
                    )}
                </Grid>
                <Grid container className='d-flex justify-content-center'>
                    {!loading ? (
                        comentarioPublico.map((comentario) => {
                            return <ComentarioPublico token={token} comentarioPublico={comentario} setNuevo={setNuevo} />
                        })
                    ) : (
                        <div className='justify-content-center'>
                            <Loader />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <ComentarioNuevo token={token} device={alarma.numserie} id_alarm={alarma.id} setNuevo={setNuevo} />
                </Grid>
            </Grid>
        </Modal>
    )
}
