export default function ChartInlineEnersim({ total = 30, used = 29.5 }) {
    let available
    let percentage

    if (used !== '-') {
        available = total - parseFloat(used)
        percentage = (parseFloat(used) / total) * 100
    }

    return (
        <div className={`chart-inline-enersim-container ${used === '-' ? 'chart-inline-enersim-container-no-data' : ''}`}>
            <p className='chart-inline-enersim-text chart-inline-enersim-text-dark'>
                {used !== '-' ? (
                    <>
                        Quedan <span className='font-semibold'>{available.toFixed(2)}</span> MB de {total} MB
                    </>
                ) : (
                    'No hay datos'
                )}
            </p>
            <div className='chart-inline-enersim-bar-wrapper'>
                {used !== '-' && (
                    <div
                        className='chart-inline-enersim-bar chart-inline-enersim-bar-used chart-inline-enersim-bar-used-dark'
                        style={{ width: percentage + '%' }}
                    ></div>
                )}
                <div className='chart-inline-enersim-bar chart-inline-enersim-bar-empty chart-inline-enersim-bar-empty-dark'></div>
            </div>
            {used !== '-' && (
                <p className='chart-inline-enersim-right-text chart-inline-enersim-right-text-dark'>
                    Has usado {parseFloat(used).toFixed(2)} MB
                </p>
            )}
        </div>
    )
}
