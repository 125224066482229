import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import './seeker.scss'
import { selectOnlyDeviceTree } from 'reduxSlice/treeDevicesSlice'

export const Seeker = ({ searchResults, setVisible }) => {
    const [myOptions, setMyOptions] = useState(
        searchResults?.flatMap((result) =>
            result?.devices
                ?.filter?.((device) => device?.error !== 1)
                ?.map((device) => {
                    return { label: device.nick, value: `${device.nick} - ${device.numserie}`, numserie: device.numserie }
                })
        )
    )
    const [toTransfer, setToTransfer] = useState([])
    const [text, setText] = useState('')
    const [keyText, setKeyText] = useState('key')
    const dispatch = useDispatch()

    const search = (e) => {
        setVisible(false)
        setText(e.target.value)
        setMyOptions(
            toTransfer?.map((device) => {
                return { label: device.nick, value: `${device.nick} - ${device.numserie}`, numserie: device.numserie }
            })
        )
    }

    const takeValue = (event, value) => {
        let deviceSelect = value
            ? myOptions?.find((device) =>
                  device?.value?.toLowerCase()?.includes(value?.label ? value?.label?.toLowerCase() : value?.toLowerCase())
              )
            : ''
        if (deviceSelect) {
            setText(deviceSelect.label)
            setVisible(false)
            setKeyText(new Date())
            dispatch(selectOnlyDeviceTree({ device: deviceSelect?.numserie }))
        } else {
            setText('')
            setVisible(true)
            setKeyText(new Date())
        }
    }

    const filterOptions = (options, { inputValue }) => {
        return options?.filter((option) => option?.value?.toLowerCase().includes(inputValue?.toLowerCase()))
    }

    useEffect(() => {
        setToTransfer([])
        searchResults !== undefined &&
            searchResults.forEach((group, k) => {
                group?.devices?.length > 0 &&
                    group?.devices?.forEach((device, key) => {
                        if (device?.error !== 1) {
                            setToTransfer((prev) => [...prev, device])
                        }
                    })
                group?.subgroups &&
                    group?.subgroups?.length > 0 &&
                    group?.subgroups?.forEach((subgroup, index) => {
                        subgroup?.devices?.length > 0 &&
                            subgroup?.devices?.forEach((device, i) => {
                                if (device?.error !== 1) {
                                    setToTransfer((prev) => [...prev, device])
                                }
                            })
                    })
            })
    }, [searchResults])

    return (
        <div className='flex justify-start'>
            <Autocomplete
                key={keyText}
                style={{ width: '100%' }}
                size='small'
                freeSolo
                getOptionLabel={(option) => option['label']}
                options={myOptions?.filter(Boolean)}
                filterOptions={filterOptions}
                className={'autoComplete style-textfield-text autocomplete-seekers'}
                onChange={(event, value) => takeValue(event, value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className='style-textfield-text textField'
                        variant='outlined'
                        style={{ width: '100%' }}
                        placeholder={text ? text : 'Buscar...'}
                        InputProps={{
                            ...params.InputProps,
                            color: 'primary',
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position='end'></InputAdornment>
                        }}
                        onChange={(e) => search(e)}
                    />
                )}
            />
        </div>
    )
}
