import React, { useEffect, useState } from 'react'
import { TableEditDataDevice } from '../EditDevice/TableEditDataDevice'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Grid } from '@mui/material'
import { ModalConfigChangeInstall } from './ModalConfigChangeInstall'

export const InstallationsConfigScada = ({ master }) => {
    const [dataInitial, setDataInitial] = useState([])
    const [dataEdit, setDataEdit] = useState([])
    const [open, setOpen] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [answer, setAnswer] = useState()

    useEffect(() => {
        setDisabled(!dataEdit.length > 0)
    }, [dataEdit])

    return (
        <>
            <Grid item xs={12}>
                <TableEditDataDevice
                    setDataInitial={setDataInitial}
                    dataInitial={dataInitial}
                    master={master?.[0]}
                    setDataEdit={setDataEdit}
                    dataEdit={dataEdit}
                    answer={answer}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12} md={12} lg={12} xl={12} className='button-apply-table d-flex justify-content-flex-end mt-3'>
                    <ButtonStyled size='small' color='#fff' background='#FF8A1F' disabled={disabled} onClick={() => setOpen(true)}>
                        Aplicar
                    </ButtonStyled>
                </Grid>
            </Grid>
            {open && (
                <ModalConfigChangeInstall
                    master={master}
                    dataInitialPmax={dataInitial}
                    setDataInitialPmax={setDataInitial}
                    setDisabled={setDisabled}
                    dataPotMax={dataEdit}
                    dataInitial={dataInitial}
                    setAnswer={setAnswer}
                    activeTab={1}
                    open={open}
                    setOpen={setOpen}
                    idMaster={master?.[0].numserie}
                />
            )}
        </>
    )
}
