import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'
import { Grid } from '@mui/material'
import { devices, initialColumns } from 'utils/Utils'
import { Modal } from 'components/Modal/Modal'
import { apiScada } from 'services/api'

export const ModalDeleteLayout = ({
    open,
    isLoading,
    setOpen,
    setIsLoading,
    setRelayCount,
    setLayoutData,
    setColumns,
    numserie,
    setConfiguration,
    setMoves,
    updateMovesCount
}) => {
    const dispatch = useDispatch()

    const deleteLayout = async ({ numserie }) => {
        setIsLoading(true)
        const url = `distributionPanel/${numserie}`

        try {
            const response = await apiScada.delete(url)
            if (response.status === 200) {
                setLayoutData(null)
                setConfiguration([])
                setRelayCount(1)
                setMoves(updateMovesCount())
                setColumns({
                    Devices: {
                        name: 'Devices',
                        items: devices
                    },
                    Relay1: {
                        name: 'Relay1',
                        columns: initialColumns('Relay1')
                    }
                })
                dispatch(createToast({ status: response.status, message: 'Configuración borrada', reload: false }))
            }
        } catch (err) {
            console.error('No ha sido posible comunicarse con la API de ....DELETE CALENDAR', err)
            dispatch(createToast({ status: 'error', message: 'Error en la solicitud', reload: false }))
        } finally {
            setIsLoading(false)
            setOpen(!open)
        }
    }

    const handleDelete = () => {
        deleteLayout({ numserie })
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title='Borrar configuración'
            buttonAction='Aceptar'
            functSave={handleDelete}
            loading={isLoading}
            disabled={isLoading}
        >
            <Grid container className='text-center mt-2'>
                <h4 className='font-weight-lighter modal-font'>¿Deseas borrar esta configuración?</h4>
            </Grid>
        </Modal>
    )
}
