import React, { useEffect, useState } from 'react'
import { FormControlLabel, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { StyledTableCellHead, StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { headersScada } from 'services/headers'
import {
    urls,
    devicesAllowedPF,
    devicesAllowedProd,
    devicesAllowedConsumption,
    dispositivos,
    devicesAllowedTablePlant
} from 'utils/constant'
import { nombreDispositivo, areArraysEqualModal } from 'utils/Utils'

export const TableConfigPlant = ({
    autoconsumo,
    data,
    configOrder,
    setConfigOrder,
    dataConfigInitial,
    setDataConfigInitial,
    setDisabled
}) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(true)

    const fetchConfigPlantInst = async (numserie) => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + `getConfigPlantInstallation/${numserie}?idCCMaster=${numserie}`, {
                method: 'GET',
                headers: headersScada(token)
            })
            if (res?.status === 200) {
                let json = await res.json()
                let data = json.filter((ele) => devicesAllowedTablePlant?.includes(ele?.id_tipo_dispositivo))
                setConfigOrder(JSON.parse(JSON.stringify(data)))
                setDataConfigInitial(JSON.parse(JSON.stringify(data)))
            } else {
                return []
            }
        } catch (err) {
            console.error('No se ha podido traer datos de la API', err)
        } finally {
            setLoading(false)
        }
    }

    const activeMarker = (event, index) => {
        let aux = [...configOrder]
        aux[index].ID_plant = aux?.[index]?.ID_plant === null ? '-' : null
        setConfigOrder(aux)
    }

    const redMarker = (event, index) => {
        let aux = [...configOrder]
        aux[index].ID_plant = aux?.[index]?.ID_plant === 'CONS_0001' ? '-' : 'CONS_0001'
        aux?.forEach((ele, i) => {
            if (ele?.ID_plant === 'CONS_0001' && index !== i) {
                ele.ID_plant = !autoconsumo ? null : '-'
            }
        })
        setConfigOrder(aux)
    }

    const productionMarker = (event, index) => {
        let aux = [...configOrder]
        aux[index].ID_plant =
            aux?.[index]?.id_tipo_dispositivo === dispositivos.inversor
                ? 'PROD_0001'
                : aux?.[index]?.ID_plant === 'PROD_0001'
                  ? '-'
                  : 'PROD_0001'
        setConfigOrder(aux)
    }

    useEffect(() => {
        fetchConfigPlantInst(data.numserie ? data.numserie : data?.[0]?.numserie)
    }, [])

    useEffect(() => {
        setDisabled(areArraysEqualModal(configOrder, dataConfigInitial))
    }, [configOrder])

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                {loading && (
                    <Grid items xs={12} className='calendar-historical-flex'>
                        <Loader />
                    </Grid>
                )}
                {!loading && configOrder?.length === 0 && (
                    <Grid items xs={12} className='calendar-historical-flex'>
                        <h2 style={{ width: '100%', textAlign: 'center', marginTop: '2%' }}>
                            No hay dispositivos conectados en este máster.
                        </h2>
                    </Grid>
                )}
                {!loading && configOrder?.length > 0 && (
                    <TableContainer style={{ border: '1px solid #e0e0e0' }}>
                        <Table aria-label='customized table'>
                            <TableHead style={{ borderRadius: '50px' }}>
                                <TableRow style={{ padding: '0px', margin: '0px' }}>
                                    <StyledTableCellHead align='center'>Model</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Nombre</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Serial</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Punto Frontera</StyledTableCellHead>
                                    {autoconsumo && (
                                        <StyledTableCellHead align='center' style={{ padding: '0px' }}>
                                            Producción
                                        </StyledTableCellHead>
                                    )}
                                    <StyledTableCellHead align='center'>Consumo</StyledTableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {configOrder !== undefined &&
                                    configOrder?.length > 0 &&
                                    configOrder?.map((device, key) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell align='center' className='cell-word-table-editplant'>
                                                    {device?.id_tipo_dispositivo !== undefined && device?.id_tipo_dispositivo !== null
                                                        ? nombreDispositivo(device?.id_tipo_dispositivo)
                                                        : '-'}
                                                </StyledTableCell>
                                                <StyledTableCell align='center' className='cell-word-table-editplant'>
                                                    {device?.nick !== undefined && device?.nick !== null ? device?.nick : '-'}
                                                </StyledTableCell>
                                                <StyledTableCell align='center' className='cell-word-table-editplant'>
                                                    {device?.numserie !== undefined && device?.numserie !== null ? device?.numserie : '-'}
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <FormControlLabel
                                                        style={{ margin: '0px' }}
                                                        control={
                                                            <label
                                                                className='checkbox path checkInstallations checkbox-display-group check-pasos'
                                                                style={{ stroke: device?.ID_plant === 'CONS_0001' ? '#FF8A1F' : '#fff' }}
                                                            >
                                                                <input
                                                                    type='checkbox'
                                                                    className='checkbox-editInstall checbox-edit-plan'
                                                                    name={`Red`}
                                                                    style={{
                                                                        background: '#fff',
                                                                        width: '18px',
                                                                        height: '18px',
                                                                        margin: '0px',
                                                                        stroke: device?.ID_plant === `CONS_0001` ? '#FF8A1F' : '#fff',
                                                                        color: device?.ID_plant === `CONS_0001` ? '#FF8A1F' : '#fff',
                                                                        cursor: !devicesAllowedPF?.includes(device?.id_tipo_dispositivo)
                                                                            ? 'context-menu'
                                                                            : 'pointer'
                                                                    }}
                                                                    checked={device?.ID_plant === 'CONS_0001' ? true : false}
                                                                    onChange={(event) => redMarker(event, key)}
                                                                    disabled={!devicesAllowedPF?.includes(device?.id_tipo_dispositivo)}
                                                                />
                                                            </label>
                                                        }
                                                    />
                                                </StyledTableCell>
                                                {autoconsumo && (
                                                    <StyledTableCell align='center'>
                                                        <FormControlLabel
                                                            style={{ margin: '0px' }}
                                                            control={
                                                                <label
                                                                    className='checkbox path checkInstallations checkbox-display-group check-pasos'
                                                                    style={{
                                                                        stroke: device?.ID_plant === 'PROD_0001' ? '#FF8A1F' : '#fff'
                                                                    }}
                                                                >
                                                                    <input
                                                                        type='checkbox'
                                                                        className='checkbox-editInstall checbox-edit-plan'
                                                                        name={'Producción'}
                                                                        style={{
                                                                            background: '#fff',
                                                                            width: '18px',
                                                                            height: '18px',
                                                                            margin: '0px',
                                                                            stroke: device?.ID_plant === 'PROD_0001' ? '#FF8A1F' : '#fff',
                                                                            color: device?.ID_plant === 'PROD_0001' ? '#FF8A1F' : '#fff',
                                                                            cursor: !devicesAllowedProd?.includes(
                                                                                device?.id_tipo_dispositivo
                                                                            )
                                                                                ? 'context-menu'
                                                                                : 'pointer'
                                                                        }}
                                                                        checked={device?.ID_plant === 'PROD_0001' ? true : false}
                                                                        onChange={(event) => productionMarker(event, key)}
                                                                        disabled={
                                                                            !devicesAllowedProd?.includes(device?.id_tipo_dispositivo)
                                                                        }
                                                                    />
                                                                </label>
                                                            }
                                                        />
                                                    </StyledTableCell>
                                                )}
                                                <StyledTableCell align='center'>
                                                    <FormControlLabel
                                                        style={{ margin: '0px' }}
                                                        control={
                                                            <label
                                                                className='checkbox path checkInstallations checkbox-display-group check-pasos'
                                                                style={{ stroke: device?.ID_plant === null ? '#FF8A1F' : '#fff' }}
                                                            >
                                                                <input
                                                                    type='checkbox'
                                                                    className='checkbox-editInstall checbox-edit-plan'
                                                                    name={`Consumo`}
                                                                    style={{
                                                                        background: '#fff',
                                                                        width: '18px',
                                                                        height: '18px',
                                                                        margin: '0px',
                                                                        stroke: device?.ID_plant === null ? '#FF8A1F' : '#fff',
                                                                        color: device?.ID_plant === null ? '#FF8A1F' : '#fff',
                                                                        cursor: !devicesAllowedConsumption?.includes(
                                                                            device?.id_tipo_dispositivo
                                                                        )
                                                                            ? 'context-menu'
                                                                            : 'pointer'
                                                                    }}
                                                                    checked={device?.ID_plant === null ? true : false}
                                                                    onChange={(event) => {
                                                                        activeMarker(event, key)
                                                                    }}
                                                                    disabled={
                                                                        !devicesAllowedConsumption?.includes(device?.id_tipo_dispositivo)
                                                                    }
                                                                />
                                                            </label>
                                                        }
                                                    />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
    )
}
