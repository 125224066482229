import React, { useEffect, useState } from 'react'
import { IconShared, IconCloseAllSessions, IconUser } from 'utils/icon-centrocontrol'
import './menuOptions.scss'

const positions = {
    left: 'right-[100%] top-1/2',
    right: 'left-[100%] top-1/2'
}

const defaultOptions = [
    {
        title: 'Mi cuenta',
        icon: <IconUser className='h-4 w-5' />,
        onClick: () => {}
    },
    {
        title: 'Cerrar sesión',
        icon: <IconCloseAllSessions className='h-4 w-5' />,
        href: 'google.com'
    },
    {
        title: 'Cerrar sesiones abiertas',
        icon: <IconShared className='h-4 w-5' />
    }
]

export default function MenuOptions({ children, options = defaultOptions, selector, position = 'left', ...props }) {
    const [showMenu, setShowMenu] = useState(false)
    const [idButton] = useState(`btn-show-menu-options-${genRndId()}`)
    const [idMenu] = useState(`menu-options-${genRndId()}`)
    const [currPosition, setCurrPosition] = useState(position)

    useEffect(() => {
        const handleClick = (e) => {
            if (!e.target.closest(`#${idButton}`) && !e.target.closest(`#${idMenu}`)) {
                setShowMenu(false)
            }
        }

        if (showMenu) {
            document.addEventListener('click', handleClick)
        }

        return () => {
            if (showMenu) {
                document.removeEventListener('click', handleClick)
            }
        }
    }, [showMenu, idButton, idMenu])

    useEffect(() => {
        const updateMenuPosition = () => {
            const menu = document.getElementById(idMenu)
            if (menu) {
                const { right, left } = menu.getBoundingClientRect()
                if (right > document.body.clientWidth) {
                    setCurrPosition('left')
                }
                if (left < 0) {
                    setCurrPosition('right')
                }
            }
        }

        updateMenuPosition()
        window.addEventListener('resize', updateMenuPosition)

        return () => {
            window.removeEventListener('resize', updateMenuPosition)
        }
    }, [idMenu, showMenu])

    return (
        <div className='menu-container menu-container-icon'>
            {selector ? (
                <div className='cursor-pointer' id={idButton} onClick={() => setShowMenu((prevShowCol) => !prevShowCol)}>
                    {selector}
                </div>
            ) : (
                <button className='selector-button' id={idButton} onClick={() => setShowMenu((prevShowCol) => !prevShowCol)}>
                    Menú
                </button>
            )}
            {showMenu && (
                <div className={`${positions[currPosition]} menu-item`} id={idMenu} {...props}>
                    {children ??
                        options?.map((item, index) => {
                            const Element = item.href ? 'a' : 'div'
                            return (
                                <Element
                                    {...(item.href ? { href: item.href } : { onClick: item.onClick })}
                                    key={index}
                                    className='item-navbar'
                                >
                                    {' '}
                                    {item.component && item.component}
                                    <span className='item-icon'>{item.icon}</span>
                                    <span className='item-text'>{item.title?.toLowerCase()}</span>
                                </Element>
                            )
                        })}
                </div>
            )}
        </div>
    )
}

const genRndId = () =>
    Math.random()
        .toString(36)
        .slice(2, 10)
        .split('')
        .filter((char) => isNaN(parseInt(char)))
        .join('')
