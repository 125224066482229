import React from 'react'
import { Grid } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconVtnT } from 'utils/icon-centrocontrol'
import { datoNull, datoNullPercent } from './utils'

export const FooterCcMabt = ({ datos, alarmComunication }) => {
    return (
        <>
            <Grid item xs={6} md={6} lg={6} xl={6} className='flex align-end'>
                <h4 className='flex-baseline justify-start'>
                    <HtmlTooltip className='flex' title='Máxima carga del día'>
                        Max:
                    </HtmlTooltip>
                    <span className='pl-1 text-center'>
                        {alarmComunication ? '-' : datoNullPercent(datos?.max) + (datoNullPercent(datos?.max) !== '-' ? '%' : '')}
                    </span>
                </h4>
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6} className='flex-end align-end'>
                <h4 className='flex-baseline justify-end'>
                    <HtmlTooltip className='flex' title='VTN'>
                        <IconVtnT height={15} />
                    </HtmlTooltip>
                    <span className='pl-1 text-center'>
                        {' '}
                        {alarmComunication ? '-' : datoNull(datos?.vtn) + (datoNull(datos?.vtn) !== '-' ? 'V' : '')}
                    </span>
                </h4>
            </Grid>
        </>
    )
}
