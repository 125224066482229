import React from 'react'
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell } from 'components/Table/TableStyle'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import { StyledTableRow } from 'components/Table/TableStyle'
import { constFormatDateTable } from 'utils/Utils'

export const TableGeneralSelfConsumption = ({ data, headers, start }) => {
    return (
        <>
            <TableContainer component={Paper} className='mt-3'>
                <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                    <TableHead style={{ borderRadius: '50px' }}>
                        <TableRow>
                            {headers &&
                                headers.map((head) => {
                                    return (
                                        <StyledTableCellHead>
                                            <span>{head.label}</span>
                                        </StyledTableCellHead>
                                    )
                                })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            data.map((cell, i) => {
                                return (
                                    <StyledTableRow key={i}>
                                        <StyledTableCell align='center'>
                                            <p>{constFormatDateTable(cell?.datetime, start)}</p>
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            <p>{cell.eaconsdia}</p>
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            <p>{cell.consumida}</p>
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            <p>{cell.eagendia}</p>
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            <p>{cell.vertida}</p>
                                        </StyledTableCell>
                                        <StyledTableCell align='center'>
                                            <p>{cell.eco}</p>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
