import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { getPercentGraphs } from 'utils/functionsforGraphs'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'

export const ProgressBarGeneral = ({ general, data }) => {
    const energiaProd = general?.eaconsdia - general?.consumida
    const energiaCons = general?.consumida
    const percentProduction = getPercentGraphs(energiaProd, general?.eaconsdia)
    const percentConsumption = getPercentGraphs(energiaCons, general?.eaconsdia)

    return (
        <>
            {data && percentConsumption !== 100 ? (
                <ProgressBar className='progressBar'>
                    <ProgressBar
                        className='lineProgress'
                        style={{ backgroundColor: '#35A151', fontSize: percentProduction > 5 ? '20px' : null }}
                        now={percentProduction}
                        label={`${percentProduction}%`}
                        key={2}
                    />
                    <ProgressBar
                        className='lineProgress'
                        style={{ backgroundColor: '#3B9FD1', fontSize: percentConsumption > 4 ? '20px' : null }}
                        now={percentConsumption}
                        label={`${percentConsumption}%`}
                        key={1}
                    />
                </ProgressBar>
            ) : (
                <ProgressBar className='progressBar'>
                    <ProgressBar
                        className='lineProgress'
                        style={{ backgroundColor: '#3B9FD1', fontSize: percentConsumption > 4 ? '20px' : null }}
                        now={percentConsumption}
                        label={`${percentConsumption}%`}
                        key={1}
                    />
                </ProgressBar>
            )}
        </>
    )
}
