import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import moment from "moment"
import Grid from '@mui/material/Grid'
import { Loader } from "components/Loader/Loader"
import { headersScada, headersCentroControl } from 'services/headers'
import { compararFecha, orderForInstallation } from 'utils/Utils'
import { urls } from "utils/constant"
import { GraficaAlarma } from "./Grafica/GraficaAlarma"
import { FiltroAlarms } from './FilterAlarm/FiltroAlarms'
import { TablaAlarms } from "./TableAlarm/TablaAlarms"
import './styleAlarm.scss'

/**
 * @param {{ instalacioneSeleccionadas: string[] | number[], dispositivos: {}[] }} 
 * @param instalacioneSeleccionadas Instalaciones de las que se gestionan las alarmas
 * @param dispositivos Array de los dispositivos que tienen las instalaciones
 * @returns Devuelve la tabla de alarmas activas e historicas de las instalaciones seleccionadas
 */
export const Alarms = ({ instalacioneSeleccionadas, loadingInstal }) => {
    const [visible, setVisible] = useState(true)
    const now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const [start, setStart] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
    const [end, setEnd] = useState(moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }))
    const [tipoDispositivo, setTipoDispositivo] = useState(-1)
    const filterAlarm = useSelector((state) => state.filterAlarm)
    const [tipoAlarma, setTipoAlarma] = useState(-1)
    const [nickDispositivo, setNickDispositivo] = useState(filterAlarm?.numserie)
    const [dispositivosProyecto, setDispositivosProyecto] = useState()
    const [alarmasProyecto, setAlarmasProyecto] = useState()
    const [historico, setHistorico] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [dataOriginal, setDataOriginal] = useState()
    const [filas, setFilas] = useState(30)
    const [actualizarFiltro, setActualizarFiltro] = useState(true)
    const location = useLocation()
    const token = sessionStorage?.token
    const idProject = location.pathname.split("/")[3]
    const isFechaIgual = compararFecha(start, now)

    /**
     * @returns Devuelve los tipos de alarma que pueden tener las instalaciones
     */
    const fetchTypeAlarms = async () => {
        try {
            const res = await fetch(urls.scada + 'alarms/getTypeAlarms', { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                let json = await res.json()
                setAlarmasProyecto(json)
            }
            return true
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    /** 
     * @param {boolean} histo 
     * @returns Devuelve las alarmas activas o historicas de las instalaciones seleccionadas
     */
    const fetchDataAlarms = async ({ histo }) => {
        setLoading(true)
        let url = 'alarms/'
        let body = { 'devices': instalacioneSeleccionadas }
        if (histo) {
            url += 'getHistoricalAlarms'
            body.iniDate = start.format('YYYY-MM-DD')
            body.endDate = end.format('YYYY-MM-DD')
        } else {
            url += 'getActiveAlarms'
        }
        try {
            const res = await fetch(urls.scada + url, { method: 'POST', headers: headersScada(token), body: JSON.stringify(body) })
            if (res.status === 200) {
                let json = await res.json()
                if(json?.message) {
                    setDataOriginal([])
                    setData([])
                } else {
                    setDataOriginal(json)
                    if (tipoAlarma !== undefined && tipoAlarma !== -1) {
                        json = json?.filter(ele => ele?.alarm_id === tipoAlarma)
                    }
                    if (tipoDispositivo !== undefined && tipoDispositivo !== -1) {
                        json = json?.filter(ele => ele?.device_type === tipoDispositivo)
                    }
                    if (nickDispositivo !== undefined && nickDispositivo !== -1) {
                        json = json?.filter(ele => ele?.numserie === nickDispositivo)
                    }
                    setData(json)
                }
            } else {
                setData([])
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        } finally {
            setLoading(false)
        }
    }

    const fetchTypeDeviceProject = async () => {
        try {
            const res = await fetch(urls.ccontrol + 'getTypeDevices', { method: 'POST', headers: headersCentroControl(token), body: JSON.stringify({ "idProject": idProject }) })
            if (res.status === 200) {
                let json = await res.json()
                return json
            }
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        }
    }

    /**
     * @param {event} e Evento
     * @param {{}} fila Fila de la tabla con información de la alarma activa o historico de alarma
     * @description Selecciona el tipo de dispositivo y el dispositivo en el filtro seleccionados
     */
    const enviarFormulario = (e, fila) => {
        e.preventDefault()
        setTipoDispositivo(fila.device_type)
        setNickDispositivo(fila.numserie)
        setActualizarFiltro(current => !current)
    }

    const initAlarms = async () => {
        const tiposDispositivos = await fetchTypeDeviceProject()
        await fetchTypeAlarms()
        setDispositivosProyecto(tiposDispositivos)
    }

    useEffect(() => {
        initAlarms()
    }, [])
    
    useEffect(() => {
        setFilas(30)
        if(!loadingInstal && !historico && !isFechaIgual) {
            setHistorico(true)
        } else if (!loadingInstal && historico) {
            fetchDataAlarms({ histo: true })
        }
    }, [start, end])

    useEffect(() => {
        setFilas(30)
        if(!loadingInstal) {
            fetchDataAlarms({ histo: historico })
        }
    }, [loadingInstal, instalacioneSeleccionadas, historico])

    useEffect(() => {
        if (data) {
            let arrayAlarms = [...dataOriginal]
            if (tipoAlarma !== undefined && tipoAlarma !== -1) {
                arrayAlarms = arrayAlarms.filter(ele => ele.alarm_id === tipoAlarma)
            }
            if (tipoDispositivo !== undefined && tipoDispositivo !== -1) {
                arrayAlarms = arrayAlarms.filter(ele => ele.device_type === tipoDispositivo)
            }
            if (nickDispositivo !== undefined && nickDispositivo !== -1) {
                arrayAlarms = arrayAlarms.filter(ele => ele.numserie === nickDispositivo)
            }
            setData(arrayAlarms)
        }
    }, [tipoAlarma, tipoDispositivo, nickDispositivo])

    return (
        <div className="mt-2">
            <Grid container className="mt-3">
                {alarmasProyecto && dispositivosProyecto?.length > 0 && data &&
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <FiltroAlarms
                            data={data} visible={visible} setVisible={setVisible} loading={loading} now={now} start={start} setStart={setStart} end={end}
                            setEnd={setEnd} alarmasProyecto={alarmasProyecto} dispositivosProyecto={dispositivosProyecto} tipoDispositivo={tipoDispositivo}
                            setTipoDispositivo={setTipoDispositivo} tipoAlarma={tipoAlarma} setTipoAlarma={setTipoAlarma} historico={historico}
                            setHistorico={setHistorico} nickDispositivo={nickDispositivo} setNickDispositivo={setNickDispositivo} actualizarFiltro={actualizarFiltro}
                        />
                    </Grid>
                }
                {!loadingInstal && !loading && alarmasProyecto && dispositivosProyecto?.length > 0 && data ?
                    <Grid item xs={12} md={12} lg={12} xl={12} className="mt-4">
                        <TablaAlarms
                            visible={visible} filas={filas} setFilas={setFilas} historico={historico} data={data?.sort(orderForInstallation)} setData={setData}
                            token={token} enviarFormulario={enviarFormulario}
                        />
                        {tipoDispositivo >= 0 && nickDispositivo >= 0 && !loading && data.length > 0 &&
                            <GraficaAlarma data={data} start={start} end={end} />
                        }
                    </Grid>
                :
                    <Grid item xs={12} md={12} lg={12} xl={12} className="justify-content-center">
                        <Loader />
                    </Grid>
                }
            </Grid>
        </div>
    )
}