import React, { useEffect, useState } from 'react'
import error1 from 'assets/img/error/image_error_1.png'
import error2 from 'assets/img/error/image_error_2.png'
import error3 from 'assets/img/error/image_error_3.png'
import error4 from 'assets/img/error/image_error_4.png'

export const ImageRotator = ({ style }) => {
    const [currentImage, setCurrentImage] = useState()
    const [imageRandom, setImageRandom] = useState()

    useEffect(() => {
        const randomNumber = Math.random()
        const matchedNumber = Math.ceil(randomNumber * 4)
        setCurrentImage(matchedNumber)
    }, [])

    const randomImage = (type) => {
        switch (type) {
            case 1:
                return setImageRandom(error1)
            case 2:
                return setImageRandom(error2)
            case 3:
                return setImageRandom(error3)
            case 4:
                return setImageRandom(error4)
            default:
                break
        }
    }

    useEffect(() => {
        randomImage(currentImage)
    }, [currentImage])

    return <img src={imageRandom} alt={imageRandom} style={style} />
}
