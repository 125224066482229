import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { GlobalContext } from 'context/GlobalContext'
import { apiCentroControl } from 'services/api'
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import { CalendarList } from 'views/Configuracion/Calendar/CalendarList'
import { Alarms } from 'views/Alarms/Alarms'
import { PrincipalResumeDevicesAllDevices } from '../Installations/PrincipalResumeDevicesAllDevices'
import MapCentroControl from '../../MapCentroControl/MapCentroControl'
import { Tables } from '../../DataCentroControl/TableData/Tables'

export const PrincipalResume = ({ setData, data, actualProject, activeTab, masterError, allDevicesProject }) => {
    const { updateDevice } = useContext(GlobalContext)
    const [masterPermission, setMasterPermission] = useState({})
    const [loadingData, setLoadingData] = useState(true)
    const [orderBy, setOrderBy] = useState('asc')
    const [loading, setLoading] = useState(true)
    const [loadingFetch, setLoadingFetch] = useState(true)
    const [result, setResult] = useState([])
    const tree = useSelector((state) => state.treeDevices.tree)

    const fetchInfoInstalations = async (numserie) => {
        try {
            const result = await apiCentroControl.get(`userInfoInstallations/${numserie}`)
            if (result.status === 200) {
                let json = result?.data
                if (json?.length >= 1) {
                    for (let i = 0; i < json.length; i++) {
                        json[i].permissions = masterPermission[numserie]
                    }
                } else {
                    json = [
                        {
                            nick: 'Instalación Error',
                            inst_type: null,
                            numserie: null,
                            id_tipo_dispositivo: null,
                            activo: null,
                            id_padre: null,
                            datetime: null,
                            nick_padre: null
                        }
                    ]
                }
                return json
            }
        } catch (err) {
            console.error('ERROR. API Centro de Control', err)
        }
    }

    const getDevices = async (seleccionados) => {
        setLoadingFetch(true)
        const instalations = await Promise.all(seleccionados?.flatMap((numserie) => fetchInfoInstalations(Number(numserie))))
        setData([]?.concat(...instalations))
        setLoadingFetch(false)
    }

    useEffect(() => {
        if (Object?.keys(tree)?.length > 0 && masterError?.length > 0) {
            let seleccionados = []
            Object.keys(tree).forEach((x) => {
                if (tree[x] !== false && x?.includes('D')) {
                    let split = x.split('D-')
                    seleccionados.push(split[1])
                }
            })
            if (Array.isArray(seleccionados) && seleccionados.length > 0) {
                setLoading(true)
                setLoadingData(true)
                getDevices(seleccionados)
            } else {
                setData([])
                setLoadingFetch(false)
            }
            setResult(seleccionados)
        }
    }, [tree, updateDevice, masterError])

    useEffect(() => {
        if ((data?.length > 0 && result?.length > 0 && !loadingFetch) || (!loadingFetch && data?.length === 0 && result?.length === 0)) {
            setLoading(false)
            setLoadingData(false)
        }
    }, [data, loadingFetch, result])

    useEffect(() => {
        if (allDevicesProject?.length === 0) {
            setLoading(false)
            setLoadingData(false)
            setLoadingFetch(false)
        }
    }, [allDevicesProject])

    useLayoutEffect(() => {
        actualProject?.groups?.forEach((grp) => {
            grp?.devices?.forEach((devices) =>
                setMasterPermission((prev) => ({
                    ...prev,
                    [devices.numserie]: devices
                }))
            )
            grp?.subgroups?.forEach((subgroup) => {
                subgroup?.devices?.forEach((devices) =>
                    setMasterPermission((prev) => ({
                        ...prev,
                        [devices.numserie]: devices
                    }))
                )
            })
        })
    }, [])

    return (
        <div className='principal-resume' style={{ zIndex: 99 }}>
            {/*++++++++++++++++++++++++++++++++++ MAPA +++++++++++++++++++++++++++++++++++++++ */}
            <MapCentroControl data={data} active={activeTab === 0} actualProject={actualProject} />
            {data.length <= 0 && activeTab === 0 && actualProject?.category !== 4 && (
                <Grid item xs={12} className='d-flex justify-content-center align-items-center'>
                    <h2 className='p-4 text-xl font-bold'>No tiene instalaciones</h2>
                </Grid>
            )}
            {/* ++++++++++++++++++++++++++++++++++ DISPOSITIVOS +++++++++++++++++++++++++++++++++++++++++++ */}
            <div className={`tab-item-wrapper ${activeTab === 1 ? 'active' : ''}`} index={1}>
                {loadingData || loading || allDevicesProject === null ? (
                    <div className='justify-content-center'>
                        <Loader />
                    </div>
                ) : result?.length > 0 ? (
                    <>{activeTab === 1 && <PrincipalResumeDevicesAllDevices data={data} masterPermission={masterPermission} />}</>
                ) : data?.length === 0 && Object.keys(masterPermission).length !== 0 ? (
                    <Grid item xs={12} className='d-flex justify-content-center align-items-center'>
                        <h2 className='p-4'>Selecciona una instalación</h2>
                    </Grid>
                ) : (
                    <>
                        {Object.keys(masterPermission).length === 0 && (
                            <Grid item xs={12} className='d-flex justify-content-center align-items-center'>
                                <h2 className='p-4 text-xl font-bold'>No tiene instalaciones</h2>
                            </Grid>
                        )}
                    </>
                )}
            </div>
            {/*++++++++++++++++++++++++++++++++++ DATOS +++++++++++++++++++++++++++++++++++++++ */}
            <div className={`tab-item-wrapper ${activeTab === 2 ? 'active' : ''}`} index={2} style={{ zIndex: 30 }}>
                {loadingData ? (
                    <div className='justify-content-center'>
                        <Loader />
                    </div>
                ) : (
                    <>
                        {data.length > 0 && activeTab === 2 && (
                            <Tables
                                activeTab={activeTab}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                instalacioneSeleccionadas={result}
                                result3={data}
                                result={result}
                                masterPermission={masterPermission}
                            />
                        )}
                        {data?.length === 0 && Object.keys(masterPermission).length !== 0 ? (
                            <Grid item xs={12} className='d-flex justify-content-center align-items-center'>
                                <h2 className='p-4'>Selecciona una instalación</h2>
                            </Grid>
                        ) : (
                            <>
                                {Object.keys(masterPermission).length === 0 && (
                                    <Grid item xs={12} className='d-flex justify-content-center align-items-center'>
                                        <h2 className='p-4 text-xl font-bold'>No tiene instalaciones</h2>
                                    </Grid>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            {/* *****************************ALARMAS ********************************** */}
            <div className={`tab-item-wrapper ${activeTab === 3 ? 'active' : ''}`} index={3}>
                {data?.length === 0 && Object.keys(masterPermission).length !== 0 && !(loadingData || loading) ? (
                    <Grid item xs={12} className='d-flex justify-content-center align-items-center'>
                        <h2 className='p-4'>Selecciona una instalación</h2>
                    </Grid>
                ) : Object.keys(masterPermission).length === 0 && !(loadingData || loading) ? (
                    <Grid item xs={12} className='d-flex justify-content-center align-items-center'>
                        <h2 className='p-4 text-xl font-bold'>No tiene instalaciones</h2>
                    </Grid>
                ) : (
                    activeTab === 3 && <Alarms instalacioneSeleccionadas={result} loadingInstal={loadingData || loading} />
                )}
            </div>
            {/* ***************************** CONTROL ********************************** */}
            <div className={`tab-item-wrapper ${activeTab === 4 ? 'active' : ''}`} index={4}>
                {activeTab === 4 && Object.keys(masterPermission).length === 0 ? (
                    <Grid item xs={12} className='d-flex justify-content-center align-items-center'>
                        <h2 className='p-4 text-xl font-bold'>No tiene instalaciones</h2>
                    </Grid>
                ) : (
                    <>
                        {activeTab === 4 && (
                            <ConfiguracionList instalacioneSeleccionadas={result} dispositivo={data} masterError={masterError} />
                        )}
                    </>
                )}
            </div>
            {activeTab === 5 && (
                <CalendarList
                    centroControl={true}
                    instalacioneSeleccionadas={result}
                    masterPermission={masterPermission}
                    dispositivos={data.filter((dev) => dev.id_tipo_dispositivo === 0)}
                />
            )}
        </div>
    )
}
