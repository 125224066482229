import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)
ChartJS.register(zoomPlugin)

export const Grafica = ({
    tipo,
    labels,
    datos,
    titulo,
    zoom,
    maintainAspectRatio,
    scalesX,
    displayX,
    posicion,
    rotationX,
    fontSizeX,
    scalesY,
    minY,
    maxY,
    displayY,
    funcionTickX,
    funcionTickY,
    fontSizeY
}) => {
    function funcionBaseY(value) {
        return value
    }

    function funcionBaseX(value) {
        return labels[value]
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                },
                display: true,
                position: posicion
            },
            title: {
                display: true,
                text: titulo
            },
            zoom:
                zoom && datos.length > 0
                    ? {
                          pan: {
                              enabled: true,
                              mode: 'x',
                              speed: 0.5
                          },
                          zoom: {
                              wheel: {
                                  enabled: true,
                                  speed: 0.1
                              },
                              mode: 'x'
                          }
                      }
                    : undefined
        },
        scales: {
            y: scalesY
                ? {
                      min: minY !== undefined ? minY : undefined,
                      max: maxY ? maxY : undefined,
                      display: displayY !== undefined ? displayY : true,
                      ticks: {
                          callback: funcionTickY ? funcionTickY : funcionBaseY,
                          font: {
                              size: fontSizeY ? fontSizeY : 11
                          }
                      }
                  }
                : undefined,
            x: scalesX
                ? {
                      display: displayX ? displayX : true,
                      ticks: {
                          maxRotation: rotationX ? rotationX : undefined,
                          minRotation: rotationX ? rotationX : undefined,
                          callback: funcionTickX ? funcionTickX : funcionBaseX,
                          font: {
                              size: fontSizeX ? fontSizeX : 11
                          }
                      }
                  }
                : undefined
        },
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
        animations: {
            radius: {
                duration: 400,
                easing: 'linear',
                loop: (context) => context.active
            }
        },
        hoverRadius: 6,
        hoverOpacity: 0.6,
        maintainAspectRatio: maintainAspectRatio
    }

    const data = {
        labels,
        datasets: datos
    }

    return <Chart type={tipo} options={options} data={data} />
}
