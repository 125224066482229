import React from 'react'
import { FormControl, Grid, MenuItem } from '@mui/material'
import { SelectStyled } from 'components/Select/SelectStyled'
import InputStyled from 'components/Input/InputStyled'
import Map from 'components/Mapa/Map'
import { ResumeFormCreateSensor } from './ResumeFormCreateSensor'

export const FormCreateSensor = ({ onBlurNumserie, medidores, positions, step, body, setBody, errors, validateBody }) => {

    const onSelectMarker = (lat, lng, adres) => {
        const bodyObject = {...body}
        bodyObject.latitud = lat === null ? '' : lat
        bodyObject.longitud = lng === null ? '' : lng
        bodyObject.address = adres
        setBody(bodyObject)
    }
    
    const handleChange = (e) => {
        setBody({...body, [e.target.name]: e.target.value})
    }

    return (
        <Grid item xs={12} md={12} lg={12} xl={12}>
            {step === 0 &&
                <>
                    <div className='pb-1'>
                        <small className="color-black">
                            Nombre de la instalación*:
                        </small>
                    </div>
                    <InputStyled
                        error={errors?.nick?.length > 0}
                        errorMessage={errors?.nick}
                        maxLength={100}
                        fullWidth
                        name="nick"
                        value={body?.nick}
                        eventComplete={true}
                        onChange={handleChange}
                        onBlur={() => validateBody(0)}
                    />
                </>
            }
            {step === 1 &&
                <>
                    <div className='pb-1'>
                        <small className="color-black">
                            Numero de Serie*:
                        </small>
                    </div>
                    <InputStyled
                        error={errors?.numserie?.length > 0}
                        errorMessage={errors?.numserie}
                        maxLength={14}
                        fullWidth
                        name="numserie"
                        value={body?.numserie}
                        eventComplete={true}
                        onChange={handleChange}
                        onBlur={onBlurNumserie}
                    />
                </>
            }
            {step === 2 &&
                <FormControl className='width-100' size="small">
                    <div className='pb-1'>
                        <small className="color-black">
                            Medidor Asociado*:
                        </small>
                    </div>
                    <SelectStyled value={body?.numserie_medidor} onChange={handleChange} id='numserie_medidor' name='numserie_medidor' >
                        {medidores?.filter(device => device?.id_tipo_dispositivo === 21)?.map(medidor => {
                            return <MenuItem value={medidor?.numserie}>{medidor?.nick + ' - '  + medidor?.numserie}</MenuItem>
                        })}
                    </SelectStyled>
                    {errors?.numserie_medidor && <p className='color-red-error'>{errors?.numserie_medidor}</p>}
                </FormControl>
            }
            {step === 3 &&
                <>
                    <FormControl className='width-100' size="small">
                        <div className='pb-1'>
                            <small className="color-black">
                                Orden Dentro de la Línea*:
                            </small>
                        </div>
                        <SelectStyled value={body?.position} onChange={handleChange} id='position' name='position' >
                            {positions?.map(pos => {
                                return <MenuItem value={pos}>{pos}</MenuItem>
                            })}
                        </SelectStyled>
                        {errors?.position && <p className='color-red-error'>{errors?.position}</p>}
                    </FormControl>
                </>
            }
            {step === 4 &&
                <>
                    <div className='pb-1'>
                        <small className="color-black">
                            Localización:
                        </small>
                    </div>
                    <Map
                        center={{ lat: body?.latitud ?? 36, lng: body?.longitud ?? 10 }}
                        onSelectMarker={onSelectMarker}
                        zoom={5}
                        address={body?.address}
                        messageError={errors?.address && <p className='color-red-error'>{errors?.address}</p>}
                    />
                </>
            }
            {step === 5 &&
                <ResumeFormCreateSensor body={body} medidores={medidores} />
            }
        </Grid>
    )
}