import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import InputStyled from 'components/Input/InputStyled'
import { Loader } from 'components/Loader/Loader'
import { headersScada } from 'services/headers'
import { validatorNumber } from 'utils/ValidatorForm'
import { dispositivos, urls } from 'utils/constant'
import { ModalConfigSurplusEms } from './Modal/ModalConfigSurplusEms'
import { TableEmsScada } from './TableEmsScada'

export const SurplusEms = ({ configEms, master, meter, setConfigEms, action }) => {
    const location = useLocation()
    const idMaster = location.pathname.split('/')[4]
    const [open, setOpen] = useState(false)
    const [devices, setDevices] = useState([])
    const [surplusEnable, setSurplusEnable] = useState(configEms?.surplus?.enable)
    const [validPotMin, setValidPotMin] = useState(false)
    const [dataEdit, setDataEdit] = useState([])
    const [edit, setEdit] = useState(false)
    const [potMin, setPotMin] = useState(configEms?.surplus?.minimum_power === null ? '' : configEms?.surplus?.minimum_power)
    const [disabled, setDisabled] = useState(true)
    const [disabledTable, setDisabledTable] = useState(true)
    const [loading, setLoading] = useState(false)
    const token = sessionStorage?.token
    const enableDevices = [dispositivos.ccmaster, dispositivos.enchufe]

    const handlePotMin = (valor) => {
        setPotMin(valor)
        setValidPotMin(validatorNumber(valor))
    }

    const fethGetEmsDevice = () => {
        let url = `emsDevices/${idMaster}?idCCMaster=${idMaster}`
        setLoading(true)
        fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            .then((res) => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then((data) => {
                setDevices(data?.filter((dev) => enableDevices?.includes(dev?.id_tipo_dispositivo)))
            })
            .catch((error) => {
                console.error('Ha habido un error en API Scada', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const compareOptions = () => {
        let change = true
        if (edit) {
            change = true
        } else if (
            configEms?.surplus?.enable !== surplusEnable ||
            (String(configEms?.surplus?.minimum_power) !== String(potMin) && potMin !== '' && configEms?.surplus?.minimum_power !== null)
        ) {
            change = false
        } else if (!disabledTable) {
            change = false
        }
        setDisabled(change)
    }

    useEffect(() => {
        fethGetEmsDevice()
    }, [action])

    useEffect(() => {
        compareOptions()
    }, [surplusEnable, potMin, dataEdit, edit, configEms, disabledTable])

    return (
        <Grid container className='ml-3 mb-4'>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center'>
                <SwitchStyled
                    id='surplusenable'
                    className='switch-small'
                    checked={surplusEnable}
                    onClick={() => setSurplusEnable(!surplusEnable)}
                />
                <label className='ml-2 mb-0' htmlFor='surplusenable'>
                    {' '}
                    Surplus{' '}
                </label>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3 flex-center'>
                <span className='section-modal-edit-mp input-mp-modal'>
                    <h4 className='ml-0'>Potencia mínima para gestión de excedentes (W)</h4>
                    <InputStyled value={potMin} onChange={handlePotMin} error={validPotMin} />
                </span>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3 flex-center containter-table-drag-scada'>
                {loading && <Loader />}
                {!loading && devices?.length > 0 && (
                    <TableEmsScada
                        modeEms={5}
                        setNewArray={setDataEdit}
                        devicesEms={devices}
                        edit={edit}
                        setEdit={setEdit}
                        setDisabledApply={setDisabledTable}
                    />
                )}
                {!loading && devices?.length <= 0 && (
                    <Grid items xs={12} className='calendar-historical-flex'>
                        <h4 align='center' className='mt-4'>
                            Ha habido un error al comunicarse con el máster
                        </h4>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='button-apply-table d-flex justify-content-flex-end mt-3'>
                <ButtonStyled size='small' color='#fff' background='#FF8A1F' disabled={disabled} onClick={() => setOpen(true)}>
                    Aplicar
                </ButtonStyled>
            </Grid>
            {open && (
                <ModalConfigSurplusEms
                    master={master}
                    open={open}
                    setOpen={setOpen}
                    configEms={configEms}
                    potMin={potMin}
                    surplusEnable={surplusEnable}
                    dataEdit={dataEdit}
                    meter={meter}
                    setConfigEms={setConfigEms}
                    setDevices={setDevices}
                    setSurplusEnable={setSurplusEnable}
                />
            )}
        </Grid>
    )
}
