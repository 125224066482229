import React from 'react'
import { useSelector } from 'react-redux'
import Footer from 'components/Footer/Footer'
import { parseDefaultImg } from 'utils/MiddleWareProjects'
import { VERSION } from 'utils/constant'
import './styleFooter.scss'

export const AuthFooter = () => {
    const logos = useSelector((state) => state.logos)
    const refreshSlice = useSelector((state) => state.refreshNavFooter.name)
    const FooterLogos = parseDefaultImg(logos.user, 'Footer')
    const projectFooterLogos = parseDefaultImg(logos.project, 'Footer')

    return <Footer key={refreshSlice} version={VERSION} position='sticky' companyLogos={[...FooterLogos, ...projectFooterLogos]} />
}
