import React, { useEffect, useState } from 'react'
import { toKiloW } from 'utils/functionsforGraphs'
import { convertDatetime, esHoy } from 'utils/Utils'
import Weather from 'views/Weather/Weather'
import './ScadaGeneral.scss'

/**
 * @param {loading: boolean,  master:number, data: []}
 * @idCcMaster Devuelve los datos del master
 * @loading para ver si tiene que se salir el icono de cargar
 * @returns
 */
export const DateGeneral = ({ loading, master, data }) => {
    const [weatherData, setWeatherData] = useState()
    const [date, setDate] = useState()

    useEffect(() => {
        if (data) {
            let fecha
            if (data?.bateria && data?.bateria?.datetime) {
                fecha = data.bateria.datetime
            }
            if (!fecha && data?.consumo && data?.consumo?.datetime) fecha = data?.consumo?.datetime
            else if (data?.consumo && data?.consumo?.datetime && data?.consumo?.datetime > fecha) {
                fecha = data?.consumo?.datetime
            }
            if (!fecha && data?.produccion && data?.produccion?.datetime) {
                fecha = data?.produccion?.datetime
            } else if (data?.produccion && data?.produccion?.datetime && data?.consumo?.datetime > fecha) {
                fecha = data?.producion?.datetime
            }
            if (!fecha && data?.red && data?.red?.datetime) {
                fecha = data?.red?.datetime
            } else if (data?.red && data?.red?.datetime && data?.red?.datetime > fecha) {
                fecha = data?.red?.datetime
            }
            setDate(fecha)
        }
    }, [data])

    return (
        <div className='flex-space-between-center font-montserrat mt-3'>
            <span className='flex-center icon-weather '>
                <Weather master={master} setWeatherData={setWeatherData} loading={loading} weatherData={weatherData} />
                {weatherData?.city && (weatherData?.temp_max || weatherData?.temp_min) && (
                    <span>
                        TMax {weatherData?.temp_max ? weatherData?.temp_max + '°' : ''}/TMin{' '}
                        {weatherData?.temp_min ? weatherData?.temp_min + '°' : ''}
                    </span>
                )}
            </span>
            <span className={`date-header-general-scada flex-center `}>{date && convertDatetime(date)}</span>
            <div className='flex flex-direction-column'>
                <span className='font-weight-600'>Potencia Contratada: {toKiloW(data?.produccion?.potenciaT / 1000)} kW</span>
                <span className='font-weight-600'>Potencia Total: {toKiloW(data?.consumo?.pact)} kW</span>
            </div>
        </div>
    )
}
