import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { Modal } from 'components/Modal/Modal'
import { urls } from 'utils/constant'
import './StyleModal.scss'

export const ModalDeleteCalendarCcMaster = ({ body, modal, setModal, setUpdateCalendar }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [loadingDelete, setLoadingDelete] = useState(false)

    const DeleteCalendar = () => {
        setLoadingDelete(true)
        let status
        fetch(urls.scada + `calendar`, {
            method: 'DELETE',
            headers: headersScada(token),
            body: JSON.stringify(body)
        })
            .then((res) => {
                status = res.status
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: false }))
            })
            .catch((err) => {
                console.error('No ha sido posible comunicarse con la Api de ....DELETE CALENDAR', err)
            })
            .finally(() => {
                setLoadingDelete(false)
                setUpdateCalendar((current) => !current)
            })
    }

    return (
        <>
            <Modal
                open={modal}
                disabled={loadingDelete}
                setOpen={setModal}
                title='Confirmación sobre el calendario'
                functSave={DeleteCalendar}
                loading={loadingDelete}
                buttonAction='Aplicar'
            >
                <span style={{ margin: '0 auto' }}>
                    <h2>¿Quieres borrar esta programación?</h2>
                </span>
            </Modal>
        </>
    )
}
