import React from 'react'
import { dispositivos } from 'utils/constant.jsx'
import { ModalInfoInverter } from 'views/Modals/ModalInverter/ModalInfoInverter'
import { ModalInfoPlanta } from 'views/Modals/ModalPlanta/ModalInfoPlanta'
import { ModalInfoSupervisor } from 'views/Modals/ModalInfoSupervisor'
import { ModalInfoCCM } from 'views/Modals/ModalInfoCCM/ModalInfoCCM'
import { ModalInfoDevices } from './ModalInfoDevices'
import { ModalInfoBatery } from 'views/Modals/ModalInfoBatery'
import { ModalInfoMABT } from 'views/Modals/ModalInfoMABT'

export const ModalViewInfoDevices = (props) => {
    return (
        <>
            {Number(props?.device.id_tipo_dispositivo) === dispositivos.ccm1 ||
            Number(props?.device.id_tipo_dispositivo) === dispositivos.ccm2 ||
            Number(props?.device.id_tipo_dispositivo) === dispositivos.ccm3 ||
            Number(props?.device.id_tipo_dispositivo) === dispositivos.ccm4 ||
            Number(props?.device?.id_tipo_dispositivo) === dispositivos.analizador ? (
                <ModalInfoCCM
                    device={[props?.device]}
                    devices={props?.device}
                    permissions={props?.permissions}
                    updateCcM={props?.updateCcM}
                    instalacion={props?.instalacion}
                    setDevices={props?.setDevices}
                    loose={props?.loose}
                    master={props?.master}
                    deviceType={props?.permissions?.deviceType}
                    modal={props?.modal}
                    setModal={props?.setModal}
                />
            ) : Number(props?.device?.data?.currentData?.id_tipo_dispositivo) === dispositivos.inversor ||
              Number(props?.device?.id_tipo_dispositivo) === dispositivos.inversor ? (
                <ModalInfoInverter
                    device={[props?.device]}
                    devices={props?.devices}
                    permissions={props?.permissions}
                    updateCcM={props?.updateCcM}
                    instalacion={props?.instalacion}
                    setDevices={props?.setDevices}
                    loose={props?.loose}
                    master={props?.master}
                    deviceType={props?.permissions?.deviceType}
                    modal={props?.modal}
                    setModal={props?.setModal}
                />
            ) : props?.device?.data?.consumo ? (
                <ModalInfoPlanta
                    device={[props?.device]}
                    devices={props?.devices}
                    permissions={props?.permissions}
                    updateCcM={props?.updateCcM}
                    instalacion={props?.instalacion}
                    setDevices={props?.setDevices}
                    loose={props?.loose}
                    master={props?.master}
                    deviceType={props?.permissions?.deviceType}
                    modal={props?.modal}
                    setModal={props?.setModal}
                />
            ) : props?.device?.data?.currentData?.id_tipo_dispositivo === dispositivos.ccmabt ||
              props?.device?.id_tipo_dispositivo === dispositivos.ccmabt ? (
                <ModalInfoSupervisor
                    icon={props.icon}
                    device={[props?.devices]}
                    devices={props?.devices}
                    permissions={props?.permissions}
                    updateCcM={props?.updateCcM}
                    instalacion={props?.instalacion}
                    setDevices={props?.setDevices}
                    loose={props?.loose}
                    master={props?.master}
                    deviceType={props?.permissions?.deviceType}
                    modal={props?.modal}
                    setModal={props?.setModal}
                />
            ) : props?.device?.data?.currentData?.id_tipo_dispositivo === dispositivos.ccmabtm ||
              props?.device?.id_tipo_dispositivo === dispositivos.ccmabtm ? (
                <ModalInfoMABT
                    icon={props.icon}
                    device={[props?.devices]}
                    devices={props?.devices}
                    permissions={props?.permissions}
                    updateCcM={props?.updateCcM}
                    instalacion={props?.instalacion}
                    setDevices={props?.setDevices}
                    loose={props?.loose}
                    master={props?.master}
                    deviceType={props?.permissions?.deviceType}
                    modal={props?.modal}
                    setModal={props?.setModal}
                />
            ) : props?.device?.data?.currentData?.id_tipo_dispositivo === dispositivos.bateria ||
              props?.device?.id_tipo_dispositivo === dispositivos.bateria ? (
                <ModalInfoBatery
                    icon={props.icon}
                    device={[props?.devices]}
                    devices={props?.devices}
                    permissions={props?.permissions}
                    updateCcM={props?.updateCcM}
                    instalacion={props?.instalacion}
                    setDevices={props?.setDevices}
                    loose={props?.loose}
                    master={props?.master}
                    deviceType={props?.permissions?.deviceType}
                    modal={props?.modal}
                    setModal={props?.setModal}
                />
            ) : (
                <ModalInfoDevices
                    icon={props.icon}
                    device={[props?.devices]}
                    devices={props?.devices}
                    permissions={props?.permissions}
                    updateCcM={props?.updateCcM}
                    instalacion={props?.instalacion}
                    setDevices={props?.setDevices}
                    loose={props?.loose}
                    master={props?.master}
                    deviceType={props?.permissions?.deviceType}
                    modal={props?.modal}
                    setModal={props?.setModal}
                />
            )}
        </>
    )
}
