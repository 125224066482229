import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'
import { Grid } from '@mui/material'
import { headersScada } from 'services/headers'
import { Modal } from 'components/Modal/Modal'
import { urls } from 'utils/constant'

export const ModalSaveLayout = ({ open, setOpen, layoutData, numserie, setLayoutData, setIsLoading, isLoading, configuration }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()

    const editLayout = ({ numserie, configuration }) => {
        const url = `distributionPanel/${numserie}`
        let status
        fetch(urls.scada + url, {
            method: 'PATCH',
            headers: headersScada(token),
            body: JSON.stringify({
                confPanel: configuration
            })
        })
            .then((res) => {
                status = res.status
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(`Error en la solicitud: ${res.status} ${res.statusText}`)
                }
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: 'Configuración guardada', reload: false }))
                const configuration = JSON.parse(data?.confPanel)
                setLayoutData(configuration)
                return data
            })
            .catch((error) => {
                console.error('Error en la solicitud:', error)
            })
            .finally(() => {
                setIsLoading(false)
                setOpen(!open)
            })
    }

    const createLayout = ({ numserie, configuration }) => {
        const url = `distributionPanel/${numserie}`
        let status
        fetch(urls.scada + url, {
            method: 'POST',
            headers: headersScada(token),
            body: JSON.stringify({
                confPanel: configuration
            })
        })
            .then((res) => {
                status = res?.status
                if (res?.ok) {
                    return res.json()
                } else {
                    throw new Error(`Error en la solicitud: ${res?.status} ${res?.statusText}`)
                }
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: 'Configuración guardada', reload: false }))
                const configuration = JSON.parse(data.confPanel)
                setLayoutData(configuration)
                return data
            })
            .catch((error) => {
                console.error('Error en la solicitud:', error)
            })
            .finally(() => {
                setIsLoading(false)
                setOpen(!open)
            })
    }

    const handleSave = () => {
        if (layoutData) {
            editLayout({ numserie: numserie, configuration: configuration })
        } else {
            createLayout({ numserie: numserie, configuration: configuration })
        }
        setIsLoading(true)
    }

    return (
        <Modal open={open} setOpen={setOpen} title='Guardar configuración' functSave={handleSave} loading={isLoading} disabled={isLoading}>
            <Grid container className='text-center mt-2'>
                <h4 className='font-weight-lighter modal-font'>¿Deseas guardar esta configuración?</h4>
            </Grid>
        </Modal>
    )
}
