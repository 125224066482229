import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { BeatLoader } from 'react-spinners'
import { Grid } from 'semantic-ui-react'

export const SelectMultiple = ({ value, onChange, options, optionLabel, id, className, disabled, optionLabelFunction, loading }) => {
    return (
        <Autocomplete
            multiple
            id={`SelectMultiple${id}`}
            size='small'
            disabled={disabled}
            value={value}
            className={`style-textfield-text ${className}`}
            onChange={onChange}
            options={options}
            getOptionLabel={optionLabelFunction ? (option) => optionLabelFunction(option) : (option) => option[optionLabel]}
            filterSelectedOptions
            renderInput={(params) =>
                loading ? (
                    <TextField
                        {...params}
                        InputProps={{
                            startAdornment: (
                                <Grid container className='flex justify-start'>
                                    <BeatLoader color='#ea5e0b' size={5} />
                                </Grid>
                            )
                        }}
                    />
                ) : (
                    <TextField {...params} />
                )
            }
        />
    )
}
