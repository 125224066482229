// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-0 {
  position: relative;
  z-index: 0;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.leaflet-container a {
  padding: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Map/styleMap.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":[".index-0 {\n    position: relative;\n    z-index: 0;\n}\n\n.leaflet-control-container .leaflet-routing-container-hide {\n    display: none;\n}\n\n.leaflet-container a {\n    padding: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
