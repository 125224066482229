// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upValue {
  position: relative;
  top: 150%;
}

.downValue {
  position: relative;
  top: -8%;
}

.TensionGraph {
  position: relative;
  top: 3.7em;
}
.TensionGraph canvas {
  height: 15em !important;
  width: 15em !important;
}

.tensionDivider {
  background: black;
  height: 2px;
  overflow: hidden;
}

.container-first-tension {
  height: 6.75em;
  border-bottom: 2px solid black;
}

.tension-title {
  color: #ff8a1f;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/Consumption/GraphConsumption/ScadaConsumptionTension/PhasesTensionGraph.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;AACJ;AAAI;EACI,uBAAA;EACA,sBAAA;AAER;;AAEA;EACI,iBAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAEA;EACI,cAAA;EACA,8BAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACJ","sourcesContent":[".upValue {\n    position: relative;\n    top: 150%;\n}\n\n.downValue {\n    position: relative;\n    top: -8%;\n}\n\n.TensionGraph {\n    position: relative;\n    top: 3.7em;\n    canvas {\n        height: 15em !important;\n        width: 15em !important;\n    }\n}\n\n.tensionDivider {\n    background: black;\n    height: 2px;\n    overflow: hidden;\n}\n\n.container-first-tension {\n    height: 6.75em;\n    border-bottom: 2px solid black;\n}\n\n.tension-title {\n    color: #ff8a1f;\n    font-size: 1.5rem;\n    font-weight: bold;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
