import React from 'react'
import { Grid } from '@mui/material'
import { GraphsBar } from 'components/Graph/GraphsBar'
import IntensityRadialGraphs from '../Consumption/GraphConsumption/ScadaConsumptionIntensity/IntensityRadialGraphs'
import ConsuptionPowerRealTime from '../Consumption/GraphConsumption/ScadaConsumptionPower/PhasesActivepower'
import PhasesTensionGraph from '../Consumption/GraphConsumption/ScadaConsumptionTension/PhasesTensionGraph'
import { TotalActivepower } from '../Consumption/GraphConsumption/ScadaConsumptionPower/TotalActivePower'
import './StyleLighting.scss'

export const PrincipalGraphsTriphasic = ({ activeTab, currentDevice, noConect, data, eacons, eagen, labels }) => {
    return (
        <Grid item md={13} className='container-active-power'>
            {activeTab === 0 && (
                <Grid columns={13} className='containter-scada-graphs-center'>
                    <Grid item xs={13} md={6} lg={3} xl={2} className='container-active-power'>
                        <h3 className='active-power-title'>Energía activa</h3>
                        <GraphsBar eacons={eacons} eagen={eagen} labels={labels} />
                    </Grid>
                    <Grid item xs={13} md={6} lg={10} xl={4} className='mt-4'>
                        <TotalActivepower sinConectar={noConect} data={currentDevice?.currentData} />
                    </Grid>
                    <Grid item xs={13} md={13} lg={13} xl={7}>
                        <ConsuptionPowerRealTime sinConectar={noConect} ccm={data} data={currentDevice?.currentData} />
                    </Grid>
                </Grid>
            )}
            {activeTab === 1 && (
                <Grid item md={12} index={1}>
                    <IntensityRadialGraphs activeTab={activeTab} ccm={data} data={currentDevice?.currentData} sinConectar={noConect} />
                </Grid>
            )}
            {activeTab === 2 && (
                <Grid item md={12} index={2}>
                    <PhasesTensionGraph data={currentDevice?.currentData} ccm={data} />
                </Grid>
            )}
        </Grid>
    )
}
