import React from 'react'
import moment from 'moment'
import { TableBody, TableContainer, TableHead, TableRow, Paper, Grid, Table, Card } from '@mui/material'
import { StyledTableCell, StyledTableCellHead } from 'components/Table/TableStyle'
import { Loader } from 'components/Loader/Loader'
import { toKiloW } from 'utils/functionsforGraphs'
import { dispositivos } from 'utils/constant'
import { showTime } from 'utils/Utils'

const ConsuptionTable = ({ daily, data, energyData, hoy, loading, photovoltaic }) => {
    const cabeceraMabt = hoy
        ? [
              'Salida',
              'Fecha',
              'P. Activa Total',
              'P. Activa R',
              'P. Activa S',
              'P. Activa T',
              'P. Reactiva R',
              'P. Reactiva S',
              'P. Reactiva T',
              'Corriente R',
              'Corriente S',
              'Corriente T',
              'Tensión R',
              'Tensión S',
              'Tensión T'
          ]
        : [
              'Salida',
              'Fecha',
              'P. Max Total',
              'P. Min Total',
              'P. Activa R Max',
              'P. Activa R Min',
              'P. Activa S Max',
              'P. Activa S Min',
              'P. Activa T Max',
              'P. Activa T Min',
              'Corriente R Max',
              'Corriente S Max',
              'Corriente T Max',
              'Tensión R Max',
              'Tensión S Max',
              'Tensión T Max',
              'Energía Reactiva 1',
              'Energía Reactiva 2',
              'Energía Reactiva 3',
              'Energía Reactiva 4',
              'Energía Importada (kWh)',
              'Energía Exportada (kWh)'
          ]
    const cabeceraMabtVtn = hoy ? ['Salida', 'Fecha', 'VTN (V)'] : ['Salida', 'Fecha', 'VTN Máximo - Hora', 'VTN Mínimo - Hora']

    return (
        <Card>
            {!loading ? (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                        <TableHead style={{ borderBottom: '5px solid #FF8A1F', borderRadius: '50px' }}>
                            <TableRow>
                                {data?.id_tipo_dispositivo === dispositivos.ccmabtv && (
                                    <>
                                        {cabeceraMabtVtn?.map((elem) => {
                                            return <StyledTableCellHead align='center'>{elem}</StyledTableCellHead>
                                        })}
                                    </>
                                )}
                                {data?.id_tipo_dispositivo === dispositivos.ccmabtm && (
                                    <>
                                        {cabeceraMabt?.map((elem) => {
                                            return <StyledTableCellHead align='center'>{elem}</StyledTableCellHead>
                                        })}
                                    </>
                                )}
                                {data?.id_tipo_dispositivo !== dispositivos.ccmabtv &&
                                    data?.id_tipo_dispositivo !== dispositivos.ccmabtm && (
                                        <>
                                            <StyledTableCellHead align='center'>Nombre</StyledTableCellHead>
                                            <StyledTableCellHead align='center'>Fecha</StyledTableCellHead>
                                            {!photovoltaic && (
                                                <>
                                                    <StyledTableCellHead align='center'>
                                                        Energía<br></br>Importada (kWh)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Energía<br></br>Exportada (kWh)
                                                    </StyledTableCellHead>
                                                </>
                                            )}
                                            {photovoltaic && (
                                                <StyledTableCellHead align='center'>
                                                    Energía<br></br>Generada (kWh)
                                                </StyledTableCellHead>
                                            )}
                                            {hoy ? (
                                                <StyledTableCellHead align='center'>
                                                    Potencia<br></br>Total (kW)
                                                </StyledTableCellHead>
                                            ) : (
                                                <>
                                                    <StyledTableCellHead align='center'>
                                                        Potencia<br></br>Máxima (kW)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Potencia<br></br>Mínima (kW)
                                                    </StyledTableCellHead>
                                                </>
                                            )}
                                            {data?.id_tipo_dispositivo === 3 && (
                                                <>
                                                    <StyledTableCellHead align='center'>
                                                        Intensidad <br></br>Total (A)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Tensión <br></br>Total (V)
                                                    </StyledTableCellHead>
                                                </>
                                            )}
                                            {data?.id_tipo_dispositivo === 2 && hoy && (
                                                <>
                                                    <StyledTableCellHead align='center'>
                                                        Distorsión Armónica Voltaje (%)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Distorsión Armónica Corriente (%)
                                                    </StyledTableCellHead>
                                                </>
                                            )}
                                            {(data?.id_tipo_dispositivo === 4 || data?.id_tipo_dispositivo === 5) && hoy && (
                                                <>
                                                    <StyledTableCellHead align='center'>
                                                        Distorsión Armónica Voltaje L1 (%)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Distorsión Armónica Voltaje L2 (%)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Distorsión Armónica Voltaje L3 (%)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Distorsión Armónica Corriente L1 (%)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Distorsión Armónica Corriente L2 (%)
                                                    </StyledTableCellHead>
                                                    <StyledTableCellHead align='center'>
                                                        Distorsión Armónica Corriente L3 (%)
                                                    </StyledTableCellHead>
                                                </>
                                            )}
                                        </>
                                    )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.id_tipo_dispositivo === dispositivos.ccmabtv && energyData && (
                                <>
                                    {energyData?.map((datos, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <StyledTableCell align='center'>{datos?.nick ? datos?.nick : 'VTN'}</StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    {daily === 'hours' && hoy
                                                        ? datos?.datetime
                                                        : daily === 'days' && hoy && datos?.fecha
                                                          ? moment(datos?.fecha, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')
                                                          : daily === 'hours'
                                                            ? datos?.fecha + ' ' + datos?.datetime
                                                            : datos?.datetime}
                                                </StyledTableCell>
                                                {hoy ? (
                                                    <StyledTableCell align='center'>{toKiloW(datos?.vtn)}</StyledTableCell>
                                                ) : (
                                                    <>
                                                        <StyledTableCell align='center'>
                                                            {toKiloW(datos?.vtn_max) !== '-' ? toKiloW(datos?.vtn_max) + ' V' : ''} -{' '}
                                                            {showTime(datos?.datetime_vtn_max)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {toKiloW(datos?.vtn_min) !== '-' ? toKiloW(datos?.vtn_min) + ' V' : ''} -{' '}
                                                            {showTime(datos?.datetime_vtn_min)}
                                                        </StyledTableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        )
                                    })}
                                </>
                            )}
                            {data?.id_tipo_dispositivo === dispositivos.ccmabtm && energyData && (
                                <>
                                    {energyData?.map((datos, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <StyledTableCell align='center'>
                                                    {datos?.nick
                                                        ? datos?.nick
                                                        : 'Sin nombre ' + (datos?.mabt_output ? datos?.mabt_output : '')}
                                                </StyledTableCell>
                                                <StyledTableCell align='center' style={{ minWidth: '8em' }}>
                                                    {daily === 'hours' && hoy
                                                        ? datos?.datetime
                                                        : daily === 'days' && hoy && datos?.fecha
                                                          ? moment(datos?.fecha, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')
                                                          : daily === 'hours'
                                                            ? datos?.fecha + ' ' + datos?.datetime
                                                            : datos?.datetime}
                                                </StyledTableCell>
                                                {hoy && <StyledTableCell align='center'>{toKiloW(datos.pacTotal)}</StyledTableCell>}
                                                {hoy ? (
                                                    <>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac1)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac2)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac3)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pre1)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pre2)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pre3)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.i1)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.i2)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.i3)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.v1)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.v2)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.v3)}</StyledTableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac_min)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac1_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac1_min)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac2_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac2_min)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac3_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.pac3_min)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.i1_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.i2_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.i3_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.v1_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.v2_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.v3_max)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.ereactq1dia)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.ereactq2dia)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.ereactq3dia)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.ereactq4dia)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.eaconsdia)}</StyledTableCell>
                                                        <StyledTableCell align='center'>{toKiloW(datos?.eagendia)}</StyledTableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        )
                                    })}
                                </>
                            )}
                            {data?.id_tipo_dispositivo !== dispositivos.ccmabtm &&
                                data?.id_tipo_dispositivo !== dispositivos.ccmabtv &&
                                energyData && (
                                    <>
                                        {energyData?.map((datos, key) => {
                                            return (
                                                <TableRow key={key}>
                                                    <StyledTableCell align='center'>
                                                        {datos?.nick !== null && data?.nick !== undefined ? data?.nick : '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        {datos?.datetime !== null && datos?.datetime !== undefined ? datos?.datetime : '-'}
                                                    </StyledTableCell>
                                                    {!photovoltaic && (
                                                        <StyledTableCell align='center'>{toKiloW(datos?.eaconsdia)}</StyledTableCell>
                                                    )}
                                                    <StyledTableCell align='center'>{toKiloW(datos?.eagendia)}</StyledTableCell>
                                                    {hoy ? (
                                                        <StyledTableCell align='center'>
                                                            {toKiloW(datos?.pact ? datos?.pact : datos?.pacTotal)}
                                                        </StyledTableCell>
                                                    ) : (
                                                        <>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.pact_max)}</StyledTableCell>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.pact_min)}</StyledTableCell>
                                                        </>
                                                    )}
                                                    {data?.id_tipo_dispositivo === 3 && (
                                                        <>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.iTotal)}</StyledTableCell>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.vTotal)}</StyledTableCell>
                                                        </>
                                                    )}
                                                    {data?.id_tipo_dispositivo === 2 && hoy && (
                                                        <>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.thdv)}</StyledTableCell>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.thdi)}</StyledTableCell>
                                                        </>
                                                    )}
                                                    {(data?.id_tipo_dispositivo === 4 || data?.id_tipo_dispositivo === 5) && hoy && (
                                                        <>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.thdv1)}</StyledTableCell>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.thdv2)}</StyledTableCell>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.thdv3)}</StyledTableCell>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.thdi1)}</StyledTableCell>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.thdi2)}</StyledTableCell>
                                                            <StyledTableCell align='center'>{toKiloW(datos?.thdi3)}</StyledTableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            )
                                        })}
                                    </>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Grid item lg={12} xl={12} className='mt-2 mb-6'>
                    <div className='justify-content-center'>
                        <Loader />
                    </div>
                </Grid>
            )}
        </Card>
    )
}
export default ConsuptionTable
