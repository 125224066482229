import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { GlobalContext } from 'context/GlobalContext'
import { updateDeviceTree } from 'reduxSlice/treeDevicesSlice'
import { createToast } from 'reduxSlice/toastSlice'
import { apiScada } from 'services/api'
import { dispositivos } from 'utils/constant'
import { areArraysEqualModal } from 'utils/Utils'

export const ModalConfigChangeInstall = ({
    centroControl,
    open,
    setOpen,
    data,
    activeTab,
    idMaster,
    setDataInitial,
    dataInitial,
    dataPotMax,
    setAnswer,
    setDisabled,
    setDisabledTable,
    dataInitialPmax,
    setDataInitialPmax,
    masterConfigInstall,
    dataConfigInstall,
    master
}) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { actualProject } = useContext(GlobalContext)

    const arrayBody = async () => {
        let compare = areArraysEqualModal(data, dataInitial)

        let body = []
        if (masterConfigInstall?.length > 0 && !compare) {
            body = await data?.concat(masterConfigInstall)
        } else if (masterConfigInstall?.length > 0 && compare) {
            body = masterConfigInstall
        } else {
            body = data
        }
        return body
    }

    const fetchUpdatePlant = async () => {
        setLoading(true)
        let body = await arrayBody()
        try {
            const res = await apiScada.patch(`configPlantInstallation/${idMaster}?idCcMaster=${idMaster}`, body)
            if (res.status === 200) {
                setDataInitial(JSON.parse(JSON.stringify(data)))
                dataConfigInstall.error = actualProject.category !== masterConfigInstall[0].config.plant_type ? 1 : 0
                dataConfigInstall.inst_type = masterConfigInstall[0].config.plant_type
                setDisabled(true)
                setDisabledTable(true)
                if (dataConfigInstall?.error === 1) {
                    dispatch(updateDeviceTree({ device: idMaster, value: true }))
                }
                setAnswer([{ status: true, message: res.data.message }])
            }
        } catch (err) {
            if (err.status === 434) {
                setAnswer([
                    {
                        status: false,
                        message: `${err.response.data.Devices} 
                    \n- ${err.response.data.Master}`
                    }
                ])
            } else {
                setAnswer([{ status: false, message: err.response.data.message }])
            }
            console.error('Ha habido un erroe en API Scada', err)
        } finally {
            setOpen(false)
            setLoading(false)
        }
    }

    const fetchUpdatePotMax = async () => {
        setLoading(true)
        try {
            const res = await apiScada.patch(`updateDevicePlant/${idMaster}?idCCMaster=${idMaster}`, dataPotMax)
            if (res.status === 200) {
                setDisabled(true)
                AcceptChangePoxMax()
                setAnswer([{ status: false, message: 'Configuración actualizada correctamente', reload: false }])
            }
        } catch (error) {
            setAnswer([{ status: false, message: 'Ha habido un error al actualizar la configuración', reload: false }])
            console.error('Ha habido un erroe en API Scada', error)
        } finally {
            setOpen(false)
            setLoading(false)
        }
    }

    const fetchUpdatePotMaxLoose = async (numserie, body) => {
        try {
            const res = await apiScada.patch(`updateDeviceInfo/${numserie}`, {
                nick: body?.nick,
                potencia_contratada: body?.potencia_contratada
            })
            if (res.status === 200) {
                setDisabled(true)
                AcceptChangePoxMax()
                dispatch(createToast({ status: res.status, message: 'Configuración actualizada correctamente', reload: false }))
            }
        } catch {
            dispatch(createToast({ status: 400, message: 'Ha habido un error al actualizar la configuración', reload: false }))
            console.error('Ha habido un erroe en API Scada')
        } finally {
            setOpen(false)
            setLoading(false)
        }
    }

    const AcceptChangePoxMax = () => {
        const arrayNew = dataInitialPmax?.map((devIni) => {
            const match = dataPotMax?.find((dev) => dev?.numserie === devIni?.numserie)
            if (match) {
                return {
                    ...devIni,
                    potencia_contratada: match?.potencia_contratada,
                    nick: match?.nick
                }
            }
            return devIni
        })
        setDataInitialPmax(arrayNew)
    }

    const bodyPotMax = async () => {
        setLoading(true)
        await Promise.all(dataPotMax?.map((device) => fetchUpdatePotMaxLoose(device.numserie, device)))
        setLoading(false)
    }

    const ApplyChange = () => {
        if (centroControl || master?.[0]?.id_tipo_dispositivo === dispositivos.ccmaster) {
            if (activeTab === 0) {
                fetchUpdatePlant()
            } else if (activeTab === 1) {
                fetchUpdatePotMax()
            }
        } else {
            bodyPotMax()
        }
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title='Configurar instalación'
            disabled={loading}
            functSave={() => ApplyChange()}
            loading={loading}
        >
            <Grid className='modal-eliminar-alarma' item lg={12}>
                <h4 className='font-weight-lighter text-center mt-2' style={{ fontSize: '1.2rem' }}>
                    ¿Desea aplicar el cambio en la {activeTab === 0 && 'configuración de la Planta ?'}{' '}
                    {activeTab === 1 && 'configuración de los dispositivos?'}
                </h4>
            </Grid>
        </Modal>
    )
}
