import React from 'react'
import { IpMeter } from 'utils/icon-centrocontrol'
import {
    Iconccm1,
    Iconccm2,
    Iconccm3,
    Iconccm4,
    IconInverter,
    IconAnalyzer,
    Iconselfconsumption,
    Icondataloger,
    IconBattery,
    Iconplug,
    Iconmedidor,
    Iconvtn,
    IconCcMaster,
    IconCT,
    IconUnindentified
} from 'utils/icon-centrocontrol'

// Sacar automaticamente la imagen del dispositivo pasandole el id_tipo_dispositivo y el nombre
export const ImageDevice = ({ id_type_device, width, height, marginTop, className, projectList }) => {
    const imgDevice = {
        1: [<Iconccm1 key='ccm1' />],
        2: [<Iconccm2 key='ccm2' />],
        3: [<Iconccm3 key='ccm3' />],
        4: [<Iconccm4 key='ccm4' />],
        5: [<IconInverter key='inverter' />],
        6: [<IconAnalyzer key='analyzer' />],
        7: [<Iconselfconsumption key='selfconsumption' />],
        8: [<Icondataloger key='logger' />],
        9: [<IconBattery key='batery' />],
        10: [<IpMeter key='ipmeter' />],
        12: [<Iconplug key='ccm1' />],
        13: [<IconUnindentified key='unnamed1' />],
        15: [<IconUnindentified key='unnamed2' />],
        16: [<Icondataloger key='dataLogger' />],
        20: [<IconCT key='ct' />],
        21: [<Iconmedidor key='medidor' />],
        23: [<Iconvtn key='vtn' />],
        0: [<IconCcMaster key='ccmmaster' />]
    }

    return (
        <>
            {projectList ? (
                <>{imgDevice[id_type_device]} </>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: width ? width : '25px',
                        height: height ? height : 'auto',
                        marginTop: marginTop
                    }}
                    className={className}
                >
                    {imgDevice[id_type_device]}
                </div>
            )}
        </>
    )
}
