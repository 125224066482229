import React, { useEffect, useState } from 'react'
import { Grid, FormControl, MenuItem } from '@mui/material'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { SelectStyled } from 'components/Select/SelectStyled'
import InputStyled from 'components/Input/InputStyled'
import { getNameVariable, getLineVariable, getNameByVariable, getLineByVariable } from 'utils/Utils'
import { validatorFloatVacio } from 'utils/ValidatorForm'

export const FormEditarTramo = ({ tipoDispositivo, habilitado, setHabilitado, setValidateFormTramo, objAlarmTramo, setObjAlarmTramo }) => {
    const nameVariable = getNameVariable(tipoDispositivo)
    const [selectedVar, setSelectedVar] = useState(getNameByVariable(objAlarmTramo.variable_name, tipoDispositivo))
    const [selectedLine, setSelectedLine] = useState(getLineByVariable(objAlarmTramo.variable_name, tipoDispositivo))
    const [lineVariable, setLineVariable] = useState(getLineVariable(selectedVar, tipoDispositivo))
    const [valueLower, setValueLower] = useState(objAlarmTramo.lower_value)
    const [valueUpper, setValueUpper] = useState(objAlarmTramo.upper_value)
    const [valueExpected, setValueExpected] = useState(objAlarmTramo.expected_value)
    const error = valueLower !== '' && valueUpper !== '' && Number(valueLower) > Number(valueUpper)

    const handleSelectVar = (event) => {
        setSelectedVar(event.target.value)
        setSelectedLine('')
        objAlarmTramo.variable_name = selectedVar + (event.target.value !== '-1' ? event.target.value : '')
        setObjAlarmTramo(objAlarmTramo)
    }

    const handleSelectLine = (event) => {
        setSelectedLine(event.target.value)
        objAlarmTramo.variable_name = selectedVar + (event.target.value !== '-1' ? event.target.value : '')
        setObjAlarmTramo(objAlarmTramo)
    }

    const handleLowerValue = (valor) => {
        if (validatorFloatVacio(valor)) {
            setValueLower(valor)
            objAlarmTramo.lower_value = valor === '' ? '' : parseFloat(valor)
            setObjAlarmTramo(objAlarmTramo)
        }
    }

    const handleUpperValue = (valor) => {
        if (validatorFloatVacio(valor)) {
            setValueUpper(valor)
            objAlarmTramo.upper_value = valor === '' ? '' : parseFloat(valor)
            setObjAlarmTramo(objAlarmTramo)
        }
    }

    const handleExpectedValue = (valor) => {
        if (validatorFloatVacio(valor)) {
            setValueExpected(valor)
            objAlarmTramo.expected_value = valor === '' ? '' : parseFloat(valor)
            setObjAlarmTramo(objAlarmTramo)
        }
    }

    const handleActive = () => {
        objAlarmTramo.expected_value = !habilitado
        setObjAlarmTramo(objAlarmTramo)
        setHabilitado(!habilitado)
    }

    useEffect(() => {
        if (selectedVar !== '') {
            setLineVariable(getLineVariable(selectedVar, tipoDispositivo))
        }
    }, [selectedVar])

    useEffect(() => {
        if (
            selectedVar !== '' &&
            selectedLine !== '' &&
            ((valueLower !== '' && valueUpper === '') ||
                (valueLower === '' && valueUpper !== '') ||
                (valueLower !== '' && valueUpper !== '' && Number(valueLower) < Number(valueUpper)))
        ) {
            setValidateFormTramo(true)
        } else {
            setValidateFormTramo(false)
        }
    }, [selectedVar, selectedLine, valueLower, valueUpper, valueExpected])

    return (
        <Grid container className='pt-3'>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={4} md={4} lg={4} xl={4}>
                <FormControl className='width-100' size='small'>
                    <small className='font-weight-lighter'>Seleccione una medida*:</small>
                    <SelectStyled value={selectedVar} onChange={handleSelectVar} id='SelectNameVariable'>
                        {nameVariable.map((ele) => {
                            return <MenuItem value={ele.value}>{ele.name}</MenuItem>
                        })}
                    </SelectStyled>
                </FormControl>
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2} />
            <Grid item xs={4} md={4} lg={4} xl={4}>
                <FormControl className='width-100' size='small'>
                    <small className='font-weight-lighter'>Seleccione una linea*:</small>
                    <SelectStyled value={selectedLine} onChange={handleSelectLine} id='SelectLineVariable' disabled={selectedVar === ''}>
                        {lineVariable.map((ele) => {
                            return <MenuItem value={ele.value}>{ele.name}</MenuItem>
                        })}
                    </SelectStyled>
                </FormControl>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={4} md={4} lg={4} xl={4}>
                <small className='font-weight-lighter'>Introduzca valor mínimo:</small>
                <InputStyled value={valueLower} onChange={handleLowerValue} />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2} />
            <Grid item xs={4} md={4} lg={4} xl={4}>
                <small className='font-weight-lighter'>Introduzca el valor máximo:</small>
                <InputStyled value={valueUpper} onChange={handleUpperValue} />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            {error && (
                <>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                    <Grid item xs={10} md={10} lg={10} xl={10} className='mt-2'>
                        <FormControl className='width-100 color-red' size='small'>
                            <small className='font-weight-lighter'>El valor máximo no puede ser superior al valor mínimo</small>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                </>
            )}
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={4} md={4} lg={4} xl={4}>
                <small className='font-weight-lighter'>Introduzca el valor esperado:</small>
                <InputStyled value={valueExpected} onChange={handleExpectedValue} />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2} />
            <Grid item xs={4} md={4} lg={4} xl={4} className='flex justify-start align-end'>
                <SwitchStyled id='habilitado' className='switch-small' checked={habilitado} onClick={handleActive} />
                <label className='ml-2 mb-0' htmlFor='habilitado'>
                    {' '}
                    Activar alarma{' '}
                </label>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} />
        </Grid>
    )
}
