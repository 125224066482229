import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GlobalContext } from 'context/GlobalContext'
import { Loader } from 'components/Loader/Loader'
import { apiScada } from 'services/api'
import { Mabt } from './Mabt'

export const MabtController = () => {
    const location = useLocation()
    const idMaster = location.pathname.split('/')[4]
    const url = `/${idMaster}?idCCMaster=${idMaster}`
    const [devices, setDevices] = useState()
    const [masterError, setMasterError] = useState()
    const [loading, setLoading] = useState(true)
    const { actualProject } = useContext(GlobalContext)
    const loadingSlice = useSelector((state) => state.loading.name)

    const getDevices = async () => {
        try {
            const response = await apiScada.get('getccm' + url)
            if (response.status === 200) {
                response.data.permissions = actualProject?.permissions
                setDevices(response?.data)
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        } finally {
            setLoading(false)
        }
    }

    const getInfoPower = async () => {
        try {
            const response = await apiScada.get('getInfoPower' + url)
            if (response.status === 200) {
                setMasterError(response?.data)
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        }
    }

    useEffect(() => {
        getInfoPower()
    }, [loadingSlice])

    useEffect(() => {
        if (actualProject?.permissions) {
            setLoading(true)
            getDevices()
        }
    }, [actualProject])

    return (
        <div className='container-scada-principal'>
            {loading ? <Loader /> : <Mabt devices={devices} masterPermission={devices} masterError={masterError} />}
        </div>
    )
}
