import React from 'react'
import { Grid } from '@mui/material'

export const MenuSelector = ({ options, activeTab, onClick }) => {
    return (
        <Grid container className='menu-selector'>
            {options.map((option, index) => {
                return (
                    <div selected={activeTab === index} onClick={() => onClick(index)} className={`${option.disabled && 'disabled-menu'}`}>
                        <span className={activeTab === index ? 'list-item-icon active-button' : 'list-item-icon'}>{option.title}</span>
                    </div>
                )
            })}
        </Grid>
    )
}
