import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'context/GlobalContext'
import { Table, TableBody, Grid, InputAdornment, TextField, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import SearchIcon from '@mui/icons-material/Search'
import GradeIcon from '@mui/icons-material/Grade'
import { StyledTableCellProject, StyledTableRow, StyledTableCellOrdenado } from 'components/Table/TableStyle'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { headersCentroControl } from 'services/headers'
import { IconNewObject, IconShared, IconoProyectos, IconAlarmWith } from 'utils/icon-centrocontrol'
import { CategoryType, PermissionType } from 'utils/MiddleWareProjects'
import { ordenarDatos, isMobile, iconoCategoria } from 'utils/Utils'
import { urls } from 'utils/constant'
import { DropdownProject } from 'views/Project/MenuProject/DropdownProject'
import { UserPermission } from 'views/User/Functions/userPermission'
import { NewProject } from '../CreateProject/NewProject'

export const ProjectsListPremium = ({
    openModal,
    setProject,
    handleSelectedAlarm,
    open,
    setOpen,
    searchTerm,
    handleChange,
    searchResults,
    sendAndChange,
    modal
}) => {
    const { projectsCompleto } = useContext(GlobalContext)
    const [sortConfig, setSortConfig] = useState({ key: 'nick', direction: 'asc' })
    const [loading, setLoading] = useState(false)
    const [favs, setFavs] = useState([])
    const [showFavs, setShowFavs] = useState(false)
    const token = sessionStorage.token

    const fetchFavProjects = async () => {
        try {
            const res = await fetch(urls.ccontrol + 'userSettings', { method: 'GET', headers: headersCentroControl(token) })
            if (res.status === 200) {
                let json = await res.json()
                setFavs(json?.project_fav)
            } else {
                setFavs([])
            }
            return true
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        }
    }

    const fetchAddDeleteFavProjects = async (idProject, isFav) => {
        let updatedFavs = []
        if (isFav) {
            updatedFavs = favs.filter((id) => id !== idProject)
        } else {
            updatedFavs = [...favs, idProject]
        }
        setFavs(updatedFavs)
        try {
            await fetch(urls.ccontrol + 'userSettings', {
                method: isFav ? 'DELETE' : 'POST',
                headers: headersCentroControl(token),
                body: JSON.stringify({ project_fav: idProject })
            })
            return true
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        }
    }

    useEffect(() => {
        sessionStorage.removeItem('treeState')
        fetchFavProjects()
    }, [])

    return (
        <>
            {modal !== true && (
                <span>
                    <div>
                        <span className='header-projectList'>
                            <div className='header-title'>
                                <IconoProyectos />
                                <h2 className='title-section p-1'> Mis proyectos</h2>
                            </div>
                            <div className='header-button-projectList'>
                                <ButtonStyled className='button-with-text' onClick={(e) => openModal(e)}>
                                    <IconNewObject className='icon-hover-with-text' />
                                    <p className='ml-2'>Nuevo proyecto</p>
                                </ButtonStyled>
                                {open && (
                                    <NewProject
                                        loading={loading}
                                        setLoading={setLoading}
                                        setOpen={setOpen}
                                        setProject={setProject}
                                        open={open}
                                    />
                                )}
                            </div>
                        </span>
                    </div>
                    <div>
                        <span className='leyend-header'>
                            <span className='buscador-projects'>
                                <ButtonStyled className='p-1 button-with-text mr-2 flex-center' onClick={(e) => setShowFavs(!showFavs)}>
                                    {showFavs ? (
                                        <GradeIcon className='icon-hover-with-text-enerclic' />
                                    ) : (
                                        <StarOutlineIcon className='icon-hover-with-text-enerclic' />
                                    )}
                                    <p className='ml-2'>{showFavs ? 'Todos' : 'Favoritos'}</p>
                                </ButtonStyled>
                                <TextField
                                    className='style-textfield-text textField'
                                    type='search'
                                    placeholder='Buscar por nombre de proyecto'
                                    value={searchTerm}
                                    InputProps={{
                                        className: 'search-projects-textfields',
                                        color: 'primary',
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={handleChange}
                                />
                            </span>
                        </span>
                    </div>
                </span>
            )}
            <div className={isMobile(780) ? `justify-content-center projectList ` : `list-projects`}>
                {showFavs && favs?.length === 0 ? (
                    <h4 align='center' className='mt-3'>
                        Aún no hay proyectos marcados cómo favoritos
                    </h4>
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCellOrdenado
                                        celda={'nick'}
                                        funcion={() => {
                                            ordenarDatos(searchResults, sortConfig, setSortConfig, 'nick')
                                        }}
                                        sortConfig={sortConfig}
                                        align='center'
                                    >
                                        {' '}
                                        {!isMobile(780) ? 'Nombre' : 'Proyecto'}{' '}
                                    </StyledTableCellOrdenado>
                                    {!isMobile(780) && (
                                        <StyledTableCellOrdenado
                                            celda={'shared'}
                                            funcion={() => {
                                                ordenarDatos(searchResults, sortConfig, setSortConfig, 'shared')
                                            }}
                                            sortConfig={sortConfig}
                                            align='center'
                                        >
                                            Compartido
                                        </StyledTableCellOrdenado>
                                    )}
                                    {!isMobile(780) && (
                                        <StyledTableCellOrdenado
                                            celda={'category'}
                                            funcion={() => {
                                                ordenarDatos(searchResults, sortConfig, setSortConfig, 'category', CategoryType)
                                            }}
                                            sortConfig={sortConfig}
                                            align='center'
                                        >
                                            Tipo
                                        </StyledTableCellOrdenado>
                                    )}
                                    {!isMobile(780) && (
                                        <StyledTableCellOrdenado
                                            celda={'permissions'}
                                            funcion={() => {
                                                ordenarDatos(searchResults, sortConfig, setSortConfig, 'permissions')
                                            }}
                                            sortConfig={sortConfig}
                                            align='center'
                                        >
                                            Rol
                                        </StyledTableCellOrdenado>
                                    )}
                                    {!isMobile(780) && (
                                        <StyledTableCellOrdenado
                                            celda={'alarm'}
                                            funcion={() => {
                                                ordenarDatos(searchResults, sortConfig, setSortConfig, 'alarm')
                                            }}
                                            sortConfig={sortConfig}
                                            align='center'
                                        >
                                            <IconAlarmWith />
                                            <div className='separator-left'>Alarmas</div>
                                        </StyledTableCellOrdenado>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projectsCompleto.length > 0 &&
                                    favs &&
                                    searchResults?.map((project, i) => {
                                        let fav = favs.includes(project.id_project)
                                        return (
                                            <React.Fragment key={i}>
                                                {!isMobile(780) ? (
                                                    <StyledTableRow className={`${showFavs && !fav ? 'display-none' : ''}`}>
                                                        <StyledTableCellProject>
                                                            <div className='card-projects-name'>
                                                                {iconoCategoria(project?.category)}
                                                                {project?.permissions !== 'user' ? (
                                                                    <DropdownProject
                                                                        project={project}
                                                                        centroControl={true}
                                                                        loading={loading}
                                                                        permissions={project?.permissions}
                                                                    />
                                                                ) : (
                                                                    <span style={{ width: '20px', height: 'auto' }}></span>
                                                                )}
                                                                <a
                                                                    onClick={() => {
                                                                        fetchAddDeleteFavProjects(project.id_project, fav)
                                                                    }}
                                                                >
                                                                    {fav ? <GradeIcon className='color-enerclic' /> : <StarOutlineIcon />}
                                                                </a>
                                                                <a
                                                                    onClick={() => {
                                                                        sendAndChange(project)
                                                                    }}
                                                                >
                                                                    {project?.nick}
                                                                </a>
                                                            </div>
                                                        </StyledTableCellProject>
                                                        <StyledTableCellProject>
                                                            {' '}
                                                            {project.shared === 1 ? (
                                                                <span className='shared-project'>
                                                                    <HtmlTooltip
                                                                        title={<span>Proyecto compartido por: {project?.owner}</span>}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: '#fff',
                                                                                textAlign: 'center'
                                                                            }}
                                                                        >
                                                                            <span className='icon-shared-project'>
                                                                                <IconShared />
                                                                            </span>
                                                                        </span>
                                                                    </HtmlTooltip>
                                                                </span>
                                                            ) : (
                                                                <span className='shared-project'></span>
                                                            )}
                                                        </StyledTableCellProject>
                                                        <StyledTableCellProject>
                                                            <p className='th-data'> {CategoryType(project?.category)} </p>
                                                        </StyledTableCellProject>
                                                        <StyledTableCellProject>
                                                            <p className='th-data'> {PermissionType(project?.permissions)}</p>
                                                        </StyledTableCellProject>
                                                        <StyledTableCellProject>
                                                            {project?.alarm > 0 && (
                                                                <span
                                                                    onClick={() => handleSelectedAlarm(project.id_project)}
                                                                    className='total-alarm'
                                                                >
                                                                    {project?.alarm}
                                                                </span>
                                                            )}
                                                        </StyledTableCellProject>
                                                    </StyledTableRow>
                                                ) : (
                                                    <StyledTableRow
                                                        key={i}
                                                        className={`${(showFavs && !fav) || ['enerSIM', 'IP-Meter'].includes(project.category) ? 'display-none' : ''}`}
                                                    >
                                                        <StyledTableCellProject>
                                                            <div className='accordion-menu-mobile-projectsList'>
                                                                <span className='table-cell-projectlist-mobile'>
                                                                    <DropdownProject
                                                                        project={project}
                                                                        loading={loading}
                                                                        centroControl={true}
                                                                        permissions={project?.permissions}
                                                                    />
                                                                    <Grid item xs={6} md={8}>
                                                                        <a
                                                                            onClick={() => {
                                                                                fetchAddDeleteFavProjects(project.id_project, fav)
                                                                            }}
                                                                        >
                                                                            {fav ? (
                                                                                <GradeIcon className='color-enerclic' />
                                                                            ) : (
                                                                                <StarOutlineIcon />
                                                                            )}
                                                                        </a>
                                                                        <a
                                                                            onClick={() => sendAndChange(project)}
                                                                            className='name-project letter-table-projectlist letter-table-projectlist-mobile'
                                                                        >
                                                                            {project?.nick}
                                                                        </a>
                                                                        <p className='property-project letter-table-projectlist '>
                                                                            {CategoryType(project?.category)}
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid item xs={3} md={2} className='icons-project-mobile-projectList'>
                                                                        {project.shared === 1 && <IconShared />}
                                                                        <UserPermission project={project} />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={3}
                                                                        md={2}
                                                                        className='icons-project-mobile-projectList flex-center'
                                                                    >
                                                                        {project?.alarm > 0 && (
                                                                            <button
                                                                                onClick={() => handleSelectedAlarm(project.id_project)}
                                                                                className='total-alarm p-2'
                                                                            >
                                                                                {project?.alarm}
                                                                            </button>
                                                                        )}
                                                                        {project?.alarm > 0 && <IconAlarmWith />}
                                                                    </Grid>
                                                                </span>
                                                            </div>
                                                        </StyledTableCellProject>
                                                    </StyledTableRow>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </>
    )
}
