import React, { useEffect, useState } from 'react'
import { Button, FormControl, Grid, MenuItem } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { urls } from 'utils/constant'
import { SelectStyled } from 'components/Select/SelectStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import InputStyled from 'components/Input/InputStyled'
import { headersCentroControl } from 'services/headers'
import { validatorEmail } from 'utils/ValidatorForm'
import { Modal } from 'components/Modal/Modal'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

/**
 * @param {groups {}, admin: boolean} Recibimos datos del grupo
 * @param Hacemos una llamada a validateAction con la acción que nos dice a quien le compartimos, que le compartimos y con qué permisos
 * @returns Retornamos un modal que nos permite introducir el mail de la persona a compartir, además del permiso que se le otorga sobre el grupo
 */
export const ModalShareGroup = ({ groups, modal, toggle, setModal }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [permissions, setPermissions] = useState([])
    const [email, setEmail] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [selectedPermissions, setSelectedPermissions] = useState(groups?.permissions === 'admin' ? 'Instalador' : 'Usuario')
    const [loading, setLoading] = useState(false)
    const [whole, setWhole] = useState(true)
    const [isValid, setIsValid] = useState(true)

    const shareGroup = () => {
        setLoading(true)
        let status
        if (email && email.length > 0 && isValid === true) {
            fetch(urls.ccontrol + `validateAction/group`, {
                method: 'PATCH',
                redirect: 'follow',
                headers: headersCentroControl(token),
                body: JSON.stringify({
                    id_group: groups.id_group,
                    address: email,
                    action: 'share',
                    nick: groups?.nick,
                    permission: selectedPermissions === 'Instalador' ? 'instal' : 'user',
                    whole: whole,
                    acepted: 1
                })
            })
                .then((res) => {
                    setLoading(false)
                    status = res.status
                    return res.json()
                })
                .then((data) => {
                    dispatch(createToast({ status: status, message: data.message, reload: true }))
                    setModal(false)
                })
                .catch((err) => console.error('ERROR. API Centro de control', err))
        } else {
            setDisabled(true)
            setLoading(false)
        }
    }

    const validateEmail = () => {
        setIsValid(validatorEmail(email))
    }

    const handleBlur = () => {
        if (email.length > 0) {
            validateEmail()
        }
        if (!email) {
            setIsValid(true)
        }
    }

    //Defino el array de los permisos en función del permiso del usuario que quiere compartir el Grupo.
    useEffect(() => {
        if (groups?.permissions === 'admin') {
            setPermissions(['Instalador', 'Usuario'])
        } else {
            setPermissions(['Usuario'])
        }
    }, [])

    useEffect(() => {
        if (isValid === true && email.length > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [isValid, email])

    useEffect(() => {
        setIsValid(true)
        setEmail('')
    }, [modal])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                maxWidth='sm'
                open={modal}
                setOpen={setModal}
                title={`Compartir grupo "${groups?.nick}"`}
                loading={loading}
                functSave={shareGroup}
                buttonAction='Compartir'
                disabled={disabled}
            >
                <Grid container className='pr-5 pl-5'>
                    <Grid className='pb-1' item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className='color-black'>Email de usuario:</small>
                        </div>
                        <InputStyled
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e)}
                            onBlur={handleBlur}
                            error={!isValid}
                            errorMessage={'Email inválido'}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid className='pb-1' item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1 pt-2'>
                            <small className='color-black'>Permiso:</small>
                        </div>
                        <FormControl className='width-100' size='small'>
                            <SelectStyled value={selectedPermissions} onChange={(e) => setSelectedPermissions(e.target.value)}>
                                {permissions.map((prm, i) => {
                                    return (
                                        <MenuItem value={prm} key={i}>
                                            {prm}
                                        </MenuItem>
                                    )
                                })}
                            </SelectStyled>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='d-flex justify-content-flex-start align-items-center mt-2'>
                        <HtmlTooltip
                            title={
                                <div className='p-1'>
                                    <span>
                                        Al compartir el grupo, se compartirá todo su contenido, incluyendo futuros subgrupos e instalaciones
                                        que se creen en el grupo.
                                    </span>
                                </div>
                            }
                        >
                            <Button className='pl-0 pr-1'>
                                <FontAwesomeIcon icon={faInfoCircle} className='info-icon' />
                            </Button>
                        </HtmlTooltip>
                        <span className='font-size-11px color-black mr-1'>¿Compartir todo?</span>
                        <SwitchStyled className='switch-small' checked={whole} onClick={() => setWhole(!whole)} />
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
