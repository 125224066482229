import { createSlice } from '@reduxjs/toolkit'

export const instalacionSlice = createSlice({
    name: 'instalacion',
    initialState: {
        name: ''
    },
    reducers: {
        updateInstalacion: (state, action) => {
            if (action.payload.name) {
                state.name = action.payload.name
            }
        }
    }
})

export const { updateInstalacion } = instalacionSlice.actions

export default instalacionSlice.reducer
