import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { initialColumns } from 'utils/Utils'

export const ModalAddRelay = ({ open, setOpen, relayCount, setRelayCount, setColumns }) => {
    const handleAddRelay = () => {
        if (relayCount < 4) {
            const newRelayCount = relayCount + 1
            const newRelayId = `Relay${newRelayCount}`
            setColumns((prevColumns) => ({
                ...prevColumns,
                [newRelayId]: {
                    name: newRelayId,
                    columns: initialColumns(newRelayId)
                }
            }))
            setRelayCount(newRelayCount)
            setOpen(!open)
        }
    }

    return (
        <Modal open={open} setOpen={setOpen} title='Añadir relay' buttonAction='Añadir' functSave={handleAddRelay}>
            <Grid container className='text-center mt-2'>
                <h4 className='font-weight-lighter modal-font'>¿Deseas añadir un relay?</h4>
            </Grid>
        </Modal>
    )
}
