import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import moment from 'moment'
import { GraphsBar } from 'components/Graph/GraphsBar'
import { GraphsLine } from 'components/Graph/GraphsLine'
import { Loader } from 'components/Loader/Loader'
import { headersScada } from 'services/headers'
import { urls, vertidosPhoto } from 'utils/constant'
import { moreOneDay, formatDateNoSecond } from 'utils/Utils'
import { formatLabel } from '../MABT/utils'
import './StylePhotovoltaic.scss'

export const GraphsPhotovoltaic = ({ childrenDevices, devicesSelect, daily, data, end, start, idCcMaster }) => {
    const token = sessionStorage?.token
    const [dataGraphs, setDataGrahps] = useState([])
    const [loading, setLoading] = useState(true)
    const [labels, setLabels] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const [dataSets, setDataSets] = useState([])
    const [labelEnergy, setLabelEnergy] = useState([])
    const [dataSetsEnergy, setDataSetsEnergy] = useState([])

    const fetchGraphProduction = async (numserie, type) => {
        if (childrenDevices) {
            try {
                const res = await fetch(urls.scada + `chart/getDataChartsCurves/${numserie}?idCCMaster=${idCcMaster}`, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: headersScada(token),
                    body: JSON.stringify({
                        iniDate: start?.format('YYYY-MM-DD'),
                        endDate: end?.format('YYYY-MM-DD'),
                        iniTime: `00:00`,
                        endTime: moment().format('YYYY-MM-DD') === end?.format('YYYY-MM-DD') ? moment().format('HH:mm') : `23:59`,
                        interval: '5',
                        axis: [
                            {
                                idDeviceType: type,
                                fields: type === 2 ? ['pact1'] : ['pact'],
                                numseries: [`${numserie}`],
                                unit: 'kWh'
                            }
                        ]
                    })
                })
                if (res.status === 200) {
                    return res.json()
                } else {
                    return []
                }
            } catch (err) {
                console.error('ERROR. API Scada', err)
            }
        }
    }

    const arrayGraphsPhotovoltaic = async () => {
        setLoading(true)
        setDataGrahps([])
        let result = await Promise.all(
            childrenDevices?.map((device) => fetchGraphProduction(device?.numserie, device?.id_tipo_dispositivo))
        )
        setDataGrahps(result)
        setLoading(false)
    }

    const dataGraphsPower = () => {
        setDataSets([])
        dataGraphs &&
            dataGraphs?.forEach((item, i) => {
                item?.data?.forEach((datos) => {
                    if (devicesSelect?.includes(Number(datos?.numserie))) {
                        setDataSets((prev) => [
                            ...prev,
                            {
                                label: datos?.nick,
                                data: datos?.data,
                                borderColor: vertidosPhoto?.[i],
                                pointBackgroundColor: vertidosPhoto?.[i],
                                fill: false,
                                id: i + 5,
                                pointRadius: 0,
                                tension: 0.1,
                                numserie: `${datos.numserie}`
                            }
                        ])
                    }
                })
            })
        setLabels(dataGraphs && dataGraphs?.[0]?.categories)
    }

    const EnergyProduction = () => {
        setDataSetsEnergy([])
        childrenDevices &&
            childrenDevices?.forEach((dev, i) => {
                if (devicesSelect?.includes(Number(dev.numserie))) {
                    setDataSetsEnergy((prev) => [
                        ...prev,
                        {
                            Type: 'bar',
                            label: dev?.nick ? dev?.nick : 'Importada',
                            data: data && data?.filter((disp) => disp?.numserie === dev?.numserie).map((device) => device?.eagendia),
                            backgroundColor: vertidosPhoto?.[i],
                            borderColor: vertidosPhoto?.[i],
                            pointBackgroundColor: vertidosPhoto?.[i],
                            fill: true,
                            id: '1',
                            numserie: dev.numserie,
                            maintainAspectRatio: 'true',
                            borderWidth: 3
                        }
                    ])
                }
            })
    }

    useEffect(() => {
        setLabelEnergy([])
        setLabelEnergy((prev) => {
            const updatedLabelEnergy = [...prev]
            data?.forEach((dev) => {
                const datetime = dev?.datetime
                if (datetime && !updatedLabelEnergy?.includes(formatDateNoSecond(datetime))) {
                    updatedLabelEnergy?.push(formatDateNoSecond(datetime))
                }
            })
            return updatedLabelEnergy
        })
    }, [data])

    useEffect(() => {
        if (moreOneDay(start, end)) {
            arrayGraphsPhotovoltaic()
        }
        if (!moreOneDay(start, end) && daily !== 'days' && daily !== 'hours') {
            setActiveTab(1)
        }
    }, [start, end, daily])

    useEffect(() => {
        EnergyProduction()
    }, [data, devicesSelect])

    useEffect(() => {
        dataGraphsPower()
    }, [dataGraphs, devicesSelect])

    useEffect(() => {
        if (start && end && !moreOneDay(start, end)) {
            setActiveTab(1)
        }
    }, [start, end])

    return (
        <Grid container className='report-scada-menuconsumo-calendar container-graphs-photovoltaic'>
            <Grid item xs={12}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <Grid item xs={12} md={12} className='button-tab-graphs-photovoltaic'>
                            <button
                                className={activeTab === 0 ? 'button-general-active' : 'button-general'}
                                disabled={!moreOneDay(start, end)}
                                selected={activeTab === 0}
                                onClick={() => setActiveTab(0)}
                            >
                                Potencia (kW)
                            </button>
                            <button
                                className={activeTab === 1 ? 'button-general-active' : 'button-general'}
                                selected={activeTab === 1}
                                onClick={() => setActiveTab(1)}
                            >
                                Energía (kWh)
                            </button>
                        </Grid>
                        {activeTab === 0 && <GraphsLine data={dataSets} labels={formatLabel(labels)} />}
                        {activeTab === 1 && <GraphsBar dataSet={dataSetsEnergy} labels={labelEnergy} />}
                    </>
                )}
            </Grid>
        </Grid>
    )
}
