import React from 'react'
import { AuthFooter } from 'views/Footer/AuthFooter'
import { NavBar } from 'views/NavBar/NavBar'

const MainLayout = ({ children, scada, centroControl }) => {
    return (
        <div>
            <NavBar scada={scada} centroControl={centroControl} />
            {children}
            <AuthFooter />
        </div>
    )
}

export default MainLayout
