export const variableTitles = {
    p: 'Potencia',
    p1: 'Potencia L1',
    p2: 'Potencia L2',
    p3: 'Potencia L3',

    pft: 'Factor Potencia',
    pft1: 'Factor Potencia L1',
    pft2: 'Factor Potencia L2',
    pft3: 'Factor Potencia L3',

    pf1: 'Factor de Potencia L1',
    pf2: 'Factor de Potencia L2',
    pf3: 'Factor de Potencia L3',

    fecha: 'Fecha',
    numserie: 'Número de Serie',

    energia: 'Energia',
    potencia: 'Potencia',
    corriente: 'Corriente',
    tension: 'Voltaje',

    horario: 'horario',
    diario: 'diario',
    datosActuales: 'datosactuales',
    datosHistoricos: 'datoshistoricos',

    power: 'Potencia',
    fase: 'Fase',

    nick: 'Nick',
    name: 'Nombre',
    datetime: 'Fecha',

    in: 'Corriente Neutra',
    it: 'Corriente',
    temp: 'Temperatura',

    i: 'Corriente',
    v: 'Tension',

    i1: 'Corriente L1',
    i2: 'Corriente L2',
    i3: 'Corriente L3',
    intensidad_magneto: 'Intensidad máxima del magneto-térmico',

    v1: 'Tensión L1',
    v2: 'Tensión L2',
    v3: 'Tensión L3',

    pact: 'Potencia Activa',
    pact1: 'Potencia Activa L1',
    pact2: 'Potencia Activa L2',
    pact3: 'Potencia Activa L3',
    potencia_contratada: 'Potencia Contratada',

    pac: 'Potencia Activa',
    pac1: 'Potencia Activa L1',
    pac2: 'Potencia Activa L2',
    pac3: 'Potencia Activa L3',

    pap: 'Potencia Aparente',
    pap1: 'Potencia Aparente L1',
    pap2: 'Potencia Aparente L2',
    pap3: 'Potencia Aparente L3',

    preac: 'Potencia Reactiva',
    preac1: 'Potencia Reactiva L1',
    preac2: 'Potencia Reactiva L2',
    preac3: 'Potencia Reactiva L3',

    pre1: 'Potencia Reactiva L1',
    pre2: 'Potencia Reactiva L2',
    pre3: 'Potencia Reactiva L3',

    g_eacons: 'Energía Activa Consumida',

    eacons: 'Energía Consumida',
    eacons1: 'Energía Consumida L1',
    eacons2: 'Energía Consumida L2',
    eacons3: 'Energía Consumida L3',
    eaconsdia: 'Energía Activa Consumida',

    g_eagen: 'Energía Activa Generada',
    eagen: 'Energía Activa Generada',
    eagen1: 'Energía Activa Generada L1',
    eagen2: 'Energía Activa Generada L2',
    eagen3: 'Energía Activa Generada L3',
    eagendia: 'Energía Activa Generada',

    g_ereac_q1: 'Energía Reactiva Q1',
    g_ereac_q2: 'Energía Reactiva Q2',
    g_ereac_q3: 'Energía Reactiva Q3',
    g_ereac_q4: 'Energía Reactiva Q4',

    ereac_q1: 'Energía reactiva Q1',
    ereac_q2: 'Energía reactiva Q2',
    ereac_q3: 'Energía reactiva Q3',
    ereac_q4: 'Energía reactiva Q4',

    ereac1_q1: 'Energía Reactiva L1 Q1',
    ereac1_q2: 'Energía Reactiva L1 Q2',
    ereac1_q3: 'Energía Reactiva L1 Q3',
    ereac1_q4: 'Energía Reactiva L1 Q4',

    ereac2_q1: 'Energía Reactiva L2 Q1',
    ereac2_q2: 'Energía Reactiva L2 Q2',
    ereac2_q3: 'Energía Reactiva L2 Q3',
    ereac2_q4: 'Energía Reactiva L2 Q4',

    ereac3_q1: 'Energía Reactiva L3 Q1 ',
    ereac3_q2: 'Energía Reactiva L3 Q2 ',
    ereac3_q3: 'Energía Reactiva L3 Q3 ',
    ereac3_q4: 'Energía Reactiva L3 Q4 ',

    freq: 'Frecuencia de Señal',
    freq1: 'Frecuencia de Señal L1',
    freq2: 'Frecuencia de Señal L2',
    freq3: 'Frecuencia de Señal L3',

    idc: 'Corriente continua',
    vdc: 'Tensión continua',
    pdc: 'Potencia continua',

    idc1: 'Corriente continua String 1',
    idc2: 'Corriente continua String 2',
    idc3: 'Corriente continua String 3',
    idc4: 'Corriente continua String 4',
    idc5: 'Corriente continua String 5',
    idc6: 'Corriente continua String 6',
    idc7: 'Corriente continua String 7',
    idc8: 'Corriente continua String 8',
    idc9: 'Corriente continua String 9',
    idc10: 'Corriente continua String 10',
    idc11: 'Corriente continua String 11',
    idc12: 'Corriente continua String 12',
    idc13: 'Corriente continua String 13',
    idc14: 'Corriente continua String 14',

    vdc1: 'Tensión continua String 1',
    vdc2: 'Tensión continua String 2',
    vdc3: 'Tensión continua String 3',
    vdc4: 'Tensión continua String 4',
    vdc5: 'Tensión continua String 5',
    vdc6: 'Tensión continua String 6',
    vdc7: 'Tensión continua String 7',
    vdc8: 'Tensión continua String 8',
    vdc9: 'Tensión continua String 9',
    vdc10: 'Tensión continua String 10',
    vdc11: 'Tensión continua String 11',
    vdc12: 'Tensión continua String 12',
    vdc13: 'Tensión continua String 13',
    vdc14: 'Tensión continua String 14',

    pdc1: 'Potencia continua String 1',
    pdc2: 'Potencia continua String 2',
    pdc3: 'Potencia continua String 3',
    pdc4: 'Potencia continua String 4',
    pdc5: 'Potencia continua String 5',
    pdc6: 'Potencia continua String 6',
    pdc7: 'Potencia continua String 7',
    pdc8: 'Potencia continua String 8',
    pdc9: 'Potencia continua String 9',
    pdc10: 'Potencia continua String 10',
    pdc11: 'Potencia continua String 11',
    pdc12: 'Potencia continua String 12',
    pdc13: 'Potencia continua String 13',
    pdc14: 'Potencia continua String 14',

    p_charge: 'Potencia de carga',
    p1_charge: 'P Carga L1',
    p2_charge: 'P Carga L2',
    p3_charge: 'P Carga L3',

    p_discharge: 'Potencia de descarga',
    p1_discharge: 'P Descarga L1',
    p2_discharge: 'P Descarga L2',
    p3_discharge: 'P Descarga L3',

    i_charge: 'Corriente de carga',
    i1_charge: 'C Carga L1',
    i2_charge: 'C Carga L2',
    i3_charge: 'C Carga L3',

    i_discharge: 'Corriente de descarga',
    i1_discharge: 'C Descarga L1',
    i2_discharge: 'C Descarga L2',
    i3_discharge: 'C Descarga L3',

    v_charge: 'Tensión en carga',
    v1_charge: 'T Carga L1',
    v2_charge: 'T Carga L2',
    v3_charge: 'T Carga L3',

    v_input: 'Tension de entrada',
    v_output: 'Tension de salida',

    v_discharge: 'Tensión en descarga',
    v1_discharge: 'T Descarga L1',
    v2_discharge: 'T Descarga L2',
    v3_discharge: 'T Descarga L3',

    e: 'Energía',
    e1: 'Energía L1',
    e2: 'Energía L2',
    e3: 'Energía L3',

    e_charge: 'Energía en carga',
    e1_charge: 'E Carga L1',
    e2_charge: 'E Carga L2',
    e3_charge: 'E Carga L3',

    e_discharge: 'Energía en descarga',
    e1_discharge: 'E Descarga L1',
    e2_discharge: 'E Descarga L2',
    e3_discharge: 'E Descarga L3',

    p_battery: 'Potencia de la batería',
    e_battery: 'Energía de la batería',
    i_battery: 'Corriente de la batería',
    v_battery: 'Tensión de la batería',

    soc: 'SOC',

    flagErr2: 'Regulacion',
    ereact: 'Energia Reactiva Total',
    ereact_q1: 'Energia Reactiva Total Cuadrante Q1',
    ereact_q2: 'Energia Reactiva Total Cuadrante Q2',
    ereact_q3: 'Energia Reactiva Total Cuadrante Q3',
    ereact_q4: 'Energia Reactiva Total Cuadrante Q4',
    ereact_q5: 'Energia Reactiva Total Cuadrante Q5',
    ereact_q6: 'Energia Reactiva Total Cuadrante Q6',
    ereact_q7: 'Energia Reactiva Total Cuadrante Q7',
    ereact_q12: 'Energia Reactiva Total Cuadrantes Q12',
    ereact_q13: 'Energia Reactiva Total Cuadrantes Q13',
    ereact_q23: 'Energia Reactiva Total Cuadrantes Q23',
    ereact_q24: 'Energia Reactiva Total Cuadrantes Q24',
    ereact_q34: 'Energia Reactiva Total Cuadrantes Q34',
    ereact_q41: 'Energia Reactiva Total Cuadrantes Q41',

    ereact1: 'Energia Reactiva Total',
    ereact1_q1: 'Energia Reactiva Total Cuadrante Q1 L1',
    ereact1_q2: 'Energia Reactiva Total Cuadrante Q2 L1',
    ereact1_q3: 'Energia Reactiva Total Cuadrante Q3 L1',
    ereact1_q4: 'Energia Reactiva Total Cuadrante Q4 L1',
    ereact1_q5: 'Energia Reactiva Total Cuadrante Q5 L1',
    ereact1_q6: 'Energia Reactiva Total Cuadrante Q6 L1',
    ereact1_q7: 'Energia Reactiva Total Cuadrante Q7 L1',
    ereact1_q12: 'Energia Reactiva Total Cuadrantes Q12 L1',
    ereact1_q13: 'Energia Reactiva Total Cuadrantes Q13 L1',
    ereact1_q23: 'Energia Reactiva Total Cuadrantes Q23 L1',
    ereact1_q24: 'Energia Reactiva Total Cuadrantes Q24 L1',
    ereact1_q34: 'Energia Reactiva Total Cuadrantes Q34 L1',
    ereact1_q41: 'Energia Reactiva Total Cuadrantes Q41 L1',

    ereact2: 'Energia Reactiva Total',
    ereact2_q1: 'Energia Reactiva Total Cuadrante Q1 L2',
    ereact2_q2: 'Energia Reactiva Total Cuadrante Q2 L2',
    ereact2_q3: 'Energia Reactiva Total Cuadrante Q3 L2',
    ereact2_q4: 'Energia Reactiva Total Cuadrante Q4 L2',
    ereact2_q5: 'Energia Reactiva Total Cuadrante Q5 L2',
    ereact2_q6: 'Energia Reactiva Total Cuadrante Q6 L2',
    ereact2_q7: 'Energia Reactiva Total Cuadrante Q7 L2',
    ereact2_q12: 'Energia Reactiva Total Cuadrantes Q12 L2',
    ereact2_q13: 'Energia Reactiva Total Cuadrantes Q13 L2',
    ereact2_q23: 'Energia Reactiva Total Cuadrantes Q23 L2',
    ereact2_q24: 'Energia Reactiva Total Cuadrantes Q24 L2',
    ereact2_q34: 'Energia Reactiva Total Cuadrantes Q34 L2',
    ereact2_q41: 'Energia Reactiva Total Cuadrantes Q41 L2',

    ereact3: 'Energia Reactiva Total',
    ereact3_q1: 'Energia Reactiva Total Cuadrante Q1 L3',
    ereact3_q2: 'Energia Reactiva Total Cuadrante Q2 L3',
    ereact3_q3: 'Energia Reactiva Total Cuadrante Q3 L3',
    ereact3_q4: 'Energia Reactiva Total Cuadrante Q4 L3',
    ereact3_q5: 'Energia Reactiva Total Cuadrante Q5 L3',
    ereact3_q6: 'Energia Reactiva Total Cuadrante Q6 L3',
    ereact3_q7: 'Energia Reactiva Total Cuadrante Q7 L3',
    ereact3_q12: 'Energia Reactiva Total Cuadrantes Q12 L3',
    ereact3_q13: 'Energia Reactiva Total Cuadrantes Q13 L3',
    ereact3_q23: 'Energia Reactiva Total Cuadrantes Q23 L3',
    ereact3_q24: 'Energia Reactiva Total Cuadrantes Q24 L3',
    ereact3_q34: 'Energia Reactiva Total Cuadrantes Q34 L3',
    ereact3_q41: 'Energia Reactiva Total Cuadrantes Q41 L3',
    modo: 'Modo',

    /*ESCLAVOS*/
    i_inst: 'Corriente Instantanea',
    i_med: 'Corriente',
    i_max: 'Corriente Máxima Detectada',
    i_min: 'Corriente Mínima Detectada',
    id_padre_electrico: 'Padre Elećtrico',
    id_padre: 'Padre Elećtrico',

    type: 'Tipo',
    num_slaves: 'Esclavos',
    activo: 'Activo',

    if: 'Corriente Fundamental',
    if1: 'Corriente Fundamental L1',
    if2: 'Corriente Fundamental L2',
    if3: 'Corriente Fundamental L3',

    // vf: "Tensión Fundamental",
    // vf1: "Tensión Fundamental L1",
    // vf2: "Tensión Fundamental L2",
    // vf3: "Tensión Fundamental L3",

    temperatura: 'Temperatura',
    temp1: 'Temperatura L1',
    temp2: 'Temperatura L2',
    temp3: 'Temperatura L3',

    eapa: 'Energía Aparente',
    eapa1: 'Energía Aparente L1',
    eapa2: 'Energía Aparente L2',
    eapa3: 'Energía Aparente L3',

    papt: 'Potencia Aparente',
    eacons_total: 'Energia Consumida Total',
    eagen_total: 'Energia Generada Total',

    preact: 'Potencia Reactiva',

    thdv: 'Distorsión Armónica Voltaje',
    thdv1: 'Distorsión Armónica Voltaje L1',
    thdv2: 'Distorsión Armónica Voltaje L2',
    thdv3: 'Distorsión Armónica Voltaje L3',

    thdi: 'Distorsión Armónica Corriente',
    thdi1: 'Distorsión Armónica Corriente L1',
    thdi2: 'Distorsión Armónica Corriente L2',
    thdi3: 'Distorsión Armónica Corriente L3',

    thdp: 'Distorsión Armónica VoltajPotenciae',
    thdp1: 'Distorsión Armónica Potencia L1',
    thdp2: 'Distorsión Armónica Potencia L2',
    thdp3: 'Distorsión Armónica Potencia L3',

    tipoCCM: 'ID tipo del dispositivo',
    pf: 'Factor de Potencia',
    eact: 'Energía Activa',

    vab: 'Corriente continua A -> B',
    vbc: 'Corriente continua B -> C',
    vba: 'Corriente continua B -> A',
    vca: 'Corriente continua C -> A',
    va: 'Corriente continua A',
    vb: 'Corriente continua B',
    vc: 'Corriente continua C',

    vac: 'Tensión',
    ine: 'Intensidad de Neutro',
    vtn: 'Voltaje Neutro-Tierra',
    eact1_q14: 'Energía Activa de Consumo L1',
    eact2_q14: 'Energía Activa de Consumo L2',
    eact3_q14: 'Energía Activa de Consumo L3',
    eact1_q23: 'Energía activa de generación L1',
    eact2_q23: 'Energía activa de generación L2',
    eact3_q23: 'Energía activa de generación L3',

    id_tipo_dispositivo: 'Tipo de dispositivo',

    eact_q14: 'Energía exportada',
    eact_q23: 'Energía importada'
}

export const variableTitlesMabt = {
    nick: 'Salida',
    name: 'Nombre',
    datetime: 'Fecha',

    p: 'Potencia',
    p1: 'Potencia R',
    p2: 'Potencia S',
    p3: 'Potencia T',

    pft: 'Factor Potencia',
    pft1: 'Factor Potencia R',
    pft2: 'Factor Potencia S',
    pft3: 'Factor Potencia T',

    pf1: 'Factor de Potencia R',
    pf2: 'Factor de Potencia S',
    pf3: 'Factor de Potencia T',

    fecha: 'Fecha',
    numserie: 'Número de Serie',

    energia: 'Energia',
    potencia: 'Potencia',
    corriente: 'Corriente',
    tension: 'Voltaje',

    horario: 'horario',
    diario: 'diario',
    datosActuales: 'datosactuales',
    datosHistoricos: 'datoshistoricos',

    power: 'Potencia',
    fase: 'Fase',

    in: 'Corriente Neutra',
    it: 'Corriente',
    temp: 'Temperatura',

    i: 'Corriente',
    v: 'Tension',

    idc: 'Corriente Continua',
    vdc: 'Tension Continua',
    pdc: 'Potencia Continua',

    i1: 'Corriente R',
    i2: 'Corriente S',
    i3: 'Corriente T',
    intensidad_magneto: 'Intensidad máxima del magneto-térmico',

    v1: 'Tensión R',
    v2: 'Tensión S',
    v3: 'Tensión T',

    pact: 'Potencia Activa',
    pact1: 'Potencia Activa R',
    pact2: 'Potencia Activa S',
    pact3: 'Potencia Activa T',
    potencia_contratada: 'Potencia Contratada',

    pac: 'Potencia Activa',
    pac1: 'Potencia Activa R',
    pac2: 'Potencia Activa S',
    pac3: 'Potencia Activa T',

    pap: 'Potencia Aparente',
    pap1: 'Potencia Aparente R',
    pap2: 'Potencia Aparente S',
    pap3: 'Potencia Aparente T',

    preac: 'Potencia Reactiva',
    preac1: 'Potencia Reactiva R',
    preac2: 'Potencia Reactiva S',
    preac3: 'Potencia Reactiva T',

    pre1: 'Potencia Reactiva R',
    pre2: 'Potencia Reactiva S',
    pre3: 'Potencia Reactiva T',

    g_eacons: 'Energía Activa Consumida',

    eacons: 'Energía Consumida',
    eacons1: 'Energía Consumida R',
    eacons2: 'Energía Consumida S',
    eacons3: 'Energía Consumida T',
    eaconsdia: 'Energía Activa Consumida',

    g_eagen: 'Energía Activa Generada',
    eagen: 'Energía Activa Generada',
    eagen1: 'Energía Activa Generada R',
    eagen2: 'Energía Activa Generada S',
    eagen3: 'Energía Activa Generada T',
    eagendia: 'Energía Activa Generada',

    g_ereac_q1: 'Energía Reactiva Q1',
    g_ereac_q2: 'Energía Reactiva Q2',
    g_ereac_q3: 'Energía Reactiva Q3',
    g_ereac_q4: 'Energía Reactiva Q4',

    ereac_q1: 'Energía reactiva Q1',
    ereac_q2: 'Energía reactiva Q2',
    ereac_q3: 'Energía reactiva Q3',
    ereac_q4: 'Energía reactiva Q4',

    ereac1_q1: 'Energía Reactiva R Q1',
    ereac1_q2: 'Energía Reactiva R Q2',
    ereac1_q3: 'Energía Reactiva R Q3',
    ereac1_q4: 'Energía Reactiva R Q4',

    ereac2_q1: 'Energía Reactiva S Q1',
    ereac2_q2: 'Energía Reactiva S Q2',
    ereac2_q3: 'Energía Reactiva S Q3',
    ereac2_q4: 'Energía Reactiva S Q4',

    ereac3_q1: 'Energía Reactiva T Q1 ',
    ereac3_q2: 'Energía Reactiva T Q2 ',
    ereac3_q3: 'Energía Reactiva T Q3 ',
    ereac3_q4: 'Energía Reactiva T Q4 ',

    freq: 'Frecuencia de Señal',
    freq1: 'Frecuencia de Señal R',
    freq2: 'Frecuencia de Señal S',
    freq3: 'Frecuencia de Señal T',

    idc1: 'Corriente continua String 1',
    idc2: 'Corriente continua String 2',
    idc3: 'Corriente continua String 3',
    idc4: 'Corriente continua String 4',
    idc5: 'Corriente continua String 5',
    idc6: 'Corriente continua String 6',
    idc7: 'Corriente continua String 7',
    idc8: 'Corriente continua String 8',
    idc9: 'Corriente continua String 9',
    idc10: 'Corriente continua String 10',
    idc11: 'Corriente continua String 11',
    idc12: 'Corriente continua String 12',
    idc13: 'Corriente continua String 13',
    idc14: 'Corriente continua String 14',

    vdc1: 'Tensión continua String 1',
    vdc2: 'Tensión continua String 2',
    vdc3: 'Tensión continua String 3',
    vdc4: 'Tensión continua String 4',
    vdc5: 'Tensión continua String 5',
    vdc6: 'Tensión continua String 6',
    vdc7: 'Tensión continua String 7',
    vdc8: 'Tensión continua String 8',
    vdc9: 'Tensión continua String 9',
    vdc10: 'Tensión continua String 10',
    vdc11: 'Tensión continua String 11',
    vdc12: 'Tensión continua String 12',
    vdc13: 'Tensión continua String 13',
    vdc14: 'Tensión continua String 14',

    pdc1: 'Potencia continua String 1',
    pdc2: 'Potencia continua String 2',
    pdc3: 'Potencia continua String 3',
    pdc4: 'Potencia continua String 4',
    pdc5: 'Potencia continua String 5',
    pdc6: 'Potencia continua String 6',
    pdc7: 'Potencia continua String 7',
    pdc8: 'Potencia continua String 8',
    pdc9: 'Potencia continua String 9',
    pdc10: 'Potencia continua String 10',
    pdc11: 'Potencia continua String 11',
    pdc12: 'Potencia continua String 12',
    pdc13: 'Potencia continua String 13',
    pdc14: 'Potencia continua String 14',

    p_charge: 'Potencia de carga',
    p1_charge: 'P Carga R',
    p2_charge: 'P Carga S',
    p3_charge: 'P Carga T',

    p_discharge: 'Potencia de descarga',
    p1_discharge: 'P Descarga R',
    p2_discharge: 'P Descarga S',
    p3_discharge: 'P Descarga T',

    i_charge: 'Corriente de carga',
    i1_charge: 'C Carga R',
    i2_charge: 'C Carga S',
    i3_charge: 'C Carga T',

    i_discharge: 'Corriente de descarga',
    i1_discharge: 'C Descarga R',
    i2_discharge: 'C Descarga S',
    i3_discharge: 'C Descarga T',

    v_charge: 'Tensión en carga',
    v1_charge: 'T Carga R',
    v2_charge: 'T Carga S',
    v3_charge: 'T Carga T',

    v_input: 'Tension de entrada',
    v_output: 'Tension de salida',

    v_discharge: 'Tensión en descarga',
    v1_discharge: 'T Descarga R',
    v2_discharge: 'T Descarga S',
    v3_discharge: 'T Descarga T',

    e: 'Energía',
    e1: 'Energía R',
    e2: 'Energía S',
    e3: 'Energía T',

    e_charge: 'Energía en carga',
    e1_charge: 'E Carga R',
    e2_charge: 'E Carga S',
    e3_charge: 'E Carga T',

    e_discharge: 'Energía en descarga',
    e1_discharge: 'E Descarga R',
    e2_discharge: 'E Descarga S',
    e3_discharge: 'E Descarga T',

    p_battery: 'Potencia de la batería',
    e_battery: 'Energía de la batería',
    i_battery: 'Corriente de la batería',
    v_battery: 'Tensión de la batería',

    soc: 'SOC',

    flagErr2: 'Regulacion',
    ereact: 'Energia Reactiva Total',
    ereact_q1: 'Energia Reactiva Total Cuadrante Q1',
    ereact_q2: 'Energia Reactiva Total Cuadrante Q2',
    ereact_q3: 'Energia Reactiva Total Cuadrante Q3',
    ereact_q4: 'Energia Reactiva Total Cuadrante Q4',
    ereact_q5: 'Energia Reactiva Total Cuadrante Q5',
    ereact_q6: 'Energia Reactiva Total Cuadrante Q6',
    ereact_q7: 'Energia Reactiva Total Cuadrante Q7',
    ereact_q12: 'Energia Reactiva Total Cuadrantes Q12',
    ereact_q13: 'Energia Reactiva Total Cuadrantes Q13',
    ereact_q23: 'Energia Reactiva Total Cuadrantes Q23',
    ereact_q24: 'Energia Reactiva Total Cuadrantes Q24',
    ereact_q34: 'Energia Reactiva Total Cuadrantes Q34',
    ereact_q41: 'Energia Reactiva Total Cuadrantes Q41',

    ereact1: 'Energia Reactiva Total',
    ereact1_q1: 'Energia Reactiva Total Cuadrante Q1 R',
    ereact1_q2: 'Energia Reactiva Total Cuadrante Q2 R',
    ereact1_q3: 'Energia Reactiva Total Cuadrante Q3 R',
    ereact1_q4: 'Energia Reactiva Total Cuadrante Q4 R',
    ereact1_q5: 'Energia Reactiva Total Cuadrante Q5 R',
    ereact1_q6: 'Energia Reactiva Total Cuadrante Q6 R',
    ereact1_q7: 'Energia Reactiva Total Cuadrante Q7 R',
    ereact1_q12: 'Energia Reactiva Total Cuadrantes Q12 R',
    ereact1_q13: 'Energia Reactiva Total Cuadrantes Q13 R',
    ereact1_q23: 'Energia Reactiva Total Cuadrantes Q23 R',
    ereact1_q24: 'Energia Reactiva Total Cuadrantes Q24 R',
    ereact1_q34: 'Energia Reactiva Total Cuadrantes Q34 R',
    ereact1_q41: 'Energia Reactiva Total Cuadrantes Q41 R',

    ereact2: 'Energia Reactiva Total',
    ereact2_q1: 'Energia Reactiva Total Cuadrante Q1 S',
    ereact2_q2: 'Energia Reactiva Total Cuadrante Q2 S',
    ereact2_q3: 'Energia Reactiva Total Cuadrante Q3 S',
    ereact2_q4: 'Energia Reactiva Total Cuadrante Q4 S',
    ereact2_q5: 'Energia Reactiva Total Cuadrante Q5 S',
    ereact2_q6: 'Energia Reactiva Total Cuadrante Q6 S',
    ereact2_q7: 'Energia Reactiva Total Cuadrante Q7 S',
    ereact2_q12: 'Energia Reactiva Total Cuadrantes Q12 S',
    ereact2_q13: 'Energia Reactiva Total Cuadrantes Q13 S',
    ereact2_q23: 'Energia Reactiva Total Cuadrantes Q23 S',
    ereact2_q24: 'Energia Reactiva Total Cuadrantes Q24 S',
    ereact2_q34: 'Energia Reactiva Total Cuadrantes Q34 S',
    ereact2_q41: 'Energia Reactiva Total Cuadrantes Q41 S',

    ereact3: 'Energia Reactiva Total',
    ereact3_q1: 'Energia Reactiva Total Cuadrante Q1 T',
    ereact3_q2: 'Energia Reactiva Total Cuadrante Q2 T',
    ereact3_q3: 'Energia Reactiva Total Cuadrante Q3 T',
    ereact3_q4: 'Energia Reactiva Total Cuadrante Q4 T',
    ereact3_q5: 'Energia Reactiva Total Cuadrante Q5 T',
    ereact3_q6: 'Energia Reactiva Total Cuadrante Q6 T',
    ereact3_q7: 'Energia Reactiva Total Cuadrante Q7 T',
    ereact3_q12: 'Energia Reactiva Total Cuadrantes Q12 T',
    ereact3_q13: 'Energia Reactiva Total Cuadrantes Q13 T',
    ereact3_q23: 'Energia Reactiva Total Cuadrantes Q23 T',
    ereact3_q24: 'Energia Reactiva Total Cuadrantes Q24 T',
    ereact3_q34: 'Energia Reactiva Total Cuadrantes Q34 T',
    ereact3_q41: 'Energia Reactiva Total Cuadrantes Q41 T',
    modo: 'Modo',

    /*ESCLAVOS*/
    i_inst: 'Corriente Instantanea',
    i_med: 'Corriente',
    i_max: 'Corriente Máxima Detectada',
    i_min: 'Corriente Mínima Detectada',
    id_padre_electrico: 'Padre Elećtrico',
    id_padre: 'Padre Elećtrico',

    type: 'Tipo',
    num_slaves: 'Esclavos',
    activo: 'Activo',

    if: 'Corriente Fundamental',
    if1: 'Corriente Fundamental R',
    if2: 'Corriente Fundamental S',
    if3: 'Corriente Fundamental T',

    // vf: "Tensión Fundamental",
    // vf1: "Tensión Fundamental R",
    // vf2: "Tensión Fundamental S",
    // vf3: "Tensión Fundamental T",

    temperatura: 'Temperatura',
    temp1: 'Temperatura R',
    temp2: 'Temperatura S',
    temp3: 'Temperatura T',

    eapa: 'Energía Aparente',
    eapa1: 'Energía Aparente R',
    eapa2: 'Energía Aparente S',
    eapa3: 'Energía Aparente T',

    papt: 'Potencia Aparente',
    eacons_total: 'Energia Consumida Total',
    eagen_total: 'Energia Generada Total',

    preact: 'Potencia Reactiva',

    thdv: 'Distorsión Armónica Voltaje',
    thdv1: 'Distorsión Armónica Voltaje R',
    thdv2: 'Distorsión Armónica Voltaje S',
    thdv3: 'Distorsión Armónica Voltaje T',

    thdi: 'Distorsión Armónica Corriente',
    thdi1: 'Distorsión Armónica Corriente R',
    thdi2: 'Distorsión Armónica Corriente S',
    thdi3: 'Distorsión Armónica Corriente T',

    thdp: 'Distorsión Armónica VoltajPotenciae',
    thdp1: 'Distorsión Armónica Potencia R',
    thdp2: 'Distorsión Armónica Potencia S',
    thdp3: 'Distorsión Armónica Potencia T',

    tipoCCM: 'ID tipo del dispositivo',
    pf: 'Factor de Potencia',
    eact: 'Energía Activa',

    vab: 'Corriente continua A -> B',
    vbc: 'Corriente continua B -> C',
    vba: 'Corriente continua B -> A',
    vca: 'Corriente continua C -> A',
    va: 'Corriente continua A',
    vb: 'Corriente continua B',
    vc: 'Corriente continua C',

    vac: 'Tensión',
    ine: 'Intensidad de Neutro',
    eact1_q14: 'Energía Activa de Consumo R',
    eact2_q14: 'Energía Activa de Consumo S',
    eact3_q14: 'Energía Activa de Consumo T',
    eact1_q23: 'Energía activa de generación R',
    eact2_q23: 'Energía activa de generación S',
    eact3_q23: 'Energía activa de generación T',

    id_tipo_dispositivo: 'Tipo de dispositivo',

    eact_q14: 'Energía exportada',
    eact_q23: 'Energía importada',

    vtn: 'VTN'
}
