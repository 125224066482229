import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { Grid } from '@mui/material'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha'
import { CSVLink } from 'react-csv'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import { esHoy } from 'utils/Utils'
import { cambiarCabecera } from 'utils/funcionesCsv'
import ConsuptionTable from '../Consumption/GraphConsumption/ConsuptionTable'
import { GraphsLine } from 'components/Graph/GraphsLine'
import { formatLabel } from '../MABT/utils'
import { GraphsBar } from 'components/Graph/GraphsBar'
import { Loader } from 'components/Loader/Loader'
import { moreOneDay } from 'utils/Utils'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import './StylePhotovoltaic.scss'

export const ScadaPhotovoltaicGeneral = ({ devices, idCcMaster, nickProject, data }) => {
    const token = sessionStorage?.token
    const [historicalData, setHistoricalData] = useState()

    const [loading, setLoading] = useState(true)
    const [loadingCurves, setLoadingCurves] = useState(true)
    const [graphData, setGraphData] = useState()
    const [activeTab, setActiveTab] = useState(0)
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [dataSets, setDataSets] = useState([])
    const [eagen, setEagen] = useState([])
    const [labelEnergy, setLabelEnergy] = useState([])
    const [daily, setDaily] = useState('days')
    const [selectDaily, setSelectDaily] = useState('day')
    const namesPact = ['Potencia Total', 'Potencia Activa - L1', 'Potencia Activa - L2', 'Potencia Activa - L3']

    const getSelfConsumption = devices?.[0]?.childrenDevices?.filter((device) => device.id_tipo_dispositivo === 7)

    const fetchHistoricalData = async () => {
        if (start && end) {
            setLoading(true)
            try {
                const res = await fetch(
                    urls.scada +
                        `getHistoricalMain/?idCCMaster=${idCcMaster}&iniDate=${start?.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}&groupDate=${daily}`,
                    { method: 'GET', headers: headersScada(token) }
                )
                if (res.status !== 204) {
                    const json = await res.json()
                    return setHistoricalData(json)
                } else {
                    return []
                }
            } catch (err) {
                console.error('ERROR. API Scada', err)
            } finally {
                setLoading(false)
            }
        }
    }

    const fetchDataGraph = () => {
        let auxToday = start && start?.format('YYYY-MM-DD') === moment()?.format('YYYY-MM-DD') ? true : false
        if (getSelfConsumption) {
            fetch(urls.scada + `chart/getDataChartsCurves/${getSelfConsumption?.[0]?.numserie}?idCCMaster=${idCcMaster}`, {
                method: 'POST',
                redirect: 'follow',
                headers: headersScada(token),
                body: JSON.stringify({
                    iniDate: `${start && start?.format('YYYY-MM-DD')}`,
                    endDate: `${end && end?.format('YYYY-MM-DD')}`,
                    iniTime: `00:00`,
                    endTime: auxToday ? `${moment().format('HH:mm')}` : `23:59`,
                    interval: '5',
                    axis: [
                        {
                            idDeviceType: Number(getSelfConsumption?.[0]?.id_tipo_dispositivo),
                            fields: ['pact'],
                            numseries: [`${getSelfConsumption?.[0]?.numserie}`],
                            unit: 'kWh',
                            plantType: 'produccion'
                        }
                    ]
                })
            })
                .then((res) => res.json())
                .then((data) => {
                    setGraphData(data)
                })
                .catch((err) => {
                    console.error('ERROR. API Scada', err)
                })
                .finally(() => {
                    setLoadingCurves(false)
                })
        }
    }

    const colorPower = ['#078307', '#800080', '#ffa500', '#a52a2a']
    const labels = graphData && graphData?.categories

    const dataGraphs = () => {
        setDataSets([])
        graphData &&
            graphData?.data.forEach((datos, i) => {
                setDataSets((prev) => [
                    ...prev,
                    {
                        label: namesPact[i],
                        data: datos.data,
                        borderColor: colorPower[i],
                        backgroundColor: 'rgb(153, 205, 133)',
                        pointBackgroundColor: colorPower[i],
                        fill: true,
                        id: i + 5,
                        pointRadius: 0,
                        tension: 0.1,
                        numserie: `${datos.numserie}`
                    }
                ])
            })
    }

    const EnergyConsumption = () => {
        setEagen([])
        setLabelEnergy([])
        historicalData?.forEach((dev) => {
            setEagen((prev) => [...prev, dev.eagendia])
            setLabelEnergy((prev) => [...prev, dev.datetime])
        })
    }

    useEffect(() => {
        if (start && end) {
            fetchHistoricalData()
        }
        if (moreOneDay(start, end)) {
            fetchDataGraph()
        }
    }, [start, end, daily])

    useEffect(() => {
        EnergyConsumption()
    }, [historicalData])

    useEffect(() => {
        dataGraphs()
    }, [graphData])

    useEffect(() => {
        if (!moreOneDay(start, end) && start && end) {
            setActiveTab(1)
        }
    }, [start, end])

    return (
        <>
            <Grid container>
                <Grid item xs={12} className='container-report-photo'>
                    <ul className='nav nav-tabs list-general'>
                        <li className='nav-item' selected={true}>
                            <span className='nav-link active list-p'>Informe de producción</span>
                        </li>
                    </ul>
                    <Grid item xs={12} md={12} className='tab-item-wrapper photo-general-selector active new' index={0}>
                        <Grid container className='report-scada-menuconsumo-calendar'>
                            <Grid item xs={12} md={8}>
                                <button
                                    className={activeTab === 0 ? 'button-general-active' : 'button-general'}
                                    disabled={!moreOneDay(start, end)}
                                    selected={activeTab === 0}
                                    onClick={() => setActiveTab(0)}
                                >
                                    Potencia (kW)
                                </button>
                                <button
                                    className={activeTab === 1 ? 'button-general-active' : 'button-general'}
                                    selected={activeTab === 1}
                                    onClick={() => setActiveTab(1)}
                                >
                                    Energía (kWh)
                                </button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} className='report-scada-select-calendar'>
                                <div>
                                    <CalendarComponent
                                        setStart={setStart}
                                        setEnd={setEnd}
                                        maxDate={end}
                                        setDaily={setDaily}
                                        setSelectDaily={setSelectDaily}
                                    />
                                </div>
                                <div>
                                    <SelectorFecha daily={daily} setDaily={setDaily} tipo={daily} selectDaily={selectDaily} />
                                </div>
                                {(data || historicalData) && (
                                    <CSVLink
                                        data={historicalData ? cambiarCabecera(historicalData, false, false, false, esHoy(start)) : []}
                                        separator={';'}
                                        style={{ color: 'white', marginTop: '3px' }}
                                        filename={`${nickProject}._${start ? start?.format('DD-MM-YYYY') : ' '}_${end ? end?.format('DD-MM-YYYY') : ' '}.csv`}
                                    >
                                        <HtmlTooltip title={<span>Descargar datos</span>}>
                                            <IconDownloadFile />
                                        </HtmlTooltip>
                                    </CSVLink>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className='report-scada-select-calendar'></Grid>
                    {!loading ? (
                        <span>
                            {loadingCurves ? (
                                <Loader />
                            ) : (
                                <>
                                    {activeTab === 0 && <GraphsLine data={dataSets} labels={formatLabel(labels)} />}
                                    {activeTab === 1 && <GraphsBar eagen={eagen} labels={labelEnergy} photovoltaic={true} />}
                                </>
                            )}
                            <span className='table-photo-general'>
                                <ConsuptionTable
                                    photovoltaic={true}
                                    energyData={historicalData}
                                    daily={daily}
                                    data={getSelfConsumption?.[0]}
                                    start={start}
                                    hoy={esHoy(start)}
                                />
                            </span>
                        </span>
                    ) : (
                        <Loader />
                    )}
                </Grid>
            </Grid>
        </>
    )
}
