import React, { useContext, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GlobalContext } from 'context/GlobalContext'

export const ToastResponse = () => {
    const { setUpdateDevice } = useContext(GlobalContext)
    const [firstCharge, setFirstCharge] = useState(true)
    const reload = useSelector((state) => state.toast.reload)
    const action = useSelector((state) => state.toast.action)
    const status = useSelector((state) => state.toast.status)
    const message = useSelector((state) => state.toast.message)

    const noteToast = () => {
        if (status && status === 200) {
            toast.success(<p>{message}</p>)
        } else if (status === 400) {
            toast.error(<p>{message}</p>)
        }
        if (reload) {
            setUpdateDevice((current) => !current)
        }
    }

    useEffect(() => {
        if (!firstCharge) {
            noteToast()
        } else {
            setFirstCharge(false)
        }
    }, [action])

    return (
        <Toaster
            position='buttom-right'
            reverseOrder={false}
            toastOptions={{
                className: 'toast-response',
                duration: 3000,
                style: {
                    padding: '16px'
                }
            }}
        />
    )
}
