import * as React from 'react'
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material'

export const OrderSelect = ({ orderBy, setOrderBy }) => {
    const handleChange = (event) => {
        setOrderBy(event.target.value)
    }

    return (
        <FormControl sx={{ m: 0 }} size='small'>
            <InputLabel id='demo-select-small' style={{ color: '#8c8c8c' }}>
                Ordenar
            </InputLabel>
            <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={orderBy}
                defaultValue={orderBy}
                label='orderBy'
                onChange={handleChange}
                className='style-textfield-text'
            >
                <MenuItem value={'nick'}>Nombre</MenuItem>
                <MenuItem value={'alarmCount'}>Alarmas</MenuItem>
            </Select>
        </FormControl>
    )
}
