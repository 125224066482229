const getPlaces = (places, selected) => {
    const markers = []
    places?.forEach((place, i) => {
        if (place?.latitud || place?.longitud) {
            markers.push({
                id: i,
                title: place?.nick,
                lat: parseFloat(place?.latitud),
                lng: parseFloat(place?.longitud),
                ns: place?.numserie,
                selected: selected?.includes(place?.numserie?.toString())
            })
        }
    })

    return markers
}
export default getPlaces
