import React, { useState, useEffect } from 'react'
import { WeatherIcon } from './WeatherIcon'
import { ModalWeather } from './ModalWeather'
import './weather.scss'

export const Weather = ({ master, setWeatherData }) => {
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    const withoutLocation = 'Configura la ubicación'

    const [weather, setWeather] = useState({
        city: undefined,
        temp_current: undefined,
        temp_max: undefined,
        temp_min: undefined,
        precipitation: undefined,
        humidity: undefined,
        wind: undefined,
        current: undefined,
        icon: undefined,
        description: undefined
    })

    const [fullWeather, setFullWeather] = useState()
    const convertCelsius = (temp) => {
        let cel = Math.floor(temp - 273.15)
        return cel
    }

    const getWeather = (lat, lon) => {
        if (lat && lon) {
            const API_KEY = 'e29c2dc6685d7a8a6313b05f5637c260'
            fetch(
                `https://api.openweathermap.org/data/2.5/weather?lat=${parseFloat(lat)}&lon=${parseFloat(lon)}&appid=${API_KEY}&lang=es`,
                {
                    method: 'GET'
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    setFullWeather(data)
                    setWeather({
                        city: data?.name,
                        temp_current: convertCelsius(data?.main?.temp),
                        temp_max: convertCelsius(data?.main?.temp_max),
                        temp_min: convertCelsius(data?.main?.temp_min),
                        humidity: data?.main?.humidity,
                        wind: data?.wind?.speed,
                        current: data?.weather?.[0]?.main,
                        icon: data?.weather?.[0]?.icon,
                        description: data?.weather?.[0]?.description
                    })
                })
                .catch((err) => console.error('ERROR. API Weather', err))
        }
    }

    useEffect(() => {
        getWeather(master?.latitud, master?.longitud)
        if (setWeatherData) {
            setWeatherData(weather)
        }
    }, [master])

    useEffect(() => {
        if (setWeatherData) {
            setWeatherData(weather)
        }
    }, [weather])

    return (
        <div className='weather m-0 p-0' style={{ cursor: 'pointer' }}>
            {weather.city && withoutLocation !== 'Instalación sin localizacion' ? (
                <>
                    <div className='card__info m-0 p-0'></div>
                    <div className='card__weather m-0 p-0' onClick={toggle}>
                        <WeatherIcon weather={weather} size={30} />
                    </div>
                    <ModalWeather weather={weather} fullWeather={fullWeather} modal={modal} toggle={toggle} />
                </>
            ) : null}
        </div>
    )
}

export default Weather
