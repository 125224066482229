import { dispositivos } from 'utils/constant'

export const fieldPower = (deviceType) => {
    switch (deviceType) {
        case dispositivos.ccm2:
            return ['pact1']
        case dispositivos.ccm1:
            return ['pact']
        case dispositivos.enchufe:
            return ['pac']
        default:
            return ['pact', 'pact1', 'pact2', 'pact3']
    }
}

export const fieldIntensity = (deviceType) => {
    switch (deviceType) {
        case dispositivos.ccm2:
            return ['i1']
        case dispositivos.ccm1:
            return ['i_med']
        case dispositivos.enchufe:
            return ['i']
        default:
            return ['i1', 'i2', 'i3']
    }
}

export const fieldTension = (deviceType) => {
    switch (deviceType) {
        case dispositivos.ccm2:
            return ['v1']
        case dispositivos.ccm1:
            return ['vac']
        case dispositivos.enchufe:
            return ['v']
        default:
            return ['v1', 'v2', 'v3']
    }
}
