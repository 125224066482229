import { useEffect, useState } from 'react'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'
import {
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'
import { ButtonModal } from 'components/Buttons/ButtonModal'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { Modal } from 'components/Modal/Modal'
import { SelectStyled } from 'components/Select/SelectStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { StyledTableRow, StyledTableCell, StyledTableCellHead } from 'components/Table/TableStyle'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { apiCentroControl } from 'services/api'
import { IconDeleteAlarm, IconWarning } from 'utils/icon-centrocontrol'
import { urls } from 'utils/constant'
import './MaintenanceMabtModal.scss'

export const MaintenanceMabtModal = ({ open, setOpen, dispositivos }) => {
    const updateOptions = [
        { label: 'Actualizar controladores', value: 1 },
        { label: 'Actualizar medidores', value: 2 }
    ]
    const dispatch = useDispatch()
    const [selectedDevices, setSelectedDevices] = useState(dispositivos)
    const [option, setOption] = useState(updateOptions?.[0]?.value)
    const [filteredDevices, setFilteredDevices] = useState([])
    const [checkedItems, setCheckedItems] = useState({})
    const [allSelected, setAllSelected] = useState(true)
    const [versionTo, setVersionTo] = useState('')
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [versions, setVersions] = useState([])

    const fetchDataFwVersions = async () => {
        try {
            let res = await apiCentroControl.get(`getVersionsFWMABT`)
            if (res.status === 200) {
                setVersions(res.data)
            }
        } catch (err) {
            console.error('ERROR. API centro de control', err)
        }
    }

    const fetchFirmwareForDevices = async () => {
        try {
            const updatedDevices = await Promise.all(
                dispositivos?.map(async (device) => {
                    try {
                        const response = await apiCentroControl(`getFWMABT/${device?.numserie}`)
                        let updatedDevice = { ...device, measurers: [] }
                        if (response.ok) {
                            const firmwareDataList = response.data
                            const measurers = firmwareDataList
                                .filter((fw) => fw?.id_tipo_dispositivo === 21 || fw?.id_tipo_dispositivo === 23)
                                .map((fw) => ({
                                    numserie: fw?.numserie,
                                    tipo_dispostivo: fw?.id_tipo_dispositivo,
                                    measurer_FW: fw?.firmware && fw?.firmware !== '' ? fw?.firmware : null
                                }))
                            const firmwareData = firmwareDataList?.find(
                                (fw) => fw?.numserie === device?.numserie && fw?.id_tipo_dispositivo === 20
                            )
                            updatedDevice = {
                                ...device,
                                controller_FW: firmwareData?.firmware && firmwareData?.firmware !== '' ? firmwareData?.firmware : null,
                                measurers: measurers
                            }
                        }
                        const statusDevice = dispositivos?.find((status) => status?.numserie?.toString() === device?.numserie?.toString())
                        if (statusDevice) {
                            updatedDevice = {
                                ...updatedDevice,
                                status: statusDevice?.status,
                                version: statusDevice?.version
                            }
                        }
                        return updatedDevice
                    } catch (error) {
                        console.error(`Error fetching firmware for device ${device?.numserie}:`, error)
                        return { ...device, controller_FW: null, measurers: [] }
                    }
                })
            )
            setFilteredDevices(updatedDevices)
        } catch (error) {
            console.error('Error fetching firmware data:', error)
        }
    }

    const upgradeFirmware = async () => {
        setLoading(true)
        if (selectedDevices?.length === 0) {
            console.error('No hay dispositivos seleccionados para actualizar.')
            return
        }
        const devicesInfo =
            option === 1
                ? selectedDevices?.map((device) => ({
                      numserie: device?.numserie,
                      FW_actual: device?.controller_FW ? device?.controller_FW : device?.version
                  }))
                : selectedDevices?.map((device) => {
                      const firmwares = device?.measurers
                          .map((measurer) => measurer?.measurer_FW)
                          .filter((fw) => fw !== null && fw !== undefined && fw !== '')
                          .map((fw) => Number(fw.split('.').join('')))
                      const highestFirmwareNumber = firmwares.length > 0 ? Math.max(...firmwares) : null
                      const highestFirmwareString = highestFirmwareNumber ? highestFirmwareNumber.toString().split('').join('.') : null
                      return {
                          numserie: device?.numserie,
                          FW_actual: highestFirmwareString
                      }
                  })
        const allVersionsEqual = devicesInfo?.every((device) => device?.FW_actual === devicesInfo?.[0].FW_actual)
        const requestBody = {
            numserie: selectedDevices.map((dev) => dev?.numserie),
            type: option === 1 ? 'controller' : 'measurer',
            upgrade: versionTo,
            FW_actual: option === 1 && allVersionsEqual ? devicesInfo?.[0]?.FW_actual : null
        }
        try {
            const response = await apiCentroControl.post(`${urls.ccontrol}statusFWMABT/${devicesInfo?.[0]?.numserie}`, requestBody)
            const status = response.status
            if (response?.status === 200) {
                dispatch(createToast({ status, message: 'Petición enviada correctamente', reload: false }))
            } else {
                console.error(`Error en la actualización de firmware:`, response.statusText)
                dispatch(createToast({ status, message: 'Error al enviar la petición', reload: false }))
            }
        } catch (error) {
            console.error(`Error al hacer la petición de actualización de firmware:`, error)
            dispatch(createToast({ status: 400, message: 'Error inesperado al enviar la petición', reload: false }))
        } finally {
            setOpen(false)
            setLoading(false)
        }
    }

    const updatedfilter = (ele) => {
        let filter = false
        const selectedDevice = selectedDevices?.find((dispo) => dispo?.numserie === ele?.numserie)
        if (selectedDevice && selectedDevice?.master_FW === versionTo) {
            handleChange({ target: { name: selectedDevice?.numserie?.toString(), checked: false } })
            filter = true
        }
        return false
    }

    const handleChange = (event) => {
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: event.target.checked
        })
        select(event.target.name, event.target.checked)
    }

    const select = (numserie, estado) => {
        const dispositivo = filteredDevices?.find((ele) => ele?.numserie === Number(numserie))
        if (!dispositivo) {
            return
        }
        if (estado) {
            setSelectedDevices((prevSeleccionados) => [...prevSeleccionados, dispositivo])
        } else {
            setSelectedDevices((prevSeleccionados) => prevSeleccionados?.filter((dispo) => dispo?.numserie !== dispositivo?.numserie))
        }
    }

    const selectAll = () => {
        const newCheckedItems = {}
        filteredDevices?.forEach((dispositivo) => {
            newCheckedItems[dispositivo?.numserie] = allSelected ? dispositivo?.status !== 'Pendiente de actualización' : false
        })
        setCheckedItems(newCheckedItems)
        setSelectedDevices(allSelected ? filteredDevices : [])
    }

    useEffect(() => {
        fetchDataFwVersions()
    }, [])

    useEffect(() => {
        if (option === 1 && versions?.controller?.length > 0) {
            const latestVersion = versions?.controller?.[versions?.controller?.length - 1]?.id
            setVersionTo(latestVersion)
        } else if (option === 2 && versions?.measurer?.length > 0) {
            const latestVersion = versions?.measurer?.[versions?.measurer?.length - 1]?.id
            setVersionTo(latestVersion)
        }
    }, [option, versions])

    useEffect(() => {
        selectAll()
    }, [filteredDevices, allSelected])

    useEffect(() => {
        if (open) {
            fetchFirmwareForDevices()
            setAllSelected(true)
            selectAll()
        }
    }, [open])

    return (
        <Modal
            open={open}
            buttonAction='Siguiente'
            setOpen={setOpen}
            title='Mantenimiento'
            loading={loading}
            footer={
                <Grid container className='mt-4 mb-4'>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='modal-footer'>
                            {activeTab === 0 && (
                                <ButtonModal fullWidth loading={loading} className='button-cancel-modal' onClick={() => setOpen(!open)}>
                                    {window.innerWidth < 1200 ? 'Canc' : 'Cancelar'}
                                </ButtonModal>
                            )}
                            {activeTab !== 0 && (
                                <ButtonModal
                                    fullWidth
                                    loading={loading}
                                    className='button-cancel-modal'
                                    onClick={() => setActiveTab(activeTab - 1)}
                                >
                                    {window.innerWidth < 1200 ? 'Atr' : 'Atrás'}
                                </ButtonModal>
                            )}
                            <div className='div-modal-style'></div>
                            {activeTab === 0 ? (
                                <ButtonModal
                                    fullWidth
                                    loading={loading}
                                    className='button-ok-modal'
                                    onClick={() => setActiveTab(activeTab + 1)}
                                >
                                    {window.innerWidth < 1200 ? 'Sig' : 'Siguiente'}
                                </ButtonModal>
                            ) : activeTab === 2 ? (
                                <ButtonModal
                                    fullWidth
                                    loading={loading}
                                    className='button-ok-modal'
                                    onClick={upgradeFirmware}
                                    disabled={selectedDevices?.length === 0}
                                >
                                    {window.innerWidth < 1200 ? 'Act' : 'Actualizar'}
                                </ButtonModal>
                            ) : (
                                <ButtonModal
                                    fullWidth
                                    loading={loading}
                                    className='button-ok-modal'
                                    disabled={selectedDevices?.length === 0}
                                    onClick={() => setActiveTab(activeTab + 1)}
                                >
                                    {window.innerWidth < 1200 ? 'Sig' : 'Siguiente'}
                                </ButtonModal>
                            )}
                        </div>
                    </Grid>
                </Grid>
            }
        >
            <Grid item xs={12} className='d-flex justify-content-center align-items-center flex-column p-2'>
                {activeTab === 0 ? (
                    <Grid container className='flex px-4'>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <div className='pb-1'>
                                <small className='color-black'>Opciones:</small>
                            </div>
                            <FormControl className='width-100' size='small'>
                                <SelectStyled value={option} onChange={(e) => setOption(e.target.value)}>
                                    {updateOptions?.map((item) => (
                                        <MenuItem key={item?.value} value={item?.value}>
                                            {item?.label}
                                        </MenuItem>
                                    ))}
                                </SelectStyled>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={6} className='pb-1 pl-4'>
                            <div className='pb-1'>
                                <small className='color-black'>Elige versión:</small>
                            </div>
                            <FormControl className='width-100' size='small'>
                                <SelectStyled value={versionTo} onChange={(e) => setVersionTo(e.target.value)} autoWidth>
                                    {option === 1
                                        ? versions?.controller?.map((item, key) => (
                                              <MenuItem key={key} value={item?.id}>
                                                  v.{item?.id}
                                              </MenuItem>
                                          ))
                                        : versions?.measurer?.map((item, key) => (
                                              <MenuItem key={key} value={item?.id}>
                                                  v.{item?.id}
                                              </MenuItem>
                                          ))}
                                </SelectStyled>
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : (
                    <DialogContent className='p-4'>
                        <h3 className='mb-3 font-weight-lighter text-center'>
                            <div>
                                {activeTab === 1 ? 'Selecciona' : 'Revisa'} los dispositivos de los que deseas actualizar los
                                {option === 1 ? ' controladores' : ' medidores'} a la versión:{' '}
                                <span className='version-to-style'>{versionTo}</span>
                            </div>
                        </h3>
                        <TableContainer className='table-container-style'>
                            <Table aria-label='customized table'>
                                <TableHead className='table-head-style'>
                                    <TableRow className='table-row-style'>
                                        <StyledTableCellHead align='center'>
                                            <p className='p-table-style'>Dispositivo</p>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align='center'>
                                            <p className='p-table-style-center'>Nº serie</p>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align='center'>
                                            <p className='p-table-style-center'>Fw</p>
                                        </StyledTableCellHead>
                                        {option === 2 && (
                                            <StyledTableCellHead align='center'>
                                                <p className='p-table-style-center'>Fw Med</p>
                                            </StyledTableCellHead>
                                        )}
                                        <StyledTableCellHead align='center'>
                                            {activeTab === 1 && (
                                                <SwitchStyled
                                                    name='allSelected'
                                                    checked={allSelected}
                                                    className='switch-small'
                                                    onChange={() => setAllSelected(!allSelected)}
                                                />
                                            )}
                                        </StyledTableCellHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activeTab === 1
                                        ? filteredDevices?.map((ele, index) => {
                                              const measurerFWVersions = ele?.measurers
                                                  .map((measurer) => measurer?.measurer_FW)
                                                  .filter((version, index, self) => version && self?.indexOf(version) === index)
                                              const allVersionsAreEqual = measurerFWVersions?.length === 1
                                              const latestVersion = measurerFWVersions?.sort((a, b) =>
                                                  b.localeCompare(a, undefined, { numeric: true })
                                              )[0]
                                              return (
                                                  <StyledTableRow key={index}>
                                                      <StyledTableCell align='center'>
                                                          <p className='p-style-left'>{ele?.nick}</p>
                                                      </StyledTableCell>
                                                      <StyledTableCell align='center'>
                                                          <p className='p-style-center'>{ele?.numserie}</p>
                                                      </StyledTableCell>
                                                      <StyledTableCell align='center'>
                                                          <p className='p-style-center'>
                                                              {ele?.controller_FW ? ele?.controller_FW : ele?.version ? ele?.version : '-'}
                                                          </p>
                                                      </StyledTableCell>
                                                      {option === 2 && (
                                                          <StyledTableCell align='center'>
                                                              <div className={!allVersionsAreEqual ? 'warning-style' : ''}>
                                                                  <span className='p-style-center'>
                                                                      {latestVersion ? latestVersion : '-'}
                                                                  </span>
                                                                  {!allVersionsAreEqual && (
                                                                      <HtmlTooltip title={<span>No todas las versiones son iguales.</span>}>
                                                                          <IconWarning width={15} height={15} />
                                                                      </HtmlTooltip>
                                                                  )}
                                                              </div>
                                                          </StyledTableCell>
                                                      )}
                                                      <StyledTableCell align='center'>
                                                          <FormControlLabel
                                                              style={{ marginLeft: '15px', margin: '5px' }}
                                                              control={
                                                                  <label
                                                                      className='checkbox path checkInstallations checkbox-display-group check-pasos'
                                                                      style={{ stroke: checkedItems?.[ele.numserie] ? '#FF8A1F' : '#fff' }}
                                                                  >
                                                                      {selectedDevices?.length !== 0 ? (
                                                                          updatedfilter(ele) === false ? (
                                                                              <SwitchStyled
                                                                                  name={ele?.numserie}
                                                                                  checked={
                                                                                      ele?.status !== 'Pendiente de actualización'
                                                                                          ? checkedItems?.[ele.numserie]
                                                                                          : false
                                                                                  }
                                                                                  className='switch-small'
                                                                                  onChange={handleChange}
                                                                                  disabled={ele?.status === 'Pendiente de actualización'}
                                                                              />
                                                                          ) : ele?.master_FW !== versionTo ? (
                                                                              <SwitchStyled
                                                                                  name={ele?.numserie}
                                                                                  checked={
                                                                                      ele?.status !== 'Pendiente de actualización'
                                                                                          ? checkedItems?.[ele.numserie]
                                                                                          : false
                                                                                  }
                                                                                  className='switch-small'
                                                                                  onChange={handleChange}
                                                                                  disabled={ele?.status === 'Pendiente de actualización'}
                                                                              />
                                                                          ) : (
                                                                              <p>Actualizado</p>
                                                                          )
                                                                      ) : ele?.master_FW !== versionTo ? (
                                                                          <SwitchStyled
                                                                              name={ele?.numserie}
                                                                              checked={
                                                                                  ele?.status !== 'Pendiente de actualización'
                                                                                      ? checkedItems?.[ele.numserie]
                                                                                      : false
                                                                              }
                                                                              className='switch-small'
                                                                              onChange={handleChange}
                                                                              disabled={ele?.status === 'Pendiente de actualización'}
                                                                          />
                                                                      ) : (
                                                                          <p>Actualizado</p>
                                                                      )}
                                                                  </label>
                                                              }
                                                          />
                                                      </StyledTableCell>
                                                  </StyledTableRow>
                                              )
                                          })
                                        : selectedDevices?.map((ele, index) => {
                                              const measurerFWVersions = ele?.measurers
                                                  ?.map((measurer) => measurer?.measurer_FW)
                                                  ?.filter((version, index, self) => version && self?.indexOf(version) === index)
                                              const allVersionsAreEqual = measurerFWVersions?.length === 1
                                              const latestVersion = measurerFWVersions?.sort((a, b) =>
                                                  b.localeCompare(a, undefined, { numeric: true })
                                              )[0]
                                              return (
                                                  <StyledTableRow key={index}>
                                                      <StyledTableCell align='center'>
                                                          <p className='p-style-left'>{ele?.nick}</p>
                                                      </StyledTableCell>
                                                      <StyledTableCell align='center'>
                                                          <p className='p-style-center'>{ele?.numserie}</p>
                                                      </StyledTableCell>
                                                      <StyledTableCell align='center'>
                                                          <p className='p-style-center'>
                                                              {ele?.CONTROLLER_FW ? ele?.CONTROLLER_FW : ele?.version ? ele?.version : '-'}
                                                          </p>
                                                      </StyledTableCell>
                                                      {option === 2 && (
                                                          <StyledTableCell align='center'>
                                                              <div className={!allVersionsAreEqual ? 'warning-style' : ''}>
                                                                  <span className='p-style-center'>
                                                                      {latestVersion ? latestVersion : '-'}
                                                                  </span>
                                                                  {!allVersionsAreEqual && (
                                                                      <HtmlTooltip title={<span>No todas las versiones son iguales.</span>}>
                                                                          <IconWarning width={15} height={15} />
                                                                      </HtmlTooltip>
                                                                  )}
                                                              </div>
                                                          </StyledTableCell>
                                                      )}
                                                      <StyledTableCell align='center'>
                                                          <ButtonStyledIcon
                                                              className='boton-historical-install'
                                                              title='Eliminar'
                                                              onClick={() =>
                                                                  handleChange({ target: { name: ele?.numserie, checked: false } })
                                                              }
                                                          >
                                                              <IconDeleteAlarm className='boton-historical-hijo' />
                                                          </ButtonStyledIcon>
                                                      </StyledTableCell>
                                                  </StyledTableRow>
                                              )
                                          })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                )}
            </Grid>
        </Modal>
    )
}
