// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-body: Poppins, sans-serif;
  --secondary: #eef7fe;
  --primary: #fff;
  --primary-light: #fff;
}

.weather {
  text-align: center;
  align-items: center;
  border-radius: 30px;
  padding: 32px;
  color: #fff;
  color: var(--primary);
}
.weather__info__place {
  font-size: 14px;
  color: #fff;
  color: var(--primary-light);
}
.weather__info__time {
  font-size: 30px;
  color: #fff;
  color: var(--primary);
}
.weather__info__date {
  font-size: 14px;
  color: #fff;
  color: var(--primary-light);
}
.weather__weather {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 12px;
}
.weather__weather__temp {
  font-size: 30px;
}
.weather__weather__icon {
  animation: move 0.1s infinite alternate ease-in-out;
}

@keyframes move {
  to {
    transform: translateY(-10px);
  }
}`, "",{"version":3,"sources":["webpack://./src/views/Weather/weather.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,oBAAA;EACA,eAAA;EACA,qBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;EAAA,qBAAA;AACJ;AAEQ;EACI,eAAA;EACA,WAAA;EAAA,2BAAA;AAAZ;AAGQ;EACI,eAAA;EACA,WAAA;EAAA,qBAAA;AADZ;AAIQ;EACI,eAAA;EACA,WAAA;EAAA,2BAAA;AAFZ;AAMI;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAJR;AAMQ;EACI,eAAA;AAJZ;AAOQ;EACI,mDAAA;AALZ;;AAUA;EACI;IACI,4BAAA;EAPN;AACF","sourcesContent":[":root {\n    --font-body: Poppins, sans-serif;\n    --secondary: #eef7fe;\n    --primary: #fff;\n    --primary-light: #fff;\n}\n\n.weather {\n    text-align: center;\n    align-items: center;\n    border-radius: 30px;\n    padding: 32px;\n    color: var(--primary);\n\n    &__info {\n        &__place {\n            font-size: 14px;\n            color: var(--primary-light);\n        }\n\n        &__time {\n            font-size: 30px;\n            color: var(--primary);\n        }\n\n        &__date {\n            font-size: 14px;\n            color: var(--primary-light);\n        }\n    }\n\n    &__weather {\n        margin-left: auto;\n        display: flex;\n        align-items: center;\n        gap: 12px;\n\n        &__temp {\n            font-size: 30px;\n        }\n\n        &__icon {\n            animation: move 0.1s infinite alternate ease-in-out;\n        }\n    }\n}\n\n@keyframes move {\n    to {\n        transform: translateY(-10px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
