import React from 'react'
import { Grid } from '@mui/material'
import './ChangeLogStyled.scss'

export const ChangeLog = () => {
    // <span className="uk-badge uk-badge-warning uk-text-uppercase">Changed</span>
    // <span className="uk-badge uk-badge-danger uk-text-uppercase">Fixed</span>
    // <span className="uk-badge uk-text-uppercase uk-badge-new">New</span>
    // <span className="uk-badge uk-badge-success uk-text-uppercase">Added</span>

    return (
        <div className='container-changelog'>
            <h1 className='uk-text-center uk-margin-large-bottom uk-title'>Changelog</h1>
            <Grid item xs={12} className='container-changelog-change'>
                <div className='tm-timeline-item'>
                    <h2>
                        1.10.1 <span className='uk-text-muted'>(25 de Octubre de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-success uk-text-uppercase'>Added</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Nueva vista de instalación para contadores</p>
                                <p>Nueva vista de Scada para contadores</p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-warning uk-text-uppercase'>Changed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Nuevo diseño de gráficas para Scada Autoconsumo</p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-danger uk-text-uppercase'>Fixed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Arreglo para poder cambiarle la potencia contratada y el nombre a los hijos de dispositivos sueltos</p>
                                <p>
                                    Corrección de energía consumida, tensión y potencia en Scada consumo para un caso concreto de
                                    dispositivo e instalación
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tm-timeline-item'>
                    <h2>
                        1.10.0 <span className='uk-text-muted'>(17 de Octubre de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-warning uk-text-uppercase'>Changed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Nueva vista de configuración de instalación y dispositivos</p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-danger uk-text-uppercase'>Fixed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Poder introducir puntos en el nick de la instalación</p>
                                <p>Colocar por defecto el permiso de instalador al compartir dispositivo</p>
                                <p>Gestión de pulso en el mapa de la bombilla en iluminación pública</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tm-timeline-item'>
                    <h2>
                        1.9.1 <span className='uk-text-muted'>(17 de Septiembre de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-success uk-text-uppercase'>Added</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Nueva vista de EMS en centro de control</p>
                                <p>Nueva vista de EMS en scada</p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-warning uk-text-uppercase'>Changed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>
                                    Añadidas mejoras en Riel para una mejor funcionalidad (identificación de dispositivos y posicionamiento)
                                </p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-danger uk-text-uppercase'>Fixed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Arreglado el buscandor de instalación de centro de control</p>
                                <p>Arreglado error de carga infinita en gráficas de medidor de MABT</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tm-timeline-item'>
                    <h2>
                        1.9.0 <span className='uk-text-muted'>(5 de Septiembre de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-warning uk-text-uppercase'>Changed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Mejora de carga en la pantalla de perfiles</p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-danger uk-text-uppercase'>Fixed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Habilitada la carga en ceder y compartir</p>
                                <p>Habilitados los modales de respuesta en cambiar contraseña y verificar usuario</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tm-timeline-item'>
                    <h2>
                        1.8.0 <span className='uk-text-muted'>(14 de Agosto de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-warning uk-text-uppercase'>Changed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Cambio de calendario con el fin de mejorar la velocidad de respuesta</p>
                                <p>Cambio en las gráficas de autoconsumo y fotovoltaico</p>
                                <p>
                                    Cambio en la visualización del la configuración de instalación para permitir más dispositivos y más
                                    opciones de configuración
                                </p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-success uk-text-uppercase'>Added</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Añadida la opción de mover más de un dispositivo a la vez</p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-text-uppercase uk-badge-new'>New</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>Mapa interactivo con una geolocalización más precisa</p>
                            <p>Opción de apagar y encender los masters desde el mapa</p>
                            <p>Nueva funcionalidad de EMS para poder controlar las diferentes instalaciones</p>
                        </div>
                    </div>
                </div>
                <div className='tm-timeline-item'>
                    <h2>
                        1.7.1 <span className='uk-text-muted'>(12 de Julio de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-danger uk-text-uppercase'>Fixed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Mejorada la velocidad de carga en centro de control alarmas</p>
                                <p>Mejorada la velocidad de carga en centro de control configuración</p>
                                <p>Corregido error diseño grafica tensión en scada consumo</p>
                                <p>Corregido error al mostrar alarmas en las tarjetas en centro de control</p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-text-uppercase uk-badge-new'>New</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>Añadida la recarga de alarmas en el arbol cada 10 segundos</p>
                            <p>Añadido la actualización de la web manteniendo el arbol seleccionado</p>
                        </div>
                    </div>
                </div>
                <div className='tm-timeline-item'>
                    <h2>
                        1.7.0 <span className='uk-text-muted'>(4 de Julio de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry mb-5'>
                            <div className='tm-timeline-entry-label'>
                                <span className='uk-badge uk-badge-danger uk-text-uppercase'>Fixed</span>
                            </div>
                            <div className='tm-timeline-entry-data'>
                                <p>Mejorada la velocidad de carga en centro de control arbol de dispositivos</p>
                                <p>Corregido error que no mostraba valores correctos en la grafica de energia en scada consumo </p>
                            </div>
                        </div>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-text-uppercase uk-badge-new'>New</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>Añadida la funcionalidad de descargar en PDF la programación de los calendarios</p>
                            <p>Añadido la posibilidad de configurar el riel y su orden en scada</p>
                        </div>
                    </div>
                </div>
                <div className='tm-timeline-item'>
                    <h2>
                        1.6.1 <span className='uk-text-muted'>(24 de Junio de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-badge-danger uk-text-uppercase'>Fixed</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>
                                Corregido el error que no mostraba las correctamente las mediciones de autoconsumo en producción en los ccm
                            </p>
                            <p>Corregido el error que no mostraba correctamente la barra de potencia en las card en centro de control</p>
                            <p>Corregido el error que en las cards de centro de control no salía valor cuando el valor era 0</p>
                            <p>Corregidos los errores que imposibilitaban actualizar un máster</p>
                        </div>
                    </div>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-text-uppercase uk-badge-new'>New</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>Añadida las nuevas alarmas de tramo y de contactor.</p>
                            <p>Añadido un riel para facilitar la configuración del cuadro en scada de consumo.</p>
                            <p>Añadido el kit 1 y el kit 2 como posibles configuración de equipos.</p>
                            <p>Añadido un histórico de acciones para salidas digitales.</p>
                            <p>Añadido el progreso de actualización del master en la tabla de configuración de dispositivos.</p>
                        </div>
                    </div>
                </div>
                <div className='tm-timeline-item'>
                    <h2>
                        1.6.0 <span className='uk-text-muted'>(3 de Junio de 2024)</span>
                    </h2>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-badge-danger uk-text-uppercase'>Fixed</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>Corregido el error que no permitía subir correctamente logos en los proyectos.</p>
                            <p>Corregido el error que no mostraba correctamente la intensidad de magneto en los dispositivos ccm1.</p>
                            <p>
                                Corregido el error que no te mostraba datos en la gráfica cuando los datos eran null, se ha sustituido por
                                un 0 en el soc de las baterías.
                            </p>
                            <p>Se muestra correctamente la barra de potencia en los CCM4.</p>
                            <p>Mostrar correctamente la tensión cuando su valor es igual a 0.</p>
                        </div>
                    </div>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-badge-warning uk-text-uppercase'>Changed</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>
                                Cambiado el tiempo de espera a 15 segundos cuando han habido demasiados intentos incorrectos de loggearse.
                            </p>
                            <p>Eliminada la caducidad de la contraseña de los usuarios.</p>
                        </div>
                    </div>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-badge-success uk-text-uppercase'>Added</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>Se han agregado la funcionalidad de prioridad a las programaciones en el master.</p>
                            <p>Se han agregado la funcionalidad de eliminar en masa programaciones en el máster.</p>
                            <p>Añadida la hora en las card de los dispositivos ccm3.</p>
                        </div>
                    </div>
                    <div className='tm-timeline-entry'>
                        <div className='tm-timeline-entry-label'>
                            <span className='uk-badge uk-text-uppercase uk-badge-new'>New</span>
                        </div>
                        <div className='tm-timeline-entry-data'>
                            <p>Añadido el campo de ID de Telegram al perfil del usuario</p>
                            <p>Añadida la posibilidad de notificar por Telegram a los usuarios de las alarmas que estén activas </p>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}
