import React from 'react'
import { FormControl, MenuItem, Select } from '@mui/material'

export const SelectorFecha = ({ daily, setDaily, setKeySwitch, selectDaily }) => {
    return (
        <FormControl sx={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} size='small'>
            <Select
                className='tamano-select'
                style={{ height: '2.4em', maxWidth: '10em' }}
                id='select-agrupacion-fecha'
                value={daily}
                onChange={(e) => {
                    setDaily(e.target.value)
                    setKeySwitch(Date.now())
                }}
            >
                {selectDaily === 'day' && <MenuItem value='hours'>Horas</MenuItem>}
                {(selectDaily === 'day' || selectDaily === 'month') && <MenuItem value='days'>Días</MenuItem>}
                {(selectDaily === 'month' || selectDaily === 'year') && <MenuItem value='months'>Meses</MenuItem>}
                {selectDaily === 'year' && <MenuItem value='years'>Años</MenuItem>}
            </Select>
        </FormControl>
    )
}
