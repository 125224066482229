// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-center {
  text-align: center;
}

.div-modal-style {
  width: 2em;
}

.version-to-style {
  font-size: 15px;
  font-weight: bold;
}

.table-container-style {
  max-height: 30rem;
  border: 1px solid #e0e0e0;
}

.table-head-style {
  border-bottom: 2px solid black;
  border-radius: 50px;
}

.table-row-style {
  padding: 0px;
  margin: 0px;
}

.p-table-style {
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.p-table-style-center {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.p-style-left {
  width: 100%;
  text-align: left;
}

.p-style-center {
  width: 100%;
  text-align: center;
}

.warning-style {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}`, "",{"version":3,"sources":["webpack://./src/views/Configuracion/Installation/ModalMaintance/MaintenanceMabtModal.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;AACF","sourcesContent":[".text-center {\n  text-align: center;\n}\n\n.div-modal-style {\n  width: 2em;\n}\n\n.version-to-style {\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.table-container-style {\n  max-height: 30rem;\n  border: 1px solid #e0e0e0;\n}\n\n.table-head-style {\n  border-bottom: 2px solid black;\n  border-radius: 50px;\n}\n\n.table-row-style {\n  padding: 0px;\n  margin: 0px;\n}\n\n.p-table-style {\n  width: 100%;\n  text-align: left;\n  font-weight: bold;\n}\n\n.p-table-style-center {\n  width: 100%;\n  text-align: center;\n  font-weight: bold;\n}\n\n.p-style-left {\n  width: 100%;\n  text-align: left;\n}\n\n.p-style-center {\n  width: 100%;\n  text-align: center;\n}\n\n.warning-style {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
