import { createContext, useState } from 'react'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
    const [projectsCompleto, setProjectsCompleto] = useState([])
    const [updateDevice, setUpdateDevice] = useState(false)
    const [actualProject, setActualProject] = useState({})
    const [refreshApp, setRefreshApp] = useState(false)
    const [loadingApp, setLoadingApp] = useState(false)

    return (
        <GlobalContext.Provider
            value={{
                updateDevice,
                setUpdateDevice,
                projectsCompleto,
                setProjectsCompleto,
                actualProject,
                setActualProject,
                refreshApp,
                setRefreshApp,
                loadingApp,
                setLoadingApp
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}

export const UserContext = createContext()

export const FilterContext = createContext()
