import React, { useState } from 'react'
import { Button, Menu } from '@mui/material'
import { PermissionsForMenuGroup } from 'views/Menu/PermissionsforMenu.jsx'
import { IconMenu } from 'utils/icon-centrocontrol'

export const DropdownGroup = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    return (
        <>
            {props?.groups?.permissions !== 'user' && (
                <>
                    <Button
                        className='btn-square'
                        onClick={handleClick}
                        style={{ backgroundColor: 'transparent', border: '0px solid transparent' }}
                    >
                        <span>{props?.showInstalations ? <IconMenu color='white' /> : <IconMenu />}</span>
                    </Button>
                    <Menu
                        id='simple-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        classes={{ list: 'p-0' }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <PermissionsForMenuGroup
                            setResultSearch={props?.setResultSearch}
                            setProject={props?.setProject}
                            group={props?.groups}
                            project={props?.projects}
                            permissions={props?.groups?.permissions}
                            handleClose={handleClose}
                            loading={props?.loading}
                            groupId={props?.groups?.id_group}
                        />
                    </Menu>
                </>
            )}
        </>
    )
}
