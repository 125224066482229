import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'
import { Table } from 'reactstrap'
import { Grid, TextField, MenuItem, FormControl } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { StyledTableCell, StyledTableCellHead } from 'components/Table/TableStyle'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { SelectStyled } from 'components/Select/SelectStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { StyledTableRow } from 'components/Table/TableStyle'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { NameMode, resultCalendar, getCurrentDate } from 'utils/Utils'
import { IconNewObject, IconWarning } from 'utils/icon-centrocontrol'
import { urls } from 'utils/constant'
import { headersScada } from 'services/headers'
import './StyleModal.scss'

export const ModalAddCalendarCcMaster = ({
    masterCount,
    data,
    masterAll,
    idCcMaster,
    open1,
    setOpen1,
    relay,
    setRelay,
    centroControl,
    setUpdateCalendar
}) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [enable, setEnable] = useState(true)
    const [time, setTime] = useState()
    const [idOutPut, setIdOutPut] = useState(1)
    const [internal, setInternal] = useState(1)
    const [sunrise, setSunrise] = useState(false)
    const [sunset, setSunset] = useState(false)
    const [selectWeekly, setSelectWeekly] = useState(true)
    const [selectDate, setSelectDate] = useState(false)
    const [offset, setOffset] = useState(0)
    const [error, setError] = useState()
    const [date, setDate] = useState()
    const [dateIni, setDateIni] = useState()
    const [dateFinal, setDateFinal] = useState()
    const [onOff, setOnOff] = useState(true)
    const [body, setBody] = useState([])
    const [bodyPriority, setBodyPriority] = useState([])
    const [master, setMaster] = useState([])
    const [masterPriority, setMasterPriority] = useState([])
    const [internalState, setInternalState] = useState()
    const [priority, setPriority] = useState(false)
    const [total, setTotal] = useState(0)
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [errorFecha, setErrorFecha] = useState(false)
    const result = resultCalendar(masterAll, data)
    const isMobile = useMediaQuery({ maxWidth: 789 })
    const intervalo = Array.from({ length: 119 }, (_, index) => index - 59)
    const validateForm = () =>
        idOutPut > -1 && internal > -1 && (time || sunset || sunrise) && ((date && selectDate) || (total > 0 && selectWeekly))

    const internal_calendar = [
        {
            value: 1,
            label: 'Normal'
        },
        {
            value: 2,
            label: 'Pulso'
        }
    ]

    const output = [
        {
            ID_entry: 1,
            name: 'Salida 1'
        },
        {
            ID_entry: 2,
            name: 'Salida 2'
        }
    ]

    const initialWeek = [
        { id: 0, label: 'Lunes', valor: 1, checked: false },
        { id: 1, label: 'Martes', valor: 2, checked: false },
        { id: 2, label: 'Miércoles', valor: 4, checked: false },
        { id: 3, label: 'Jueves', valor: 8, checked: false },
        { id: 4, label: 'Viernes', valor: 16, checked: false },
        { id: 5, label: 'Sábado', valor: 32, checked: false },
        { id: 6, label: 'Domingo', valor: 64, checked: false },
        { id: 7, label: 'Todos', valor: 0, checked: false }
    ]

    ////////SUMA CHECKBOX
    const [dataWeek, setDataWeek] = useState(initialWeek)

    //////ADD Calendar
    const AddNewCalendar = (calendar) => {
        let status
        if (calendar.SN_device) setLoadingAdd(true)
        fetch(urls.scada + `calendar`, { method: 'POST', headers: headersScada(token), body: JSON.stringify(calendar) })
            .then((res) => {
                status = res.status
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: false }))
            })
            .catch((err) => console.error('ERROR. API Scada', err))
            .finally(() => {
                setOpen1(false)
                setLoadingAdd(false)
                setUpdateCalendar((current) => !current)
            })
    }

    const handleCheckboxChange = (index) => {
        let newData
        if (index === 7) {
            // Marcar o desmarcar todos los elementos dependiendo del estado del ítem 7
            const isChecked = dataWeek.find((item) => item.id === index)?.checked || false
            newData = dataWeek.map((item) => ({ ...item, checked: !isChecked }))
        } else {
            // Actualizar el estado normalmente
            newData = dataWeek.map((item) => (item.id === index ? { ...item, checked: !item.checked } : item))

            // Si se desmarca algún ítem del 0 al 6, desmarcar el ítem 7
            const isAnyUnchecked = newData.some((item) => item.id !== 7 && !item.checked)
            newData = newData.map((item) => (item.id === 7 ? { ...item, checked: !isAnyUnchecked } : item))
        }
        // Actualizar el estado final
        setDataWeek(newData)
        // Calcular el total sumando los valores de los elementos seleccionados
        const selectedItems = newData.filter((item) => item.checked)
        const sum = selectedItems.reduce((acc, item) => acc + item.valor, 0)
        setTotal(sum)
    }

    const handleWeekly = () => {
        setSelectWeekly(!selectWeekly)
        setSelectDate(!selectDate)
    }

    const handleDateMode = () => {
        setSelectDate(!selectDate)
        setSelectWeekly(!selectWeekly)
    }

    const handleSunrise = () => {
        setSunrise(!sunrise)
        setOffset(0)
        if (sunset) {
            setSunset(false)
        }
    }

    const handleSunset = () => {
        setSunset(!sunset)
        setOffset(0)
        if (sunrise) {
            setSunrise(false)
        }
    }

    const handleEnable = () => {
        setEnable((current) => !current)
    }

    const handleModeOnOff = () => {
        if (internal === 1 && onOff) {
            return setInternalState(100)
        } else if (internal === 1 && !onOff) {
            return setInternalState(103)
        } else if (internal === 2 && onOff) {
            return setInternalState(101)
        } else if (internal === 2 && !onOff) {
            return setInternalState(102)
        }
    }

    // Generar opciones de -59 a 59 minutos
    const handleCheckboxMaster = (id) => {
        const isSelected = master.includes(id)
        if (isSelected) {
            setMaster(master.filter((item) => item !== id))
        } else {
            setMaster([...master, id])
        }
    }

    const removePriorityNumbers = () => {
        if (master?.length > 0) {
            const updatedMaster = master?.filter((number) => !masterPriority?.includes(number))
            return updatedMaster
        }
    }

    const handleCheckboxPriority = (id) => {
        const isSelected = masterPriority.includes(id)
        if (isSelected) {
            setMasterPriority(masterPriority.filter((item) => item !== id))
        } else {
            setMasterPriority([...masterPriority, id])
        }
    }

    const fetchRelay = (idCcMaster) => {
        fetch(urls.scada + `calendarRelay/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'GET', headers: headersScada(token) })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                if (data.length === 0) {
                    setRelay(output)
                } else {
                    setRelay(data)
                }
            })
            .catch((err) => console.error('ERROR. API Scada', err))
    }

    const modificarNumero = (numero) => {
        switch (numero) {
            case 3:
                return 4
            case 4:
                return 8
            case 5:
                return 16
            case 6:
                return 32
            case 7:
                return 64
            default:
                return numero
        }
    }

    const handleDateIni = (value) => {
        setDateIni(value)
        if (value > dateFinal && dateFinal !== '' && value !== '') {
            setErrorFecha(true)
        } else {
            setErrorFecha(false)
        }
    }

    const handleDateFinal = (value) => {
        setDateFinal(value)
        if (value < dateIni && dateIni !== '' && value !== '') {
            setErrorFecha(true)
        } else {
            setErrorFecha(false)
        }
    }

    const checkDay = (week, day) => {
        if (selectDate) {
            const dateNew = new Date(date)
            return (week & modificarNumero(dateNew?.getDay())) !== 0
        } else {
            const dateNew = new Date(day)
            return day ? total & modificarNumero(dateNew?.getDay()) : (week & total) !== 0
        }
    }

    const DuplicatedPriority = (id) => {
        if (data) {
            const hour = time ? parseFloat(time?.split(':')[0]) : 0
            const min = time ? parseFloat(time?.split(':')[1]) : 0
            const snDeviceFound = data?.filter((entry) => entry?.SN_device?.[0] === id || entry?.SN_device === id)
            const isSelected = centroControl ? masterPriority?.includes(id) : priority
            const duplicated = snDeviceFound.find(
                (calendar) =>
                    data?.ID_entry !== calendar?.ID_entry &&
                    calendar?.ID_output === idOutPut - 1 &&
                    calendar?.priority === isSelected &&
                    ((calendar?.hour === hour && calendar?.min === min && calendar?.sunrise === false && calendar?.sunset === false) ||
                        (calendar?.hour === 26 && calendar?.sunrise === true && Number(calendar?.min_offset) === Number(offset)) ||
                        (calendar?.hour === 27 && calendar?.sunset === true && Number(calendar?.min_offset) === Number(offset))) &&
                    ((calendar?.sunset === false &&
                        !sunset &&
                        calendar?.sunrise === false &&
                        !sunrise &&
                        calendar?.min_offset === 0 &&
                        offset === 0) ||
                        (calendar?.sunset === true &&
                            sunset &&
                            calendar?.sunrise === false &&
                            !sunrise &&
                            Number(calendar?.min_offset) === Number(offset)) ||
                        (calendar?.sunset === false &&
                            !sunset &&
                            calendar?.sunrise === true &&
                            sunrise &&
                            Number(calendar?.min_offset) === Number(offset))) &&
                    (checkDay(calendar?.week_day, calendar?.day) ||
                        (calendar?.day === date && selectDate && date !== '' && date !== undefined))
            )
            if (duplicated) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const handleAddCalendarCC = async () => {
        if (removePriorityNumbers(master)?.length > 0) {
            AddNewCalendar(body)
        }
        if (masterPriority.length > 0) {
            AddNewCalendar(bodyPriority)
        }
    }

    const handleOpen = () => {
        setMaster(centroControl ? [] : [idCcMaster.toString()])
        setMasterPriority([])
        setPriority(false)
        setBody([])
        setBodyPriority([])
        setOnOff(true)
        setDate()
        setSelectDate(false)
        setSelectWeekly(true)
        setSunset(false)
        setSunrise(false)
        setInternal(1)
        setIdOutPut(1)
        setTime()
        setEnable(true)
        setTotal(0)
        setDataWeek(initialWeek)
        setOpen1(!open1)
    }

    const PriorityMaster = () => {
        let disabled = false
        master?.forEach((device) => {
            if (DuplicatedPriority(device)) {
                disabled = true
            }
        })
        return disabled
    }

    function checkSerialNumber(serialNumber) {
        return masterCount[serialNumber] >= 12
    }

    useEffect(() => {
        if (priority) {
            setMasterPriority([...masterPriority, idCcMaster.toString()])
        } else {
            setMasterPriority(masterPriority.filter((item) => item !== idCcMaster?.toString()))
        }
    }, [priority])

    useEffect(() => {
        let bodyForm = {
            state: 1,
            enable: enable,
            week_day: selectWeekly ? total : 0,
            type_date: selectDate ? 1 : 0,
            start_date: selectWeekly ? dateIni : '',
            end_date: selectWeekly ? dateFinal : '',
            day: selectDate ? date : '',
            min_offset: Number(offset ? offset : 0),
            hour: time ? parseFloat(time?.split(':')[0]) : null,
            min: time ? parseFloat(time?.split(':')[1]) : null,
            internal_mode: parseFloat(internalState),
            ID_output: idOutPut - 1,
            sunrise: sunrise,
            sunset: sunset,
            priority: false
        }
        if (master.length > 0) {
            bodyForm.SN_device = removePriorityNumbers(master)
            setBody(bodyForm)
        }
        if (masterPriority.length > 0) {
            let bodyFormPriority = { ...bodyForm }
            bodyFormPriority.SN_device = masterPriority
            bodyFormPriority.priority = true
            setBodyPriority(bodyFormPriority)
        }
        setError(DuplicatedPriority(idCcMaster))
    }, [
        master,
        masterPriority,
        enable,
        total,
        date,
        dateIni,
        dateFinal,
        selectDate,
        time,
        offset,
        internal,
        idOutPut,
        sunrise,
        sunset,
        internalState
    ])

    useEffect(() => {
        if (master && master?.length === 1 && centroControl) {
            fetchRelay(master)
        } else {
            setRelay(output)
        }
    }, [master])

    useEffect(() => {
        handleModeOnOff()
    }, [internal, onOff])

    return (
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <span
                className='button-add-calendar-digital'
                title={data?.lenght > 11 && !centroControl && 'No están permitidas más de 12 programaciones'}
            >
                <ButtonStyled
                    disabled={(!centroControl && data?.length >= 12) || result === 0}
                    variant='contained'
                    className='button-with-text'
                    type='submit'
                    onClick={() => handleOpen()}
                >
                    <IconNewObject className='icon-hover-with-text' />
                    Nueva programación
                </ButtonStyled>
            </span>
            <Modal
                disabled={errorFecha || PriorityMaster() || !validateForm() || loadingAdd || (master.length <= 0 && centroControl)}
                open={open1}
                setOpen={setOpen1}
                functSave={handleAddCalendarCC}
                title='Añadir nueva programación'
                loading={loadingAdd}
                maxWidth='xl'
                onClick={(e) => e.stopPropagation()}
            >
                <Grid items xs={12} className='d-flex justify-content-around align-items-start ml-2'>
                    {centroControl && (
                        <Grid items xs={3}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCellHead align='center'>Dispositivo</StyledTableCellHead>
                                            <StyledTableCellHead align='center'>Seleccionar</StyledTableCellHead>
                                            <StyledTableCellHead align='center'>Prioritario</StyledTableCellHead>
                                            <StyledTableCellHead align='center'></StyledTableCellHead>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {masterAll &&
                                            masterAll?.map((instal) => {
                                                return (
                                                    <StyledTableRow>
                                                        <StyledTableCell>
                                                            <div className='flex justify-center gap-2'>
                                                                {instal && instal?.nick?.length > 20 ? (
                                                                    <HtmlTooltip title={<span> {instal?.nick} </span>}>
                                                                        <p className='mb-0'>{instal?.nick?.substr(0, 19) + '...'}</p>
                                                                    </HtmlTooltip>
                                                                ) : (
                                                                    <p className='mb-0'>{instal?.nick}</p>
                                                                )}
                                                                {checkSerialNumber(instal?.numserie) && (
                                                                    <HtmlTooltip
                                                                        title={<span>Máximo de programaciones configuradas.</span>}
                                                                    >
                                                                        <IconWarning width={20} height={20} />
                                                                    </HtmlTooltip>
                                                                )}
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TextField
                                                                type='checkbox'
                                                                className='checkAddCalendar'
                                                                disabled={checkSerialNumber(instal?.numserie)}
                                                                onChange={() => {
                                                                    handleCheckboxMaster(instal?.numserie?.toString())
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <TextField
                                                                type='checkbox'
                                                                className='checkAddCalendar'
                                                                disabled={checkSerialNumber(instal?.numserie)}
                                                                onChange={() => {
                                                                    handleCheckboxPriority(instal?.numserie?.toString())
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {master.includes(instal?.numserie?.toString()) &&
                                                                DuplicatedPriority(instal?.numserie?.toString()) && (
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <span>
                                                                                {' '}
                                                                                {instal?.nick} tiene una programación con la misma prioridad
                                                                            </span>
                                                                        }
                                                                    >
                                                                        <IconWarning width='24px' height='24px' />
                                                                    </HtmlTooltip>
                                                                )}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                    <Grid items xs={centroControl ? 9 : 12}>
                        <div className='modal-create-new-calendar'>
                            <Grid container items xs={12} className='d-flex justify-content-around align-items-start'>
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    lg={1}
                                    xl={1}
                                    className='d-flex align-items-center justify-content-center flex-column'
                                >
                                    <p className='h3-switch-add-calendar' style={{ marginButton: '15px !important' }}>
                                        Activo
                                    </p>
                                    <SwitchStyled
                                        id='habilitado'
                                        className='switch-small'
                                        checked={enable}
                                        onClick={() => handleEnable()}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={2}
                                    xl={2}
                                    className='d-flex align-items-center justify-content-center flex-column'
                                >
                                    <FormControl className='width-100' size='small'>
                                        <p className='font-weight-lighter mb-0'>Seleccione una Salida:</p>
                                        <SelectStyled
                                            fullWidth
                                            size='small'
                                            select
                                            value={idOutPut}
                                            onChange={(e) => setIdOutPut(e.target.value)}
                                            variant='outlined'
                                        >
                                            {relay?.map((option, i) => (
                                                <MenuItem key={i} value={option.ID_entry}>
                                                    {option.name} - Relay {option.ID_entry}
                                                </MenuItem>
                                            ))}
                                        </SelectStyled>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    md={8}
                                    lg={2}
                                    xl={2}
                                    className='d-flex align-items-center justify-content-center flex-column'
                                >
                                    <FormControl className='width-100' size='small'>
                                        <p className='font-weight-lighter mb-0'>Seleccione un modo:</p>
                                        <SelectStyled
                                            fullWidth
                                            size='small'
                                            select
                                            defaultValue={NameMode(data?.internal_mode)}
                                            value={internal}
                                            onChange={(e) => setInternal(e.target.value)}
                                            variant='outlined'
                                        >
                                            {internal_calendar?.map((option, i) => (
                                                <MenuItem key={i} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </SelectStyled>
                                    </FormControl>
                                </Grid>
                                {internal === 1 && (
                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        lg={2}
                                        xl={2}
                                        className='d-flex align-items-center justify-content-center flex-column'
                                    >
                                        <p className='h3-switch-add-calendar' style={{ marginButton: '15px !important' }}>
                                            Apagado / Encendido
                                        </p>
                                        <SwitchStyled
                                            id='habilitado'
                                            className='switch-small'
                                            checked={onOff}
                                            onClick={() => {
                                                setOnOff(!onOff)
                                            }}
                                        />
                                    </Grid>
                                )}
                                {internal === 2 && (
                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        lg={2}
                                        xl={2}
                                        className='d-flex align-items-center justify-content-center flex-column'
                                    >
                                        <p className='h3-switch-add-calendar' style={{ marginButton: '15px !important' }}>
                                            Pulso apagado
                                        </p>
                                        <SwitchStyled
                                            id='habilitado'
                                            className='switch-small'
                                            checked={!onOff}
                                            onClick={() => {
                                                setOnOff(!onOff)
                                            }}
                                        />
                                        <p className='h3-switch-add-calendar' style={{ marginButton: '15px !important' }}>
                                            Pulso encendido
                                        </p>
                                        <SwitchStyled
                                            id='habilitado'
                                            className='switch-small'
                                            checked={onOff}
                                            onClick={() => {
                                                setOnOff(!onOff)
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    lg={1}
                                    xl={1}
                                    className='d-flex align-items-center justify-content-center flex-column'
                                >
                                    <p className='h3-switch-add-calendar' style={{ marginButton: '15px !important' }}>
                                        Semana
                                    </p>
                                    <SwitchStyled
                                        id='habilitado'
                                        className='switch-small'
                                        checked={selectWeekly}
                                        onClick={() => handleWeekly()}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    lg={1}
                                    xl={1}
                                    className='d-flex align-items-center justify-content-center flex-column'
                                >
                                    <p className='h3-switch-add-calendar' style={{ marginButton: '15px !important' }}>
                                        Fecha
                                    </p>
                                    <SwitchStyled
                                        id='habilitado'
                                        className='switch-small'
                                        checked={selectDate}
                                        onClick={() => handleDateMode()}
                                    />
                                </Grid>
                                {!centroControl && (
                                    <Grid item xs={6} md={4} lg={1} xl={1}>
                                        <p className='h3-switch-add-calendar' style={{ marginButton: '15px !important' }}>
                                            Prioridad
                                        </p>
                                        <SwitchStyled
                                            id='habilitado'
                                            className='switch-small'
                                            checked={priority}
                                            onClick={() => setPriority(!priority)}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container spacing={1} item xs={12} style={{ marginTop: '15px' }}>
                                <Grid item xs={12} md={12} lg={4} xl={4}>
                                    <p className='font-weight-lighter mb-0'>Hora:</p>
                                    <InputStyled
                                        disabled={sunset || sunrise}
                                        fullWidth
                                        size='small'
                                        type='time'
                                        value={time}
                                        onChange={(e) => {
                                            setTime(e)
                                        }}
                                    ></InputStyled>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    lg={2}
                                    xl={2}
                                    className='d-flex align-items-center justify-content-center flex-column'
                                >
                                    <p className='h3-switch-add-calendar' style={{ marginButton: '0px !important' }}>
                                        Amanecer
                                    </p>
                                    <SwitchStyled
                                        id='habilitado'
                                        className='switch-small'
                                        checked={sunrise}
                                        onClick={() => handleSunrise()}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    lg={2}
                                    xl={2}
                                    className='d-flex align-items-center justify-content-center flex-column'
                                >
                                    <p className='h3-switch-add-calendar' style={{ marginButton: '0px !important' }}>
                                        Atardecer
                                    </p>
                                    <SwitchStyled
                                        id='habilitado'
                                        className='switch-small'
                                        checked={sunset}
                                        onClick={() => handleSunset()}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={4} xl={4} className='d-flex align-items-center justify-content-center'>
                                    <p className='h3-switch-add-calendar' style={{ marginButton: '0px !important' }}>
                                        Minutos de desfase{' '}
                                    </p>
                                    <FormControl className='width-100' size='small'>
                                        <SelectStyled
                                            select
                                            disabled={!sunset && !sunrise}
                                            fullWidth
                                            size='small'
                                            value={offset}
                                            onChange={(e) => setOffset(e.target.value)}
                                        >
                                            {intervalo.map((numero) => (
                                                <MenuItem key={numero} value={numero}>
                                                    {numero}
                                                </MenuItem>
                                            ))}
                                        </SelectStyled>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className='d-flex justify-content-around align-items-start mt-2'>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    {!isMobile && selectWeekly && (
                                        <Grid container>
                                            <Grid item xs={5}>
                                                <p className='h3-switch-add-calendar' style={{ marginButton: '0px !important' }}>
                                                    Fecha Inicio
                                                </p>
                                                <TextField
                                                    className='modal-add-calendar-number-input'
                                                    size='small'
                                                    placeholder='Hora'
                                                    fullWidth
                                                    type='date'
                                                    value={dateIni}
                                                    format={'DD-MM-YYYY'}
                                                    onChange={(e) => handleDateIni(e.target.value)}
                                                    inputProps={{
                                                        min: getCurrentDate()
                                                    }}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <Grid item xs={1} />
                                            <Grid item xs={5}>
                                                <p className='h3-switch-add-calendar' style={{ marginButton: '0px !important' }}>
                                                    Fecha Fin
                                                </p>
                                                <TextField
                                                    className='modal-add-calendar-number-input'
                                                    size='small'
                                                    placeholder='Hora'
                                                    fullWidth
                                                    type='date'
                                                    value={dateFinal}
                                                    format={'DD-MM-YYYY'}
                                                    onChange={(e) => handleDateFinal(e.target.value)}
                                                    inputProps={{
                                                        min: getCurrentDate()
                                                    }}
                                                    variant='outlined'
                                                />
                                            </Grid>
                                            <TableContainer component={Paper} className='mt-3'>
                                                <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                                                    <TableHead style={{ borderRadius: '50px' }}>
                                                        <TableRow>
                                                            <StyledTableCellHead align='center'>Lunes</StyledTableCellHead>
                                                            <StyledTableCellHead align='center'>Martes</StyledTableCellHead>
                                                            <StyledTableCellHead align='center'>Miércoles</StyledTableCellHead>
                                                            <StyledTableCellHead align='center'>Jueves</StyledTableCellHead>
                                                            <StyledTableCellHead align='center'>Viernes</StyledTableCellHead>
                                                            <StyledTableCellHead align='center'>Sábado</StyledTableCellHead>
                                                            <StyledTableCellHead align='center'>Domingo</StyledTableCellHead>
                                                            <StyledTableCellHead align='center'>Todos</StyledTableCellHead>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <StyledTableRow>
                                                            {dataWeek.map((checkbox, index) => {
                                                                return (
                                                                    <StyledTableCell align='center'>
                                                                        <SwitchStyled
                                                                            className='switch-small'
                                                                            checked={checkbox.checked}
                                                                            onChange={() => handleCheckboxChange(index)}
                                                                        />
                                                                    </StyledTableCell>
                                                                )
                                                            })}
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    )}
                                    {!isMobile && selectDate && (
                                        <Grid Grid item xs={12}>
                                            <Grid item xs={8}>
                                                <TextField
                                                    className='modal-add-calendar-number-input'
                                                    size='small'
                                                    placeholder='Hora'
                                                    fullWidth
                                                    type='date'
                                                    value={date}
                                                    format={'DD-MM-YYYY'}
                                                    onChange={(e) => setDate(e.target.value)}
                                                    inputProps={{
                                                        min: getCurrentDate()
                                                    }}
                                                    variant='outlined'
                                                ></TextField>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {error && (
                                        <Grid Grid item xs={12} className='text-align-center mt-4 color-red'>
                                            Ya existe una programación para esta fecha con la misma prioridad
                                        </Grid>
                                    )}
                                    {errorFecha && (
                                        <Grid Grid item xs={12} className='text-align-center mt-4 color-red'>
                                            La fecha de finalización del calendario no puede ser anterior a la incial
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    )
}
