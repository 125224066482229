import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { IconEdit, IconSave, IconExit } from 'utils/icon-centrocontrol'
import './InputStyled.scss'

const InputStyled = ({
    InputProps,
    cabecera,
    pie,
    enableEdit,
    defaultValue,
    placeholder,
    title,
    type,
    enableRefresh,
    value,
    onChange,
    onSave,
    onCancelar,
    disabled,
    pattern,
    error,
    errorMessage,
    onClick,
    style,
    classname,
    maxLength,
    onBlur,
    onKeyDown,
    fullWidth,
    name,
    eventComplete
}) => {
    const [editar, setEditar] = useState(false)
    const prev = value
    const errorDefaultMessage = 'Caracteres no permitidos'

    const handleEditar = () => {
        setEditar(!editar)
    }

    const handleSave = () => {
        setEditar(!editar)
        onSave(value)
    }

    const handleCancelar = () => {
        setEditar(!editar)
        onCancelar(prev)
    }

    return (
        <div className={`${fullWidth ? '' : 'flex-center'}`}>
            {cabecera && <div style={{ marginRight: '10px' }}>{cabecera}</div>}
            <div>
                <TextField
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    title={title}
                    name={name}
                    fullWidth={fullWidth}
                    style={style}
                    className={`${classname} inputStyledPadre`}
                    disabled={(enableEdit !== undefined && enableEdit && !editar) || disabled}
                    value={value}
                    onChange={(event) => onChange(eventComplete ? event : event.target.value)}
                    inputProps={{
                        className: 'outlined-input inputStyled',
                        maxLength: maxLength ? maxLength : 100,
                        patter: pattern && pattern
                    }}
                    InputProps={InputProps}
                    pattern={pattern}
                    error={error}
                    helperText={error ? (errorMessage ? errorMessage : errorDefaultMessage) : ''}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    onClick={onClick}
                    type={type}
                    autoComplete={type === 'password' ? 'passWord' : undefined}
                />
                {enableEdit && (
                    <>
                        <ButtonStyledIcon
                            disabled={error && editar}
                            className='ml-1 boton-icon'
                            title={editar ? 'Guardar' : 'Editar'}
                            onClick={editar ? handleSave : handleEditar}
                        >
                            {editar ? <IconSave className='color-icon' /> : <IconEdit width='23' height='23' className='color-icon' />}
                        </ButtonStyledIcon>
                        {editar && (
                            <ButtonStyledIcon
                                className='ml-1 boton-icon-red'
                                title='Cancelar'
                                backgroundColor='RED'
                                onClick={handleCancelar}
                            >
                                <IconExit className='color-icon color-icon-red' />
                            </ButtonStyledIcon>
                        )}
                    </>
                )}
                {enableRefresh && (
                    <ButtonStyledIcon className='ml-1 boton-icon-red' title='Cancelar' backgroundColor='RED' onClick={handleCancelar}>
                        <IconExit className='color-icon color-icon-red' />
                    </ButtonStyledIcon>
                )}
            </div>
            {pie && <div style={{ marginLeft: '10px' }}>{pie}</div>}
        </div>
    )
}

export default InputStyled
