import { createSlice } from '@reduxjs/toolkit'

export const alarmaSlice = createSlice({
    name: 'alarma',
    initialState: {
        alarmas: {},
        history: []
    },
    reducers: {
        addAllAlarms: (state, action) => {
            let array = []
            Object?.keys(action.payload)?.forEach((key) => {
                array.push({ numserie: Number(key), alarmCount: action.payload?.[key]?.length, alarms: action.payload?.[key] })
            })
            return {
                alarmas: {},
                history: array
            }
        },
        updateAllAlarms: (state, action) => {
            let array = [...state.history]
            Object?.keys(action.payload)?.forEach((key) => {
                const numSerie = Number(key)
                const index = array.findIndex((objeto) => objeto.numserie === numSerie)
                if (index !== -1) {
                    array[index] = {
                        ...array[index],
                        alarmHide: action.payload[key].length,
                        alarmCount:
                            array[index].alarmCount - action.payload[key].length < 0
                                ? 0
                                : array[index].alarmCount - action.payload[key].length
                    }
                }
            })
            return {
                alarmas: {},
                history: array
            }
        },
        addAlarma: (state, action) => {
            const objetoEncontrado = state.history.find((objeto) => objeto.numserie === action.payload?.numserie)
            if (objetoEncontrado === undefined) {
                state.alarmas = {
                    numserie: action.payload.numserie,
                    alarmCount: action.payload?.alarmCount ? action.payload.alarmCount - action.payload.alarmHide : 0
                }
                state.history.push(state.alarmas)
            }
        },
        updateAlarmaHide: (state, action) => {
            const index = state.history.findIndex((objeto) => objeto.numserie === action.payload.numserie)
            const objetoEncontrado = state.history[index]
            state.alarmas = { numserie: objetoEncontrado.numserie, alarmCount: objetoEncontrado.alarmCount - action.payload.hide }
            state.history[index] = state.alarmas
        },
        updateAlarma: (state, action) => {
            const index = state.history.findIndex((objeto) => objeto.numserie === action.payload.numserie)
            const objetoEncontrado = state.history[index]
            if (action.payload.hide === 1) {
                state.alarmas = { numserie: objetoEncontrado.numserie, alarmCount: objetoEncontrado.alarmCount - 1 }
            } else {
                state.alarmas = { numserie: objetoEncontrado.numserie, alarmCount: objetoEncontrado.alarmCount + 1 }
            }
            state.history[index] = state.alarmas
        },
        clearAlarms: (state, action) => {
            return {
                alarmas: {},
                history: []
            }
        }
    }
})

export const { addAllAlarms, addAlarma, updateAlarma, updateAlarmaHide, updateAllAlarms, clearAlarms } = alarmaSlice.actions

export default alarmaSlice.reducer
